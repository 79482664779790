import React from 'react';

import {
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import {
// getClient,
// getClientData
} from '../../data/store/clients/clientActions';
import { PrivateRoute } from '../../PrivateRouter';
import FinancialSituationPartOne from './FinancialSituationPartOne/FinancialSituationPartOne';
import FinancialSituationPartTwo from './FinancialSituationPartTwo/FinancialSituationPartTwo';
import FinancialSituationPartThree from './FinancialSituationPartThree/FinancialSituationPartThree';

const FinancialSituation = () => {
  const { id } = useParams();
  const { url } = useRouteMatch();
  // const dispatch = useDispatch();
  // const { user } = useSelector(state => state.clientReducer);

  // useEffect(() => {
  //   // dispatch(getClient(id));
  //   // dispatch(getClientData({ id, name: 'economicSituation' }));
  // }, []);

  const PartOne = () => <FinancialSituationPartOne id={id}/>;
  const PartTwo = () => <FinancialSituationPartTwo id={id}/>;
  const PartThree = () => <FinancialSituationPartThree id={id}/>;
  return (
    <Switch>
        <PrivateRoute exact path={`${url}/page-one`} component={PartOne} />
        <PrivateRoute exact path={`${url}/page-two`} component={PartTwo} />
        <PrivateRoute exact path={`${url}/page-three`} component={PartThree} />
        <Redirect to={`/dashboard/${id}`} />
    </Switch>
  );
};

export default FinancialSituation;
