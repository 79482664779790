import { MODAL_NOT_CLOSED, SET_AUTH_LABEL } from './auxiliaryActionTypes';

const initialState = {
  authLabel: '/login',
  modal: true
};

export const auxiliaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_LABEL:
      return {
        ...state,
        authLabel: action.payload
      };

    case MODAL_NOT_CLOSED:
      return {
        ...state,
        modal: false
      };

    default: {
      return state;
    }
  }
};
