/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  assetRequest,
  deleteRequest,
  getCurrency,
  getDiversification,
  getInstrumentsAll,
  getInstrumentsTBD,
} from '../../data/store/instruments/instrumentsActions';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { ReactComponent as Linked } from '../../assets/link-icon.svg';
import MappingInstrument from './MappingInstrument';
import { numberWithSpaces } from '../../helpers/formatInteger';
import { formatPercentage2 } from '../../helpers/formatPercentage2';

import Instruments from '../Instruments/Instruments';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import './style.scss';

const InstrumentsTBD = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const instrumentsTBD = useSelector(state => state.instrumentsReducer.instrumentsTBD?.list);
  const assets = useSelector(state => state.instrumentsReducer.assets);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const { diversificationList } = useSelector((state) => state.instrumentsReducer);

  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [daleteKey, setDaleteKey] = useState(null);

  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [connectingKey, setConnectingKey] = useState(null);

  const [sorter, setSorter] = useState('byDate');
  const [sortedList, setSortedList] = useState([]);

  const [editingKey, setEditingKey] = useState(null);
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    dispatch(getInstrumentsTBD({ limit: -1, offset: 0 }));
    if (!currencyList?.length) dispatch(getCurrency());
    if (!diversificationList?.length) dispatch(getDiversification());
  }, []);

  const handleChangeSorter = (e) => {
    setSorter(e.target.value);
  };

  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    if (!currencyList?.length) dispatch(getCurrency());
    if (!diversificationList?.length) dispatch(getDiversification());
  }, []);

  const getDeletingInstrument = useMemo(() => {
    if (daleteKey) {
      return sortedList.find(i => i.ID === daleteKey);
    }
  }, [sortedList, daleteKey]);

  useEffect(() => {
    if (!instrumentsTBD?.length) {
      setSortedList([]);
    } else if (sorter && instrumentsTBD?.length) {
      let newArray = [];
      sorter === 'byName'
        ? newArray = instrumentsTBD.sort((a, b) => textSort(a.instrumentName, b.instrumentName))
        : sorter === 'byDate'
          ? newArray = instrumentsTBD.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
          : newArray = [];
      setSortedList([...newArray]);
    }
  }, [sorter, instrumentsTBD]);

  const getCurrencyById = (id) => {
    return currencyList?.find(el => el.id === id)?.name;
  };

  const hendleCancel = () => {
    dispatch(getInstrumentsTBD({ limit: -1, offset: 0 }));
    setEditingKey(null);
  };

  const onSave = (data) => () => {
    if (!notValidSave(data)) {
      const { asset_class, instrument, ISIN, fee, portfolioContentID, id, latest_value, currency_id, diversification_id, ticker } = data;
      const newData = {
        financialAssetID: assets.find(item => item.name === asset_class).ID,
        name: instrument,
        // eslint-disable-next-line no-undef
        latest_value: +latest_value,
        currency_id,
        diversification_id: +diversification_id,
        ticker: ticker?.toUpperCase() || '',
        isin: ISIN,
        fee: +fee,
      };

      dispatch(assetRequest({ newData, portfolioContentID, id, history, path: '' }));
      setEditingKey(null);
    }
  };

  const hendleEdit = (id) => {
    history.push(`/quant/asset/edit-instrument/${id}`, { key: 'requests' });
  };

  const handleConnect = (id) => {
    setConnectingKey(id);
  };
  useEffect(() => {
    setOpenConnectModal(!!connectingKey);
  }, [connectingKey]);

  const handleDelete = (key) => {
    setModalDeleteVisible(true);
    setDaleteKey(key);
  };
  const onDeleteInstrument = () => {
    dispatch(deleteRequest(daleteKey));
    handleCloseDelModal();
  };
  const handleCloseDelModal = () => {
    setDaleteKey(null);
    setModalDeleteVisible(false);
  };
  const notValidSave = (record) => {
    const { latest_value, asset_category, asset_class, instrument, ISIN } = record;
    return asset_category === 'TBD' || !Number(latest_value) ||
    !asset_class || asset_class === 'N/A' || !instrument || !ISIN || ISIN.length !== 12 || !/[A-Z]{2}[A-Z0-9]{10}/.test(ISIN);
  };

  const getdiversification = (id) => {
    return diversificationList?.find(el => el.id === id)?.short_name;
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const columns = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      className: 'name',
      sorter: (a, b) => textSort(a.instrument, b.instrument),
      width: '17%',
    },
    {
      title: t('ISIN'),
      align: 'center',
      dataIndex: 'ISIN',
      render: (value) => value || 'NOT_APPLICABLE',
      className: 'isin table-header-position-center',
      sorter: (a, b) => textSort(a.ISIN, b.ISIN),
      width: '11%',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      align: 'right',
      render: (value) => value || '-',
      sorter: (a, b) => textSort(a.ticker, b.ticker),
      width: '8%',
    },
    {
      title: t('FEE'),
      dataIndex: 'fee',
      className: 'fee table-header-position-center',
      align: 'center',
      render: (value) => <span>{formatPercentage2(value, 2)} %</span>,
      sorter: (a, b) => a.fee - b.fee,
      width: '8%',
    },
    {
      title: t('SHARE_PRICE'),
      align: 'right',
      dataIndex: 'latest_value',
      className: 'share-price table-header-position-right',
      render: (value) => numberWithSpaces((Math.round(value * 100) / 100).toFixed(2)),
      sorter: (a, b) => a.latest_value - b.latest_value,
      width: '11%',
    },
    {
      title: t('CURRENCY'),
      align: 'center',
      dataIndex: 'currency_id',
      render: (value) => getCurrencyById(value),
      sorter: (a, b) => textSort(getCurrencyById(a.currency_id), getCurrencyById(b.currency_id)),
      width: '9%',
    },
    {
      title: t('DIVERSIFICATION'),
      align: 'center',
      dataIndex: 'diversification_id',
      className: 'diversification',
      render: (value) => {
        return <span className={`diver-block 
              ${value === 28
                ? 'none'
                : value === 29
                  ? 'medi'
                  : value === 30
                    ? 'hight'
                    : ''}`
              }>
                {getdiversification(value)}
              </span>;
      },
      sorter: (a, b) => textSort(getdiversification(a.diversification_id), getdiversification(b.diversification_id)),
      width: '11%',
    },
    {
      title: t('ASSET_CLASS'),
      dataIndex: 'asset_class',
      className: 'asset-class',
      render: (value, record) => {
        return (
          <>
            <p className="holdings-assetclass-cell-asset">{value}</p>
            <p className="holdings-assetclass-cell-category">
              {record.asset_category}
            </p>
          </>
        );
      },
      sorter: (a, b) => {
        if (a.assetClass < b.assetClass) {
          return 1;
        } else if (a.assetClass > b.assetClass) {
          return -1;
        } else {
          return 0;
        }
      },
      width: '17%',
    },
    {
      title: t('ACTIONS'),
      align: 'center',
      dataIndex: 'save_instrument',
      width: '8%',
      render: (value, record) => (
        editingKey !== record.key
          ? (
        <div
          className='cell-button-wrapper'
        >
          <Tooltip
            placement="top"
            title={<span style={{ color: '#000000' }}>{t('EDIT_INSTRUMENT')}</span>} color='white'
          >
            <Button
              type='text'
              onClick={() => hendleEdit(record.id)} >
              <Edit />
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={<span style={{ color: '#000000' }}>{t('CONNECT_INSTRUMENT')}</span>} color='white'
          >
            <Button
              icon={<Linked />}
              type="text"
              onClick={() => handleConnect(record.id)}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={<span style={{ color: '#000000' }}>{t('DELETE_INSTRUMENT')}</span>} color='white'
          >
            <Button
              type="text"
              onClick={() => handleDelete(record.id)}>
              <Delete />
            </Button>
          </Tooltip>
        </div>
            )
          : (
          <div className='cell-button-wrapper'>
            <Button disabled={notValidSave(record)} onClick={onSave(record)} size='small' key="save">{t('SAVE')}</Button>
            <Button onClick={ hendleCancel} size='small' key="cancel">{t('CANCEL')}</Button>
          </div>
            )
      )
    }
  ];

  return (
    <>
      <Instruments
        instruments={sortedList}
        columns={columns}
        TBD
        handleChangeSorter={handleChangeSorter}
        sorter={sorter}
        isEditing={isEditing}
        editingKey={editingKey}
      />
      <Modal
        width={650}
        className="instrument-del-modal"
        centered
        open={modalDeleteVisible}
        onCancel={handleCloseDelModal}
        footer={false}
        zIndex={1700}
      >
        <h2 className="del-modal-header">{t('CONFIRM_DELETION')}</h2>
        <p className="del-modal-text">{t('TEXT_DELETION_REQUEST')}</p>
        <p className="del-modal-name">
        {` ${getDeletingInstrument?.instrumentName}`}
        </p>
        <Row
          gutter={16}
          justify={'center'}
          align={'middle'}
          style={{ marginTop: '50px' }}
        >
          <Col>
            <Button className="del-cancel-button" onClick={handleCloseDelModal}>
              {t('CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button className="del-ok-button" onClick={onDeleteInstrument}>
              {t('DELETE')}
            </Button>
          </Col>
        </Row>
      </Modal>
      <MappingInstrument
        openConnectModal={openConnectModal}
        setConnectingKey={setConnectingKey}
        connectingKey={connectingKey}
        data={sortedList}
      />
    </>
  );
};

export default InstrumentsTBD;
