import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Slider,
  Space,
} from 'antd';
import MainFooter from '../../components/MainFooter';

import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-icon.svg';
import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import dayjs from 'dayjs';
import Container from '../../components/Container';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import ModalFilterItemsFP from '../../components/ModalFilterItemsFP/ModalFilterItemsFP';
import TabsItemsFilter from './TabsItemsFilter/TabsItemsFilter';
import { patchFinPlan } from '../../data/store/clients/clientActions';
import ItemsListItem from './ItemsListItem';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { categoryList } from './data/itemsCategory';
import ModalDeletePlan from './ModalDeletePlan';
import { getUserByID } from '../../data/store/user/userActions';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import { Bubble } from '../../components/charts/Bubble/Bubble';

const { Header } = Layout;

const SortArrow = ({ direction }) => {
  return (
    <div className={'sortArroy'}>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'up'
              ? '#fb7b34'
              : '#00000091'
        }
      >
        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
      </svg>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'down'
              ? '#fb7b34'
              : '#00000091'
        }
      >
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    </div>
  );
};

const FinancialPlanningSummary = ({
  userId,
  finPlan,
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { finplanId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();

  const portfolioList = useSelector(
    (state) => state.portfolioReducer.portfolioList.list
  );
  const { firstName, lastName } = useSelector(
    (state) => state.userReducer.creatorFinPlan
  );

  const [selectedItemType, setSelectedItemType] = useState('expenses');
  const [openFilter, setOpenFilter] = useState(false);
  const [itemsFilter, setItemsFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortName, setSortName] = useState(null);
  const [sortAmount, setSortAmount] = useState(null);
  const [sortEnd, setSortEnd] = useState(null);

  const [selectedPlanItems, setSelectedPlanItems] = useState(null);
  const [viewType, setViewType] = useState('table'); // 'chart'

  const [filteredPlanItems, setFilteredPlanItems] = useState([]);
  const [dataSampling, setDataSampling] = useState([]);
  const [modalDeletePlan, setModalDeletePlan] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, setDelPlanId] = useState(null);
  const [modalUnsaved, setModalUnsaved] = useState(false);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalUnsaved(true);
  }, [saveAlert]);

  const filterItems = useMemo(() => {
    if (!itemsFilter) return filteredPlanItems;
    return filteredPlanItems.filter((item) => {
      for (const key in itemsFilter) {
        const { min, max } = itemsFilter[key];
        const value = item[key];

        if (min === 0 && max === 0) {
          continue;
        }
        if (min !== 0 && max === 0) {
          if (value < min) {
            return false;
          }
        }
        if (min === 0 && max !== 0) {
          if (value > max) {
            return false;
          }
        }
        if (min !== 0 && max !== 0) {
          if (value < min || value > max) {
            return false;
          }
        }
      }
      return true;
    });
  }, [filteredPlanItems, itemsFilter]);

  useEffect(() => {
    setDataSampling(filterItems);
    setSortName(null);
    setSortAmount(null);
    setSortEnd(null);
  }, [itemsFilter, filterItems]);

  const getFinPlanData = useMemo(() => {
    const plan = finPlan?.find((i) => i.id === +finplanId);
    return plan;
  }, [finplanId, finPlan]);

  const getExpensesTotal = useMemo(() => {
    if (getFinPlanData && getFinPlanData?.expenses) {
      return getFinPlanData.expenses?.reduce(
        (acc, current) => acc + current.amount,
        0
      );
    } else {
      return 0;
    }
  }, [getFinPlanData]);

  const getDistributionData = useMemo(() => {
    if (getFinPlanData && getFinPlanData?.expenses) {
      const result = getFinPlanData?.expenses?.reduce((acc, current) => {
        const existingCategory = acc.find(
          (item) => item.category === current.category
        );
        if (existingCategory) {
          existingCategory.amount += current.amount;
        } else {
          acc.push({ category: current.category, amount: current.amount });
        }
        return acc;
      }, []);
      const parsedResult = result.map((i) => ({
        ...i,
        category: categoryList.expenses.find((el) => el.value === i.category)
          ?.label,
      }));
      return parsedResult.sort((a, b) => b.amount - a.amount);
    } else {
      return null;
    }
  }, [getFinPlanData]);

  const getWidthColorArray = () => {
    const colorArray = [
      '#EE6002',
      '#6367C3',
      '#56576A',
      '#917362',
      '#B78160',
      '#F2B28C',
    ];
    const data = getDistributionData?.slice(0, 5);
    let other = [];
    if (getDistributionData?.length > 5) {
      other = getDistributionData.slice(5);
    }
    const count = data?.length;
    function calculateWidths (n, decrement = 0.1) {
      const firstBlockWidth = decrement / (1 - Math.pow(1 - decrement, n));
      const widths = [];
      for (let i = 0; i < n; i++) {
        widths.push(firstBlockWidth * Math.pow(1 - decrement, i));
      }
      return widths.map((width) => width * 100);
    }
    if (other.length) {
      const widths = calculateWidths(count + 1, 0.2);
      return {
        data,
        widths,
        colorArray,
        other: {
          category: 'OTHER',
          amount: other.reduce((acc, current) => acc + current.amount, 0),
        },
      };
    } else {
      const widths = calculateWidths(count, 0.2);
      return {
        data,
        widths,
        colorArray,
        other: null,
      };
    }
  };

  useEffect(() => {
    setSelectedPlanItems(null);
    let itemList = getFinPlanData ? getFinPlanData[selectedItemType] : [];
    if (categoryFilter !== 'all') { itemList = itemList.filter((i) => i.category === categoryFilter); }
    setFilteredPlanItems(itemList);
  }, [categoryFilter, getFinPlanData, selectedItemType]);

  useEffect(() => {
    if (getFinPlanData?.created_by) {
      dispatch(getUserByID(getFinPlanData.created_by));
    }
    return () => {
      dispatch({ type: 'CLEAR_CREATOR_FINPLAN' });
    };
  }, [getFinPlanData]);

  const handleSort = (coll) => {
    if (coll === 'name') {
      setSortAmount(null);
      setSortEnd(null);
      if (sortName !== null) {
        if (sortName) {
          setSortName(false);
        } else {
          setSortName(null);
        }
      } else {
        setSortName(true);
      }
    } else if (coll === 'amount') {
      setSortName(null);
      setSortEnd(null);
      if (sortAmount !== null) {
        if (!sortAmount) {
          setSortAmount(true);
        } else {
          setSortAmount(null);
        }
      } else {
        setSortAmount(false);
      }
    } else if (coll === 'end') {
      setSortName(null);
      setSortAmount(null);
      if (sortEnd !== null) {
        if (!sortEnd) {
          setSortEnd(true);
        } else {
          setSortEnd(null);
        }
      } else {
        setSortEnd(false);
      }
    }
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  function sortItemsTable () {
    if (sortName !== null) {
      const sortData = dataSampling
        .slice()
        .sort((a, b) => textSort(a.name, b.name, sortName ? 1 : -1));
      setDataSampling(sortData);
    }
    if (sortAmount !== null) {
      const sortData = dataSampling
        .slice()
        .sort((a, b) =>
          sortAmount ? a.amount - b.amount : b.amount - a.amount
        );
      setDataSampling(sortData);
    }
    if (sortEnd !== null) {
      const sortData = dataSampling
        .slice()
        .sort((a, b) => textSort(a.end_date, b.end_date, sortEnd ? 1 : -1));
      console.log('🚀 ~ ~ sortData:', sortData);
      setDataSampling(sortData);
    }
  }

  useEffect(() => {
    sortItemsTable();
  }, [sortName, sortAmount, sortEnd]);

  useEffect(() => {
    form.setFieldsValue(getFinPlanData);
  }, [finPlan]);

  const handleDeleteModal = () => {
    setModalDeletePlan(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleReset = () => {
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    form.resetFields();
    history.goBack();
  };

  const isNeedSave = () => {
    setNeedSave(true);
  };

  const closeModal = () => {
    setModalUnsaved(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const submitPlan = (data) => {
    dispatch(
      patchFinPlan({ history, clientId: userId, finplanId, body: { ...data } })
    );
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  const handleChangeItemType = (event) => {
    const { value } = event.target;
    setCategoryFilter('all');
    setSelectedItemType(value);
    setItemsFilter(null);
  };

  const handleViewType = () => {
    setViewType(viewType === 'chart' ? 'table' : 'chart');
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper">
            <Button type="link" className="overview-btn ant-btn-active">
              {t('LIFETIMING')}
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="finplan-summary-container">
        <div>
          <div className="top-btn-wrapper">
            <Button
              // disabled={!needSave}
              className="portfolio-details-btn portfolio-export-btn"
              onClick={handleReset}
            >
              {t('CANCEL')}
            </Button>
            <Button
              disabled={!needSave}
              onClick={handleSubmit}
              className="portfolio-details-btn btn-edit-save"
            >
              {t('SAVE')}
            </Button>
          </div>
          <Row className="portfolio-details-wrapper">
            <Col span={18}>
              <h2 className="portfolio-details-title">
                {t('FINANCIAL_PLAN_DETAILS')}
              </h2>
            </Col>
            <Col span={6} className="button-wrap">
              <Button
                type="text"
                icon={<Delete />}
                iconPosition="end"
                className="portfolio-delete-btn"
                onClick={handleDeleteModal}
              >
                {t('DELETE_FINANCIAL_PLAN')}
              </Button>
            </Col>
          </Row>
          <div className="plan-wrap">
            <div className="finplan-info">
              <Row justify={'space-between'} style={{ paddingBottom: '16px' }}>
                <Col className="finplan-name">{getFinPlanData?.name}</Col>
                <Col className="finplan-end-date-title">{t('END_DATE')}</Col>
              </Row>
              <Row justify={'space-between'} style={{ paddingBottom: '8px' }}>
                <Col className="finplan-amuont">
                  {twoDecimalWithSpaces(getExpensesTotal)}
                </Col>
                <Col className="finplan-end-date">
                  {dayjs()
                    .add(getFinPlanData?.time_horizon, 'year')
                    .format('DD MMM YYYY')}
                </Col>
              </Row>
              <Row justify={'space-between'}>
                <Col>
                  <span className="finplan-achived-persent">60%</span>
                  <span className="finplan-achived-text">achived</span>
                </Col>
                <Col className="finplan-by-any">{`by ${firstName || '--'} ${
                  lastName || '--'
                }`}</Col>
              </Row>
              <Divider className="finplan-divider" />
              <h2 className="finplan-info-header">{t('DISTRIBUTION_NEW')}</h2>
              <div className="expenses-parts-wrapper">
                {getWidthColorArray()?.data?.map((i, index) => {
                  return (
                    <div
                      key={i.category}
                      style={{
                        width: `${getWidthColorArray().widths[index]}%`,
                      }}
                      className={
                        index === 0
                          ? 'expenses-parts-card first'
                          : 'expenses-parts-card'
                      }
                    >
                      <div className="expenses-parts-card-dot" />
                      <p className="expenses-parts-card-amount">
                        {twoDecimalWithSpaces(i.amount)}kr
                      </p>
                      <p className="expenses-parts-card-category">
                        {t(i.category)}
                      </p>
                      <div
                        style={{
                          height: '24px',
                          backgroundColor: `${
                            getWidthColorArray().colorArray[index]
                          }`,
                        }}
                      />
                    </div>
                  );
                })}
                {getWidthColorArray()?.other && (
                  <div
                    style={{ width: `${getWidthColorArray().widths[5]}%` }}
                    className={'expenses-parts-card'}
                  >
                    <div className="expenses-parts-card-dot" />
                    <p className="expenses-parts-card-amount">
                      {twoDecimalWithSpaces(
                        getWidthColorArray()?.other?.amount
                      )}
                      kr
                    </p>
                    <p className="expenses-parts-card-category">
                      {t(getWidthColorArray()?.other?.category)}
                    </p>
                    <div
                      style={{
                        height: '24px',
                        backgroundColor: `${
                          getWidthColorArray().colorArray[5]
                        }`,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <Row style={{ margin: '24px 0' }}>
              <Col>
                <h2 className="portfolio-details-title">
                  {t('MAIN_INFORMATION')}
                </h2>
              </Col>
            </Row>
            <Form
              form={form}
              name="client-profile"
              layout="vertical"
              onFinish={submitPlan}
              onValuesChange={isNeedSave}
            >
              <Container widthP={100} padding="19px 40px" mb="24px">
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{
                    maxWidth: '1128px',
                    marginBottom: '0',
                    padding: '0',
                  }}
                >
                  <Col className="finplan-info-title">
                    {t('FINANCIAL_PLAN_NAME')}
                  </Col>
                  <Col>
                    <Form.Item
                      className="custom-required"
                      placeholder={t('FINANCIAL_PLAN_NAME')}
                      name="name"
                      required={false}
                    >
                      <Input autoComplete="off" className="custom-input" />
                    </Form.Item>
                  </Col>
                </Row>
              </Container>
              <Container widthP={100} padding="19px 40px" mb="24px">
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{
                    maxWidth: '1128px',
                    marginBottom: '0',
                    padding: '0',
                  }}
                >
                  <Col className="finplan-info-title">
                    {t('ASSIGNED_TO_PORTFOLIO')}
                  </Col>
                  <Col style={{ width: '360px' }}>
                    <Form.Item
                      className="custom-required"
                      placeholder={t('ASSIGNED_TO_PORTFOLIO')}
                      name="portfolio_ids"
                      required={false}
                    >
                      <CustomSelect
                        width={'100%'}
                        className="custom-select"
                        mode={'multiple'}
                        maxTagCount="responsive"
                        maxTagTextLength={12}
                        placeholder={t('SELECT_PORTFOLIOS')}
                      >
                        {portfolioList?.map((i) => {
                          return (
                            <Select.Option key={i.ID} value={i.ID}>
                              {i?.name}
                            </Select.Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </Container>
              <Container widthP={100} padding="19px 40px" mb="24px">
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{
                    maxWidth: '1128px',
                    marginBottom: '0',
                    padding: '0',
                  }}
                >
                  <Col className="finplan-info-title">
                    {t('TIME_HORIZON_YEARS')}
                  </Col>
                  <Col style={{ minWidth: '360px' }}>
                    <div className="range-wrap">
                      <p className="range-min-max">20</p>
                      <Form.Item
                        className="custom-required plan-slider-item"
                        name="time_horizon"
                        required={false}
                      >
                        <Slider
                          classNames={{
                            rail: 'plan-slider-rail',
                            handle: 'plan-slider-handle',
                            track: 'plan-slider-track',
                          }}
                          min={20}
                          max={100}
                          tooltip={{
                            formatter: (value) => `${value} ${t('YEARS')}`,
                          }}
                        />
                      </Form.Item>
                      <p className="range-min-max">100</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
            <Row justify={'space-between'} style={{ marginBottom: '24px' }}>
              <Col>
                <h2 className="portfolio-details-title">
                  {t('FINANCIAL_PLAN_DETAILS')}
                </h2>
              </Col>
              <Col>
                <Button
                  type="text"
                  className="finplan-item-add-btn"
                  onClick={() =>
                    history.push(
                      `/clients/${userId}/lifetiming/${finplanId}/items/new?category=expenses`
                    )
                  }
                  icon={<Pluse />}
                  iconPosition={'end'}
                >
                  {t('ADD_ITEM')}
                </Button>
              </Col>
            </Row>
            <Container widthP={100} mb="47px" padding="48px 24px 25px 24px">
              <Row justify={'space-between'}>
                <Col>
                  <Radio.Group
                    className="portfolio-form-radio"
                    value={selectedItemType}
                    onChange={handleChangeItemType}
                  >
                    <Space direction="horizontal">
                      <CustomRadioButtom value="expenses">
                        {t('EXPENSES')}
                      </CustomRadioButtom>
                      <CustomRadioButtom value="income">
                        {t('INCOME')}
                      </CustomRadioButtom>
                    </Space>
                  </Radio.Group>
                </Col>
                <Col className="view-btn-wrapper">
                  <Button
                    className="filter-btn"
                    type="default"
                    onClick={handleViewType}
                  >
                    {t(viewType === 'chart' ? 'VIEW_AS_LIST' : 'VIEW_AS_CHART')}
                  </Button>
                  <Button
                    className="filter-btn"
                    type="default"
                    onClick={() => setOpenFilter(true)}
                    icon={<Filter />}
                    iconPosition="end"
                  >
                    {t('FILTER')}
                  </Button>
                </Col>
              </Row>
              <TabsItemsFilter
                currentFinplan={getFinPlanData}
                category={selectedItemType}
                count={6}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
              />
              {viewType === 'table' &&
                <Row wrap={false} className="finplan-items-header">
                <Col className="finplan-items-name">
                  {t('NAME')}
                  <div onClick={() => handleSort('name')}>
                    <SortArrow
                      direction={
                        sortName ? 'up' : sortName !== null ? 'down' : ''
                      }
                    />
                  </div>
                </Col>
                <Col className="finplan-items-amount">
                  {t('AMOUNT')}
                  <div onClick={() => handleSort('amount')}>
                    <SortArrow
                      direction={
                        sortAmount ? 'up' : sortAmount !== null ? 'down' : ''
                      }
                    />
                  </div>
                </Col>
                <Col className="finplan-items-start">
                  {t('START')}
                  <div onClick={() => handleSort('start')}>
                    <SortArrow
                      direction={
                        sortEnd ? 'up' : sortEnd !== null ? 'down' : ''
                      }
                    />
                  </div>
                </Col>
                <Col className="finplan-items-end">
                  {t('END')}
                  <div onClick={() => handleSort('end')}>
                    <SortArrow
                      direction={
                        sortEnd ? 'up' : sortEnd !== null ? 'down' : ''
                      }
                    />
                  </div>
                </Col>
              </Row>}
              {viewType === 'table'
                ? dataSampling?.map((item) => {
                  return (
                  <ItemsListItem
                    key={item.id}
                    userId={userId}
                    finplanId={finplanId}
                    type={selectedItemType}
                    item={item}
                    selected={item.id === selectedPlanItems}
                    setSelectedPlanItems={setSelectedPlanItems}
                    getFinPlanData={getFinPlanData}
                  />
                  );
                })
                : dataSampling?.length
                  ? (
                    <Bubble data={dataSampling} />
                    )
                  : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              }
            </Container>
          </div>
        </div>
        <MainFooter />
      </div>
      <ModalFilterItemsFP
        itemsFilter={itemsFilter}
        setItemsFilter={setItemsFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
        selectedItemType={selectedItemType}
      />
      <ModalDeletePlan
        userId={userId}
        modalDeletePlan={modalDeletePlan}
        setModalDeletePlan={setModalDeletePlan}
        delPlanId={+finplanId}
        setDelPlanId={setDelPlanId}
        redirect={true}
      />
      <ModalUnsaveData
        open={modalUnsaved}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default FinancialPlanningSummary;

FinancialPlanningSummary.propTypes = {
  userId: PropTypes.string,
  finPlan: PropTypes.array,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};

SortArrow.propTypes = {
  direction: PropTypes.string,
};
