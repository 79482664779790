import React, { useCallback, useState, useEffect } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotPassword } from '../../../data/store/user/userActions';
import { LOGIN, SIGN_UP } from '../../../constants/routes';
import { emailRules } from '../../../helpers/validation';
import { Button, Divider, Form, Input, Row } from 'antd';
import { NavLink as Nav } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

const ForgotPasswordForm = ({ setStep, setMail }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const onSubmit = useCallback((fields) => {
    console.log('🚀 ~ onSubmit ~ fields:', fields);
    dispatch(forgotPassword(fields));
    setStep(true);
    setMail(fields?.email);
  }, [dispatch]);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Row className='sign-up-wrapper reset-pass-input'>
      <Form
        form={form}
        name="forgot-password-form"
        layout="vertical"
        onFinish={onSubmit}
        className='forgot-password-form'>
        <h2 className='pass-setup-title'>{t('FORGOT_PASSWORD')}?</h2>
        <div className='sign-up-wrapper reset-pass-input'>
          <Form.Item
            className='custom-required'
            label={t('EMAIL')}
            name="email"
            rules={emailRules}
          >
            <Input
              placeholder={t('EMAIL')}
              autocomplete='off'
              className='custom-required custom-input'
            />
          </Form.Item>
        </div>

        <Form.Item shouldUpdate>
          {() => (
            <Row className='login-submit-btn-wrapper'>
            <Button
              onClick={() => form.submit()}
              block
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              {t('SEND_EMAIL')}
            </Button>

            </Row>
          )}
        </Form.Item>

        <Nav to={LOGIN}>
          <p className='reset-pawss-back'>{t('BACK_TO_LOGIN')}
            <Arrow className='terms-link'/>
          </p>
        </Nav>
        <Divider className='divider-password'/>

        <p className='reset-pawss-back reset-pawss-sing-wrapp'>
          {t('DONT_HAVE_AN_ACCOUNT')}
          <a href={SIGN_UP} className='reset-pawss-signup'>{t('SIGN_UP_NOW')}</a>
          <Arrow className='terms-link'/>
        </p>
      </Form>
    </Row>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  setStep: PropTypes.func,
  setMail: PropTypes.func,
};
