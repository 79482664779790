/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import PropTypes from 'prop-types';

import { Button, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import axios from 'axios';

const ModalPreview = ({modalDownloadShow, modalDownloadHide }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);

  // const baseURL = process.env.REACT_APP_BASE_URL;
  // const imagePath = `${baseURL}/v1/financialplan/image/${item.image}`;

  useEffect(() => {
    if (modalDownloadShow?.key) {
      axios.get(`/v2/document/${modalDownloadShow.id}`, {responseType: 'blob'})
        .then((response) => {
          if (!response.status === 200) {
            throw new Error('Network response was not ok');
          }
          return response.data;
        })
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          setImage(objectURL);
        })
        .catch((error) => {
          console.error(
            'There was a problem with your fetch operation:',
            error,
          );
        });
    }
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [modalDownloadShow]);

  const handleDownload = () => {
    console.log('handleDownload');
  };

  return (
    <Modal
        destroyOnClose
        width={modalDownloadShow?.extension === '.pdf' ? '80%' : '650px'}
        // height={'80vh'}
        className="modal-content-paper documents-preview"
        open={modalDownloadShow}
        onCancel={modalDownloadHide}
        footer={null}
        zIndex={1600}>
        <Container widthP={100} >
        
          {modalDownloadShow?.extension === '.pdf'
            ?   image && (
                  <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'}>
                    <Viewer fileUrl={image} />
                  </Worker>
                )

            : <img src={image} alt='Document' className='upload-document-img' />
            }
        </Container>
        {/* <Row className="modal-advisor-btn-wrapper">
          <Button className="modal-action-btn " onClick={handleDownload}>
            {t('DOWNLOAD')}
          </Button>
        </Row> */}
      </Modal>
  );
};

export default ModalPreview;

ModalPreview.propTypes = {
  modalDownloadShow: PropTypes.object,
  modalDownloadHide: PropTypes.func,
  token: PropTypes.string,
};