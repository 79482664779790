export const LOGIN = '/login';
export const SIGN_UP = '/sign-up';
export const TWO_FACTOR = '/two-factor-auth';
export const SET_USER_PASSWORD = '/auth/set-password/:invitation';
export const CONFIRM_EMAIL = '/confirm-email/:hash';
export const SIGN_UP_CONFIRM = '/auth/verify-email';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_VERIFY = '/auth/reset-password-verify-code';

export const CLIENTS = '/clients';
export const CLIENT_PORTFOLIO = '/clients/:id';
export const PROFILE = '/settings';
export const INFORMATION = '/information';
export const SUPPORT = '/support';

export const CREATE_ACCOUNT = '/create-account';

export const PORTFOLIO = '/clients/:id/portfolio';
export const HISTORY = '/clients/:id/portfolio/:portfolioId/history';
// export const PORTFOLIO_OPTIMIZATION = '/clients/:id/portfolio/:portfolioId/optimization';
export const CREATE_PORTFOLIO = '/clients/:id/create-portfolio';
export const REPORT = '/clients/:id/report';
export const MY_PROFILE = '/clients/:id/inform/profile';
export const PORTFOLIO_OPTIMIZATION = '/clients/:id/optimization';

export const ADMIN_TOOLS = '/admin-tools';
export const INSTRUMENTS = '/instruments';
export const INSTRUMENTS_TBD = '/instruments/tbd';
export const ADVISORS = '/advisors';
export const APPROVE_ADVISORS = '/advisors/approve';

export const ONBOARDING = '/onboarding/:id/:isEdit';
export const DASHBOARD = '/dashboard/:id';

export const MY_DETAILS = '/my-details/:id';
export const TEST = '/test/:id';
export const INCOME_AND_EXPENSES = '/Income-and-expenses/:id';
export const FINANCIAL_SITUATION = '/financial-situation/:id';
export const RISK_PROFILE = '/risk-profile/:id';
export const FIXED_ASSETS = '/fixed-assets/:id';
export const SCOPE_AND_INVESTABLE_CAPITAL = '/scope-and-investable-capital/:id';
export const FINANCE_PLANERING = '/finance-planering/:id';
export const LEGAL_PLANNING = '/legal-needs-planning/:id';
export const CUSTOMER_KNOWLEDGE = '/customer-knowledge/:id';
export const EXPERIENCE_BACKGROUND = '/experience-background/:id';
export const TERMS = '/terms-conditions-agreement/:id';
