/* eslint-disable camelcase */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Button, Form, Progress, Table, Tooltip } from 'antd';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import EditableCell from './EditableCell';
import { patchFinPlan } from '../../data/store/clients/clientActions';
import isEqual from 'lodash/isEqual';

import './styles.scss';
import ModalDeletePlan from './ModalDeletePlan';

export const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const FinPlanTable = ({ userId, finPlanList, portfolioList }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [tableColumns, setTableColumns] = useState();
  const [modalDeletePlan, setModalDeletePlan] = useState(false);
  const [delPlanId, setDelPlanId] = useState(null);

  const levelBarRace = (value) => {
    return (<div className='finplan-barrace'>
        <p className='race-value'>{value}%</p>
        <Progress percent={value} showInfo={false} trailColor='#E6E6E6' strokeColor='#0F0F0F'/>
      </div>);
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const itemCount = (record) => {
    const { income, expenses } = record;
    const countIncome = income?.length || 0;
    const countExpenses = expenses?.length || 0;
    return countIncome + countExpenses;
  };

  const getFinPlanById = (id) => {
    return finPlanList.find(i => i.id === id)?.portfolio_ids;
  };

  useEffect(() => {
    if (finPlanList?.length) {
      const defaultColumns = [
        {
          title: t('NAME'),
          dataIndex: 'name',
          className: 'fin-plan-name',
          width: '18.25%',
          sorter: (a, b) => textSort(a.name, b.name),
        },
        {
          title: t('ASSIGNED_TO_PORTFOLIO'),
          editable: true,
          dataIndex: 'portfolio_ids',
          className: 'fin-plan-assigned',
          width: '26.25%',
          align: 'left',
        },
        {
          title: t('NOF_ITEMS'),
          dataIndex: 'count',
          className: 'fin-plan-count',
          width: '10%',
          align: 'center',
          render: (value, record) => itemCount(record),
          sorter: (a, b) => itemCount(a) - itemCount(b),
        },
        {
          title: t('TIME_HORIZON'),
          dataIndex: 'time_horizon',
          className: 'fin-plan-time-horizon',
          width: '10%',
          align: 'center',
          render: (value) => `${value} ${t('YEARS')}`,
          sorter: (a, b) => a.time_horizon - b.time_horizonw,
        },
        {
          title: t('ACHIEVEMENT_LEVEL'),
          dataIndex: 'level',
          className: 'fin-plan-level',
          width: '19.5%',
          align: 'left',
          render: () => {
            const value = Math.floor(Math.random() * 100);
            return levelBarRace(value);
          },
          sorter: (a, b) => a.value - b.value,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: t('ACTIONS'),
          align: 'center',
          dataIndex: 'operation',
          width: '15%',
          render: (_, record) => {
            return (
            <div className="cell-button-wrapper">
              <Button
                type='text'
                onClick={() => hendleEdit(record.id)} >
                <Edit />
              </Button>
              <Button
                type='text'
                onClick={() => handleDelete(record.id)}>
                <Delete />
              </Button>
              </div>
            );
          },
        },
      ];
      setTableColumns(defaultColumns);
    }
  }, [finPlanList]);

  const handleChangeSelect = ({ id, portfolio_ids }) => {
    console.log('🚀 ~ handleChangeSelect ~ !isEqual():', !isEqual(getFinPlanById(id), portfolio_ids));
    if (!isEqual(getFinPlanById(id), portfolio_ids)) {
      dispatch(patchFinPlan({ history, clientId: userId, finplanId: id, body: { portfolio_ids } }));
    }
  };

  const columns = tableColumns?.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        portfolioList,
        handleChangeSelect,
      }),
    };
  });

  const hendleEdit = (id) => {
    history.push(`${url}/${id}`);
  };

  const handleDelete = (finplanId) => {
    setDelPlanId(finplanId);
    setModalDeletePlan(true);
  };

  return (
    <div className="finplan-table">
      <Table
        rowKey={(record) => record.id}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'holdings-table-row' : 'holdings-table-row is-odd'
        }
        columns={columns}
        components={components}
        dataSource={finPlanList}
        size="middle"
        bordered
        pagination={false}
      />
      <ModalDeletePlan
        userId={userId}
        modalDeletePlan={modalDeletePlan}
        setModalDeletePlan={setModalDeletePlan}
        delPlanId={delPlanId}
        setDelPlanId={setDelPlanId}
      />
    </div>
  );
};

FinPlanTable.propTypes = {
  userId: PropTypes.string,
  finPlanList: PropTypes.array,
  portfolioList: PropTypes.array,
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
