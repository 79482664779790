import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FORGOT_PASSWORD,
  LOGIN,
  SIGN_UP,
  SIGN_UP_CONFIRM,
  SET_USER_PASSWORD,
  CONFIRM_EMAIL,
  TWO_FACTOR,
} from '../../constants/routes';
import ForgotPassword from '../../components/ForgotPassword';
import SignUp from '../../components/SignUp';
import { Layout } from 'antd';
// import { useTranslation } from 'react-i18next';
import SignUpConfirmSuccess from '../../components/SignUp/SignUpConfirmSuccess';
import ResetPassword from '../../components/ResetPassword';
import ErrorNotification from '../../components/ErrorNotification';
import SetUserPassword from '../../components/SetUserPassword';
import ConfirmEmail from '../../components/ConfirmEmail/ConfirmEmail';
import TwoFactorPage from '../TwoFactorPage/TwoFactorPage';

import './style.scss';
import LoginPage from '../LoginPage/LoginPage';

const { Content } = Layout;

const AuthRouting = () => {
  // const { t } = useTranslation('');

  return (
    <Layout className="layout-auth">
      <Content>
        <Switch>
          <Route path={LOGIN} component={LoginPage} />
          <Route exact path={SIGN_UP} component={SignUp} />
          {/* Reset Password for Advisor */}
          <Route path={SET_USER_PASSWORD} component={SetUserPassword} />
          <Route
            path="/auth/reset-password-verify-code"
            component={ResetPassword}/>
          <Route exact path={CONFIRM_EMAIL} component={ConfirmEmail} />
          <Route
            exact
            path={SIGN_UP_CONFIRM}
            component={SignUpConfirmSuccess}
          />
          <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact path={TWO_FACTOR} component={TwoFactorPage} />
          <Route path="*">
            <Redirect to={LOGIN} />
          </Route>
        </Switch>
      </Content>
      <ErrorNotification />
    </Layout>
  );
};

export default AuthRouting;
