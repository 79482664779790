/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

const ModalViewPP = ({ viewReport, setViewReport }) => {
  const { t } = useTranslation('');
  const baseURI = 'https://view.officeapps.live.com/op/embed.aspx?src=';

  return (
    <Modal
      width={'90%'}
      height={'90vh'}
      title={t('PORTFOLIOS_REPORT')}
      centered
      open={viewReport}
      onOk={() => setViewReport('')}
      onCancel={() => setViewReport('')}
      footer={false}
      destroyOnClose={true}
    >
      <iframe
        src={`${baseURI}${viewReport}`}
        style={{ width: '100%', height: '80vh' }}
      ></iframe>
    </Modal>
  );
};

export default ModalViewPP;

ModalViewPP.propTypes = {
  viewReport: PropTypes.string,
  setViewReport: PropTypes.func,
};
