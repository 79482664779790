import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as Delete } from '../../../../assets/close-round-icon.svg';
import { useSelector } from 'react-redux';

const CardSavedItem = ({ t, item, handleDelete }) => {
  const instruments = useSelector(state => state.instrumentsReducer.instruments.list);

  const getName = (id) => {
    return instruments.find(i => i.ID === id).name;
  };
  return (
    <div className='recommended-card-wrapper'>
      <p className='recommended-card-title'>{getName(item.instrument_id)}</p>
      <p className='recommended-card-charge'>{`${t('CHARGE')} ${item.charge}%`}</p>
      <p className='recommended-card-amount'>{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} SEK</p>
      <div className='recommended-card-delete' onClick={() => handleDelete(item.instrument_id)}><Delete /></div>
    </div>
  );
};

export default CardSavedItem;

CardSavedItem.propTypes = {
  t: PropTypes.func,
  handleDelete: PropTypes.func,
  item: PropTypes.object,
};
