/*eslint-disable*/
import axios from 'axios';
// import { BASE_URL } from '../constants/api_urls';
import StorageService from '../services/StorageService';
import {useDispatch} from 'react-redux';
import {logOut} from '../data/store/user/userActions';
import store from '../data/store/configureStore';
import SessionService from '../services/SessionService';

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
});
axios.interceptors.request.use(config => {
  const token = StorageService.getJWTToken() || SessionService.getJWTToken();
  config.headers['Authorization'] = 'Bearer ' + token;
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.baseURL = process.env.REACT_APP_BASE_URL;
  return config;
})

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const { dispatch } = store;
  if(error.response.status === 403 || error.response.status === 401) {
    dispatch(logOut());
  }
  return error;
})

export {axios}
