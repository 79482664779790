import React from 'react';

const Monitoring = () => {
  return (
    <>
     <div style={{ marginTop: '100px' }}>Monitoring</div>
    </>
  );
};

export default Monitoring;
