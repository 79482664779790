import React from 'react';
import PropTypes from 'prop-types';
// import gearLoading from './gearLoading.svg';
import gearLoading from './QuantOptimizer.png';

const GearLoading = ({ children, loading }) => {
  return (
        <div className={`loading-wrapper ${loading ? 'gear' : ''}`}>
          {loading
            ? (
              <img className='loader-gear' src={gearLoading} />
              )
            : children }
        </div>
  );
};

export default GearLoading;

GearLoading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
};
