export const actionCreator = (action) => {
  return (
    {
      ACTION: action,
      REQUEST: `${action}_REQUEST`,
      SUCCESS: `${action}_SUCCESS`,
      ERROR: `${action}_ERROR`
    }
  );
};
