export const PortfolioSW = {
  EDIT: 'Redigera',
  HISTORY: 'Historia',
  EXPORT: 'Exportera',
  EXPORT_PORT: 'Exportera',
  DRAFT_PORTFOLIO: 'Utkast till portfölj',
  CURRENT_PORTFOLIO: 'Nuvarande portfölj',
  DELETE: 'Radera',
  LAUNCH_CITRONEER_QUANT: 'Starta Citroneer Quant',
  OPTIMIZE: 'Optimera',
  PORTFOLIO: 'Portfölj',
  PORTFOLIOS: 'Portföljer',
  REPORT: 'Rapporter',
  CLIENTS_LABEL: 'KUND',
  CATEGORY: 'Kategori',
  ASSET_CLASS: 'Tillgångsslag',
  INSTRUMENT: 'Instrument',
  ISIN: 'ISIN',
  VALUE: 'Värde',
  FEE: 'Avgift',
  ASSET_ALLOCATION: 'Tillgångsallokering i procent',
  CANCEL: 'Avbryt',
  ADD: 'Lägg till',
  IMPORT_FROM_EXCEL: 'Importera från Excel',
  SAVE: 'Spara',
  OPERATION: 'Drift',
  UNITS: 'Antal',
  SHARE_PRICE: 'Aktiekurs',
  CREATE_PORTFOLIO: 'Skapa portfölj',
  EDIT_DRAFT_PORTFOLIO: 'Redigera utkast till portfölj',
  NO_PORTFOLIO: 'Ingen portfölj',
  NO_PORTFOLIO_TEXT:
    'Det verkar som om du inte har någon portfölj, redigera ditt utkast av portföljen eller skapa en ny',
  ADD_INSTRUMENT: 'Lägg till instrument',
  EDIT_INSTRUMENTS: 'Redigera instrument',
  ALL_ADVISORS: 'Alla rådgivare',
  ACTIVATE_ADVISOR: 'Aktivera rådgivare',
  CREATE_ADVISOR: 'Skapa rådgivare',
  INVITE_ADVISOR: 'Bjud in rådgivare',
  ADVISOR_LIST: 'Rådgivarlista',
  APPROVE: 'Godkänn',
  HISTORY_EMPTY: 'Historiken är tom',
  GO_PORTFOLIO: 'Gå portfölj',
  UPLOAD_REPORT: 'Ladda upp rapport',
  DOWNLOAD: 'Ladda ner',
  REPORTS: 'Rapporter',
  CHOOSE_FILE: 'Välj FIL',
  NO_FILE_CHOSEN: 'Ingen fil vald',
  UPLOAD: 'Ladda upp',
  ASSET_CATEGORY: 'Tillgångskategori',
  NAME: 'Namn',
  SYS_NAME: 'Sys namn',
  CREATE: 'Skapa',
  INVITE: 'Bjud in',
  CREATE_INSTRUMENT: 'Skapa instrument',
  ENTER_REPORT_NAME: 'Ange rapportnamn',
  TBD_TOOLTIP_TEXT:
    'Detta instrument är för närvarande inte kartlagt.' +
    ' Citroner-teamet har meddelats och kommer att mappa instrumentet så ' +
    'snart som möjligt. ' +
    'När det gäller vet kan portföljen bara sparas som ett utkast men när' +
    ' instrumentet kartläggs kommer portföljen automatiskt att verifieras.',
  INSTRUMENTS_REQUESTS: 'Instrument begäranden',
  SENT_SUCCESSFULLY: 'Portföljen skickades',
  REQUEST_FOR_OPTIMIZATION:
    'Begäran om optimering har redan skickats till Admin',
  TOTAL_PORTFOLIO_VALUE: 'Totalt Portfölj Värde',
  TOTAL: 'Totalt',
  CREATED: 'Skapad',
  LAST_MODIFIED: 'Senast ändrad',
  REPLACE_DRAFT: 'Byt utkast',
  REPLACE_CURRENT: 'Byt ut nuvarande',
  REPLACE_DRAFT_TEXT: 'Vill du verkligen byta utkast till portföljen?',
  REPLACE_CURRENT_TEXT: 'Vill du verkligen ersätta den nuvarande portföljen?',
  IMPORT: 'Importera',
  SYS_NAME_UPPER: 'SYS_NAMN',
  PORTFOLIO_INFORMATION: 'Portföljinformation',
  CONTINUE: 'Fortsätta',
  ENTER_THE_NAME: 'Ange namnet',
  AVAILABLE_CASH: 'Tillgängliga kontanter',
  TAX_RATE: 'Skattenivå',
  DEPOSIT_FEE: 'Depositionsavgift',
  ADVISORY_FEE: 'Rådgivningsavgift',
  CAPITAL_GAIN: 'Kapitalvinst',
  FLAT_RATE: 'Fast pris',
  CHOOSE_METHOD_TAXATION: 'Välj en beskattningsmetod',
  TAXATION: 'Beskattning',
  LISTED_HOLDINGS: 'Börsnoterade Innehav',
  DELETE_PORTFOLIO: 'Ta bort portfölj',
  CREATE_NEW_PORTFOLIO: 'Skapa ny portfölj',
  PORTFOLIO_NUMBER: 'Portföljnummer',
  ENTER_PORTFOLIO_ID_FROM_FA: 'Ange portfölj-ID från FA',
  EXPORT_PORTFOLIO: 'Exportportfölj',
  EXPORT_PORTFOLIOS: 'Exportportföljer',
  EXPORT_PORTFOLIO_PROMT: 'Exportera portföljen till Excel:',
  EXPORT_PORTFOLIOS_PROMT: 'Exportera portföljerna till Excel:',
  CHOOSE_PORTFOLIOS: 'Välj portföljer',
  CHOOSE_PORTFOLIO: 'Välj portfölj',
  SELECT_PORTFOLIO: 'Välj Portfölj *',
  SELECT_PORTFOLIOS: 'Välj Portföljer',
  CHOOSE_CANDIDATE: 'Välj kandidat',
  ADD_ADDITIONAL_STEPS: 'Lägg till ytterligare steg',
  MIN_MAX_CONSTRAINTS: 'Min/Max begränsningar',
  PORTFOLIO_CONSTRAINTS: 'Portföljbegränsningar',
  CURRENCY: 'Valuta',
  INSTRUMENTS_TAB: 'Instrument',
  HOLDINGS: 'Innehav',
  UNLISTED_HOLDINGS: 'Onoterade Innehav',
  EDIT_PORTFOLIO: 'Redigera portfölj',
  EXPORT_PORTFOLIO_TO: 'Exportera till Excel portfölj',
  MINMAX: 'Min/Max',
  WITH_IMPORT_INSTRUMENT: 'Importera portfölj från Excel',
  DOWNLOAD_THE_TEMPLATE_EXCEL: 'Ladda ner mallen i Excel-format',
  FAILED_TO_IMPORT: 'Det gick inte att importera',
  CONFIRM_DELETION: 'Bekräfta radering',
  TEXT_DELETION: 'Är du säker på att du vill ta bort portföljen',
  CLIENT_DELETION: 'Är du säker på att du vill ta bort klienten::',
  TRANSACTIONS: 'Transaktioner',
  BUY_TRANSACTIONS: 'Köptransaktioner',
  SELL_TRANSACTIONS: 'Säljtransaktioner',
  TICKER: 'Ticker',
  OPTIMIZED_PORTFOLIO: 'Optimerad portfölj',
  CREATE_REPORT: 'Skapa rapport',
  REPORT_TEXT: 'Vill du skapa en rapport för denna portföljoptimering?',
  FINISH: 'Avsluta',
  ALLOCATION: 'Allokering',
  GO_BACKWARD: 'Gå bakåt',
  GO_FORWARD: 'Gå framåt',
  TEXT_DELETION_INSTRUMENT: 'Är du säker på att du vill ta bort instrumentet:',
  TEXT_DELETION_REQUEST: 'Är du säker på att du vill ta bort begäran:',
  CONNECT: 'Sammankoppla',
  CONNECT_INSTRUMENT: 'Sammankoppla instrument',
  MAP_INSTRUMENT: 'Välj det instrument du vill mappa denna begäran till:',
  SELECT_INSTRUMENT: 'Välj instrument',
  SELECT_INSTRUMENT_FOR: 'Välj instrument för mappning',
  TOTAL_FEE: 'Total avgift',
  ACTIVATE_SYNCHRONIZATION: 'Aktiv synkronisering',
  LAST_SYNC: 'Senaste lyckade synkroniseringen',
  PORTFOLIO_TYPE: 'Portföljtyp (Optimering)',
  CHOOSE_PORTFOLIO_TYPE: 'Välj Portföljtyp',
  PORTFOLIO_TYPE_ONE: 'Quant',
  PORTFOLIO_TYPE_TWO: 'Quant ESG',
  PORTFOLIO_TYPE_THREE: '4-Fond',
  OVERVIEW: 'Översikt',
  ORDER_INSTRUCTIONS: 'Beställningsinstruktioner',
  MONITORING: 'Övervakning',
  DOCUMENTS: 'Dokument',
  CONSTRAINTS: 'Begränsningar',
  FIXATION: 'Fixering',
  CASH: 'Kontanter',
  FX_RATE: 'FX Kurs',
  MIN: 'Min',
  MAX: 'Max',
  INSTRUMENTS: 'Instrument',
  NO_SHARES: 'Antal',
  NO_SHARES_TO_FIX: 'Antal att fixa',
  FIX: 'Fixa allt',
  BUY: 'Köpa',
  SELL: 'Sälja',
  UPDATE: 'Uppdatering',
  VALID_UNTIL: 'Giltig till',
  UNTIL_CANCELED: 'Tills avbokning',
  SHARE_PR: '% Andel',
  APPROVED_INSTRUMENT: 'Godkänt instrument',
  ADD_PORTFOLIO: 'Lägg till portfölj',
  OPTIMISE: 'Optimera',
  STANDING: 'Stående',
  RETURN_PER: 'Retur %',
  VOLATILITY_PER: 'Flyktighet %',
  CVAR_PER: 'CVaR %',
  REBALANCE: 'Rebalansera',
  GLOBAL_SHARES: 'Global aktier',
  LIQUIDITY: 'Likviditet',
  START_OPTIMIZATION: 'Starta optimering',
  DISTRIBUTION: 'Distribution',
  ASSET_CLASS_ALLOCATION: 'Tillgångsklassfördelning',
  VIEW_MORE: 'Visa mer',
  ADD_CLIENT: 'Lägg till klient',
  INFORMATION: 'Information',
  FINANCIAL_PLANING: 'Finansiell planering',
  PORTFOLIO_DETAILS: 'Portföljdetaljer',
  THAN_LAST_MONTH: 'än förra månaden',
  THAN_LAST_3_MONTHS: 'än de senaste 3 månaderna',
  THAN_LAST_YEAR: 'än förra året',
  RESET: 'Återställa',
  PORTFOLIO_OPTIMISE: 'Portföljoptimera',
  PORTFOLIO_OPTIMISATION: 'Portföljoptimering',
  CHANGE_SETTINGS: 'Ändra inställningar',
  BACK_TO_OPTIMISE: 'Tillbaka till Optimera',
  OPT_BREAK: 'Du är på väg att lämna optimeringsflödet. All inmatad information kommer att gå förlorad. Är du säker på det här?',
  CLIENT_CREATION: 'Kundskapande',
  NOPORTFOLIO_TEXT: 'Det verkar som att du inte har någon portfölj, vänligen redigera ditt utkast till portfölj eller skapa en ny',
  PORTFOLIO_CREATION: 'Skapande av portfölj',
  MAIN_INFORMATION: 'Huvudinformation',
  CLEAR: 'Klar',
  DETAIL_TEXT: 'Vänligen fyll i information för att se portföljens resultat',
  PRODUCT_NAME: 'Produktnamn',
  OPTIMIZATION: 'Optimering',
  LIKE_TO_INCLUDE: 'Vilka avgifter skulle du vilja inkludera?',
  EDIT_ADVISOR_INFORMATION: 'Redigera rådgivareinformation',
  ADVISOR_INVITATION: 'Rådgivare inbjudan',
  WEIGHT: 'Vikt',
  CASH_MANAGEMENT: 'Kontant hantering',
  MINIMUM_CASH_LEVEL: 'Minsta kontantnivå',
  CASH_AMOUNT: 'Kontantbelopp',
  //
  BY: 'av',
  CREATED_ON: 'Skapad på',
  LAST_OPTIMIZED_ON: 'Senast optimerad på',
  PERFORMANCE: 'Prestanda',
  EXCLUDE_HOLDINGS: 'Exkludera innehav',
  EXCLUDE_ASSET_CLASS: 'Exkludera tillgångsklass',
  LIFETIMING: 'Lifetiming',
  FINANCIAL_PLANS: 'Finansiella planer',
  CREATE_FINANCIAL_PLAN: 'Skapa ekonomisk plan',
  ADD_FINANCIAL_PLAN: 'Lägg till ekonomisk plan',
  NO_FINANCIAL_PLANS: 'Inga ekonomiska planer',
  NO_FINANCIAL_PLANS_TEXT: 'Det verkar som att du inte har någon ekonomisk plan, skapa en ny.',
  ASSIGNED_TO_PORTFOLIO: 'Tilldelad till Portfolio',
  NOF_ITEMS: 'Antal artiklar',
  ACHIEVEMENT_LEVEL: 'Achievement Level',
  YEARS: 'år',
  SUBMIT_AND_PROCEED: 'Skicka in och fortsätt',
  FINANCIAL_PLAN_CREATION: 'Skapande av finansiell plan',
  PLEASE_PROVIDE: 'Ange finansplanens namn',
  FINANCIAL_PLAN_NAME: 'Ekonomiplanens namn',
  FINANCIAL_PLAN_DETAILS: 'Finansiell plan Detaljer',
  DELETE_FINANCIAL_PLAN: 'Ta bort ekonomisk plan',
  END_DATE: 'Slutdatum ',
  DISTRIBUTION_NEW: 'Distribution',
  ADD_ITEM: 'Lägg till objekt',
  EXPENSES: 'Utgifter',
  INCOME: 'Inkomst',
  NECESSITY: 'Nödvändighet',
  PURPOSE: 'Ändamål',
  HAPPINESS: 'Lycka',
  SALARY: 'Lön',
  PENSION: 'Pension',
  INCOME_FROM_OTHER: 'Inkomst från annat',
  PROPERTY_SALE: 'Fastighetsförsäljning',
  RETURN_ON_INVESTMENTS: 'Avkastning på investeringar',
  SALE_OTHER: 'Försäljning (övrigt)',
  INHERITANCE: 'Arv',
  HOUSING: 'Hus',
  HEALTH: 'Hälsa',
  KIDS: 'Barn',
  EDUCATION: 'Utbildning',
  VACATION_PLANNER: 'Semesterplanerare',
  VEHICLES: 'Fordon',
  LUXURY_GOODS: 'Lyxvaror',
  LIVING_EXPENSES: 'Levnadskostnader',
  GIFTS: 'Gåvor',
  ADD_ITEMS_NECESSARY_CATEGORY: 'Lägg till objekt i nödvändig kategori.',
  WHAT_WANT_ACHIVE: 'Vad vill du uppnå?',
  ITEM_NAME: 'Objektets namn',
  ENTER_ITEM_NAME: 'Ange objektets namn',
  DESCRIPTION: 'Beskrivning',
  ENTER_DESCRIPTION: 'Ange en beskrivning...',
  FREQUENCY: 'Frekvens',
  PROBABILITY: 'Sannolikhet',
  CLOSE: 'Nära',
  VIEW_AS_CHART: 'Visa som diagram',
  VIEW_AS_LIST: 'Visa som lista',
  CREATION_DATE: 'Skapandedatum',
  HOLDINGS_FEE_TOOLTOP: 'Vägt genomsnittlig avgift för instrumenten',
  TO_SYNC_PORTFOLIOS: 'För att synkronisera portföljer anslut ditt BankId i Inställningar',
  NOT_AVAILABLE_FOR_EDITING: 'Portfolio är inte tillgänglig för redigering',
  IN_EXECUTION: 'I avrättning',
  LOCKED: 'Låst',
};
