import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { useDispatch } from 'react-redux';
import { setOnboardingVerifed } from '../../../data/store/clients/clientActions';

const CategoryRiskProfile = ({ userId, t, onboardingData}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const risk_level = onboardingData?.risk_level;
  const risk_profile = Form.useWatch('risk_profile', form);

  useEffect(() => {
    if(onboardingData?.risk_level && !onboardingData?.result?.risk_profile) {
      form.setFieldValue('risk_profile', risk_level);
    } else {
      form.setFieldValue('risk_profile', onboardingData?.result?.risk_profile);
    }
  }, [onboardingData]);

  const options = [
    {
      value: 1,
      label: 'VERY_LOW_RISK'
    },
    {
      value: 2,
      label: 'LOW_RISK'
    },
    {
      value: 3,
      label: 'MEDIUM_LOW_RISK'
    },
    {
      value: 4,
      label: 'MEDIUM_RISK'
    },
    {
      value: 5,
      label: 'MEDIUM_HIGH_RISK'
    },
    {
      value: 6,
      label: 'HIGH_RISK'
    },
    {
      value: 7,
      label: 'VERY_HIGH_RISK'
    },
  ];

  const getRiskLevel = (level) => {
    return options.find( i=> i.value === level)?.label || '';
  };

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap onboarding-risk-profile">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">{t('RISK_PROFILE')}</h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="sign-up-form"
        disabled={!onboardingData?.result?.risk_profile}
      >
        <Form.Item
          style={{marginBottom: '24px'}}
          label={t('RISK_CLASSIFICATION_PROPOSAL')}
          name={'risk_profile'}
          className="custom-required"
          required={false}>
          <CustomSelect
            disabled={!risk_level}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {options?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <div className='risk-warning-wrapper'>
          {
            risk_level !== risk_profile && <span>{t('RECOMMENDED_RISK_LEVEL')}: {t(getRiskLevel(risk_level))}</span>
          }
        </div>
        <Button
          disabled={!risk_level}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryRiskProfile;

CategoryRiskProfile.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
};
