import React, { useEffect, useCallback, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  getClient,
  getClientData,
  setClientData,
} from '../../data/store/clients/clientActions';

import { Typography, Select, Button, Radio, Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../components/Container';
import { Menu } from '../../assets';
import MainButtonArray from '../../components/MainButtonArray';
import './style.scss';
import countryEN from '../../constants/countryEN.json';
import { ReactComponent as IconPlus } from './img/icon-plus.svg';
import CompanyKnowledge from './CompanyKnowledge';

const { Title } = Typography;

const CustomerKnowledge = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getClient({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (
        !user?.client_data ||
        !Object.keys(user.client_data).includes('customerKnowledge')
      ) {
        dispatch(getClientData({ id, name: 'customerKnowledge' }));
      }
    }
  }, [user?.client_data]);

  const initFields = {
    country: user?.client_data?.customerKnowledge?.data?.country ?? [],
    countryTax: user?.client_data?.customerKnowledge?.data?.countryTax ?? null,
    relationshipUSA:
      user?.client_data?.customerKnowledge?.data?.relationshipUSA ?? null,
    relationshipUSAComment:
      user?.client_data?.customerKnowledge?.data?.relationshipUSAComment ??
      null,
    regularlyMakeTransactions:
      user?.client_data?.customerKnowledge?.data?.regularlyMakeTransactions ??
      null,
    regularlyMakeTransactionsComment:
      user?.client_data?.customerKnowledge?.data
        ?.regularlyMakeTransactionsComment ?? null,
    purposeOfYourBusiness:
      user?.client_data?.customerKnowledge?.data?.purposeOfYourBusiness ?? null,
    behalfAreYouInvesting:
      user?.client_data?.customerKnowledge?.data?.behalfAreYouInvesting ?? null,
    otherDescribe:
      user?.client_data?.customerKnowledge?.data?.otherDescribe ?? null,
    yourMainOccupation:
      user?.client_data?.customerKnowledge?.data?.yourMainOccupation ?? null,
    approximateMonthlyIncome:
      user?.client_data?.customerKnowledge?.data?.approximateMonthlyIncome ??
      null,
    personalMoney:
      user?.client_data?.customerKnowledge?.data?.personalMoney ?? null,
    whereMoney: {
      salaryPension:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.salaryPension ??
        false,
      ownCompany:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.ownCompany ??
        false,
      savings:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.savings ?? false,
      inheritageGift:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.inheritageGift ??
        false,
      housingProperty:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.housingProperty ?? false,
      capitalGains:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.capitalGains ??
        false,
      otherSale:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.otherSale ??
        false,
      capitalGainsSecurities:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.capitalGainsSecurities ?? false,
      lotteryGambling:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.lotteryGambling ?? false,
      insurancePayment:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.insurancePayment ?? false,
      allowance:
        user?.client_data?.customerKnowledge?.data?.whereMoney?.allowance ??
        false,
    },
    whichTransferMoney: {
      swedishBank:
        user?.client_data?.customerKnowledge?.data?.whichTransferMoney?.swedishBank ?? false,
      swedishInsuranceCompany:
        user?.client_data?.customerKnowledge?.data?.whichTransferMoney?.swedishInsuranceCompany ?? false,
      forreignBank:
        user?.client_data?.customerKnowledge?.data?.whichTransferMoney?.forreignBank ?? false,
      forreignInsuranceCompany:
        user?.client_data?.customerKnowledge?.data?.whichTransferMoney?.forreignInsuranceCompany ?? false,
    },
    estimatedValueTransfer:
      user?.client_data?.customerKnowledge?.data?.estimatedValueTransfer ??
      null,
    valueDepositsAmount:
      user?.client_data?.customerKnowledge?.data?.valueDepositsAmount ?? null,
    oftenWillDeposits:
      user?.client_data?.customerKnowledge?.data?.oftenWillDeposits ?? null,
    oftenWillWithdrawals:
      user?.client_data?.customerKnowledge?.data?.oftenWillWithdrawals ?? null,
    largeIndividualTransactions:
      user?.client_data?.customerKnowledge?.data?.largeIndividualTransactions ??
      null,
    havePoliticallyExposedPosition:
      user?.client_data?.customerKnowledge?.data
        ?.havePoliticallyExposedPosition ?? null,
  };

  const [fields, setFields] = useState(initFields);
  const [fieldsCompany, setFieldsCompany] = useState();
  const [isAddCountry, setIsAddCountry] = useState(false);

  useEffect(() => {
    setFields(initFields);
    if (initFields.country.length > 1) setIsAddCountry(true);
  }, [user?.client_data]);

  const changeTextAria = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleCountrySelect = (value, position) => {
    const newcountry = [...fields.country];
    newcountry[position] = value;
    setFields({ ...fields, country: newcountry });
  };
  // const handleOwnerCountrySelect = (value, position, type) => {
  //   if (!type) {
  //     const newcountry = [...fieldsCompany.countryOwner];
  //     newcountry[position] = value;
  //     setFieldsCompany({ ...fieldsCompany, countryOwner: newcountry });
  //   } else if (type) setFieldsCompany({ ...fieldsCompany, [type]: value });
  // };
  const handleChangeSelect = (value, name, type) => {
    if (!type) {
      let clearedText;
      if (name === 'behalfAreYouInvesting') {
        clearedText = 'otherDescribe';
        setFields({ ...fields, [name]: value, [clearedText]: '' });
      } else setFields({ ...fields, [name]: value });
    } else if (type === 'company') {
      setFieldsCompany({ ...fieldsCompany, [name]: value });
    }
  };
  const handleAddCountry = () => {
    setIsAddCountry(true);
  };
  const handleChangeRadio = (event, type) => {
    const { value, name } = event.target;
    let clearedText;
    if (!type) {
      if (name === 'relationshipUSA') clearedText = 'relationshipUSAComment';
      if (name === 'regularlyMakeTransactions') {
        clearedText = 'regularlyMakeTransactionsComment';
      }
      setFields({ ...fields, [name]: value === 'yes', [clearedText]: '' });
    } else if (type === 'company') {
      if (name === 'hasControlsMore25') clearedText = 'typeControlsMore25';
      let newData = { ...fieldsCompany, [name]: value === 'yes' };
      if (clearedText) newData = { ...newData, [clearedText]: null };
      setFieldsCompany(newData);
    }
  };

  const handleChangeCheckbox = (event) => {
    const { checked, name } = event.target;
    setFields({
      ...fields,
      whereMoney: { ...fields.whereMoney, [name]: checked },
    });
  };
  const handleChangeCheckboxTwo = (event) => {
    const { checked, name } = event.target;
    setFields({
      ...fields,
      whichTransferMoney: { ...fields.whichTransferMoney, [name]: checked },
    });
  };

  const validateCustomer = () => {
    const validate = { ...fields };
    delete validate.comment;
    const mandatory =
      fields.country.length > 0 &&
      !!fields.countryTax &&
      !!fields.relationshipUSA !== null &&
      (fields.relationshipUSA ? !!fields.relationshipUSAComment : true) &&
      !!fields.regularlyMakeTransactions !== null &&
      (fields.regularlyMakeTransactions
        ? !!fields.regularlyMakeTransactionsComment
        : true) &&
      !!fields.purposeOfYourBusiness &&
      !!fields.behalfAreYouInvesting &&
      (fields.behalfAreYouInvesting === 'Other (describe)'
        ? !!fields.otherDescribe
        : true) &&
      !!fields.yourMainOccupation &&
      !!fields.approximateMonthlyIncome &&
      fields.personalMoney !== null &&
      Object.values(fields.whereMoney).some((item) => !!item) &&
      Object.values(fields.whichTransferMoney).some((item) => !!item) &&
      !!fields.estimatedValueTransfer &&
      !!fields.valueDepositsAmount &&
      !!fields.oftenWillDeposits &&
      !!fields.oftenWillWithdrawals &&
      !!fields.largeIndividualTransactions &&
      fields.havePoliticallyExposedPosition !== null;
    const fullFill = mandatory;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'customerKnowledge',
      data: user.type.type === 'private' ? fields : fieldsCompany,
      full_status: user.type.type === 'private' ? validateCustomer() : 'mandatory-only',
    };
    dispatch(setClientData({ id, fields: data }));
    history.push(`/dashboard/${id}`);
  }, [history, fields, fieldsCompany]);

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {t(countryEN[data])}
      </Select.Option>
    );
  };

  return (
    <>
      <Title className='onboard-component-title'>
        {user?.type?.type === 'private'
          ? t('CUSTOMER_KNOWLEDGE')
          : user?.type?.type === 'business'
            ? t('COMPANY_KNOWLEDGE')
            : null}
      </Title>
      {user?.type?.type === 'private'
        ? <>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>{t('CITIZENSHIP_TAX_RESIDENCE')}</h4>
            </header>

            <p className='risk-profile-lable'>
              {t('WHAT_YOUR_COUNTRY_CITIZENSHIP')}
            </p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.country[0]}
              onChange={(value) => handleCountrySelect(value, 0)}
            >
              <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
              {Object.keys(countryEN).map((item) => {
                return countrysOption(item);
              })}
            </Select>

            {isAddCountry && (
              <>
                <p className='risk-profile-lable'></p>
                <Select
                  placeholder={t('SELECT_OPTION')}
                  className='risk-profile-select'
                  type='select'
                  value={fields.country[1]}
                  onChange={(value) => handleCountrySelect(value, 1)}
                >
                  <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
                  {Object.keys(countryEN).map((item) => {
                    return countrysOption(item);
                  })}
                </Select>
              </>
            )}

            {!isAddCountry && (
              <div className='goal-addbutton-wrapper'>
                <Button
                  className='goal-addbutton'
                  onClick={handleAddCountry}
                  // disabled={isAddCountry}
                >
                  <IconPlus />
                  {t('ADD_ANOTHER_COUNTRY')}
                </Button>
              </div>
            )}

            <p className='risk-profile-lable'>
              {t('WHAT_YOUR_COUNTRY_TAX_RESIDENCE')}
            </p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.countryTax}
              onChange={(value) => handleChangeSelect(value, 'countryTax')}
            >
              <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
              {Object.keys(countryEN).map((item) => {
                return countrysOption(item);
              })}
            </Select>
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>{t('ABROAD')}</h4>
            </header>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('HAVE_YOU_ANY_RELATIONSHIP_WITH')}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Radio.Group
                name='relationshipUSA'
                onChange={handleChangeRadio}
                value={
                  fields.relationshipUSA
                    ? 'yes'
                    : fields.relationshipUSA === null
                      ? ''
                      : 'no'
                }
                className='saving-radio customer-radio'
              >
                <Radio value='yes'>{t('YES')}</Radio>
                <Radio value='no'>{t('No')}</Radio>
              </Radio.Group>
            </div>
            {fields.relationshipUSA && (
              <>
                <p className='saving-lable'>{t('WHAT_PURPOSE')}</p>
                <TextArea
                  type='text'
                  name='relationshipUSAComment'
                  value={fields.relationshipUSAComment}
                  onChange={changeTextAria}
                  maxLength={200}
                />
              </>
            )}
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('DO_YOU_REGULARLY_MAKE_TRANSACTIONS')}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Radio.Group
                name='regularlyMakeTransactions'
                onChange={handleChangeRadio}
                value={
                  fields.regularlyMakeTransactions
                    ? 'yes'
                    : fields.regularlyMakeTransactions === null
                      ? ''
                      : 'no'
                }
                className='saving-radio customer-radio'
              >
                <Radio value='yes'>{t('YES')}</Radio>
                <Radio value='no'>{t('No')}</Radio>
              </Radio.Group>
            </div>
            {fields.regularlyMakeTransactions && (
              <>
                <p className='saving-lable'>{t('WHAT_PURPOSE')}</p>
                <TextArea
                  type='text'
                  name='regularlyMakeTransactionsComment'
                  value={fields.regularlyMakeTransactionsComment}
                  onChange={changeTextAria}
                  maxLength={200}
                />
              </>
            )}
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>{t('PURPOSE_WITH_INVESTMENT')}</h4>
            </header>
            <p className='customer-lable'>{t('WHAT_PURPOSE_YOUR_BUSINESS')}</p>
            <TextArea
              type='text'
              name='purposeOfYourBusiness'
              value={fields.purposeOfYourBusiness}
              onChange={changeTextAria}
              maxLength={200}
            />
            <p className='customer-lable'>{t('BEHALF_ARE_YOU_INVESTING')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.behalfAreYouInvesting}
              onChange={(value) =>
                handleChangeSelect(value, 'behalfAreYouInvesting')
              }
            >
              <Select.Option value='For my own part'>
                {t('FOR_MY_OWN_PART')}
              </Select.Option>
              <Select.Option value='For someone else by proxy'>
                {t('FOR_SOMEONE_ELSE_BY_PROXY')}
              </Select.Option>
              <Select.Option value='Other (describe)'>
                {t('OTHER_DESCRIBE')}
              </Select.Option>
            </Select>
            {fields.behalfAreYouInvesting === 'Other (describe)' && (
              <>
                <p className='customer-lable'>{t('OTHER_DESCRIBE')}</p>
                <TextArea
                  type='text'
                  name='otherDescribe'
                  value={fields.otherDescribe}
                  onChange={changeTextAria}
                  maxLength={200}
                />
              </>
            )}
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>{t('MAIN_OCCUPATION')}</h4>
            </header>
            <p className='customer-lable'>{t('WHAT_YOUR_MAIN_OCCUPATION')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.yourMainOccupation}
              onChange={(value) =>
                handleChangeSelect(value, 'yourMainOccupation')
              }
            >
              <Select.Option value='Employee'>{t('EMPLOYEE')}</Select.Option>
              <Select.Option value='Self-employed'>
                {t('SELF_EMPLOYED')}
              </Select.Option>
              <Select.Option value='Pensioner'>{t('PENSIONER')}</Select.Option>
              <Select.Option value='Student'>{t('STUDENT')}</Select.Option>
              <Select.Option value='Other'>{t('OTHER')}</Select.Option>
            </Select>
            {/* Connect to Financial situation. */}
            <p className='customer-lable'>{t('APPROXIMATE_MONTHLY_INCOME')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.approximateMonthlyIncome}
              onChange={(value) =>
                handleChangeSelect(value, 'approximateMonthlyIncome')
              }
            >
              <Select.Option value='0-20000'>{'0 - 20 000 kr'}</Select.Option>
              <Select.Option value='20000-50000'>
                {'20 000 - 50 000 kr'}
              </Select.Option>
              <Select.Option value='50000-100000'>
                {'50 000 - 100 000 kr'}
              </Select.Option>
              <Select.Option value='Over 100000'>
                {t('OVER_100000')}
              </Select.Option>
            </Select>
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>{t('CAPITAL_TRANSFER')}</h4>
            </header>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('PERSONAL_MONEY_THAT_WILL_TRANSFERED')}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Radio.Group
                name='personalMoney'
                onChange={handleChangeRadio}
                value={
                  fields.personalMoney
                    ? 'yes'
                    : fields.personalMoney === null
                      ? ''
                      : 'no'
                }
                className='saving-radio customer-radio'
              >
                <Radio value='yes'>{t('YES')}</Radio>
                <Radio value='no'>{t('No')}</Radio>
              </Radio.Group>
            </div>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('WHERE_DOES_THE_MONEY')}</span>
            </div>
            <div className='customer-where-money'>
              <Checkbox
                name='salaryPension'
                checked={fields.whereMoney.salaryPension}
                onChange={handleChangeCheckbox}
              >
                {t('SALARY_PENSION')}
              </Checkbox>
              <Checkbox
                name='ownCompany'
                checked={fields.whereMoney.ownCompany}
                onChange={handleChangeCheckbox}
              >
                {t('OWN_COMPANY')}
              </Checkbox>
              <Checkbox
                name='savings'
                checked={fields.whereMoney.savings}
                onChange={handleChangeCheckbox}
              >
                {t('SAVING')}
              </Checkbox>
              <Checkbox
                name='inheritageGift'
                checked={fields.whereMoney.inheritageGift}
                onChange={handleChangeCheckbox}
              >
                {t('INHERITAGE')}
              </Checkbox>
              <Checkbox
                name='housingProperty'
                checked={fields.whereMoney.housingProperty}
                onChange={handleChangeCheckbox}
              >
                {t('HOUSING_PROPERTY')}
              </Checkbox>
              <Checkbox
                name='capitalGains'
                checked={fields.whereMoney.capitalGains}
                onChange={handleChangeCheckbox}
              >
                {t('CAPITAL_GAINS_COMPANY')}
              </Checkbox>
              <Checkbox
                name='otherSale'
                checked={fields.whereMoney.otherSale}
                onChange={handleChangeCheckbox}
              >
                {t('OTHER_SALE')}
              </Checkbox>
              <Checkbox
                name='capitalGainsSecurities'
                checked={fields.whereMoney.capitalGainsSecurities}
                onChange={handleChangeCheckbox}
              >
                {t('CAPITAL_GAINS_SECURITIES')}
              </Checkbox>
              <Checkbox
                name='lotteryGambling'
                checked={fields.whereMoney.lotteryGambling}
                onChange={handleChangeCheckbox}
              >
                {t('LOTTERY_GAMBLING')}
              </Checkbox>
              <Checkbox
                name='insurancePayment'
                checked={fields.whereMoney.insurancePayment}
                onChange={handleChangeCheckbox}
              >
                {t('INSURANCE_PAYMENT')}
              </Checkbox>
              <Checkbox
                name='allowance'
                checked={fields.whereMoney.allowance}
                onChange={handleChangeCheckbox}
              >
                {t('ALLOWANCE')}
              </Checkbox>
            </div>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('FROM_WHICH_BANK_INSURANCE')}</span>
            </div>
            <div className='customer-where-money'>
              <Checkbox
                name='swedishBank'
                checked={fields.whichTransferMoney.swedishBank}
                onChange={handleChangeCheckboxTwo}
              >
                {t('SWEDISH_BANK')}
              </Checkbox>
              <Checkbox
                name='swedishInsuranceCompany'
                checked={fields.whichTransferMoney.swedishInsuranceCompany}
                onChange={handleChangeCheckboxTwo}
              >
                {t('SWEDISH_INSURANCE_COMPANY')}
              </Checkbox>
              <Checkbox
                name='forreignBank'
                checked={fields.whichTransferMoney.forreignBank}
                onChange={handleChangeCheckboxTwo}
              >
                {t('FORREIGN_BANK')}
              </Checkbox>
              <Checkbox
                name='forreignInsuranceCompany'
                checked={fields.whichTransferMoney.forreignInsuranceCompany}
                onChange={handleChangeCheckboxTwo}
              >
                {t('FORREIGN_INSURANCE_COMPANY')}
              </Checkbox>
            </div>
            {/* Should make a suggestion that equals the value of "investible capital" in Financial situation if that information has been filled in. */}
            <p className='customer-lable'>
              {t('ESTIMATED_VALUE_WILL_YOU_TRANSFER')}
            </p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.estimatedValueTransfer}
              onChange={(value) =>
                handleChangeSelect(value, 'estimatedValueTransfer')
              }
            >
              <Select.Option value='5000000-10000000'>
                {'5 000 000 - 10 000 000 kr'}
              </Select.Option>
              <Select.Option value='10000000-25000000'>
                {'10 000 000 - 25 000 000 kr'}
              </Select.Option>
              <Select.Option value='25000000-50000000'>
                {'25 000 000 - 50 000 000 kr'}
              </Select.Option>
              <Select.Option value='50000000-100000000'>
                {'50 000 000 - 100 000 000 kr'}
              </Select.Option>
              <Select.Option value='Over 100000000'>
                {t('OVER_100000000')}
              </Select.Option>
            </Select>
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>
                {t('DEPOSITS_WITHDRAWALS_TRANSACTIONS')}
              </h4>
            </header>
            <p className='customer-lable'>
              {t('WHAT_VALUE_YOUR_DEPOSITS_AMOUT')}
            </p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.valueDepositsAmount}
              onChange={(value) =>
                handleChangeSelect(value, 'valueDepositsAmount')
              }
            >
              <Select.Option value='0-100000'>{'0 - 100 000 kr'}</Select.Option>
              <Select.Option value='100000-1000000'>
                {'100 000 - 1 000 000 kr'}
              </Select.Option>
              <Select.Option value='1000000-5000000'>
                {'1 000 000 - 5 000 000 kr'}
              </Select.Option>
              <Select.Option value='5000000-10000000'>
                {'5 000 000 - 10 000 000 kr'}
              </Select.Option>
              <Select.Option value='Over 10000000'>
                {t('OVER_100000000')}
              </Select.Option>
            </Select>
            <p className='customer-lable'>{t('OFTEN_WILL_DEPOSITS')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.oftenWillDeposits}
              onChange={(value) =>
                handleChangeSelect(value, 'oftenWillDeposits')
              }
            >
              <Select.Option value='0-10 times'>
                {t('TIMES_0_10')}
              </Select.Option>
              <Select.Option value='10-50 times'>
                {t('TIMES_10_50')}
              </Select.Option>
              <Select.Option value='50-100 times'>
                {t('TIMES_50_100')}
              </Select.Option>
              <Select.Option value='More than 100 times'>
                {t('MORE_THAN_100_TIMES')}
              </Select.Option>
            </Select>
            <p className='customer-lable'>{t('OFTEN_WILL_WITHDRAWALS')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={fields.oftenWillWithdrawals}
              onChange={(value) =>
                handleChangeSelect(value, 'oftenWillWithdrawals')
              }
            >
              <Select.Option value='0-10 times'>
                {t('TIMES_0_10')}
              </Select.Option>
              <Select.Option value='10-50 times'>
                {t('TIMES_10_50')}
              </Select.Option>
              <Select.Option value='50-100 times'>
                {t('TIMES_50_100')}
              </Select.Option>
              <Select.Option value='More than 100 times'>
                {t('MORE_THAN_100_TIMES')}
              </Select.Option>
            </Select>
            <p className='customer-lable'>
              {t('HOW_LARGE_INDIVIDUAL_TRANSACTIONS')}
            </p>
            <Select
              placeholder={
                <span className='customer-placeholder'>
                  {t('SELECT_OPTION')}
                </span>
              }
              className='risk-profile-select'
              type='select'
              value={fields.largeIndividualTransactions}
              onChange={(value) =>
                handleChangeSelect(value, 'largeIndividualTransactions')
              }
            >
              <Select.Option value='0-100000'>{'0 - 100 000 kr'}</Select.Option>
              <Select.Option value='100000-1000000'>
                {'100 000 - 1 000 000 kr'}
              </Select.Option>
              <Select.Option value='1000000-5000000'>
                {'1 000 000 - 5 000 000 kr'}
              </Select.Option>
              <Select.Option value='5000000-10000000'>
                {'5 000 000 - 10 000 000 kr'}
              </Select.Option>
              <Select.Option value='Over 10000000'>
                {t('OVER_100000000')}
              </Select.Option>
            </Select>
          </Container>
          <Container>
            <header className='legal-header'>
              <h4 className='legal-header'>
                {t('POLITICALLY_EXPOSED_POSITION')}
              </h4>
            </header>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('KNOWN_EMPLOYEE_SUCH_PERSON')}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Radio.Group
                name='havePoliticallyExposedPosition'
                onChange={handleChangeRadio}
                value={
                  fields.havePoliticallyExposedPosition
                    ? 'yes'
                    : fields.havePoliticallyExposedPosition === null
                      ? ''
                      : 'no'
                }
                className='saving-radio customer-radio'
              >
                <Radio value='yes'>{t('YES')}</Radio>
                <Radio value='no'>{t('No')}</Radio>
              </Radio.Group>
            </div>
          </Container>
        </>
        : user?.type?.type === 'business'
          ? <CompanyKnowledge
              user={user}
              fieldsCompany={fieldsCompany}
              setFieldsCompany={setFieldsCompany}
              hendleSubmit={hendleSubmit}
            />
          : null
          }
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='submit'
          // onClick={hendleSubmit}
          arrayIconEnd
          form='owner'
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

CustomerKnowledge.propTypes = {
  id: PropTypes.string,
};

export default CustomerKnowledge;
