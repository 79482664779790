/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Input } from 'antd';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import { numberWithSpaces } from '../../helpers/formatInteger';
import { colorsCategoryOld } from '../../helpers/colors';

import './styles.scss';
import { formatPercentage } from '../../helpers/formatPercentage';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';

export const PortfolioTableOptimal = ({ tableColumns, setTableColumns, optimalPortfolio }) => {
  const { t } = useTranslation('');

  const countCategory = useCallback(() => {
    const categoryArr = {};
    optimalPortfolio?.items?.forEach(el => {
      if (Object.keys(categoryArr).includes(el.financialAssetCategory.name)) {
        categoryArr[el.financialAssetCategory.name] += 1;
      } else {
        categoryArr[el.financialAssetCategory.name] = 1;
      }
    });
    return categoryArr;
  }, [optimalPortfolio]);

  const percentage = (value, isText) => {
    const percentage = Math.round(value * 100 / optimalPortfolio?.map(i => i.value).reduce((acc, el) => acc + el, 0) * 100) / 100;
    return isText ? `${percentage} %` : percentage;
  };

  const data = optimalPortfolio?.map((row, index) => {
    return {
      key: index,
      financialInstrumentName: row.name,
      value: twoDecimalWithSpaces(row.value),
      percentage: percentage(row.value),
      fee: row.fee,
      assetClass: row?.asset_class_name,
      weight: row.current_weight_fin
    };
  }).sort((a, b) => b.value - a.value);
  console.log('🚀 ~ data ~ data:', data);

  const portfolioTotal = useMemo(() => {
    const total = {
      value: 0,
      fee: 0,
    };
    try {
      total.value = Math.round(optimalPortfolio.total_value);
      total.fee = Math.round(optimalPortfolio.total_fee * 100) / 100;
    } catch (e) {
      return total;
    }
    return total;
  }, [data]);

  useEffect(() => {
    const defaultColumns = [
      {
        title: t('INSTRUMENT'),
        dataIndex: 'financialInstrumentName',
      },
      {
        title: t('VALUE'),
        dataIndex: 'value',
        className: 'table-header-right',
        width: '10%',
        align: 'right',
        sorter: (a, b) => a.value - b.value,
      },
      {
        title: t('WEIGHT'),
        dataIndex: 'weight',
        className: 'table-header-right',
        width: '10%',
        align: 'right',
        render: (value) => {
          return formatPercentage(value) || '0.00 %';
        },
        sorter: (a, b) => a.weight - b.weight,
      },
      {
        title: t('FEE'),
        dataIndex: 'fee',
        className: 'holdings-fee table-header-center',
        width: '10%',
        align: 'center',
        render: (value) => {
          return (
            <p>
              {formatPercentage(value) ||
              `${'0.00'.replace('.', decimalSeparator())} %`}
            </p>
          );
        },
        sorter: (a, b) => a.fee - b.fee,
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'assetClass',
        width: '20%',
        sorter: (a, b) => {
          if (a.assetClass < b.assetClass) {
            return 1;
          } else if (a.assetClass > b.assetClass) {
            return -1;
          } else {
            return 0;
          }
        },
        render: (value, record) => {
          return (
            <>
              <p className="holdings-assetclass-cell-asset">{value}</p>
              <p className="holdings-assetclass-cell-category">
                {record.financialAssetCategory}
              </p>
            </>
          );
        },
      },
    ];
    setTableColumns(defaultColumns);
  }, [optimalPortfolio]);

  return (
        <div className="portfolio-table sugg-portfolio">
          <Table
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row padding' : 'editable-row padding is-odd'}
            columns={tableColumns}
            dataSource={data}
            size="middle"
            bordered
            pagination={false}
          />
        </div>
  );
};

PortfolioTableOptimal.propTypes = {
  columns: PropTypes.array,
  optimalPortfolio: PropTypes.array,
  tableColumns: PropTypes.array,
  setTableColumns: PropTypes.func,
};
