import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.scss';

const TransactionTabs = ({ tabName, setTabName, updateData, children }) => {
  const { t } = useTranslation('');

  return (
    <Row className="transaction-tabs-wrapper">
      <Col justify="start" span={6} >
        <Button
          style={{ marginRight: '16px', paddingLeft: '40px', paddingRight: '40px', marginBottom: '24px' }}
          type="text"
          className={`${tabName === 1 ? 'ant-btn-active' : ''}`}
          onClick={() => setTabName(1)}
        >
          {t('BUY')}
        </Button>
        <Button
        style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom: '24px' }}
          type="text"
          className={`${tabName === 2 ? 'ant-btn-active' : ''}`}
          onClick={() => setTabName(2)}
        >
          {t('SELL')}
        </Button>
      </Col>
      <Button
        // style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom: '24px' }}
          className='transaction-update-btn'
          onClick={updateData}
        >
          {t('UPDATE')}
        </Button>
      <Row className='transaction-table-wrapper' >{children}</Row>
    </Row>
  );
};

export default TransactionTabs;

TransactionTabs.propTypes = {
  tabName: PropTypes.number,
  setTabName: PropTypes.func,
  updateData: PropTypes.func,
  children: PropTypes.node,
};
