import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import {
  Typography,
  Input,
  Divider,
  Button,
} from 'antd';

import {
  getClient,
  getClientData,
  setClientData
} from '../../../data/store/clients/clientActions';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';
import { ReactComponent as IconCross } from './img/icon-cross.svg';
import { ReactComponent as IconPlus } from './img/icon-plus.svg';
import './style.scss';
const { Title } = Typography;

const GoalItem = (props) => {
  const { goalItemValue, handleChangeGoalItem, handleDeleteGoal } = props;
  const { t } = useTranslation('');
  return (
    <div className='goal-item-wrapper'>
    <div className='goal-item-firstinput-wrapper'>
      <Button
        className='goal-item-button'
        onClick={() => handleDeleteGoal(goalItemValue.id)}
        icon={<IconCross/>}
      />
      <div className='goal-item-input-wrapper'>
      <p className='goal-item-input-label'>{t('NAME')}</p>
      <Input
        key={goalItemValue.id}
          className='goal-item-input'
          name='name'
          value={goalItemValue.name}
          type='text'
          maxLength={30}
          placeholder={t('ENTER_NAME')}
          onChange={(event) => handleChangeGoalItem(event, goalItemValue.id)}
      />
      </div>
    </div>
      <div className='goal-item-input-wrapper'>
      <p className='goal-item-input-label'>{t('YEAR_OF_PURCHASE')}</p>
      <Input
        key={goalItemValue.id}
          className='goal-item-input'
          name='yearOfPurchase'
          value={goalItemValue.yearOfPurchase ? goalItemValue.yearOfPurchase.toString() : ''}
          type='text'
          maxLength={4}
          status={(goalItemValue.yearOfPurchase > 2022 && goalItemValue.yearOfPurchase <= 2122) || goalItemValue?.yearOfPurchase === null ? null : 'error'}
          placeholder={t('ENTER_YEAR_OF_PURCHASE')}
          onChange={(event) => handleChangeGoalItem(event, goalItemValue.id)}
      />
      </div>
      <div className='goal-item-input-wrapper'>
      <p className='goal-item-input-label'>{t('AMOUNT')}</p>
      <Input
        key={goalItemValue.id}
        className='goal-item-input'
        prefix={<span className='prefix-currence'>SEK</span>}
          name='amount'
          value={goalItemValue.amount ? goalItemValue.amount.toString() : ''}
          type='text'
          placeholder={t('ENTER_AMOUNT')}
          onChange={(event) => handleChangeGoalItem(event, goalItemValue.id)}
      />
      </div>
    </div>
  );
};

const FinancePlanneringPartTwo = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getClient({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering2')) {
        dispatch(getClientData({ id, name: 'financePlannering2' }));
      }
    }
  }, [user?.client_data]);

  const initGaolItem = {
    id: uuidv4(),
    name: '',
    yearOfPurchase: null,
    amount: null,
  };
  const initPlanneringTwo = {
    bufferSavings: user?.client_data?.financePlannering2?.data?.bufferSavings ?? null,
    goalSavings: user?.client_data?.financePlannering2?.data?.goalSavings ?? [{ ...initGaolItem, id: uuidv4() }],
  };
  const [bufferSavingField, setBufferSavingField] = useState(initPlanneringTwo);

  useEffect(() => {
    setBufferSavingField(initPlanneringTwo);
  }, [user?.client_data]);

  const handleChangeGoalItem = (event, id) => {
    let { name, value } = event.target;
    if (name === 'yearOfPurchase') value = +value;
    if (name === 'amount') {
      !isNaN(+value) ? value = +value : value = null;
    }
    const newGoal = bufferSavingField.goalSavings.map(item => item.id === id ? { ...item, [name]: value } : item);
    setBufferSavingField({ ...bufferSavingField, goalSavings: newGoal });
  };

  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const validatedValue = () => {
      if (isNaN(+value)) return false;
      if (value.length > 10) return false;
      if (value <= 0) return false;
      return true;
    };
    if (validatedValue()) setBufferSavingField({ ...bufferSavingField, [name]: +value });
    if (value === '') setBufferSavingField({ ...bufferSavingField, [name]: null });
  };
  const handleAddGoal = () => {
    setBufferSavingField({ ...bufferSavingField, goalSavings: [...bufferSavingField.goalSavings, initGaolItem] });
  };
  const handleDeleteGoal = (id) => {
    if (bufferSavingField.goalSavings.length > 1) {
      const newGoal = bufferSavingField.goalSavings.filter(item => item.id !== id);
      setBufferSavingField({ ...bufferSavingField, goalSavings: newGoal });
    }
  };

  const validateFinPlanTwo = () => {
    const mandatory = true;
    const fullFill = mandatory && !!bufferSavingField.bufferSavings && Object.values(bufferSavingField.goalSavings[0]).every(item => !!item);
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'financePlannering2',
      data: bufferSavingField,
      full_status: validateFinPlanTwo()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push('./page-three');
  }, [history, bufferSavingField]);

  return (
    <>
      <Title className='onboard-component-title'>
      {t('SAVING')}
      </Title>
      <Container>
        <header className='saving-header'>
          <h4 className='ekonomik-header'>{t('SAVING')}</h4>
        </header>
        <p className='saving-lable'>{t('BUFFER_SAVINGS')}</p>
          <Input
          className='saving-input'
          name='bufferSavings'
          value={bufferSavingField.bufferSavings}
          type='text'
          placeholder={t('ENTER_YOUR_DESIRED_BUFFER_SAVING')}
          onChange={handleChangeNumber}
          />
        <p className='divider-label'>{t('GOAL_SAVING')}</p>
        <Divider className='goal-divider'/>
        {bufferSavingField.goalSavings
          .map(item => <GoalItem
            key={item.id}
            goalItemValue={item}
            handleChangeGoalItem={handleChangeGoalItem}
            handleDeleteGoal={handleDeleteGoal}
          />)}
        <Divider className='goal-divider'/>
        <div className='goal-addbutton-wrapper'>
        <Button
          className='goal-addbutton'
          onClick={handleAddGoal}
          ><IconPlus/>{t('ADD_SAVING')}</Button>
        </div>
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

FinancePlanneringPartTwo.propTypes = {
  id: PropTypes.string,
};
GoalItem.propTypes = {
  handleChangeGoalItem: PropTypes.func,
  handleDeleteGoal: PropTypes.func,
  goalItemValue: PropTypes.object
};

export default FinancePlanneringPartTwo;
