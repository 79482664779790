/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Form, Radio, Space } from 'antd';

import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { setOnboardingVerifed } from '../../../data/store/clients/clientActions';

const CategoryPurposeMatter = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingData) {
      form.setFieldsValue(onboardingData?.result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('PURPOSE_MATTER')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body">
        <Form.Item
          name={['invest_target', 'additional_questions', 'investment_purpose']}
          className="onboarding-textarea-purpose custom-required"
          label={t('WHAT_IS_THE_PURPOSE_GOAL')}
          // rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}>
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} maxLength={800}/>
        </Form.Item>
        <Form.Item
          name={['invest_target', 'additional_questions', 'heart_affairs']}
          className="onboarding-textarea-purpose"
          label={t('HEART_AFFAIRS')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} maxLength={800}/>
        </Form.Item>
        <Form.Item
          label={t('QUALITATIVE_GOALS')}
          name={['invest_target', 'additional_questions', 'qualitative_goals']}
          className="m-top-12 m-bottom-0 custom-required"
          // rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          >
          <Radio.Group className="checkbox-block">
            <Space direction="vertical" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value="qualitative_goals_option1">
                {t('NONE_RETURN_FOCUS')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option2">
                {t('BASIC_SUSTAINABILITY')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option3">
                {t('SUSTAINABILITY_IN_DEPTH')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option4">
                {t('STRONG_IMPACT')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('SUSTAINABLE_VIEW')}
          name={['invest_target', 'additional_questions', 'sustainable_view']}
          className="m-top-12 m-bottom-0 custom-required"
          // rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}>
          <Radio.Group className="checkbox-block">
            <Space direction="vertical" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value="sustainable_view_option1">
                {t('MY_FOCUS_IN_INVESTMENTS')}
              </CustomRadioButtom>
              <CustomRadioButtom value="sustainable_view_option2">
                {t('I_WANT_INTEGRATE_SUSTAINABILITY')}
              </CustomRadioButtom>
              <CustomRadioButtom value="sustainable_view_option3">
                {t('SUSTAINABILITY_IS_VERY_IMPORTANT')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['invest_target', 'additional_questions', 'other_aspects']}
          className="onboarding-textarea-purpose"
          label={t('THERE_OTHER_ASPECTS_WORKING')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} />
        </Form.Item>
        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording} >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryPurposeMatter;

CategoryPurposeMatter.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
