import React from 'react';

import {
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { PrivateRoute } from '../../PrivateRouter';
import RiskProfilePartOne from './RiskProfilePartOne/RiskProfilePartOne';
import RiskProfilePartTwo from './RiskProfilePartTwo/RiskProfilePartTwo';

const RiskProfile = () => {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const PartOne = () => <RiskProfilePartOne id={id}/>;
  const PartTwo = () => <RiskProfilePartTwo id={id}/>;
  return (
    <Switch>
        <PrivateRoute exact path={`${url}/page-one`} component={PartOne} />
        <PrivateRoute exact path={`${url}/page-two`} component={PartTwo} />
        <Redirect to={`/dashboard/${id}`} />
    </Switch>
  );
};

export default RiskProfile;
