/* eslint-disable camelcase */
import React from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Form, Button, Input, Select } from 'antd';

import { ReactComponent as IconCross } from './img/icon-cross.svg';
import { phoneCodeEN } from '../../constants/phoneCodeEN';
import { ReactComponent as IconPlus } from './img/icon-plus.svg';
// import { ReactComponent as IconPlus } from './img/icon-plus.svg';

const MobilePhone = ({ repres, form }) => {
  const { t } = useTranslation('');
  const { Option } = Select;

  const getLengthPhone = () =>
    form.getFieldsValue(['representative']).representative[repres]
      ?.mobile_number?.length ?? 0;

  return (
    <Form.List name={[repres, 'mobile_number']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div key={key} className="phone-input-wrapper">
              <Form.Item
                {...restField}
                label={t('MOBILE_NUMBER')}
                name={[name, 'phone']}
                className="myDetails-phone"
                rules={[
                  {
                    required: name === 0,
                    message: t('ENTER_PHONE_NUMBER'),
                  },
                  () => ({
                    validator (rule, value) {
                      if (value && value.match(/^\d+$/)) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Phone must contain number only');
                    },
                  }),
                ]}
              >
                <Input
                  addonBefore={
                    <Form.Item name={[name, 'prefix']} noStyle>
                      <Select
                        style={{
                          minWidth: '70px',
                          maxWidth: '95px',
                        }}
                        dropdownStyle={{
                          minWidth: '220px',
                          maxWidth: '220px',
                          width: '220px',
                        }}
                      >
                        {phoneCodeEN.map((i) => (
                          <Option key={i.name} value={i.phone}>
                            {`${i.phone} - ${i.name}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  placeholder={t('ENTER_PHONE_NUMBER')}
                />
              </Form.Item>
              <Button
                className="phone-del-button"
                onClick={() => remove(name)}
                icon={<IconCross />}
              />
            </div>
          ))}
          {getLengthPhone() < 3 && (
            <div className="goal-addbutton-wrapper">
              <Form.Item>
                <Button
                  className="goal-addbutton myDetails-addbutton"
                  onClick={() => add()}
                  block
                  icon={<IconPlus />}
                >
                  {/* <IconPlus /> */}
                  {t('ADD_MORE_PHONE_NUMBERS')}
                </Button>
              </Form.Item>
            </div>
          )}
        </>
      )}
    </Form.List>
  );
};

MobilePhone.propTypes = {
  repres: PropTypes.number,
  form: PropTypes.object,
};

export default MobilePhone;
