export const banksList = [
  { index: 1, label: 'Avanza Bank' },
  { index: 2, label: 'Bank2' },
  { index: 3, label: 'Bankgirocentralen, BGC' },
  { index: 4, label: 'Citibank' },
  { index: 5, label: 'Danske Bank' },
  { index: 6, label: 'Danish Mortgage' },
  { index: 7, label: 'DnD NOR Bank ASA' },
  { index: 8, label: 'Forex Bank' },
  { index: 9, label: 'GE Money Bank' },
  { index: 10, label: 'Gävleborgs Provinsbank' },
  { index: 11, label: 'Halland\'s Provincial Bank' },
  { index: 12, label: 'Handelsbanken' },
  { index: 13, label: 'ICA Bank' },
  { index: 14, label: 'IKANO Bank' },
  { index: 15, label: 'Jämtland Provincial Bank' },
  { index: 16, label: 'Länsförsäkringar Bank' },
  { index: 17, label: 'Marginalen Bank' },
  { index: 18, label: 'Nordea' },
  { index: 19, label: 'Nordnet Bank' },
  { index: 20, label: 'Närke\'s Provincial Bank' },
  { index: 21, label: 'Plusgirot' },
  { index: 22, label: 'Resource Bank' },
  { index: 23, label: 'Royal Bank of Scotland' },
  { index: 24, label: 'SBAB' },
  { index: 25, label: 'SEB' },
  { index: 26, label: 'Skandiabanken' },
  { index: 27, label: 'Skaraborgs Provinsbank' },
  { index: 28, label: 'Skåne\'s Provincial Bank' },
  { index: 29, label: 'Smålandsbanken' },
  { index: 30, label: 'Sparbanken Finn' },
  { index: 31, label: 'Savings Bank Gripen' },
  { index: 32, label: 'Sparbanken Nord' },
  { index: 33, label: 'Sparbanken Rekarne' },
  { index: 34, label: 'Sundsvallsbanken' },
  { index: 35, label: 'Swedbank' },
  { index: 36, label: 'Sörmland Provincial Bank' },
  { index: 37, label: 'Upplandsbanken' },
  { index: 38, label: 'Värmland\'s Provincial Bank' },
  { index: 39, label: 'Västmanland\'s Provincial Bank' },
  { index: 40, label: 'Älvsborgs Provinsbank' },
  { index: 41, label: 'The Bank of Åland' },
  { index: 42, label: 'Östgöta Enskilda Bank' },
  { index: 43, label: 'Other' },
];
