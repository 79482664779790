import React from 'react';

import {
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { PrivateRoute } from '../../PrivateRouter';
import FinancePlanneringPartOne from './FinancePlanneringPartOne/FinancePlanneringPartOne';
import FinancePlanneringPartTwo from './FinancePlanneringPartTwo/FinancePlanneringPartTwo';
import FinancePlanneringPartThree from './FinancePlanneringPartThree/FinancePlanneringPartThree';

const FinancePlannering = () => {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const PartOne = () => <FinancePlanneringPartOne id={id}/>;
  const PartTwo = () => <FinancePlanneringPartTwo id={id}/>;
  const PartThree = () => <FinancePlanneringPartThree id={id}/>;
  return (
    <Switch>
        <PrivateRoute exact path={`${url}/page-one`} component={PartOne} />
        <PrivateRoute exact path={`${url}/page-two`} component={PartTwo} />
        <PrivateRoute exact path={`${url}/page-three`} component={PartThree} />
        <Redirect to={`/dashboard/${id}`} />
    </Switch>
  );
};

export default FinancePlannering;
