/* eslint-disable camelcase */
import React, { Fragment, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select, Spin, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import MobilePhone from './MobilePhone';
import PropTypes from 'prop-types';

import countryEN from '../../constants/countryEN.json';
import { deleteRepresentative } from '../../data/store/clients/clientActions';

const InputUpload = ({ onChange, ...attr }) => {
  return <input style={{ display: 'none' }} onChange={onChange} {...attr} />;
};

const Representative = ({ form, clientId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { company_representive } = useSelector(
    (state) => state.clientReducer.user,
  );
  const [prepearImg, setPrepearImg] = useState(false);

  const optionsPurpose = [
    {
      label: `${t('AUTHORITY_1')}`,
      value: 'Authorized to Sign',
    },
    {
      label: `${t('AUTHORITY_2')}`,
      value: 'Beneficiary Owner',
    },
    {
      label: `${t('AUTHORITY_3')}`,
      value: 'Power of attorney 1',
    },
  ];

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {t(countryEN[data])}
      </Select.Option>
    );
  };

  const getLengthRepresentative = () =>
    form.getFieldsValue(['representative']).representative.length;

  const handleRemove = (name) => {
    if (company_representive[name]?.ID) {
      dispatch(
        deleteRepresentative({
          id: clientId,
          ID: company_representive[name].ID,
        }),
      );
    }
  };

  const handlUploadImg = (event, key, type) => {
    setPrepearImg(true);
    const file = event.target.files[0];
    const { representative } = form.getFieldsValue();
    Object.assign(
      representative[key],
      representative[key]?.documentProves
        ? {
            documentProves: [
              ...representative[key].documentProves,
              { id: uuidv4(), file, type },
            ],
          }
        : { documentProves: [{ id: uuidv4(), file, type }] },
    );
    form.setFieldsValue({ representative });
    setPrepearImg(false);
  };
  const handleDeleteDoc = (key, id, isNew) => {
    const { representative } = form.getFieldsValue();
    const documentArray = representative[key].documentProves.filter(
      (doc) => (isNew && doc?.id !== id) || (!isNew && doc?.ID !== id),
    );
    Object.assign(representative[key], { documentProves: documentArray });
    form.setFieldsValue({ representative });
  };

  const getDocByType = (key, type) => {
    return form
      .getFieldsValue(['representative'])
      ?.representative[
        key
      ]?.documentProves.filter((item) => item.type === type);
  };

  return (
    <Form.List name="representative">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Fragment key={key}>
                  <Form.Item
                    {...restField}
                    label={t('AUTHORITY')}
                    className="purpose-checkbox-group"
                    name={[name, 'authority']}
                  >
                    <Checkbox.Group
                      options={optionsPurpose}
                      className="purpose-checkbox"
                    />
                  </Form.Item>
                  <div className="saving-subtitle customer-subtitle">
                    <span>{t('UPLOAD_DOCUMENT')}</span>
                  </div>
                  <InputUpload
                    id={`upload-${name}`}
                    type="file"
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(event) =>
                      !prepearImg
                        ? handlUploadImg(event, key, 'documentProves')
                        : null
                    }
                  />
                  <label
                    htmlFor={`upload-${name}`}
                    style={{ margin: '16px 0' }}
                  >
                    <div className="upload-button">
                      {prepearImg?.documentProves
                        ? (
                        <Spin />
                          )
                        : (
                        <UploadOutlined />
                          )}
                      <span>{t('CLICK_UPLOAD')}</span>
                    </div>
                  </label>
                  {form.getFieldsValue(['representative'])?.representative[key]
                    ?.documentProves &&
                    getDocByType(key, 'documentProves').map((doc) => {
                      return (
                        <div key={doc.id} className="upload-list">
                          <span>{doc.file.name}</span>
                          <span>{doc.file.size} bit</span>
                          <DeleteOutlined
                            className="upload-list-delete"
                            onClick={() =>
                              handleDeleteDoc(key, doc?.id ?? doc?.ID, !doc?.ID)
                            }
                          />
                        </div>
                      );
                    })}
                  <Form.Item
                    {...restField}
                    label={t('PERSONAL_NUMBER')}
                    name={[name, 'personal_number']}
                    rules={[
                      {
                        required: true,
                        message: 'Enter identification number',
                      },
                      () => ({
                        validator (rule, value) {
                          if (
                            !value ||
                            value.match(
                              /^(\d{4}((0\d)|(1[012]))(([012]\d)|3[01])\d{4}$)/,
                            )
                          ) {
                            return Promise.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('YYYYMMDD-XXXX');
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder={`${t('ENTER')} ${t('PRIVAT_NUMBER')}`}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={t('FIRST_NAME')}
                    name={[name, 'first_name']}
                    rules={[
                      {
                        required: true,
                        message: `${t('ENTER')} ${t('FIRST_NAME')}`,
                      },
                    ]}
                  >
                    <Input placeholder={`${t('ENTER')} ${t('FIRST_NAME')}`} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={t('LAST_NAME')}
                    name={[name, 'last_name']}
                    rules={[
                      {
                        required: true,
                        message: `${t('ENTER')} ${t('LAST_NAME')}`,
                      },
                    ]}
                  >
                    <Input placeholder={`${t('ENTER')} ${t('LAST_NAME')}`} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={t('MAILADRESS')}
                    name={[name, 'email']}
                  >
                    <Input placeholder={t('ENTER_EMAIL_ADDRESS')} />
                  </Form.Item>
                  <MobilePhone repres={name} form={form} />
                  <Form.Item
                    label={t('COUNTRY_CITIZENSHIP')}
                    name={[name, 'county_of_citizenship']}
                    className="risk-profile-lable"
                    rules={[
                      {
                        required: true,
                        message: `${t('ENTER')} ${t('COUNTRY_CITIZENSHIP')}`,
                      },
                    ]}
                  >
                    <Select
                      placeholder={t('SELECT_OPTION')}
                      className="risk-profile-select"
                      type="select"
                    >
                      <Select.Option value="Sweden">
                        {t('Sweden')}
                      </Select.Option>
                      {Object.keys(countryEN).map((item) => {
                        return countrysOption(item);
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={t('COUNTRY_TAX')}
                    name={[name, 'county_of_tax_residence']}
                    className="risk-profile-lable"
                    rules={[
                      {
                        required: true,
                        message: `${t('ENTER')} ${t('COUNTRY_TAX')}`,
                      },
                    ]}
                  >
                    <Select
                      placeholder={t('SELECT_OPTION')}
                      className="risk-profile-select"
                      type="select"
                    >
                      <Select.Option value="Sweden">
                        {t('Sweden')}
                      </Select.Option>
                      {Object.keys(countryEN).map((item) => {
                        return countrysOption(item);
                      })}
                    </Select>
                  </Form.Item>
                  <div className="saving-subtitle customer-subtitle">
                    <span>{t('UPLOAD_ID_DOCUMENT')}</span>
                  </div>
                  <InputUpload
                    id={`upload-id-${name}`}
                    type="file"
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(event) =>
                      !prepearImg
                        ? handlUploadImg(event, key, 'documentId')
                        : null
                    }
                  />
                  <label
                    htmlFor={`upload-id-${name}`}
                    style={{ margin: '16px 0' }}
                  >
                    <div className="upload-button">
                      {prepearImg?.documentId ? <Spin /> : <UploadOutlined />}
                      <span>{t('CLICK_UPLOAD')}</span>
                    </div>
                  </label>
                  {form.getFieldsValue(['representative'])?.representative[key]
                    ?.documentProves &&
                    getDocByType(key, 'documentId').map((doc) => {
                      return (
                        <div key={doc.id} className="upload-list">
                          <span>{doc.file.name}</span>
                          <span>{doc.file.size} bit</span>
                          <DeleteOutlined
                            className="upload-list-delete"
                            onClick={() =>
                              handleDeleteDoc(key, doc?.id ?? doc?.ID, !doc?.ID)
                            }
                          />
                        </div>
                      );
                    })}
                  {getLengthRepresentative() > 1 && (
                    <MinusCircleOutlined
                      className="representative-del-button"
                      onClick={() => {
                        remove(name);
                        handleRemove(name);
                      }}
                    />
                  )}
                  <Divider />
                </Fragment>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add representative
              </Button>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

Representative.propTypes = {
  form: PropTypes.object,
  clientId: PropTypes.string,
};
InputUpload.propTypes = {
  attr: PropTypes.object,
  onChange: PropTypes.func,
};
export default Representative;
