import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Card = ({ star, icon, header, isCheck, ...otherProps }) => {
  return (
      <div className='card-container' {...otherProps}>
        <div className='is-important'>{star}</div>
        <div className="card-icons">
            {icon}
        </div>
        <h4 className="card-header">{header}</h4>
        <div className="is-important">{isCheck}</div>
      </div>
  );
};

Card.propTypes = {
  star: PropTypes.element,
  icon: PropTypes.element,
  header: PropTypes.string,
  isCheck: PropTypes.element
};

export default Card;
