import { actionCreator } from '../../../helpers/actionCreator';

export const GET_INSTRUMENTS_ALL = actionCreator('GET_INSTRUMENTS_ALL');

export const GET_INSTRUMENTS_TBD = actionCreator('GET_INSTRUMENTS_TBD');

export const GET_ASSETS = actionCreator('GET_ASSETS');

export const GET_ASSET_CATEGORIES = actionCreator('GET_ASSET_CATEGORIES');

export const UPDATE_FINANCIAL_ASSET = actionCreator('UPDATE_FINANCIAL_ASSET');

export const UPDATE_INSTRUMENT = actionCreator('UPDATE_INSTRUMENT');

export const CREATE_INSTRUMENT = actionCreator('CREATE_INSTRUMENT');

export const DELETE_INSTRUMENT = actionCreator('DELETE_INSTRUMENT');

export const ASSET_REQUEST = actionCreator('ASSET_REQUEST');

export const CREATE_ASSET_CLASS = actionCreator('CREATE_ASSET_CLASS');

export const DELETE_ASSET_CLASS = actionCreator('DELETE_ASSET_CLASS');

export const UPDATE_ASSET_CLASS = actionCreator('UPDATE_ASSET_CLASS');

export const MAPPING_REQUEST = actionCreator('MAPPING_REQUEST');

export const DELETE_REQUEST = actionCreator('DELETE_REQUEST');

export const GET_CURRENCY = actionCreator('GET_CURRENCY');

export const GET_DIVERSIFICATION = actionCreator('GET_DIVERSIFICATION');
