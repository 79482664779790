/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { AudioVisualizer } from 'react-audio-visualize';

import AudioControls from './AudioControls';
import TrackBar from './TrackBar';

import volumeSVG from './icons/volume.svg';
import muteSVG from './icons/mute.svg';
import repeatSVG from './icons/repeat.svg';
import repeatOneSVG from './icons/repeat-one.svg';
import Timer from './Timer';

const AudioPlayer = ({
  // native props
  src,
  loop = false,
  volume = 0.75,
  muted = false,
  autoplay = false,
  crossOrigin = null,
  preload = 'metadata',
  playbackRate = 1.0,
  // audio element events
  onabort = null,
  oncanplay = null,
  oncanplaythrough = null,
  ondurationchange = null,
  onemptied = null,
  onended = null,
  onerror = null,
  onloadeddata = null,
  onloadedmetadata = null,
  onloadstart = null,
  onpause = null,
  onplay = null,
  onplaying = null,
  onprogress = null,
  onratechange = null,
  onseeked = null,
  onseeking = null,
  onstalled = null,
  onsuspend = null,
  ontimeupdate = null,
  onvolumechange = null,
  onwaiting = null,
  // Audio player props
  minimal = false,
  width,
  trackHeight = 75,
  barWidth = 2,
  gap = 1,
  visualise = true,
  backgroundColor = '#EFEFEF',
  barColor,
  barPlayedColor,
  allowSkip = true,
  skipDuration = 5,
  showLoopOption = true,
  showVolumeControl = true,
  seekBarColor,
  volumeControlColor,
  hideSeekBar = false,
  hideSeekKnobWhenPlaying = false,
}) => {
  const [blob, setBlob] = useState();
  const [audio] = useState(() => new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [internalVolume, setVolume] = useState(volume);
  const [isMuted, setIsMuted] = useState(false);
  const [isLoop, setIsLoop] = useState(loop);

  useEffect(() => {
    fetch(src)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const blob = await response.blob();
        audio.src = URL.createObjectURL(blob);
        audio.volume = volume;
        audio.loop = loop;
        audio.muted = muted;
        audio.autoplay = autoplay;
        audio.crossOrigin = crossOrigin;
        audio.preload = preload;
        audio.playbackRate = playbackRate;
        audio.onabort = onabort;
        audio.oncanplay = oncanplay;
        audio.oncanplaythrough = oncanplaythrough;
        audio.ondurationchange = ondurationchange;
        audio.onemptied = onemptied;
        audio.onended = onended;
        audio.onerror = onerror;
        audio.onloadeddata = onloadeddata;
        audio.onloadedmetadata = onloadedmetadata;
        audio.onloadstart = onloadstart;
        audio.onpause = onpause;
        audio.onplay = onplay;
        audio.onplaying = onplaying;
        audio.onprogress = onprogress;
        audio.onratechange = onratechange;
        audio.onseeked = onseeked;
        audio.onseeking = onseeking;
        audio.onstalled = onstalled;
        audio.onsuspend = onsuspend;
        audio.ontimeupdate = ontimeupdate;
        audio.onvolumechange = onvolumechange;
        audio.onwaiting = onwaiting;

        audio.addEventListener('ended', onAudioEnded);
        setBlob(blob);
        return blob;
      })
      .then(async (blob) => {
        const audioBuffer = await blob.arrayBuffer();
        const audioContext = new AudioContext();
        await audioContext.decodeAudioData(audioBuffer, (buffer) => {
          setDuration(buffer.duration);
        });
      });

    return () => {
      // clean up
      audio.removeEventListener('ended', onAudioEnded);
      audio.pause();
    };
  }, []);

  const onAudioEnded = () => {
    setIsPlaying(false);
    setAudioTime(0);
  };

  const playAudio = () => {
    if (!audio.src) return;
    if (audio.duration !== Infinity) setDuration(audio.duration);

    audio.play();
    setIsPlaying(true);

    // audio.ontimeupdate = () => {
    //   setCurrentTime(audio.currentTime)
    // }

    const handleTimeUpdates = () => {
      if (audio.currentTime >= duration) {
        pauseAudio();
        setAudioTime(0);
        return;
      }
      setCurrentTime(audio.currentTime);

      requestAnimationFrame(handleTimeUpdates);
    };
    requestAnimationFrame(handleTimeUpdates);
  };

  const pauseAudio = () => {
    audio.pause();
    setIsPlaying(false);
  };

  const muteAudio = () => {
    audio.volume = 0;
    setIsMuted(true);
  };

  const unMuteAudio = () => {
    audio.volume = volume;
    setIsMuted(false);
  };

  const setAudioVolume = (vol) => {
    setIsMuted(false);
    setVolume(vol);
    audio.volume = vol;
  };

  const setAudioTime = (time) => {
    audio.currentTime = time;
    setCurrentTime(time);
  };

  const toggleAudioLoop = () => {
    setIsLoop((loop) => !loop);
    audio.loop = !isLoop;
  };

  const skipForwards = () => {
    setAudioTime(Math.min(duration, currentTime + skipDuration));
  };
  const skipBackwards = () => {
    setAudioTime(Math.max(0, currentTime - skipDuration));
  };

  const seekTo = (time) => {
    setAudioTime(time);
  };

  // const seekStart = () => {
  //   audio?.pause()
  // }

  return (
      <div
        style={{
          backgroundColor,
          borderRadius: '8px',
          width,
          display: 'flex',
          gap: 10,
          flexDirection: 'column',
          alignItems: 'center',
          padding: minimal ? '0px 10px' : '10px 20px 15px',
          position: 'relative',
          height: minimal ? '40px' : 'max-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: minimal ? 10 : 15,
            width,
          }}
        >
          {minimal
            ? (
            <AudioControls
              isPlaying={isPlaying}
              allowSkip={false}
              onPauseClick={pauseAudio}
              onPlayClick={playAudio}
              onSkipBackwardClick={skipBackwards}
              onSkipForwardClick={skipForwards}
            />
              )
            : (
            <Timer seconds={currentTime} />
              )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridTemplateRows: '1fr',
              width: width * 0.7,
              height: trackHeight,
            }}
          >
            {visualise && blob && (
              <AudioVisualizer
                width={width * 0.7}
                height={trackHeight}
                barWidth={barWidth}
                gap={gap}
                blob={blob}
                currentTime={currentTime}
                backgroundColor={backgroundColor}
                barColor={barColor}
                barPlayedColor={barPlayedColor}
                style={{
                  gridColumn: 1 / 1,
                  gridRow: 1 / 1,
                  placeSelf: 'center',
                }}
              />
            )}
            <TrackBar
              total={duration}
              current={currentTime}
              setCurrent={seekTo}
              color={seekBarColor}
              showTrack={!hideSeekBar}
              showKnob={!(hideSeekKnobWhenPlaying && isPlaying)}
              data-testid="seek-trackbar"
            />
          </div>
          <Timer
            seconds={minimal && isPlaying ? currentTime : Math.round(duration)}
          />
          <div
            style={{
              display: 'flex',
              gap: 8,
              justifySelf: 'flex-end',
              width: 80,
            }}
          >
            <img
              src={!isMuted && volume > 0 ? volumeSVG : muteSVG}
              onClick={isMuted ? unMuteAudio : muteAudio}
              title={isMuted ? 'Un-mute' : 'Mute'}
              style={{
                cursor: 'pointer',
                height: 24,
                width: 24,
              }}
            />
            <TrackBar
              current={internalVolume}
              total={1}
              setCurrent={setAudioVolume}
              color={volumeControlColor}
              data-testid="volume"
            />
          </div>
        </div>
        {/* Controls */}

        {!minimal && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              placeItems: 'center',

              width: '98%',
            }}
          >
            {showLoopOption && (
              <img
                src={isLoop ? repeatSVG : repeatOneSVG}
                onClick={toggleAudioLoop}
                title={'Loop'}
                style={{
                  cursor: 'pointer',
                  justifySelf: 'flex-start',
                  height: 16,
                  width: 16,
                }}
              />
            )}
            <AudioControls
              isPlaying={isPlaying}
              allowSkip={allowSkip}
              onPauseClick={pauseAudio}
              onPlayClick={playAudio}
              onSkipBackwardClick={skipBackwards}
              onSkipForwardClick={skipForwards}
            />
            {showVolumeControl && (
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  justifySelf: 'flex-end',
                  width: 80,
                }}
              >
                <img
                  src={!isMuted && volume > 0 ? volumeSVG : muteSVG}
                  onClick={isMuted ? unMuteAudio : muteAudio}
                  title={isMuted ? 'Un-mute' : 'Mute'}
                  style={{
                    cursor: 'pointer',
                    height: 24,
                    width: 24,
                  }}
                />
                <TrackBar
                  current={internalVolume}
                  total={1}
                  setCurrent={setAudioVolume}
                  color={volumeControlColor}
                  data-testid="volume"
                />
              </div>
            )}
          </div>
        )}
      </div>
  );
};

export default AudioPlayer;
