import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Form, Input, Select } from 'antd';
import phones from '../../helpers/countries/phoneCodeEN.json';
import countryEN from '../../helpers/countries/countryEN.json';
import countrySW from '../../helpers/countries/countrySW.json';
import flags from '../../helpers/countries/flags.json';
import './style.scss';

const CustomPhoneInput = ({ nameCode, ...rest }) => {
  const { i18n } = useTranslation('');
  const [country, setCountry] = useState(phones);
  const inputRef = useRef(null);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  useEffect(() => {
    let countryList = [];
    if (currentLang === 'en') {
      countryList = [...countryEN];
    } if (currentLang === 'sw') {
      countryList = [...countrySW];
    } else {
      countryList = [...countryEN];
    }
    const translate = phones.map(i => {
      const getName = (code) => countryList?.find(i => i.alpha2 === code.toLowerCase())?.name;
      const name = getName(i.code) || '';
      return (
        { ...i, name }
      );
    });
    setCountry(translate);
  }, [currentLang]);

  const filterOption = (inputValue, option) => {
    return (
      typeof inputValue === 'string' && isNaN(+inputValue)
        ? option?.children[1]?.props?.children.toLowerCase().includes(inputValue.toLowerCase())
        : option.value.indexOf(inputValue) !== -1
    );
  };

  const handleSelect = () => {
    inputRef.current.focus();
  };
  const SelectBefore = () => (
    <Form.Item name={nameCode} noStyle>
      <Select
        className='select-phonecode'
        showSearch
        popupMatchSelectWidth={360}
        optionLabelProp="label"
        filterOption={(inputValue, option) => filterOption(inputValue, option) }
        onSelect={handleSelect}
        >
        {
          country?.map(el => (
            <Select.Option key={el.code} value={el.phone}
            label={
              <div className='code-label-wrapper'>
                <img
                  className='flag-image'
                  src={flags[el.code?.toLocaleLowerCase()]} alt=''/>
                <span className='code-text'>{el.phone}</span>
              </div>}>
              <img
                className='flag-image'
                src={flags[el.code?.toLocaleLowerCase()]}
                alt='flag'/>
              <span className='county-text'>{el.name}</span>
              <span className='code-text'>{el.phone}</span>
            </Select.Option>
          ))
        }
      </Select>
    </Form.Item>
  );
  return (
    <div className='phone-number-wrapper' style={{ position: 'relative' }}>
      <SelectBefore />
      <Input
        {...rest}
        ref={inputRef}
        autoComplete="off"
      />
    </div>
  );
};

export default CustomPhoneInput;

CustomPhoneInput.propTypes = {
  nameCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
};
