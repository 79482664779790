export const categoryList = {
  income: [
    {
      key: 1,
      value: 'Salary',
      label: 'SALARY'
    },
    {
      key: 2,
      value: 'Pension',
      label: 'PENSION',
    },
    {
      key: 3,
      value: 'Income from other',
      label: 'INCOME_FROM_OTHER'
    },
    {
      key: 4,
      value: 'Property sale',
      label: 'PROPERTY_SALE'
    },
    {
      key: 5,
      value: 'Return on investments',
      label: 'RETURN_ON_INVESTMENTS'
    },
    {
      key: 6,
      value: 'Sale (other)',
      label: 'SALE_OTHER'
    },
    {
      key: 7,
      value: 'Inheritance',
      label: 'INHERITANCE'
    },
    {
      key: 8,
      value: 'Other',
      label: 'OTHER'
    },
  ],
  expenses: [
    {
      key: 1,
      value: 'Housing',
      label: 'HOUSING'
    },
    {
      key: 2,
      value: 'Health',
      label: 'HEALTH',
    },
    {
      key: 3,
      value: 'Kids',
      label: 'KIDS'
    },
    {
      key: 4,
      value: 'Education',
      label: 'EDUCATION'
    },
    {
      key: 5,
      value: 'Vacation planner',
      label: 'VACATION_PLANNER'
    },
    {
      key: 6,
      value: 'Vehicles',
      label: 'VEHICLES'
    },
    {
      key: 7,
      value: 'Luxury goods',
      label: 'LUXURY_GOODS'
    },
    {
      key: 8,
      value: 'Living expenses',
      label: 'LIVING_EXPENSES'
    },
    {
      key: 9,
      value: 'Gifts',
      label: 'GIFTS'
    },
    {
      key: 10,
      value: 'Others',
      label: 'OTHER'
    },
  ]
};
