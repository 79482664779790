import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { numberWithSpaces } from '../../helpers/formatInteger';
import { formatPercentage } from '../../helpers/formatPercentage';

export const TransactionsTable = ({ data, tabName }) => {
  const { t } = useTranslation('');

  useEffect(() => {}, [data]);

  const columnsBuy = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      align: 'left',
      render: (value) => {
        return value !== null ? value || '- - -' : '';
      },
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      align: 'right',
      render: (value) => value ? formatPercentage(value) : '',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value
          ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr`
          : '';
      },
    },
    {
      title: t('SHARE_PRICE'),
      dataIndex: 'share_price',
      align: 'right',
      render: (value) => {
        return value ? `${value} kr` : '';
      },
    },
    {
      title: t('VALID_UNTIL'),
      dataIndex: 'valid',
      align: 'right',
      render: (value) => {
        return value === undefined ? t('UNTIL_CANCELED') : '';
      },
    },
  ];
  const columnsSell = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      align: 'left',
      render: (value) => {
        return value !== null ? value || '- - -' : '';
      },
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value
          ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr`
          : '';
      },
    },
    {
      title: t('SHARE_PRICE'),
      dataIndex: 'share_price',
      align: 'right',
      render: (value) => {
        return value ? `${value} kr` : '';
      },
    },
    {
      title: t('VALID_UNTIL'),
      dataIndex: 'valid',
      align: 'right',
      render: (value) => {
        return value === undefined ? t('UNTIL_CANCELED') : '';
      },
    },
  ];

  const dataTotal = useMemo(() => {
    const total = data?.reduce((acc, item) => acc + item.value, 0);
    return total;
  }, [data]);

  return (
    <div className="transaction-table">
      <Table
        rowKey={() => uuidv4()}
        rowClassName={(record) =>
          record.key === 'total' ? 'transaction-total-row' : ''
        }
        columns={tabName === 1 ? columnsBuy : columnsSell}
        dataSource={data?.concat({
          key: 'total',
          instrument: t('TOTAL'),
          isin: '',
          unitsNumber: '',
          value: dataTotal,
          valid: ''
        })}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
  );
};

TransactionsTable.propTypes = {
  data: PropTypes.array,
  tabName: PropTypes.number,
};
