import React, { useCallback, useState, useEffect } from 'react';
import { emailRules } from '../../../helpers/validation';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';
import { changeEmail } from '../../../data/store/user/userActions';
import MainButton from '../../MainButton';
import { Input, Form, Typography, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation('');
  const [, forceUpdate] = useState({});
  const advisor = useSelector(state => state.userReducer.advisor);

  const onSubmit = useCallback((email) => {
    dispatch(changeEmail({ ...email, userID: advisor.ID, password: advisor.password }));
  }, [dispatch]);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Row className='send-email-wrapper'>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onSubmit} className='send-email-block'>
          <Title level={3}>{t('SEND_CONFIRMATION_EMAIL')}</Title>

          <Row className='send-email-input-block'>
            <Form.Item
              label={t('EMAIL')}
              name="email"
              rules={emailRules}
            >
              <Input
                placeholder={t('EMAIL')}
                size='large'
                autoComplete='off'/>
            </Form.Item>
          </Row>

        <Form.Item shouldUpdate>
          {() => (
            <MainButton
              type='submit'
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              {t('REGISTER')}
            </MainButton>
          )}
        </Form.Item>
      </Form>
    </Row>
  );
};

export default ChangeEmail;
