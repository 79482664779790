import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';

import Portfolio from '../../components/Portfolio/Portfolio';
import PortfolioEditInf from './PortfolioEditInf';
import { clearCandidates, getPortfolioById } from '../../data/store/portfolio/portfolioActions';
import PortfolioConstraints from './PortfolioConstraints';
import PortfolioFixation from './PortfolioFixation';
import { Button, Col, Layout, Row } from 'antd';
import PortfolioHistory from '../PortfolioHistory/PortfolioHistory';
import PortfolioDetails from '../../components/PortfolioDetails/PortfolioDetails';

const { Header } = Layout;

const PortfolioRoute = ({ userId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { portfolioId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  useEffect(() => {
    return () => {
      dispatch(clearCandidates());
    };
  }, []);

  useEffect(() => {
    dispatch(getPortfolioById({ portfolioId }));
  }, [portfolioId]);

  const suffix = useMemo(() => {
    return pathname.split('/').at(-1);
  }, [pathname]);

  const handleTab = (path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
    } else {
      history.push(`${url}/${path}`);
    }
  };

  return (
    <>
      <div className='portfolio-wrapper'>
      <Header className='headerStyle'>
          <Row className="overview-tabs-wrapper">
              <Col className='tabs-tab-wrapper'>
                <Button
                  type="text"
                  className={`overview-btn ${suffix === 'portfolio-information' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('portfolio-information')}>
                  {t('PORTFOLIO_INFORMATION')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${suffix === 'holdings' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('holdings')}>
                  {t('HOLDINGS')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${suffix === 'constraints' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('constraints')}>
                  {t('CONSTRAINTS')}
                </Button>
                {/* <Button
                  type="text"
                  className={`overview-btn ${suffix === 'history' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('history')}>
                  {t('HISTORY')}
                </Button> */}
              </Col>
          </Row>
      </Header>
      <PortfolioDetails userId={userId} portfolioId={portfolioId} />
        <Switch>
          <Route exact path={`${url}/portfolio-information`}>
            <PortfolioEditInf
              userId={userId}
              portfolioId={portfolioId}
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
            />
          </Route>
          <Route exact path={`${url}/holdings`}>
            <Portfolio
              userId={userId}
              portfolioId={portfolioId}
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
            />
          </Route>
          <Route exact path={`${url}/constraints`}>
            <PortfolioConstraints
              userId={userId}
              portfolioId={portfolioId}
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
            />
          </Route>
          <Route exact path={`${url}/fixation`}>
            <PortfolioFixation
              userId={userId}
              portfolioId={portfolioId}
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
            />
          </Route>
          <Route exact path={`${url}/history`}>
            <PortfolioHistory
              userId={userId}
              portfolioId={portfolioId}
            />
          </Route>
          <Route exact path="*">
            <Redirect to={`${url}/portfolio-information`} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default PortfolioRoute;

PortfolioRoute.propTypes = {
  userId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
