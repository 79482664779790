import React from 'react';

import PropTypes from 'prop-types';

import { InputNumber } from 'antd';
import { ReactComponent as Persent } from '../../assets/persent-icon.svg';

import './style.scss';

const CustomInputNumber = (props) => {
  const { percent, width } = props;
  const newProps = { ...props };
  delete newProps.percent;
  const className = percent
    ? (props?.className ?? '') + ' ' + 'custom-inputnumber persent'
    : (props?.className ?? '') + ' ' + 'custom-inputnumber';
  return (
    <div style={{ position: 'relative' }}>
    <InputNumber
      style={{ width }}
      {...newProps}
      className={className}
      decimalSeparator=','
    />
      {percent && <Persent style={{ position: 'absolute', top: '5px', right: '16px' }}/>}
    </div>
  );
};

export default CustomInputNumber;

CustomInputNumber.propTypes = {
  percent: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
};
