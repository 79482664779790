import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Button, Divider, Form, Select } from 'antd';
import CustomInputNumber from '../../../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import CardSavedItem from './CardSavedItem';
import { useSelector } from 'react-redux';
import { decimalSeparator } from '../../../../helpers/decimalSeparator';
import { textSort } from '../../../../helpers/textSort';

const RecommendedItem = ({ t, name, itemsRecommended, setItemsRecommended }) => {
  const formMap = {
    buying_transactions: Form.useForm(),
    selling_transactions: Form.useForm(),
  };

  const [form] = formMap[name] || Form.useForm();

  const instruments = useSelector(state => state.instrumentsReducer.instruments.list);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ itemsRecommended:', itemsRecommended);
  }, [itemsRecommended]);

  const handleAddItem = () => {
    form.submit();
  };

  const handleDelete = (id) => {
    const newData = itemsRecommended[name].filter(item => item.instrument_id !== id);
    setItemsRecommended({ ...itemsRecommended, [name]: newData });
  };

  const onSubmit = (data) => {
    const newData = [...itemsRecommended[name], { ...data, id: uuidv4() }];
    setItemsRecommended({ ...itemsRecommended, [name]: newData });
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name="transactions-form"
      layout="vertical"
      onFinish={onSubmit}
      className="onboarding-form-body">
        <Form.Item
          label={t('PRODUCT_NAME_ISIN')}
          name={'instrument_id'}
          className="custom-required"
          required={false}
          rules={[
            {
              required: true,
              message: t('PLEASE_SELECT_INSTRUMENT'),
            },
          ]}
        >
          <CustomSelect
            width={'360px'}
            showSearch
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
            filterOption={(inputValue, option) => {
              return inputValue.length > 2
                ? option?.children[0]?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                : true;
            }}
              notFoundContent={<></>}
          >
            {instruments?.slice()?.sort((a, b) => textSort(a.name.toLowerCase(), b.name.toLowerCase()))?.map((i) => (
              <Select.Option key={i.ID} value={i.ID} disabled={itemsRecommended[name].some(el => el.instrument_id === i.ID)}>
                {i.name} {i.isin}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={'amount'}
          label={t('AMOUNT')}
          required={false}
          rules={[
            {
              required: true,
              message: t('PLEASE_ENTER_AMOUNT'),
            },
          ]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={'charge'}
          label={t('CHARGE')}
          required={false}
          rules={[
            {
              required: true,
              message: t('PLEASE_ENTER_CHARGE'),
            },
          ]}
          >
          <CustomInputNumber
            percent
            placeholder={t('0,00')}
            width={'360px'}
          />
        </Form.Item>
        <Button
          disabled={false}
          className="optimise-btn start-script-btn"
          onClick={handleAddItem}>
          {t('ADD')}
        </Button>
        <Divider className='recommended-divider'/>
        <div className='recommended-counter-wrap'>
          <span className='recommended-counter-title'>{t('ADDED_ITEMS')}</span>
          <div className='recommended-counter-number'>{itemsRecommended[name]?.length}</div>
        </div>
        {
          itemsRecommended[name]?.map(item => <CardSavedItem key={item?.id} item={item} t={t} handleDelete={handleDelete}/>)
        }
    </Form>
  );
};

export default RecommendedItem;

RecommendedItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  itemsRecommended: PropTypes.object,
  setItemsRecommended: PropTypes.func,
};
