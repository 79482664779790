import {
  ASSET_REQUEST,
  CREATE_ASSET_CLASS,
  CREATE_INSTRUMENT,
  DELETE_ASSET_CLASS,
  DELETE_INSTRUMENT,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL,
  GET_INSTRUMENTS_TBD,
  MAPPING_REQUEST,
  UPDATE_ASSET_CLASS,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT,
} from './instrumentsActionTypes';

export const getInstrumentsAll = (params) => ({
  type: GET_INSTRUMENTS_ALL.ACTION,
  payload: params,
});

export const getInstrumentsTBD = (offset) => ({
  type: GET_INSTRUMENTS_TBD.ACTION,
  payload: offset,
});

export const getAssets = () => ({ type: GET_ASSETS.ACTION });

export const getAssetCategories = () => ({ type: GET_ASSET_CATEGORIES.ACTION });

export const updateFinancialAsset = (data) => ({
  type: UPDATE_FINANCIAL_ASSET.ACTION,
  payload: data,
});

export const updateInstrument = (data) => ({
  type: UPDATE_INSTRUMENT.ACTION,
  payload: data,
});

export const createInstrument = (data) => ({
  type: CREATE_INSTRUMENT.ACTION,
  payload: data,
});

export const deleteInstrument = (data) => ({
  type: DELETE_INSTRUMENT.ACTION,
  payload: data,
});

export const assetRequest = (data) => ({
  type: ASSET_REQUEST.ACTION,
  payload: data,
});

export const createAssetClass = (data) => ({
  type: CREATE_ASSET_CLASS.ACTION,
  payload: data,
});

export const deleteAssetClass = (id) => ({
  type: DELETE_ASSET_CLASS.ACTION,
  payload: id,
});

export const updateAssetClass = (data) => ({
  type: UPDATE_ASSET_CLASS.ACTION,
  payload: data,
});

export const mappingRequest = (data) => ({
  type: MAPPING_REQUEST.ACTION,
  payload: data,
});

export const deleteRequest = (data) => ({
  type: DELETE_REQUEST.ACTION,
  payload: data,
});

export const getCurrency = () => ({ type: GET_CURRENCY.ACTION });

export const getDiversification = () => ({ type: GET_DIVERSIFICATION.ACTION });
