import { format } from 'date-fns';

export const formatDate = (value) => {
  if (!value) {
    return '-';
  }

  const date = new Date(value);
  return format(date, 'yyyy-MM-dd');
};
