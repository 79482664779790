import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const BarRace = ({ data }) => {
  const [barDataSorted, getBarDataSorted] = useState([]);

  useEffect(() => {
    getBarDataSorted(data.sort((a, b) => a.y - b.y).reverse());
  }, [data]);

  const options = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: '22px',
          width: '250px',
          textOverflow: 'ellipsis',
        },
      }
    },
    chart: {
      marginLeft: 300,
      marginRight: 100,
    },
    title: {
      text: null
    },
    yAxis: {
      opposite: true,
      tickPixelInterval: 150,
      floor: 0,
      ceiling: 100,
      max: 100,
      title: {
        text: null
      },
      labels: {
        format: '{text} %',
      },
    },
    plotOptions: {
      series: {
        maxPointWidth: 30,
        dataLabels: {
          style: {
            fontSize: '18px',
            fontWeight: '500',
            color: '#4D4D4D'
          }
        }
      },
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y:.1f} %',
          style: {
            width: '400px',
          }
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '{point.y:.1f} %',
          clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
        },
      }
    },
    series: [
      {
        type: 'bar',
        name: 'value',
        data: barDataSorted,
      }
    ],
  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

BarRace.propTypes = {
  data: PropTypes.array
};
