import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { resetUserPassword } from '../../data/store/user/userActions';
import { confirmPassRules, passwordRules } from '../../helpers/validation';
import { useHistory } from 'react-router';
import { Form, Input, Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import pic from '../../assets/login-pic.png';
import titleLogo from '../../assets/lt_logo.png';
import { ReactComponent as BlackDots } from '../../assets/black-dots.svg';

import './style.scss';

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  // const isLoading = useSelector(state => state.userReducer.isLoading);
  const [form] = Form.useForm();
  const [resetPasswordCode, setResetPasswordCode] = useState('');
  const [isValid, setIsValid] = useState(false);

  const getCode = useCallback(() => {
    const index = history.location.search.split('').indexOf('=') + 1;
    setResetPasswordCode(history.location.search.slice(index));
  }, [history]);

  useEffect(() => {
    getCode();
  }, [history]);

  const handleValueChange = async () => {
    const allValues = await form.getFieldsValue();
    const { password, passwordConfirm } = allValues;
    if (password?.length > 7 && passwordConfirm?.length > 7) {
      try {
        await form.validateFields();
        setIsValid(true);
      } catch (errorInfo) {
        console.log('🚀 ~ handleValueChange ~ errorInfo:', errorInfo);
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  const onSubmit = useCallback((fields) => {
    const { password } = fields;
    dispatch(resetUserPassword({ password, resetPasswordCode, history }));
  }, [dispatch, resetPasswordCode]);

  return (
    <div className="login-wrapper">
    <div className="login-picture">
      <img src={pic} alt="login-picture" />
      <BlackDots className='login-black-dots' />
    </div>
    <div className="login-form">
      <div className="login-form-title">
        <img src={titleLogo} alt="title logo" />
      </div>
        <Form
          form={form}
          name="reset"
          layout="vertical"
          onFinish={onSubmit}
          className="reset-pass-form">
          <h2 className='pass-setup-title'>{t('YOU_NEW_PASSWORD')}</h2>

          <div className="sign-up-wrapper reset-pass-input">
            <Form.Item
              label={t('PASSWORD')}
              name="password"
              rules={passwordRules}
            >
              <Input.Password
                placeholder={t('PASSWORD')}
                autoComplete='off'
                visible="true"
                className='login-input'
                onChange={handleValueChange}
              />
            </Form.Item>

            <Form.Item
              label={t('CONFIRM_PASSWORD')}
              name="passwordConfirm"
              dependencies={['password']}
              rules={confirmPassRules}
            >
              <Input.Password
                placeholder={t('CONFIRM_PASSWORD')}
                autoComplete='off'
                visible="true"
                className='login-input'
                onChange={handleValueChange}
              />
            </Form.Item>
          </div>

            <Row className='login-submit-btn-wrapper'>
                <Button
                  disabled={!isValid}
                  block
                  onClick={() => form.submit()}
                >
                  {t('SET_UP_PASSWORD')}
                </Button>
            </Row>
        </Form>
      </div>
      </div>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  history: PropTypes.object
};
