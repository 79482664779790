export const questionsOptions = {
  riskQuestion_01: [
    {
      id: 1,
      label: 'IN_LINE_WITH_THE_BANK',
      value: '0'
    },
    {
      id: 2,
      label: 'SOMEWHAT_ABOVE_THE_BANK',
      value: '25'
    },
    {
      id: 3,
      label: 'SUBSTANTIALLY_ABOVE_THE_BANK',
      value: '50'
    },
    {
      id: 4,
      label: 'I_HAVE_A_DEFINED_TARGET',
      value: '100'
    },
  ],
  riskQuestion_02: [
    {
      id: 1,
      label: '1 %',
      value: '10',
    },
    {
      id: 2,
      label: '3 %',
      value: '25',
    },
    {
      id: 3,
      label: '5 %',
      value: '50',
    },
    {
      id: 4,
      label: '7 %',
      value: '75',
    },
    {
      id: 5,
      label: '10 %',
      value: '100',
    },
    {
      id: 6,
      label: '15 %',
      value: '150',
    },
    {
      id: 7,
      label: '20 %',
      value: '200',
    },
  ],
  riskQuestion_03: [
    {
      id: 1,
      label: 'FOCUS_TO_CONTROLL_RISK_AND_LOSSES',
      value: '33',
    },
    {
      id: 2,
      label: 'ACCEPT_A_SOMEWHAT_HIGHER_RISK',
      value: '65',
    },
    {
      id: 3,
      label: 'FOCUS_IS_ON_HIGH_RETURN',
      value: '100',
    },
  ],
  riskQuestion_04: [
    {
      id: 1,
      label: 'NEWS_DOES_NOT_EFFECT',
      value: '100',
    },
    {
      id: 2,
      label: 'WHEN_I_RECIEVE_NEGATIVE_MARKET',
      value: '75',
    },
    {
      id: 3,
      label: 'WHEN_I_RECEIVE_NEGATIVE_NEWS',
      value: '55',
    },
    {
      id: 4,
      label: 'I_GET_MORE_INVOLVED_IN_MY_INVESTMENTS',
      value: '76',
    },
  ],
  riskQuestion_05: [
    {
      id: 1,
      label: 'A_NEED_TO_LOWER_MY_RISK',
      value: '10',
    },
    {
      id: 2,
      label: 'THE_PORTFOLIO_IS_LEFT_INTACT',
      value: '90',
    },
    {
      id: 3,
      label: 'CONTACT_THE_ADVISOR_TO_DISCUSS',
      value: '75',
    },
    {
      id: 4,
      label: 'NONE_ITS_NATURAL_WITH_BIG_FLUCTUATIONS',
      value: '100',
    },
    {
      id: 5,
      label: 'AN_OPPORTUNITY_TO_TAKE_ADVANTAGE',
      value: '125',
    },
  ],
  riskQuestion_06: [
    {
      id: 1,
      label: 'LARGE_AND_SMALL_MOVEMENTS',
      value: '100',
    },
    {
      id: 2,
      label: 'CONTACT_THE_ADVISOR_TO_DISCUSS',
      value: '75',
    },
    {
      id: 3,
      label: 'I_CAN_UNDERSTAND_SMALL_FLUCTUATIONS',
      value: '25',
    },
  ],
  riskQuestion_07: [
    {
      id: 1,
      label: 'FRUSTRATION_OVER_THE_MISSED',
      value: '100',
    },
    {
      id: 2,
      label: 'NOTHING_REALLY_IM_AWARE_THAT',
      value: '50',
    },
    {
      id: 3,
      label: 'I_SPEAK_WITH_MY_ADVISOR_TO_UNDERSTAND',
      value: '75',
    },
    {
      id: 4,
      label: 'NOTHING_THE_PORTFOLIO_IS_BALANCED',
      value: '25',
    },
  ],
  riskQuestion_08: [
    {
      id: 1,
      label: 'COMPARE_MY_RESULT_WITH_THE_SWEDISH',
      value: '125',
    },
    {
      id: 2,
      label: 'COMPARE_MY_RESULT_WIHT_A_GLOBAL',
      value: '100',
    },
    {
      id: 3,
      label: 'I_HAVE_A_SPECIFIC_BENCHMARK',
      value: '75',
    },
    {
      id: 4,
      label: 'I_COMPARE_MY_PORTFOLIO_WITH',
      value: '50',
    },
  ],
  riskQuestion_09: [
    {
      id: 1,
      label: 'NEWS_DOES_NOT_EFFECT',
      value: '100',
    },
    {
      id: 2,
      label: 'WHEN_I_RECIEVE_NEGATIVE_MARKET',
      value: '75',
    },
    {
      id: 3,
      label: 'WHEN_I_RECIEVE_NEGATIVE_NEWS',
      value: '55',
    },
    {
      id: 4,
      label: 'I_GET_MORE_INVOLVED',
      value: '76',
    },
  ],
};
