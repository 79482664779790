import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MainButtonArray = ({
  style,
  type,
  disabled,
  onClick,
  children,
  arrayIconStart,
  arrayIconEnd,
  ...rest
}) => {
  const className = disabled ? 'disabled main-button' : 'main-button';
  return (
    <div style={style} className={className}>
      {arrayIconStart && (
        <svg
          width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' >
          <path
            d='M0.292893 6.79289C-0.0976315 7.18342 -0.0976315 7.81658 0.292893 8.20711L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84315C8.46159 1.45262 8.46159 0.819457 8.07107 0.428932C7.68054 0.0384078 7.04738 0.0384078 6.65685 0.428932L0.292893 6.79289ZM15 6.5H1V8.5H15V6.5Z'
            fill='white' />
        </svg>
      )}
      <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={className + '_innet'}
        {...rest}
      >
        {children}
      </button>
      {arrayIconEnd &&
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.7071 8.20711C15.0976 7.81658 15.0976 7.18342 14.7071 6.79289L8.34315 0.428932C7.95262 0.0384078 7.31946 0.0384078 6.92893 0.428932C6.53841 0.819457 6.53841 1.45262 6.92893 1.84315L12.5858 7.5L6.92893 13.1569C6.53841 13.5474 6.53841 14.1805 6.92893 14.5711C7.31946 14.9616 7.95262 14.9616 8.34315 14.5711L14.7071 8.20711ZM0 8.5H14V6.5H0L0 8.5Z" fill="white"/>
        </svg>

      }
    </div>
  );
};

export default MainButtonArray;

MainButtonArray.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  arrayIconStart: PropTypes.bool,
  arrayIconEnd: PropTypes.bool,
};
