import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Col, Row, Skeleton, Table } from 'antd';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';

const TableBuySell = ({ dataBuySell, isLoading, tab }) => {
  const { t } = useTranslation('');

  const tableData = useMemo(() => {
    if (dataBuySell) {
      const data = [...dataBuySell];
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map((i) => {
        return { ...i, share: (i.value / summ) * 100 };
      });
    }
    return [];
  }, [dataBuySell]);

  const totalData = useMemo(() => {
    if (dataBuySell) {
      const data = [...dataBuySell];
      return data?.reduce((acc, item) => acc + item?.value, 0);
    }
    return 0;
  }, [dataBuySell]);

  const columnsBuySell = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      align: 'right',
    },
    {
      title: t('NO_SHARES'),
      dataIndex: 'unitsNumber',
      align: 'right',
      render: (value) => {
        return value ? `${Math.round(value)}` : '';
      },
    },
    {
      title: t('SHARE_PRICE'),
      dataIndex: 'share_price',
      align: 'right',
      render: (value) => {
        return value ? `${twoDecimalWithSpaces(value)} kr` : '';
      },
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value ? `${twoDecimalWithSpaces(value)} kr` : '';
      },
    },
  ];
  return (
    <div style={{ border: '1px solid #E9EFF6', padding: '31px 0 0' }}>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '32px', padding: '0px 40px' }}
      >
        <Col>
        {/* {isLoading
          ? <Skeleton.Input active={true} size={'small'} />
          : <h2 className="opt-paper-title">
              {t(tab === 'buy' ? 'BUY_TRANSACTIONS' : 'SELL_TRANSACTIONS')}
            </h2>
        } */}
        <Skeleton avatar={false} title={false} paragraph={{ rows: 1, width: 300 }} active loading={isLoading}>
          <h2 className="opt-paper-title">
              {t(tab === 'buy' ? 'BUY_TRANSACTIONS' : 'SELL_TRANSACTIONS')}
            </h2>
        </Skeleton>
        </Col>
        <Col>
        <Skeleton avatar={false} title={false} paragraph={{ rows: 1, width: 200 }} active loading={isLoading}>
          <>
            <span className="holdings-text">{t('TOTAL')}</span>
            <span className="holdings-value">{`${twoDecimalWithSpaces(totalData)} kr`} </span>
          </>
        </Skeleton>
        </Col>
      </Row>
      <Table
        className='table-buy-sell'
        rowKey={() => uuidv4()}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        columns={columnsBuySell}
        dataSource={tableData}
        loading={isLoading}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
  );
};

export default TableBuySell;

TableBuySell.propTypes = {
  tab: PropTypes.string,
  dataBuySell: PropTypes.array,
  isLoading: PropTypes.bool,
};
