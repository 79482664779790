import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Button, Form, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import { getInstrumentsAll } from '../../../data/store/instruments/instrumentsActions';
import { useDispatch } from 'react-redux';
import { typeOfFeeOptions } from '../data/SelectOptions';
import { banksList } from '../data/BankList';

const CategoryAssignmentAgreement = ({ t, onboardingData, setOnboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
  }, []);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    setOnboardingData({ ...onboardingData, data: { ...onboardingData?.data || {}, ...data } });
  };

  return (
    <div className="card-right-wrap">
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body"
      >
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('ASSIGNMENTS_AND_FEES')}
          </h2>
        </div>
        <Form.Item
          className="custom-required"
          name={['assignment_agreement', 'assignments_and_fees', 'deposit_fee']}
          label={t('DEPOSIT_FEE')}
          required={false}
          // rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            disabled
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['assignment_agreement', 'assignments_and_fees', 'fee_persent']}
          label={t('ENTER_FEE_IN')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            percent
            min={0}
            step={0.01}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          label={t('TYPE_OF_FEE')}
          name={['assignment_agreement', 'assignments_and_fees', 'type_of_fee']}
          className="custom-required"
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}>
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}>
            {typeOfFeeOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['assignment_agreement', 'assignments_and_fees', 'fee']}
          label={t('ENTER_FEE_IN')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}>
          <CustomInputNumber
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto', marginTop: '40px' }}>
          <h2 className="onbording-card-header complet-step">
            {t('DEFAULT_ACCOUNT')}
          </h2>
        </div>
        <Form.Item
          label={t('BANK')}
          name={['assignment_agreement', 'default_account', 'bank']}
          className="custom-required"
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}>
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}>
            {banksList?.map((i) => (
              <Select.Option key={i.index} value={i.label}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['assignment_agreement', 'default_account', 'clearing_number']}
          label={t('CLEARING_NUMBER')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}>
          <CustomInputNumber
            placeholder={t('CLEARING_NUMBER')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['assignment_agreement', 'default_account', 'account_number']}
          label={t('ACCOUNT_NUMBER')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}>
          <CustomInputNumber
            placeholder={t('ACCOUNT_NUMBER')}
            width={'360px'} />
        </Form.Item>
        <div className="onbording-check-wrap">
          <Form.Item
            name={['assignment_agreement', 'default_account', 'allow_account_numbers']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('ALLOW_ACCOUNT_NUMBERS')}</CustomCheckbox>
          </Form.Item>
        </div>
        <div style={{ width: '327px', margin: '0 auto', marginTop: '40px' }}>
          <h2 className="onbording-card-header complet-step">
            {t('AGREEMENT')}
          </h2>
        </div>
        <div className="onbording-check-wrap">
          <Form.Item
            name={['assignment_agreement', 'agreement', 'agreement_option_1']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('AGREEMENT_CHECK_1')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['assignment_agreement', 'agreement', 'agreement_option_2']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('AGREEMENT_CHECK_2')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['assignment_agreement', 'agreement', 'agreement_option_3']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('AGREEMENT_CHECK_3')}</CustomCheckbox>
          </Form.Item>
        </div>

        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}>
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryAssignmentAgreement;

CategoryAssignmentAgreement.propTypes = {
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
