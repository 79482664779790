import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { Typography, Switch, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButton from '../../../components/MainButton';
import { Menu } from '../../../assets';

import './style.scss';

const { Title } = Typography;

const FixedAssets = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const toggleSwitch = useCallback(() => {
    setDisabled(!disabled);
  }, [disabled]);
  const goToScopeAndInvestable = useCallback(() => {
    history.push('/scope-and-investable-capital/:id');
  }, [history]);

  return (
    <Form name='horizontal_login' layout='vertical'>
    <Title style={{ display: 'flex', justifyContent: 'center' }}>
      Ekonomisk situation
    </Title>
    <Container>
      <header className='header'>
        <h4 className='ekonomik-header'>Finansiella tillgångar</h4>
        <Switch onChange={toggleSwitch} defaultChecked={disabled} />
      </header>
      <div className='ekonomik-subtitle'>
        <span>Finansiella investeringar</span>
        <span>28 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <div className='ekonomik-subtitle'>
        <span>Likvida medel</span>
        <span>5 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <div className='ekonomik-subtitle'>
        <span>Pensionssparande</span>
        <span>10 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <div className='ekonomik-subtitle'>
        <span>Övriga finansiella tillgångar</span>
        <span>10 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <Form.Item label={'Kommentar'} style={{ marginTop: 40 }}>
        <TextArea type='text' />
      </Form.Item>
    </Container>
    <Container>
      <header className='header'>
        <h4 className='ekonomik-header'>Fasta tillgångar</h4>
      </header>
      <div className='ekonomik-subtitle'>
        <span>Boende</span>
        <span>15 000 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <div className='ekonomik-subtitle'>
        <span>Övriga fasta tillgångar</span>
        <span>5 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <Form.Item label={'Kommentar'} style={{ marginTop: 40 }}>
        <TextArea type='text' />
      </Form.Item>
    </Container>
    <Container>
      <header className='header'>
        <h4 className='ekonomik-header'>Skulder</h4>
      </header>
      <div className='ekonomik-subtitle'>
        <span>Bolån</span>
        <span>3 000 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <div className='ekonomik-subtitle'>
        <span>Övriga skulder</span>
        <span>500 000 kr</span>
      </div>
      <Form.Item>
        <Input type='range' disabled={disabled} />
      </Form.Item>
      <Form.Item label={'Kommentar'} style={{ marginTop: 40 }}>
        <TextArea type='text' />
      </Form.Item>
    </Container>
    <div className='btn-container'>
      <MainButton style={{ width: 150 }} type='link'>
        Gå tillbaka
      </MainButton>
      <Menu style={{ width: 85 }} />
      <MainButton style={{ width: 150 }} type='submit' onClick={goToScopeAndInvestable}>
        Gå vidare
      </MainButton>
    </div>
  </Form>
  );
};

export default FixedAssets;
