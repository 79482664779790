import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Radio, Space } from 'antd';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { useDispatch } from 'react-redux';
import { setOnboardingVerifed } from '../../../data/store/clients/clientActions';

const CategoryBasicPrerequisites = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingData) {
      form.setFieldsValue(onboardingData?.result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('BASIC_PREREQUISITES_MANAGEMENT')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body" >
        <Form.Item
          label={t('ABLE_TO_LIQUIDATE_THE_HOLDINGS')}
          name={['prerequisites', 'general_questions', 'liquidate_holdings']}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group className="checkbox-block">
            <Space direction="horizontal" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['prerequisites', 'general_questions', 'future_generation']}
          className="onboarding-textarea-purpose"
          label={t('ANY_THOUGHTS_ON_HOW_FUTURE')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['prerequisites', 'general_questions', 'time_horizon']}
          label={t('TIME_HORIZON')}>
          <CustomInputNumber placeholder='0,00' min={1} />
        </Form.Item>
        <Form.Item
          name={['prerequisites', 'general_questions', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT_TIME_HORIZON')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200} />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['prerequisites', 'general_questions', 'buffer_capital']}
          label={t('BUFFER_CAPITAL')}>
          <CustomInputNumber placeholder='0,00' min={0}/>
        </Form.Item>

        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording} >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryBasicPrerequisites;

CategoryBasicPrerequisites.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
