import { actionCreator } from '../../../helpers/actionCreator';

export const GET_ADVISORS = actionCreator('GET_ADVISORS');

export const GET_ADVISORS_AWAIT_APPROVE = actionCreator('GET_ADVISORS_AWAIT_APPROVE');

export const ACTIVATE_ADVISOR = actionCreator('ACTIVATE_ADVISOR');

export const DEACTIVATE_ADVISOR = actionCreator('DEACTIVATE_ADVISOR');

export const UPDATE_ADVISOR = actionCreator('UPDATE_ADVISOR');

export const CREATE_ADVISOR = actionCreator('CREATE_ADVISOR');

export const REINVITE_ADVISOR = actionCreator('REINVITE_ADVISOR');

export const DELETE_ADVISOR = actionCreator('DELETE_ADVISOR');
