import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useParams, useHistory } from 'react-router-dom';
import { confirmPassRules, passwordRules } from '../../helpers/validation';
import { Button, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
// acceptInvitation,
// getInvitationInfo,
} from '../../data/store/clients/clientActions';

import pic from '../../assets/login-pic.png';
import titleLogo from '../../assets/lt_logo.png';
import { ReactComponent as BlackDots } from '../../assets/black-dots.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';

import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { resetUserPassword } from '../../data/store/user/userActions';

import './style.scss';

const SetUserPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { invitation } = useParams();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const [isValid, setIsValid] = useState(false);

  const handleValueChange = async () => {
    const allValues = await form.getFieldsValue();
    const { password, passwordConfirm, agreement } = allValues;
    if (password?.length > 7 && passwordConfirm?.length > 7 && agreement) {
      try {
        await form.validateFields();
        setIsValid(true);
      } catch (errorInfo) {
        console.log('🚀 ~ handleValueChange ~ errorInfo:', errorInfo);
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  // const { invitationInfo } = useSelector((state) => state.clientReducer);

  // useEffect(() => {
  //   dispatch(getInvitationInfo({ inviteHash: invitation }));
  // }, []);

  const handleTermClick = () => {
    console.log('Terms');
  };

  const onSubmit = (fields) => {
    const data = {
      password: fields.password,
      resetPasswordCode: invitation,
      history,
    };
    dispatch(resetUserPassword(data));
  };

  return (
    <div className="login-wrapper">
      <div className="login-picture">
        <img src={pic} alt="login-picture" />
        <BlackDots className='login-black-dots' />
      </div>
      <div className="login-form">
        <div className="login-form-title">
          <img src={titleLogo} alt="title logo" />
        </div>
        <Form
          form={form}
          name="reset"
          layout="vertical"
          onFinish={onSubmit}
          className="sign-up-container"
        >
          <div>
            <h2 className='pass-setup-title'>{t('SET_USER_PASSWORD')}</h2>
          </div>
          <div className="sign-up-wrapper reset-pass-input">
            <Form.Item
              label={t('PASSWORD')}
              name="password"
              rules={passwordRules}
            >
              <Input.Password
                placeholder={t('PASSWORD')}
                className='login-input'
                visible="true"
                autoComplete="off"
                onChange={handleValueChange}
              />
            </Form.Item>

            <Form.Item
              label={t('CONFIRM_PASSWORD')}
              name="passwordConfirm"
              dependencies={['password']}
              rules={confirmPassRules}
            >
              <Input.Password
                placeholder={t('CONFIRM_PASSWORD')}
                className='login-input'
                visible="true"
                autoComplete="off"
                onChange={handleValueChange}
              />
            </Form.Item>
          </div>

          <div>
            <h3 className='pass-setup-term-title'>{t('TERM_AND_CONDITIONS')}
            <Arrow className='terms-link' onClick={handleTermClick}/>
            </h3>
            <div className="agreement-block reset-pass-agreement">
              <Form.Item name="agreement" valuePropName="checked">
                <CustomCheckbox
                  onChange={handleValueChange}>
                  {t('AGREEMENT_TEXT')}
                </CustomCheckbox>
              </Form.Item>
            </div>
          </div>
              <Row className='login-submit-btn-wrapper'>
                <Button
                  disabled={!isValid}
                  block
                  onClick={() => form.submit()}>
                  {t('SUBMIT')}
                </Button>
              </Row>

        </Form>
      </div>
    </div>
  );
};

export default SetUserPassword;
