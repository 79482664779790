import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

// 1 - cache
// 2 - unlisted
// 3  - listed
export class InstrumentsService {
  static getAllInstruments = async (data) => {
    const { limit, offset = 0, type } = data;
    return type === 'cashes-only'
      ? await axios.get(API_URLS.ALL_INSTRUMENTS, {
        params: { limit, offset, order: 'DESC', display_type: 1 },
      })
      : type === 'without-cashes'
        ? await axios.get(API_URLS.ALL_INSTRUMENTS, {
          params: { limit, offset, order: 'DESC', display_type: 3 },
        })
        : await axios.get(API_URLS.ALL_INSTRUMENTS, {
          params: { limit, offset, order: 'DESC' },
        });
  };

  static getInstrumentsTBD = async (data) => {
    return await axios.get(API_URLS.INSTRUMENTS_TBD, {
      params: {
        limit: data.limit,
        offset: data.offset,
        status: 'ASSET_REQUEST_STATUS_NEW',
        order: 'DESC',
      },
    });
  };

  static getAssetsAll = async () => {
    return await axios.get(API_URLS.ASSETS);
  };

  static getCategoriesAll = async () => {
    return await axios.get(API_URLS.ASSETS_CATEGORIES);
  };

  static updateAsset = async (data) => {
    return await axios.put(`${API_URLS.UPDATE_ASSETS}/${data.ID}`, data.asset);
  };

  static instrumentUpdate = async (values) => {
    const { id, data } = values;
    return await axios.put(`${API_URLS.UPDATE_INSTRUMENT}/${id}`, data);
  };

  static create = async (data) => {
    return await axios.post(API_URLS.CREATE_INSTRUMENT, data);
  };

  static delete = async (id) => {
    return await axios.delete(`${API_URLS.UPDATE_INSTRUMENT}/${id}`);
  };

  static requestAsset = async (data) => {
    const { id, ...rest } = data;
    return await axios.put(`${API_URLS.INSTRUMENTS_TBD}/${id}/resolve`, {
      ...rest,
    });
  };

  static createAssetClass = async (data) => {
    return await axios.post(`${API_URLS.CREATE_ASSET_CLASS}`, data);
  };

  static deleteAssetClass = async (id) => {
    return await axios.delete(`${API_URLS.CREATE_ASSET_CLASS}/${id}`);
  };

  static updateAssetClass = async (data) => {
    const { id, ...rest } = data;
    return await axios.put(`${API_URLS.CREATE_ASSET_CLASS}/${id}`, {
      ...rest,
    });
  };

  static deleteRequest = async (id) => {
    return await axios.delete(`${API_URLS.INSTRUMENTS_TBD}/${id}`);
  };

  static getCurrency = async () => {
    return await axios.get(API_URLS.GET_CURRENCY);
  };

  static getDiversification = async () => {
    return await axios.get(API_URLS.GET_DIVERSIFICATION);
  };
}
