import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { getInstrumentsAll, mappingRequest } from '../../data/store/instruments/instrumentsActions';
import { MAPPING_REQUEST } from '../../data/store/instruments/instrumentsActionTypes';
import { mappingEditPortfolio } from '../../data/store/portfolio/portfolioActions';
import CustomSelect from '../CustomSelect/CustomSelect';

const MappingInstrument = ({ openConnectModal, setConnectingKey, connectingKey, data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const instruments = useSelector(state => state.instrumentsReducer.instruments.list);
  const mapingError = useSelector(state => state.instrumentsReducer.mapingError);

  const mapping = Form.useWatch('instrument', form);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const getRequest = (key) => {
    return key ? data?.find(i => i.ID === key) : undefined;
  };
  const getInstrumentISIN = (key) => {
    return key ? instruments?.find(i => i.ID === +key)?.isin : '';
  };

  useEffect(() => {
    if (!instruments?.length) {
      dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    }
  }, []);

  const handleCloseModal = () => {
    form.resetFields();
    dispatch({ type: MAPPING_REQUEST.ERROR, payload: null, });
    setConnectingKey(null);
  };
  const handleChange = () => {
    dispatch({ type: MAPPING_REQUEST.ERROR, payload: null, });
  };

  const onSubmit = async () => {
    const feilds = await form.validateFields();
    const data = {
      instrumentID: +feilds?.instrument.key,
      id: getRequest(connectingKey)?.ID,
      portfolioContentID: getRequest(connectingKey)?.portfolioContentID,
      callback: handleCloseModal,
    };
    dispatch(mappingRequest(data));
  };
  const handlePortfolio = (portfolioID) => {
    if (portfolioID) {
      dispatch(mappingEditPortfolio({ portfolioID, history }));
      dispatch({ type: MAPPING_REQUEST.SUCCESS });
    }
  };

  return (
    <Modal
      width={650}
      className='mapping-del-modal'
      centered
      open={openConnectModal}
      onCancel={handleCloseModal}
      footer={false}>
      <h2 className="mapping-modal-header">{t('CONNECT_INSTRUMENT')}</h2>
      <p className="mapping-modal-text">{t('MAP_INSTRUMENT')}</p>
      <Form
        form={form}
        layout='vertical'
        onFinish={onSubmit}
        id='mapping-instrument'>
        <Form.Item
          className="instrument-select"
          name='instrument'
          rules={[
            {
              required: true,
              message: `${t('SELECT_INSTRUMENT_FOR')}`,
            },
          ]}
        >
          <CustomSelect
            showSearch
            placeholder={t('SELECT_INSTRUMENT')}
            className="custom-select"
            labelInValue
            onChange={handleChange}
            filterOption={(inputValue, option) =>
              inputValue.length > 2
                ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                : true
              }
              notFoundContent={<></>}
          >
            {
              instruments?.slice()?.sort((a, b) => textSort(a.name, b.name, 1))
                .map((item) => {
                  return <Select.Option key={item.ID} value={item.name} >{item.name}</Select.Option>;
                })
            }
          </CustomSelect>
        </Form.Item>
        <p className='maping-helper'>{t('CONNECT')}:</p>
        <p>{getRequest(connectingKey)?.instrumentName ?? ''}</p>
        <p className='maping-helper'>{t('MAPPING_TO')}</p>
        <p>{mapping?.label || ''} - - - {getInstrumentISIN(mapping?.key)}</p>
        {mapingError &&
          <>
            <p className='maping-error'>{mapingError?.message}</p>
            <p className='maping-error-text'>{t('ERROE_TEXT_MODAL')}
            <Button type='text' onClick={() => handlePortfolio(mapingError?.portfolio_id)}>
              {t('LINK_EDIT_PORTFOLIO')}  id: {mapingError?.portfolio_id}
            </Button>
            </p>
          </>
        }

      </Form>
      <Row
          gutter={16}
          justify={'center'}
          align={'middle'}
          style={{ marginTop: '50px' }}
        >
          <Col>
            <Button
              className="del-cancel-button"
              onClick={handleCloseModal}
            >
              {t('CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button
              className="del-ok-button"
              onClick={() => form.submit()}
              disabled={!!mapingError}
              >
              {t('CONNECT')}
            </Button>
          </Col>
        </Row>
    </Modal>
  );
};

export default MappingInstrument;

MappingInstrument.propTypes = {
  openConnectModal: PropTypes.bool,
  setConnectingKey: PropTypes.func,
  connectingKey: PropTypes.number,
  data: PropTypes.array,
};
