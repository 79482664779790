import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { Typography, Switch, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../components/Container';
import MainButton from '../../components/MainButton';
import { Menu } from '../../assets';
import { FIXED_ASSETS } from '../../constants/routes';
import './style.scss';

const { Title } = Typography;

const IncomeAndExpenses = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const toggleSwitch = useCallback(() => {
    setDisabled(!disabled);
  }, [disabled]);

  const goToEkonomikRiskPartTwo = useCallback(() => {
    history.push(FIXED_ASSETS);
  }, [history]);
  return (
    <Form name='horizontal_login' layout='vertical'>
      <Title style={{ display: 'flex', justifyContent: 'center' }}>
        Ekonomisk situation
      </Title>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>Inkomst och utgifter</h4>
          <Switch onChange={toggleSwitch} defaultChecked={disabled} />
        </header>
        <div className='ekonomik-subtitle'>
          <span>Inkomst från anställning</span>
          <span>28 000 kr</span>
        </div>
        <Form.Item label={'Efter skatt'}>
          <Input type='range' disabled={disabled} />
        </Form.Item>
        <div className='ekonomik-subtitle'>
          <span>Annan inkomst</span>
          <span>3 000 kr</span>
        </div>
        <Form.Item>
          <Input type='range' disabled={disabled} />
        </Form.Item>
        <div className='ekonomik-subtitle'>
          <span>Utgifter</span>
          <span>21 000 kr</span>
        </div>
        <Form.Item label={'Efter skatt'}>
          <Input type='range' disabled={disabled} />
        </Form.Item>
        <div className='ekonomik-subtitle'>
          <span>Överskott</span>
          <span>10 000 kr</span>
        </div>
        <Form.Item label={'Kommentar'} style={{ marginTop: 40 }}>
          <TextArea type='text' />
        </Form.Item>
      </Container>
      <div className='btn-container'>
        <MainButton style={{ width: 150 }} type='link'>
          Gå tillbaka
        </MainButton>
        <Menu style={{ width: 85 }} />
        <MainButton style={{ width: 150 }} type='submit' onClick={goToEkonomikRiskPartTwo}>
          Gå vidare
        </MainButton>
      </div>
    </Form>
  );
};

export default IncomeAndExpenses;
