/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { clearOnboarding } from '../../data/store/clients/clientActions';
import { OnboardingPoints } from './data/OnboardingPoints';
import { validateCategiry } from './data/ValidateCategiry';
import StorageService from '../../services/StorageService';
import SessionService from '../../services/SessionService';

import AudioPlayer from '../../components/Player/AudioPlayer';
import { ReactComponent as Array } from './img/array-icon.svg';

import CategoryClientInformation from './CategoryList/CategoryClientInformation';
import CategoryPurposeMatter from './CategoryList/CategoryPurposeMatter';
import CategoryFinancialSituation from './CategoryList/CategoryFinancialSituation';
import CategoryBasicPrerequisites from './CategoryList/CategoryBasicPrerequisites';
import CategoryKYC from './CategoryList/CategoryKYC';
import CategoryRiskTolerance from './CategoryList/CategoryRiskTolerance';
import CategoryRiskProfile from './CategoryList/CategoryRiskProfile';
import CategoryExperienceBackground from './CategoryList/CategoryExperienceBackground';
import CategoryTypeOfAdvice from './CategoryList/CategoryTypeOfAdvice';
import CategoryAssignmentAgreement from './CategoryList/CategoryAssignmentAgreement';
import CategoryTypeOfAccount from './CategoryList/CategoryTypeOfAccount';
import CategorySigning from './CategoryList/CategorySigning';

import './style.scss';

const CategoryItem = ({ t, name, status, selected }) => {
  return (
    <div className={selected ? 'category-item selected' : 'category-item'}>
      <div className="category-colunm">
        <div
          className={`category-checkbx ${status === 'partially' && 'partially'} 
              ${status === 'full' && 'full'}`}
        />
        <p className="category-name">{t(name)}</p>
      </div>
      <div className="category-colunm">
        <Array />
      </div>
    </div>
  );
};

const OnboardingCategory = ({ userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  const { onbording } = useSelector((state) => state.clientReducer);
  const [onboardingData, setOnboardingData] = useState(null);

  const token = StorageService.getJWTToken() || SessionService.getJWTToken();
  const [audioSrc, setAudioSrc] = useState(null);

  useEffect(() => {
    if(onboardingData) {
      validateCategiry(onboardingData);
      const point = OnboardingPoints.find(i => i.value === category);
      setSelectedCategory(point);
    }
  }, [onboardingData]);

  useEffect(() => {
    setOnboardingData(onbording);
  }, [onbording]);

  useEffect(() => {
    if (onbording?.audio_link) {
      fetch(onbording?.audio_link, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          setAudioSrc(objectURL);
        })
        .catch((error) => {
          console.error(
            'There was a problem with your fetch operation:',
            error,
          );
        });
    }
  }, [onbording]);
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');

  useEffect(() => {
    if (!category) {
      history.push(`${url}?category=signing`);
    }
    const point = OnboardingPoints.find(
      (i) => i.value === category || i.value === 'client-information',
    );
    setSelectedCategory(point);
    return () => {
      dispatch(clearOnboarding());
    };
  }, []);

  const [selectedCategory, setSelectedCategory] = useState({
    value: 'Signing',
    label: 'SIGNING',
  });

  const handleSelectCategory = (value, label) => {
    if (value !== 'type-of-account') {
      history.push(`${url}?category=${value}`);
      setSelectedCategory({ value, label });
    }
  };

  return (
    <div
      className="profile-wrap onboarding-wrap"
      style={{ position: 'relative' }}
    >
      <div style={{ padding: '35px 24px', height: '100%', overflowY: 'auto' }}>
        <Container widthP={100} height={'670px'} padding="24px 0">
          <div className="onbording-card-wrapper">
            <div className="onbording-card card-left">
              <div className="onbording-step-wrapper step-signing">
                <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
                <p className="onbording-card-description ">
                  {t('CHECK_YOUR_CLIENT_INFO')}
                </p>
                <div className="finplan-category-list-wrapper onboarding-category-wrap">
                  {OnboardingPoints.map((i) => {
                    return (
                      <div
                        key={i.key}
                        className={
                          i.value === 'type-of-account'
                            ? 'onboarding-category-disabled'
                            : 'onboarding-category-item'
                        }
                        onClick={() => handleSelectCategory(i.value, i.label)}
                      >
                        <CategoryItem
                          name={i.label}
                          status={validateCategiry(onboardingData)[i.value]}
                          selected={selectedCategory?.value === i.value}
                          t={t}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="onbording-card card-right">
              {category === 'type-of-account' && (
                <CategoryTypeOfAccount
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'client-information' && (
                <CategoryClientInformation
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'purpose-matter' && (
                <CategoryPurposeMatter
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'financial-situation' && (
                <CategoryFinancialSituation
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'basic-prerequisites-for-management' && (
                <CategoryBasicPrerequisites
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'know-your-client' && (
                <CategoryKYC
                  userId={userId}
                  token={token}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'risk-tolerance' && (
                <CategoryRiskTolerance
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'risk-profile' && (
                <CategoryRiskProfile
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'experience-background' && (
                <CategoryExperienceBackground
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'type-of-advice' && (
                <CategoryTypeOfAdvice
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'assignment-agreement' && (
                <CategoryAssignmentAgreement
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
              {category === 'signing' && (
                <CategorySigning
                  userId={userId}
                  t={t}
                  onboardingData={onboardingData}
                  setOnboardingData={setOnboardingData}
                />
              )}
            </div>
          </div>
        </Container>
      </div>
      {onboardingData?.audio_link && (
        <div className="audioplayer-wrapper">
          {audioSrc ? (
            <AudioPlayer
              src={audioSrc}
              minimal={true}
              width={850}
              trackHeight={40}
              barWidth={1}
              gap={1}
              visualise={true}
              backgroundColor="#000000"
              barColor="#FFFFFF"
              barPlayedColor="#D9D9D9"
              skipDuration={2}
              showLoopOption={true}
              showVolumeControl={true}
            />
          ) : (
            <Skeleton height={40} />
          )}
        </div>
      )}
      <MainFooter />
    </div>
  );
};

export default OnboardingCategory;

OnboardingCategory.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  accountInfo: PropTypes.object,
};
CategoryItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  status: PropTypes.string,
  selected: PropTypes.bool,
};
