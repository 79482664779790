import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox, Col, Row } from 'antd';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { numberWithSpaces } from '../../helpers/formatInteger';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';

const PortfolioFixationItem = (props) => {
  const {
    i, selectedInstruments, setSelectedInstruments, handleSelect,
    renderSelectOption, handleChangeFix, handleDelete, cashesList
  } = props;
  const { t } = useTranslation('');
  const [cheked, setCheked] = useState(false);
  const [isCashes, setIsCashes] = useState(false);
  const [setsearchFixation, setSetsearchFixation] = useState(null);
  const [filteredFix, setFilteredFix] = useState(renderSelectOption);

  useEffect(() => {
    const { fixed, unitsNumber, financialInstrumentID } = i;
    if (fixed && fixed === unitsNumber) {
      setCheked(true);
    } else setCheked(false);
    if (financialInstrumentID) {
      setIsCashes(cashesList.some(i => i.ID === financialInstrumentID));
    }
  }, [i, selectedInstruments]);

  useEffect(() => {
    if (setsearchFixation?.length > 2) {
      const render = renderSelectOption.filter(i => i?.label.toLowerCase().includes(setsearchFixation.toLowerCase()));
      setFilteredFix(render);
    } else setFilteredFix(renderSelectOption);
  }, [setsearchFixation]);

  const changeFix = (value, key) => {
    if (i.unitsNumber === value) {
      setCheked(true);
    } else setCheked(false);
    handleChangeFix(value, key);
  };

  const handleChangeCheck = (key, total, name = 'fixed', value) => {
    const setValue = name === 'fixed' ? total : value?.target?.checked;
    if (!cheked && name === 'fixed') {
      const array = selectedInstruments.map(i => i.key === key ? { ...i, [name]: setValue } : i);
      setCheked(true);
      setSelectedInstruments(array);
    } else if (name === 'is_approved_instrumen') {
      const array = selectedInstruments.map(i => i.key === key ? { ...i, [name]: setValue, fixed: 0 } : i);
      setSelectedInstruments(array);
    } else {
      setCheked(false);
    }
  };

  return (
    <div className='bias-row-wrapper' key={i.ID}>
      <Row className="bias-form-row" align="middle" >
        <Col style={{ flexBasis: '20%' }} className='fixation-asset-name'>
          {i?.financialAssetName ?? '- - - - - - - - - - - - - -'}
        </Col>
        <Col style={{ width: '29.16%' }}>
          <CustomSelect
            showSearch
            width='100%'
            className="custom-select"
            labelInValue
            value={{ value: i.ID, label: i.instrumentName }}
            onSearch={(value) => setSetsearchFixation(value)}
            onSelect={(value) => handleSelect(value, i?.key)}
            options={filteredFix}
            placeholder={t('PRODUCT_NAME_ISIN')}
            filterOption={false}
          />
        </Col>
        <Col style={{ flexBasis: '12.33%' }} className='fixation-units'>
          {numberWithSpaces(i?.unitsNumber)}
        </Col>
        <Col style={{ flexBasis: '13.85%' }} >
          <CustomInputNumber
            width='100%'
            disabled={(cheked && !isCashes) || i.is_approved_instrumen}
            value={i?.fixed}
            onChange={(value) => changeFix(value, i?.key)}
            min={0}
            max={isCashes ? false : i?.unitsNumber}
            step={1}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            parser={(value) => value?.replace(/\s?|\s+/g, '')}
            // controls={false}
          />
        </Col>
        <Col style={{ textAlign: 'center', flexBasis: '6.33%' }}>
          <Checkbox
            className='fixation-checkbox'
            disabled={i.is_approved_instrumen}
            checked={cheked}
            onChange={() => handleChangeCheck(i?.key, i?.unitsNumber)} />
        </Col>
        <Col style={{ flexBasis: '12%' }} className='fixation-units'>
          {twoDecimalWithSpaces(i?.latest_value_sek * i?.fixed)}
        </Col>
        <Col style={{ flexBasis: '6.33%' }}>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              type='text'
              style={{ marginTop: '5px', cursor: 'pointer' }}
              icon={<Delete />}
              onClick={() => handleDelete(i?.key)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PortfolioFixationItem;

PortfolioFixationItem.propTypes = {
  i: PropTypes.object,
  selectedInstruments: PropTypes.array,
  renderSelectOption: PropTypes.array,
  cashesList: PropTypes.array,
  setSelectedInstruments: PropTypes.func,
  handleSelect: PropTypes.func,
  handleChangeFix: PropTypes.func,
  handleDelete: PropTypes.func,
};
