import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { permissions } from './config/permissions';
import { useSelector } from 'react-redux';
import checkPermissions from './utils/checkPermissions';
import Forbidden from './Forbiden';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const data = useSelector(state => state.userReducer.advisor);
  const role = data?.role ?? data?.userRole ?? 'ANONIMOUS';

  if (Object.keys(data).length) {
    if (!roles || checkPermissions(role, roles)) {
      return <Route {...rest} component={Component} />;
    }
    return <Route {...rest} component={Forbidden} />;
  }

  return null;
};
const rolesPropType = PropTypes.arrayOf(PropTypes.oneOf(Object.values(permissions.roles)));

PrivateRoute.propTypes = {
  component: PropTypes.func,
  roles: PropTypes.oneOfType([rolesPropType, PropTypes.shape({
    include: rolesPropType,
    exclude: rolesPropType
  })])
};
