import React from 'react';

import PropTypes from 'prop-types';

import { Input } from 'antd';

import './style.scss';

const { TextArea } = Input;

export const CustomTextarea = (props) => {
  const { width = '360px', rows = 4 } = props;

  return (
    <TextArea
      classNames={{ textarea: 'onboarding-textarea' }}
      rows={rows}
      style={{ width }}
      {...props}

    />
  );
};

CustomTextarea.propTypes = {
  width: PropTypes.string,
  rows: PropTypes.number,
};
