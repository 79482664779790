import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, Slider, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import { Menu } from '../../../assets';
import MainButtonArray from '../../../components/MainButtonArray';
import './style.scss';
import { getClient, getClientData, setClientData } from '../../../data/store/clients/clientActions';

const { Title } = Typography;

const FinancialSituationPartThree = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getClient({ id }));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation')) {
        dispatch(getClientData({ id, name: 'economicSituation' }));
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation2')) {
        dispatch(getClientData({ id, name: 'economicSituation2' }));
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation3')) {
        dispatch(getClientData({ id, name: 'economicSituation3' }));
      }
    }
  }, [user?.client_data]);

  const defaultInvestibleCapital = () => {
    const financialAssets = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.financialAssets?.cashEquivalents +
        user?.client_data?.economicSituation2?.data?.financialAssets?.financialInvestments +
        user?.client_data?.economicSituation2?.data?.financialAssets?.pensionSavings +
        user?.client_data?.economicSituation2?.data?.financialAssets?.otherAssets
      : 0;
    const fixedAssets = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.fixedAssets?.home +
          user?.client_data?.economicSituation2?.data?.fixedAssets?.otherRealAssets
      : 0;
    const debts = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.debts?.mortgages +
          user?.client_data?.economicSituation2?.data?.debts?.otherDebts
      : 0;
    return financialAssets + fixedAssets - debts;
  };
  const initFields = {
    partsNotBeCovered: user?.client_data?.economicSituation3?.data?.partsNotBeCovered ?? '',
    capitalNotBeCovered: user?.client_data?.economicSituation3?.data?.capitalNotBeCovered ?? 0,
    investibleCapital: user?.client_data?.economicSituation3?.data?.investibleCapital ?? defaultInvestibleCapital(),
  };

  const [fields, setFields] = useState(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [user?.client_data]);

  const handleChangeRange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const changeTextAria = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const validateFinSitThree = () => {
    const mandatory = true;
    const fullFill = mandatory && !!fields.partsNotBeCovered;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'economicSituation3',
      data: fields,
      full_status: validateFinSitThree()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push(`/dashboard/${id}`);
  }, [history, fields]);
  return (
    <>
      <Title className='onboard-component-title'>
        {t('ECONOMIC_SITUATION')}
      </Title>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('INVESTABLE_CAPITAL')}</h4>
        </header>
        <p className='ekonomik-lable ekonomik-comment'>{t('PARTS_THAT_SHOULD_NOT_BE_COVERED')}</p>
        <TextArea
          type='text'
          name='partsNotBeCovered'
          value={fields.partsNotBeCovered}
          onChange={changeTextAria}
          maxLength={200}
        />
        <div className='ekonomik-subtitle part-three-title'>
          <span>{t('CAPITAL_NOT_BE_COVERED')}</span>
          <InputNumber
            name='capitalNotBeCovered'
            min={0}
            max={20000000}
            step={100000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fields.capitalNotBeCovered}
            onChange={(value) => handleChangeRange(value, 'capitalNotBeCovered')}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={100000}
          value={fields.capitalNotBeCovered}
          onChange={(value) => handleChangeRange(value, 'capitalNotBeCovered')}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('INVESTIBLE_CAPITAL')}</span>
          <InputNumber
            name='investibleCapital'
            min={0}
            max={100000000}
            step={100000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fields.investibleCapital}
            onChange={(value) => handleChangeRange(value, 'investibleCapital')}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={100000000}
          step={100000}
          value={fields.investibleCapital}
          onChange={(value) => handleChangeRange(value, 'investibleCapital')}
        />
      </Container>
      <div className='btn-container'>
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type='link'
            arrayIconStart
            onClick={() => history.goBack()}
          >
          {t('GO_BACK')}
          </MainButtonArray>
          <div className='menu-wrapper'>
            <Menu
              onClick={() => history.replace(`/dashboard/${id}`)}
              style={{ width: 85 }}
            />
            <p className='menu-title'>{t('MY_DETAILS')}</p>
          </div>
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type='submit'
            onClick={hendleSubmit}
            arrayIconEnd
          >
          {t('MOVE_ON')}
          </MainButtonArray>
        </div>
    </>
  );
};

FinancialSituationPartThree.propTypes = {
  id: PropTypes.string
};

export default FinancialSituationPartThree;
