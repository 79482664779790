import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Divider, Form, Modal, Row, Select } from 'antd';
import CustomSelect from '../CustomSelect/CustomSelect';
import { ReactComponent as Decrease } from '../../assets/decrease-icon.svg';
import { ReactComponent as Increase } from '../../assets/increase-icon.svg';

import './style.scss';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

const ModalFilterInsrt = ({ assets, currencyList, setFilter, openFilter, handleCloseFilter }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');

  const [feeMinMax, setFeeMinMax] = useState({ min: 0, max: 0 });
  const [sharePrice, setSharePrice] = useState({ min: 0, max: 0 });

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const [category, setCategory] = useState(null);

  const initVAlue = {
    category: ['All'],
    assetClass: ['All'],
    currency: ['All'],
    hight: true,
    medium: true,
    none: true,
  };

  useEffect(() => {
    form.setFieldsValue(initVAlue);
  }, []);

  const createAssetCategory = useMemo(() => {
    const category = ['All'];
    assets.forEach(i => !category.includes(i?.financialAssetCategory?.name) && category.push(i?.financialAssetCategory?.name));
    return category?.sort((a, b) => textSort(a, b));
  }, [assets]);

  useEffect(() => {
    setCategory(createAssetCategory);
  }, [assets]);

  const handleDecrease = (target) => {
    if (target === 'fee-min') {
      if (feeMinMax.min > 0.099) {
        const min = Math.round((+feeMinMax.min - 0.1) * 100) / 100;
        setFeeMinMax({ ...feeMinMax, min });
      }
    }
    if (target === 'fee-max') {
      if (feeMinMax.max > 0.099) {
        const max = Math.round((+feeMinMax.max - 0.1) * 100) / 100;
        setFeeMinMax({ ...feeMinMax, max });
      }
    }
    if (target === 'price-min') {
      if (sharePrice.min > 0.099) {
        const min = Math.round((+sharePrice.min - 0.1) * 100) / 100;
        setSharePrice({ ...sharePrice, min });
      }
    }
    if (target === 'price-max') {
      if (sharePrice.max > 0.099) {
        const max = Math.round((+sharePrice.max - 0.1) * 100) / 100;
        setSharePrice({ ...sharePrice, max });
      }
    }
  };
  const handleIncrease = (target) => {
    if (target === 'fee-min') {
      const min = Math.round((+feeMinMax.min + 0.1) * 100) / 100;
      setFeeMinMax({ ...feeMinMax, min });
    }
    if (target === 'fee-max') {
      const max = Math.round((+feeMinMax.max + 0.1) * 100) / 100;
      setFeeMinMax({ ...feeMinMax, max });
    }

    if (target === 'price-min') {
      const min = Math.round((+sharePrice.min + 0.1) * 100) / 100;
      setSharePrice({ ...sharePrice, min });
    }
    if (target === 'price-max') {
      const max = Math.round((+sharePrice.max + 0.1) * 100) / 100;
      setSharePrice({ ...sharePrice, max });
    }
  };

  const handleInputChange = (event, target) => {
    const { value } = event.target;
    if (target === 'fee-min') {
      setFeeMinMax({ ...feeMinMax, min: +value });
    }
    if (target === 'fee-max') {
      setFeeMinMax({ ...feeMinMax, max: +value });
    }

    if (target === 'price-min') {
      setSharePrice({ ...sharePrice, min: +value });
    }
    if (target === 'price-max') {
      setSharePrice({ ...sharePrice, max: +value });
    }
  };

  const handleRest = () => {
    form.resetFields();
    setFeeMinMax({ min: 0, max: 0 });
    setSharePrice({ min: 0, max: 0 });
    form.setFieldsValue(initVAlue);
    setFilter(null);
  };

  const handleCancel = () => {
    handleCloseFilter();
  };

  const handleChangeSelect = () => {
    const values = form.getFieldsValue();
    if (values?.category.length > 1) values.category = values.category.filter(i => i !== 'All');
    if (values?.category.length === 0) values.category = ['All'];
    if (values?.assetClass.length > 1) values.assetClass = values.assetClass.filter(i => i !== 'All');
    if (values?.assetClass.length === 0) values.assetClass = ['All'];
    if (values?.currency.length > 1) values.currency = values.currency.filter(i => i !== 'All');
    if (values?.currency.length === 0) values.currency = ['All'];
    form.setFieldsValue(values);
  };

  const onSubmit = () => {
    const value = form.getFieldsValue();
    const configFilter = { ...value, fee: feeMinMax, price: sharePrice };
    setFilter(configFilter);
    handleCloseFilter();
  };

  return (
    <Modal
      width={448}
      className="filter-modal"
      centered
      open={openFilter}
      onCancel={handleCancel}
      footer={false}
      zIndex={1500}
    >
      <Form
        form={form}
        name="filter-config"
        layout="vertical"
        onFinish={onSubmit}
      >
        <div>
          <Row className="profile-data-row">
            <Col>
              <Form.Item
                label={t('ASSET_CATEGORY')}
                name="category"
                required={false}
              >
                <CustomSelect
                  mode={'multiple'}
                  maxTagCount='responsive'
                  maxTagTextLength={12}
                  onChange={handleChangeSelect}
                  className="custom-select"
                  >
                  {category?.map(i => (
                    <Select.Option key={uuidv4()} value={i}>{i}</Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider className='filter-divider' />
          <Row className="profile-data-row">
            <Col>
              <Form.Item
                label={t('ASSET_CLASS')}
                name="assetClass"
                required={false}
              >
                <CustomSelect
                  mode={'multiple'}
                  maxTagCount='responsive'
                  className="custom-select"
                  maxTagTextLength={12}
                  onChange={handleChangeSelect}
                >
                  <Select.Option value="All">All</Select.Option>
                  {assets?.slice()?.sort((a, b) => textSort(a.name, b.name))?.map(i => (
                    <Select.Option key={i.ID} value={i.name}>{i.name}</Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider className='filter-divider'/>
          <Row justify={'space-between'} style={{ marginBottom: '20px' }}>
            <Col className='filter-control-title'>{t('FEE')}, %</Col>
            <Col className='filter-control-wrapper'>
              <div className='controll-wrapper'>
                <div
                  className='controll-decrease'
                  onClick={() => handleDecrease('fee-min')}>
                  <Decrease />
                </div>
                  <input
                    type={'number'}
                    className='control-min-value'
                    value={feeMinMax.min}
                    onChange={(event) => handleInputChange(event, 'fee-min')}/>
                <div
                  className='controll-increase'
                  onClick={() => handleIncrease('fee-min')}>
                  <Increase />
                </div>
              </div>
              <span className='controll-delimiter'>-</span>
              <div className='controll-wrapper'>
                <div
                  className='controll-increase'
                  onClick={() => handleDecrease('fee-max')}>
                  <Decrease />
                </div>
                  <input
                    type={'number'}
                    className='control-max-value'
                    value={feeMinMax.max}
                    onChange={(event) => handleInputChange(event, 'fee-max')}/>
                <div
                  className='controll-increase'
                  onClick={() => handleIncrease('fee-max')}>
                  <Increase />
                </div>
              </div>
            </Col>
          </Row>
          <Divider className='filter-divider'/>
          <Row align={'top'} justify={'space-between'}>
            <Col style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Form.Item
                label={t('CURRENCY')}
                name="currency"
                required={false}>
                <CustomSelect
                  width={'140px'}
                  mode={'multiple'}
                  maxTagCount='responsive'
                  onChange={handleChangeSelect}
                  className="custom-select">
                  <Select.Option value="All">All</Select.Option>
                  {currencyList?.sort((a, b) => textSort(a.name, b.name))?.map(i => (
                    <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
            <Col>
              <p className='fillter-price-title'>{t('SHARE_PRICE')}</p>
              <div className='filter-control-wrapper'>
              <div className='controll-wrapper'>
                <div
                  className='controll-decrease'
                  onClick={() => handleDecrease('price-min')}
                  >
                  <Decrease />
                </div>
                  <input
                    type={'number'}
                    className='control-min-value'
                    value={sharePrice.min}
                    onChange={(event) => handleInputChange(event, 'price-min')}/>
                <div
                  className='controll-increase'
                  onClick={() => handleIncrease('price-min')}
                  >
                  <Increase />
                </div>
              </div>
              <span className='controll-delimiter'>-</span>
              <div className='controll-wrapper'>
                <div
                  className='controll-increase'
                  onClick={() => handleDecrease('price-max')}
                  >
                  <Decrease />
                </div>
                  <input
                    type={'number'}
                    className='control-max-value'
                    value={sharePrice.max}
                    onChange={(event) => handleInputChange(event, 'price-max')}/>
                <div
                  className='controll-increase'
                  onClick={() => handleIncrease('price-max')}>
                  <Increase />
                </div>
              </div>
              </div>
            </Col>
          </Row>
          <Divider className='filter-divider'/>
          <Row justify={'space-between'} align={'middle'} style={{ marginBottom: '20px' }}>
            <Col className='filter-control-title'>{t('DIVERSIFICATION')}</Col>
            <Col className='filter-control-wrapper'>
              <Form.Item
                name={'hight'}
                valuePropName="checked"
                className="filter-check-item">
                <CustomCheckbox>{t('HIGHT')}</CustomCheckbox>
              </Form.Item>
              <Form.Item
                name={'medium'}
                valuePropName="checked"
                className="filter-check-item">
                <CustomCheckbox>{t('MEDIUM')}</CustomCheckbox>
              </Form.Item>
              <Form.Item
                name={'none'}
                valuePropName="checked"
                className="filter-check-item">
                <CustomCheckbox>{t('NONE')}</CustomCheckbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider className='filter-divider'/>
          <Row justify='space-between'>
          <Col>
            <Button
              className='portfolio-details-btn filter-reset'
              onClick={handleRest}>
              {t('RESET')}
            </Button>
          </Col>
          <Col>
            <Button
              className='portfolio-details-btn filter-apply'
              onClick={onSubmit}>
              {t('APPLY')}
            </Button>
          </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalFilterInsrt;

ModalFilterInsrt.propTypes = {
  assets: PropTypes.array,
  currencyList: PropTypes.array,
  openFilter: PropTypes.bool,
  handleCloseFilter: PropTypes.func,
  setFilter: PropTypes.func,
};
