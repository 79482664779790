export const OnboardingEN = {
  WELCOME: 'Welcome to Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'What type of account do you want to open?',
  WANT_TO_START_A_PERSONAL:
    'Do you want to start a personal account or a business account?',
  PRIVATE_CUSTOMER: 'Private client',
  BUSINESS_CUSTOMER: 'Corporate client',
  PRIVAT_ORGANISATION_NUMBER: 'Social security/organisation number',
  PRIVAT_NUMBER: 'Social security number',
  ORGANISATION_NUMBER: 'Organisation number',
  ENTER_ORGANIZATION_NUMBER: 'Enter social security/organization number',
  MOVE_ON: 'Move on',
  RFA19: 'Would you like to identify yourself or sign with a BankID on this computer or with a Mobile BankID?',
  MOBILEID_HEADER: 'Start the BankID app on your phone or tablet. Tap the QR code button in the app. Point the camera at the QR code below.',
  USE_BANKID: 'Use BankID on this device',
  THE_LOGIN_FAILED: 'The login failed',
  LOGIN_FAILED_TEXT: 'Failed to scan the QR code. Start the BankID app and scan the QR code. Make sure that the BankID app is up to date. If you don\'t have the BankID app, you need to install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.',
  TRY_AGAIN: 'Try again',
  LOGIN_WITH_BANKID: 'Log in with Test BankID',
  TYPE_YOUR_SECURITY: 'Type your security code in the BankID app and select Identify.',
  // new-part
  WHAT_TYPE_OF_ACCOUNT_IS: 'What type of account is needed for your client?',
  WHAT_TYPE_ACCOUNT_OPEN: 'What type of account do you want to open?',
  DO_YOU_WANT_TO_START: 'Do you want to start a personal account or a business account?',
  PLEASE_PROVIDE_MEETING: 'Please provide the way you meeting your client',
  WHAT_TYPE_MEETING: 'What type of meeting do you plan?',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  PLEASE_PROVIDE_MEETING_LINK: 'Please provide a meeting link or plan it now:',
  CONFIRM: 'Confirm',
  PLAN_MEETING: 'Plan meeting',
  PLEASE_USE_SCRIPT: 'Please use Script to make meeting more productive',
  YOUR_MEETING_DETAILS: 'Your meeting details',
  MEETING_LINK: 'Meeting link',
  COPIED: 'Copied!',
  WHEN: 'When',
  GUESTS: 'Guests',
  SCRIPT: 'Script',
  RESCHEDULE: 'Reschedule',
  TO_MAKE_MEETING_TRANSCRIPTION: 'It’s take some time to make meeting transcription, we notify you when it’s done',
  PROCESSING: 'Processing',
  MEETING_PROCESSING_LEVEL: 'Meeting processing level',
  MEETING_ID: 'Meeting ID',
  MEETING_TRANSCRIBING: 'Meeting transcribing...',
  CHECK_YOUR_CLIENT_INFO: 'Check your client info and Singing the information',
  TYPE_OF_ACCOUNT: 'Type of account',
  CLIENT_INFORMATION: 'Client information',
  PURPOSE_MATTER: 'Purpose & Matter',
  FINANCIAL_SITUATION: 'Financial situation',
  BASIC_PREREQUISITES_FOR_MANAGEMENT: 'Basic prerequisites for management',
  KNOW_YOUR_CLIENT: 'Know your client',
  RISK_TOLERANCE: 'Risk tolerance',
  RISK_PROFILE: 'Risk profile',
  EXPERIENCE_BACKGROUND: 'Experience background',
  TYPE_OF_ADVICE: 'Type of advice',
  ASSIGNMENT_AGREEMENT: 'Assignment agreement',
  SIGNING: 'Signing',
  SIGNING_CHECK_ONE: 'I hereby agree that Citroneer processes personal data provided in connection with entering into this agreement. the personal data is used by Citroneer for the opening of the depot and in other cases in connection with what appears in this agreement',
  SIGNING_CHECK_TWO: 'I have read relevant information material and fact sheets.',
  SIGNING_CHECK_THREE: 'I agree that my personal data is stored and handled by Citroneer for administration.',
  SIGNING_CHECK_FOUR: 'I have understood that in exceptional cases I risk losing all or part of the invested capital.',
  MEETING_INFORMATION: 'Meeting information',

  DATE_OF_COUNSELING: 'Date of counseling',
  THE_EXECUTION_CONSULTANCY: 'The execution of the consultancy',
  BASIC_INFORMATION: 'Basic information',
  SURNAME: 'Surname',
  ENTER_SURNAME: 'Enter surname',
  SOCIAL_SECURITY_NUMBER: 'Social security number',
  ENTER_SOCIAL_SECURITY_NUMBER: 'Enter social security number',
  ONBOARDING_SCRIPT: 'Onboarding Script',
  FIND_ALL_QUESTIONS: 'You can find all questions here:',
  NEXT: 'Next',

  WHAT_IS_THE_PURPOSE_GOAL: 'What is the purpose and goal of the investments?',
  YOUR_ANSWER: 'Your answer...',
  HEART_AFFAIRS: 'Do you have any "matters close to your heart" that you want to be considered when choosing investments?',
  QUALITATIVE_GOALS: 'In addition to the return, are there qualitative goals with the investments?',
  NONE_RETURN_FOCUS: 'None - Return is the focus.',
  BASIC_SUSTAINABILITY: 'Basic Sustainability (ESG)',
  SUSTAINABILITY_IN_DEPTH: 'Sustainability in depth (SRI)',
  STRONG_IMPACT: 'Strong impact (Impact Investing)',

  SUSTAINABLE_VIEW: 'Which statement below best describes your view on investments and sustainability issues?',
  MY_FOCUS_IN_INVESTMENTS: 'My focus in investments is risk and return.',
  I_WANT_INTEGRATE_SUSTAINABILITY: 'I want to integrate sustainability aspects where possible without renouncing risk and return prospects.',
  SUSTAINABILITY_IS_VERY_IMPORTANT: 'Sustainability is a very important issue in my portfolio and I might consider lowering the risk and return outlook requirements to exclude certain industries.',
  THERE_OTHER_ASPECTS_WORKING: 'Are there any other aspects of working with the portfolio that Citroneer should be aware of?',

  REVENUE_AND_EXPENDITURE: 'Revenue and expenditure',
  INCOME_EMPLOYMENT: 'What is your income from employment',
  DO_YOU_HAVE_OTHER_INCOME: 'Do you have any other income',
  EXPENDITURE_QUESTION: 'What are your expenditures',
  WHAT_YOUR_SURPLUS: 'What\'s your surplus',
  ADDITIONAL_INFORMATION_ABOUT_REVENUE: 'Additional information about revenue and expenditures',
  YOUR_TEXT: 'Your text..',
  YOUR_FINANCIAL_INVESTMENTS: 'What are your financial investments',
  CASH_EQUIVALENTS: 'What cash and cash equivalents do you have',
  PENSION_SAVINGS: 'What are your pension savings',
  ADDITIONAL_ABOUT_FINANCIAL_ASSETS: 'Additional information about financial assets',
  OTHER_ASSETS: 'Other assets',
  HAVE_ANY_OTHER_ASSETS: 'Do you have any other assets',
  FIXED_ASSETS: 'Fixed assets',
  ONB_HOUSING: 'What\'s the approximate value of your housing assets',
  ONB_HOUSING_2: 'What\'s the shoulder on your housing assets',
  OTHER_FIXED_ASSETS: 'Do you have any other fixed assets',
  ADD_ABOUT_FIXED_ASSETS: 'Additional information about fixed assets',
  SHOULDER: 'Shoulder',
  OTHER_LIABILITIES: 'Do you have any other liabilities',
  ADD_INFORMATION_SHOULDER: 'Additional information about shoulder',
  SCOPE_INVESTABLE_CAPITAL: 'Scope and investable capital',
  NON_COVERED_ELEMENTS: 'Are there elements that should not be covered by the advice?',
  CAPITAL_NOT_COVERED_ADVICE: 'Capital not to be covered by the advice',
  INVESTABLE_CAPITAL: 'Investable capital',
  WHAT_YOUR_INVESTABLE_CAPITAL: 'What is your investable capital',
  BASIC_PREREQUISITES_MANAGEMENT: 'Basic prerequisites for management',
  ABLE_TO_LIQUIDATE_THE_HOLDINGS: 'Is there a need to be able to liquidate the holdings at short notice?',
  ANY_THOUGHTS_ON_HOW_FUTURE: 'Are there any thoughts on how future generations will share in the portfolio\'s returns that Citroneer should know about?',
  TIME_HORIZON: 'Time Horizon',
  COMMENT_TIME_HORIZON: 'Comment time horizon',
  ADD_COMMENT_TIME_HORIZON: 'Additional comment about time horizon',
  BUFFER_CAPITAL: 'Buffer capital',
  CITIZENSHIP_TAX_RESIDENCE: 'Citizenship & tax residence',
  OFFLINE_MEETING: 'Offline meeting',
  ONLINE_MEETING: 'Online meeting',
  PHONE_MEETING: 'Phone meeting',
  SELECT_YOUR_OPTION: 'Select your option',
  CITIZENSHIP: 'What is your citizenship',
  SECOND_CITIZENSHIP: 'Do you have second citizenship',
  TAX_RESIDENCE: 'What is your tax residence',
  UPLOAD_COPY_YOUR_PASSPORT: 'Upload a copy of your passport or ID card',
  ONB_UPLOAD_DOCUMENT: 'Upload document',
  NO_DOCUMENT_ADDED: 'No document added',
  ID_DOC_NUMBER: 'Provide the ID document control number',
  ID_DOC_CONTROL_NUMBER: 'ID document control number',
  FILL_EXPIRY_DATE_ID_DOCUMENT: 'Provide the expiry date of the ID document',
  ABROAD: 'Abroad',
  USA_RELATIONSHIPS: 'Do you have any relationship with the US?',
  USA_RELATIONSHIPS_TYPE: 'What is your relationship with the US?',
  ONB_UPLOAD_DOCUMENTS: 'Upload documents',
  FOREIGN_TRANSACTIONS: 'Do you usually do transactions with foreign countries?',
  TRANSACTIONS_AMOUNT: 'How large are your transactions with foreign countries?',
  PURPOSE_OF_ENGAGEMENT: 'Purpose of engagement',
  SAVINGS_PURPOSE: 'What is the purpose of your savings and/or investments?',
  DESCRIBE_OTHER_PURPOSE_TITLE: 'If in the question the option "Other (describe)" was selected',
  DESCRIBE_OTHER_PURPOSE_QUESTION: 'Provide the description of the other option',
  SAVINGS_INVESTMENTS: 'Savings/investments',
  FINANCIAL_SECURITY: 'Financial security',
  PENSION: 'Pension',
  PRIVATE_CONSUMPTION: 'Private consumption',
  GIFT_CLOSE_RELATIVE: 'Gift to a close relative',
  BENEFICIARY: 'Beneficiary',
  OTHER_DESCRIBE: 'Other (describe)',
  ON_HIS_OWN_BEHALF: 'On his own behalf',
  SOMEONE_ELSE_POWER_ATTORNEY: 'For someone else by power of attorney',
  BEHALF: 'On whose behalf are you investing?',
  COMMENT_ON_PROXY: 'Comment on the power of attorney',
  COMMENT_ON_PROXY_TITLE: 'If in the question the option "For someone else by power of attorney" was selected',
  COMMENT_ON_PROXY_QUESTION: 'Provide a comment on the power of attorney',
  COMMENT_ON_BEHALF: 'Description of the other option',
  COMMENT_ON_BEHALF_TITLE: 'If in the question the option "Other (describe)" was selected',
  COMMENT_ON_BEHALF_QUESTION: 'Provide the description of the other option',
  UPLOAD_DOCUMENTS_PROVING: 'Upload documents proving power of attorney',
  MAIN_OCCUPATION: 'Main occupation',
  MAIN_OCCUPATION_TITLE: 'If in the question "main_occupation" the option "Other (describe)" was selected',
  MAIN_OCCUPATION_QUESTION: 'Provide the description of the other option',
  WHAT_YOUR_MAIN_OCCUPATION: 'What is your main occupation?',
  EMPLOYEE: 'Employee',
  SELF_EMPLOYED: 'Self-employed',
  RETIRED: 'Retired',
  STUDENT: 'Student',
  WORK_SECTOR: 'In which sector do you work?',
  MONTHLY_INCOME: 'What is your approximate monthly income?',
  MONTHLY_INCOME_OVER: 'Over 100 000 kr',
  CAPITAL_TRANSFER: 'Capital transfer',
  MONEY_TRANFER: 'Is it your money that will be transferred?',
  WHOSE_MONEY: 'Explain whose money will be transferred',
  MONEY_ORIGIN: 'Where do the money and/or securities you deposit with Citroneer come from?',
  MONEY_ORIGIN_TITLE: 'If in the question the option "Other (describe)" was selected',
  MONEY_ORIGIN_QUESTION: 'Provide the description of the other option',
  SALARY_PENSION: 'Salary/Pension',
  SALE_OF_COMPANY: 'Sale of a company',
  INHERITANCE_GIFT: 'Inheritance/Gift',
  SALE_HOME_PROPERTY: 'Sale of home or property',
  BANK_CAPITAL: 'From which bank/insurance company do you take capital for your investments?',
  BANK_CAPITAL_OPTION_01: 'Bank or securities institution in Sweden',
  BANK_CAPITAL_OPTION_02: 'Insurance company in Sweden',
  BANK_CAPITAL_OPTION_03: 'Bank or securities institution in another EU/EEA country',
  BANK_CAPITAL_OPTION_04: 'Insurance company in another EU/EEA country',
  BANK_CAPITAL_OPTION_05: 'Bank or securities institution outside the EU/EEA',
  BANK_CAPITAL_OPTION_06: 'Insurance company outside the EU/EEA',
  TRANSFER_VALUE: 'What approximate value will you transfer when opening your account with Citroneer?',
  TRANSFER_VALUE_OVER: 'Over 5 000 000 kr',
  ESTIMATE: 'Enter estimate of the approximate value that will be transferred when opening account with Citroneer',
  ENTER_ESTIMATE: 'Enter estimate',
  DEPOSITS_WITHDRAWALS: 'Deposits, withdrawals and transactions',
  YEARLY_DEPOSIT: 'What will the average value of your deposits be per year?',
  TIME_DEPOSIT_OPENING_ACCOUNT: 'One-time deposit only when opening the account',
  ENTER_ESTIMATE_AVERAGE: 'Enter estimate of the average value of deposits per year',
  DEPOSIT_FREQUENCY: 'How often will deposits be made per year?',
  TIMES_0_10: '0 - 10 times',
  TIMES_11_50: '11 - 50 times',
  TIMES_51_100: '51 - 100 times',
  TIMES_MORE_THEN: 'More than 100 times',
  WITHDRAWAL_FREQUENCY: 'How often will withdrawals be made per year?',
  INDIVIDUAL_TRANSACTIONS: 'How large individual transactions in financial instruments do you normally make?',
  ESTIMATE_TRANSACTIONS: 'Enter estimate of the amount of individual transactions in financial instruments',
  PERSON_VULNERABLE_POSITION: 'Person in a politically vulnerable position',
  PEP_POSITION: 'Are you yourself, or have you been, a person in a politically vulnerable position?',
  PEP_TYPE_TITLE: 'If the customer is a PEP',
  PEP_TYPE_QUESTION: 'What kind of PEP are you?',
  PEP_TYPE_OPTION_1: 'The Head of State',
  PEP_TYPE_OPTION_2: 'Members of Parliament',
  PEP_TYPE_OPTION_3: 'The prime minister and other ministers in the government office',
  PEP_TYPE_OPTION_4: 'Judges of the Supreme Court and the Supreme Administrative Court',
  PEP_TYPE_OPTION_5: 'The Auditor General',
  PEP_TYPE_OPTION_6: 'The Riksbank\'s Executive Board',
  PEP_TYPE_OPTION_7: 'Ambassadors',
  PEP_TYPE_OPTION_8: 'Senior officers (general, lieutenant general, major general, admiral, vice admiral and rear admiral)',
  PEP_TYPE_OPTION_9: 'CEO or board member of state-owned companies',
  PEP_TYPE_OPTION_10: 'Person in the management of an international organization (board members, general secretary, etc.)',
  PEP_TYPE_OPTION_11: 'Member of political party\'s board',
  PEP_RELATIVE: 'Do you have someone close to you who is, or has been, a politically exposed person?',
  PEP_RELATIVE_TYPE_TITLE: 'If the customer has close relatives who are PEPs',
  PEP_RELATIVE_TYPE_QUESTION: 'What type of PEP is your relative?',
  EXPECTED_RETURN: 'What is the annual expected return on capital?',
  EXPECTED_RETURN_OPTION_1: 'In line with bank branch interest',
  EXPECTED_RETURN_OPTION_2: 'Something above bank branch interest',
  EXPECTED_RETURN_OPTION_3: 'A clearly higher yield than bank branch interest',
  EXPECTED_RETURN_OPTION_4: 'There is a defined return target',
  RETURN_TARGET: 'Return target',
  INVESTMENT_PHILOSOPHY: 'Which statement below best describes your investment philosophy?',
  INVESTMENT_PHILOSOPHY_OPTION_1: 'Focus on controlling the risk of loss and thus accept a relatively low return.',
  INVESTMENT_PHILOSOPHY_OPTION_2: 'Accepts some risk to get higher potential for returns.',
  INVESTMENT_PHILOSOPHY_OPTION_3: 'Focus on returns and thus can accept large fluctuations in the value of the investments.',
  MEDIA_INFLUENCE: 'To what extent is your view on investments influenced by market news and the media?',
  MEDIA_INFLUENCE_OPTION_1: 'Not significant and does not cause portfolio concern.',
  MEDIA_INFLUENCE_OPTION_2: 'Negative news means a greater commitment to how the investments are going.',
  MEDIA_INFLUENCE_OPTION_3: 'Negative news means that the portfolio changes to follow the experts advice.',
  MEDIA_INFLUENCE_OPTION_4: 'Increased attention to the portfolio but acts with ice in the stomach.',
  NEGATIVE_NEWS: 'How is the your view of investments affected when the media is filled with negative market news and experts offer investment tips?',
  NEGATIVE_NEWS_OPTION_1: 'Not significant and does not cause portfolio concern.',
  NEGATIVE_NEWS_OPTION_2: 'Negative news means a greater commitment to how the investments are doing.',
  NEGATIVE_NEWS_OPTION_3: 'Negative news means that the portfolio changes to follow the experts advice.',
  NEGATIVE_NEWS_OPTION_4: 'Increased attention to the portfolio but acts with ice in the stomach.',
  PORTFOLIO_VALUE_DROP: 'Suppose your portfolio is worth SEK 50 million today. How would you react if its value dropped to 45 million in 12 months?',
  PORTFOLIO_VALUE_DROP_OPTION_1: 'A willingness to reduce the risk in the portfolio and, for example, sell shares.',
  PORTFOLIO_VALUE_DROP_OPTION_2: 'The portfolio is left intact, but reduce the dividend from the portfolio.',
  PORTFOLIO_VALUE_DROP_OPTION_3: 'Contact the advisor to discuss the portfolio.',
  PORTFOLIO_VALUE_DROP_OPTION_4: 'No one, it is natural to have large swings in the markets.',
  PORTFOLIO_VALUE_DROP_OPTION_5: 'An opportunity to take advantage of and increase the risk and, for example, buy shares.',
  DOUBLE_VALUE_DROP: 'What will be your reaction if the value of your portfolio instead drops to 40 million in the next 12 months?',
  DOUBLE_VALUE_DROP_OPTION_1: 'No, big and small moves are a natural part of investing.',
  DOUBLE_VALUE_DROP_OPTION_2: 'Contact the advisor to discuss the portfolio.',
  DOUBLE_VALUE_DROP_OPTION_3: 'Small swings I can understand, but big falls definitely stress me out.',
  LOW_VALUE_INCREASE: 'What will be your reaction if the Stockholm Stock Exchange rises by 20 percent while your portfolio only increases by 5 percent?',
  LOW_VALUE_INCREASE_OPTION_1: 'Frustration of the missed opportunity and of falling behind the stock market.',
  LOW_VALUE_INCREASE_OPTION_2: 'Not worth mentioning, the Stockholm Stock Exchange has a higher risk than my portfolio.',
  LOW_VALUE_INCREASE_OPTION_3: 'Talk to the advisor to understand why the portfolio is underperforming.',
  LOW_VALUE_INCREASE_OPTION_4: 'None, the portfolio is balanced and will not follow individual market movements.',
  PORTFOLIO_EVALUATION: 'Do you compare your portfolio to anything/how do you evaluate your investments?',
  PORTFOLIO_EVALUATION_OPTION_1: 'I compare my results with Swedish shares/Stockholmsbörsen.',
  PORTFOLIO_EVALUATION_OPTION_2: 'I compare my results with a global stock portfolio.',
  PORTFOLIO_EVALUATION_OPTION_3: 'I have a specific benchmark.',
  PORTFOLIO_EVALUATION_OPTION_4: 'I compare my portfolio with a fund/manager.',
  ADDITIONAL_COMMENT: 'Comment on risk and return expectations',
  RISK_CLASSIFICATION_PROPOSAL: 'Citroneer\'s risk classification proposal',
  GENERAL_INFORMATION: 'General information',
  FIN_EDUCATION: 'Do you have education and/or current or previous occupation that brought knowledge of how financial investments and markets work?',
  TRADING_PERIOD: 'How long have you been trading securities?',
  ASSET_TYPE: 'What type of assets are you familiar with?',
  KNOWLEDGE_TYPES_ASSETS: 'Knowledge of different types of assets',
  ASSET_EXPERIENCE: 'What knowledge & experience level of trading assets do you have? (-- None, -- Basic, -- Comprehensive)',
  ASSET_EXPERIENCE_LABEL: 'Knowledge & experience',
  ASSET_EXPERIENCE_OPTION_1: 'None',
  ASSET_EXPERIENCE_OPTION_2: 'Basic',
  ASSET_EXPERIENCE_OPTION_3: 'Comprehensive',
  ASSET_TRAINED: 'Have you been trained on asset knowledge?',
  ASSET_TRAINED_LABEL: 'Knowledge & experience',
  RISK_UNDERSTANDING: 'Does the adviser believe that the customer has understood the risks of the product?',
  RISK_UNDERSTANDING_LABEL: 'The adviser believes that the customer has understood the risks of the product',
  LISTED_SHARES: 'Listed shares',
  DEALS_NUMBER: 'Number of deals in the last three years',
  UNLISTED_SHARES: 'Unlisted shares',
  UCITS_FUNDS: 'UCITS Funds',
  SPECIAL_FUNDS: 'AIF funds/special funds',
  LISTED_BONDS: 'Listed bonds',
  UNLISTED_BONDS: 'Unlisted bonds',
  EQUITY_INDEX_BONDS: 'Equity index bonds',
  HEDGE_FUNDS: 'Hedge funds',
  CERTIFICATE_OF_CREDIT: 'Certificate of Credit',
  AUTOCALLS: 'Autocalls',
  EXCHANGE_TRADED_FUNDS: 'ETF (Exchange Traded Funds)',
  DERIVATIVES_AND_COMMODITY: 'Derivatives and commodity certificates',
  ASSETS_TYPE_KNOWLEDGE: 'Provide additional comment on knowledge of different types of assets',
  ASSETS_TYPE_KNOWLEDGE_COMMENT: 'Comment: knowledge of different types of assets',
  KNOWLEDGE_PORTFOLIO_MANAGEMENT: 'Knowledge of portfolio management',
  MANAGEMENT_EXPERIENCE: 'What knowledge & experience level of managing portfolio do you have?',
  MANAGEMENT_EXPERIENCE_LABEL: 'Knowledge & experience',
  MANAGEMENT_EXPERIENCE_OPTION_1: 'None',
  MANAGEMENT_EXPERIENCE_OPTION_2: 'Basic',
  MANAGEMENT_EXPERIENCE_OPTION_3: 'Comprehensive',
  PORTFOLIO_TRAINED: 'Have you been trained on portfolio management?',
  PORTFOLIO_TRAINED_LABEL: 'The customer has been trained',
  PORTFOLIO_UNDERSTANDING: 'Does the advisor believe that the client understands portfolio management sufficiently well?',
  PORTFOLIO_UNDERSTANDING_LABEL: 'The advisor believes that the client understands portfolio management sufficiently well',
  PORTFOLIO_MANAGEMENT_ADD: 'Provide additional comment on knowledge of portfolio management',
  PORTFOLIO_MANAGEMENT_COMMENT: 'Comment: knowledge of portfolio management',
  RISK_OF_LOSS: 'Risk of loss',
  LOSS_RISK: 'Can you lose all or part of your invested capital if you invest in financial instruments?',
  LOSS_RISK_QUESTION: 'Do you agree with the statement that you can lose all or part of your invested capital if you invest in financial instruments?',
  DOCUMENTATION: 'Documentation',
  ACCOUNT_OPENING: 'Account opening',
  RECOMMENDATION: 'Recommendation',
  ACCOUNT_SELECTION: 'Account selection',
  STOCK_FUND_ACCOUNT: 'Stock & fund account',
  INVESTMENT_SAVINGS_ACCOUNT: 'Investment savings account',
  DEPOSIT_CAPITAL_INSURANCE: 'Deposit capital insurance',
  DEPOT_PENSION_INSURANCE: 'Depot occupational pension insurance',
  RECOMMENDATION_APPLIES_DEPOSITORY: 'The recommendation applies to the depository',
  ADVISER_PROPOSAL_CHANGE: 'The adviser\'s proposal for change',
  JUSTIFICATION_GIVEN_ADVICE: 'Justification for given advice based on the customer\'s wishes and needs',
  ADVISER_BELIEVE_THAT_CLIENT: 'Does the adviser believe that the client has enough  buffer even after the client has followed the advice?',
  MOTIVATE: 'Motivate',
  ADVISOR_CONSIDER_RECOMMENDATION: 'Does the advisor consider the recommendation to be appropriate with regard to the client\'s risk appetite, risk capacity and investment horizon?',
  SUITABILITY_BASED_CUSTOMER: 'Suitability based on the customer\'s knowledge and experience',
  SUITABILITY_OPTION_1: 'The recommendation is appropriate against the background of the customer\'s previous knowledge and experience.',
  SUITABILITY_OPTION_2: 'The recommendation is appropriate with the background of the customer\'s previous knowledge and experience, after the customer has been educated in the meeting.',
  SUITABILITY_OPTION_3: 'The recommendation is not suitable based on the customer\'s previous knowledge and experience.',
  TRAINING_CUSTOMER_DURING_MEETING: 'Training of the customer during the meeting',
  WHAT_TYPE_OF_INVESTMENT: 'What type of investment does the investment recommendation apply to?',
  TYPE_OF_INVESTMENT_OPTION_1: 'Initial investment',
  TYPE_OF_INVESTMENT_OPTION_2: 'Additional investment/Reallocation',
  TOTAL_INVESTED_CAPITAL: 'Total invested capital',
  RECOMMENDED_BUYING_AND_SELLING: 'Recommended buying and selling of securities',
  BUYING_TRANSACTIONS: 'Buying transactions',
  SELLING_TRANSACTIONS: 'Selling transactions',
  PRODUCT_NAME_ISIN: 'Product name & ISIN',
  ADDED_ITEMS: 'Added Items',
  CHARGE: 'Charge',
  PLEASE_SELECT_INSTRUMENT: 'Please select instrument',
  PLEASE_ENTER_AMOUNT: 'Please enter amount',
  PLEASE_ENTER_CHARGE: 'Please enter charge',
  COMMENT_RECOMMENDED: 'Comment: recommended buying and selling of securities',
  COSTS_AND_FEES: 'Costs and fees',
  COMPENSATION_ADVICE_PORTFOLIO_MANAGEMENT: 'Enter compensation for advice/portfolio management (incl. deposit fee)',
  ENTER_DATA: 'Enter data',
  BUSINESS_VOLUME: 'Business volume',
  TRANSACTION_COSTS: 'Transaction costs',
  FEE_PER_YEAR: 'Fee (%) per year underlying instrument',
  FEE_SEK_PER_YEAR: 'Fee (SEK) per year underlying instrument',
  FEE_PER_YEAR_COMPENSATION: 'Fee (%) per year compensation advice/portfolio management',
  FEE_SEK_PER_YEAR_COMPENSATION: 'Fee (SEK) per year compensation advice/portfolio management',
  TOTAL_FEE_PER_YEAR: 'Total fee (%) per year compensation advice/portfolio management',
  TOTAL_FEE_SEK_PER_YEAR: 'Total fee (SEK) per year compensation advice/portfolio management',
  COSTS_ASSOCIATED_ADVICE: 'What costs are associated with the advice given?',
  DECISION: 'Decision',
  CUSTOMERS_DECISION: 'Customer\'s decision',
  ENTER_OTHER_DECISION: 'Enter other decision',
  COMMENTARY_ON_DECISION: 'Commentary on decision and possible dissuasion',
  CUSTOMERS_DECISION_OPTION_1: 'Following advice given above',
  CUSTOMERS_DECISION_OPTION_2: 'Wish to wait, the advisor will return',
  CUSTOMERS_DECISION_OPTION_3: 'Wish to wait, customers will return',
  CUSTOMERS_DECISION_OPTION_4: 'Does not follow advice given',
  CUSTOMERS_DECISION_OPTION_5: 'Other',
  ASSIGNMENTS_AND_FEES: 'Assignments and fees',
  ENTER_FEE_IN: 'Enter fee in %',
  TYPE_OF_FEE: 'Type of fee',
  TYPE_OF_FEE_OPTION_1: 'Investment advice',
  TYPE_OF_FEE_OPTION_2: 'Portfolio management',
  TYPE_OF_FEE_OPTION_3: 'Order on the customer\'s initiative',
  TYPE_OF_FEE_OPTION_4: 'Consulting services',
  DEFAULT_ACCOUNT: 'Default account',
  BANK: 'Bank',
  CLEARING_NUMBER: 'Clearing number',
  ACCOUNT_NUMBER: 'Account number',
  ALLOW_ACCOUNT_NUMBERS: 'Allow account and clearing numbers that cannot be validated',
  AGREEMENT: 'Agreement',
  AGREEMENT_CHECK_1: 'I have taken note of the Citroneer General Terms and Conditions.',
  AGREEMENT_CHECK_2: 'I have read Citroneer\'s Pre-purchase information.',
  AGREEMENT_CHECK_3: 'I have read Citroneer\'s price list.',
  YOUR_SCHEDULED_MEETING: 'Your scheduled meeting',
  MEETING_DETAILS: 'Meeting details',
  START_RECORDING: 'Start recording',
  OR: 'Or',
  YOUR_RECORDING_IS_STOPPED: 'Your recording is stoped',
  YOUR_RECORDING_IN_PROCESSING: 'Your recording in processing...',
  YOUR_RECORDING_IS_PAUSED: 'Your recording is paused',
  ENGLISH: 'English',
  RUSSIAN: 'Russian',
  SWEDEN: 'Sweden',
  RECOMMENDED_RISK_LEVEL: 'Recommended risk level',
  CONFIRM_RESCHEDULE: 'Confirm reschedule',
  MODAL_RESCHEDULE: 'Are you sure you want to reschedule onboarding',
  FROM: 'from',
  TO: 'to',

};
