import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { formatDate } from '../../helpers/formatDate';
import { numberWithSpaces } from '../../helpers/formatInteger';

const { Panel } = Collapse;

// eslint-disable-next-line
const HistoryRecord = ({ changeType, financialInstrumentName, details, ...other }) => {

  const detailsObject = {
    FIELD_UNITS: null,
    FIELD_VALUE: null,
    FIELD_SHARE_PRICE: null,
    FIELD_CURRENCY: null
  };

  // eslint-disable-next-line
  details.forEach(detail => { detailsObject[detail.changedField] = detail});
  switch (changeType) {
    case 'INSTRUMENT_ADDED':
      return (
        <p style={{ color: '#1caf1c' }}>
          + {financialInstrumentName} Units: { numberWithSpaces(detailsObject.FIELD_UNITS?.finalValue) } Value: { numberWithSpaces(detailsObject.FIELD_VALUE.finalValue) } {detailsObject.FIELD_CURRENCY.finalValue}
        </p>
      );
    case 'INSTRUMENT_REMOVED':
      return (
        <p style={{ color: 'red' }}>
          - {financialInstrumentName} Units: { numberWithSpaces(detailsObject.FIELD_UNITS?.initialValue) }  Value: { numberWithSpaces(detailsObject.FIELD_VALUE.initialValue)} {detailsObject.FIELD_CURRENCY.initialValue}
        </p>
      );
    case 'INSTRUMENT_UPDATED':
      return (
        <p>
          <span style={{ marginRight: '5px', fontWeight: '100' }}>
           ~ {financialInstrumentName}:
          </span>

          {detailsObject.FIELD_UNITS && (
            <div style={{ paddingLeft: '30px' }}>
              Units:
              <span style={{ margin: '0 5px' }}>
                {`${numberWithSpaces(detailsObject.FIELD_UNITS?.initialValue)} ->`}
              </span>
              { numberWithSpaces(detailsObject.FIELD_UNITS?.finalValue) }
            </div>
          )}

          {detailsObject.FIELD_VALUE && (
            <div style={{ paddingLeft: '30px' }}>
              Value:
              <span style={{ margin: '0 5px' }}>
                {`${numberWithSpaces(parseInt(detailsObject.FIELD_VALUE.initialValue))} kr ->`}
              </span>
              {numberWithSpaces(parseInt(detailsObject.FIELD_VALUE.finalValue))} kr
            </div>
          )}

          {detailsObject.FIELD_SHARE_PRICE && (
            <div style={{ paddingLeft: '30px' }}>
              Share price:
              <span style={{ margin: '0 5px' }}>
                {`${numberWithSpaces(parseInt(detailsObject.FIELD_SHARE_PRICE.initialValue))} kr ->`}
              </span>
              {numberWithSpaces(parseInt(detailsObject.FIELD_SHARE_PRICE.finalValue))} kr
            </div>
          )}

          {detailsObject.FIELD_CURRENCY && (
            <div style={{ paddingLeft: '30px' }}>
              Currency:
              <span style={{ margin: '0 5px' }}>
                {`${detailsObject.FIELD_CURRENCY.initialValue} ->`}
              </span>
              {detailsObject.FIELD_CURRENCY.finalValue}
            </div>
          )
          }
        </p>
      );
  }

  return (
    changeType === 'INSTRUMENT_ADDED'
      ? <p style={{ color: '#1caf1c' }}>+ {financialInstrumentName} {detailsObject.FIELD_UNITS.finalValue} {detailsObject.FIELD_VALUE.finalValue} {detailsObject.FIELD_CURRENCY.finalValue}</p>
      : <p style={{ color: 'red' }}>- {financialInstrumentName} {detailsObject.FIELD_UNITS.initialValue} {detailsObject.FIELD_VALUE.initialValue} {detailsObject.FIELD_CURRENCY.initialValue}</p>
  );
};

HistoryRecord.propTypes = {
  item: PropTypes.object,
  details: PropTypes.array
};

const HistoryList = ({ history }) => {
  function callback (key) {
    console.log(key);
  }

  const renderItem = useCallback(() => {
    return history?.portfolioHistory?.map((el, index) => {
      return (
           <Panel header={formatDate(history.portfolioHistory[index].createdAt)} key={el.ID}>
             {
               history.portfolioHistory[index].content.map(item => <HistoryRecord {...item} key={item.ID} />)
             }
           </Panel>
      );
    });
  }, [history]);

  return (
    <Collapse style={{ width: '100%', marginTop: 50 }} defaultActiveKey={['1']} onChange={callback}>
      {renderItem()}
    </Collapse>
  );
};

export default HistoryList;

HistoryList.propTypes = {
  history: PropTypes.object
};
