import { Flex, Skeleton } from 'antd';
import React from 'react';

const SkeletonClientInfo = () => {
  return (
    <>
    <Flex className="portfolio-list-wrapper" >
      <Skeleton.Node active={true} className='portfolio-card-skeleton'>
        <Skeleton active/>
      </Skeleton.Node>
      <Skeleton.Node active={true} className='portfolio-card-skeleton'>
        <Skeleton active/>
      </Skeleton.Node>
      <Skeleton.Node active={true} className='portfolio-card-skeleton'>
        <Skeleton active/>
      </Skeleton.Node>
      <Skeleton.Node active={true} className='portfolio-card-skeleton'>
        <Skeleton active/>
      </Skeleton.Node>
    </Flex>

    </>
  );
};

export default SkeletonClientInfo;
