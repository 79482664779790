import React from 'react';

import PropTypes from 'prop-types';

import { Checkbox } from 'antd';

import './style.scss';

const CustomCheckbox = ({ children, onChange, className, ...rest }) => {
  const classN = `custom-checkbox ${className}`;
  return (
    <Checkbox className={classN} onChange={onChange} {...rest}>
      {children}
    </Checkbox>
  );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string
};
