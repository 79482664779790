import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import { useTranslation } from 'react-i18next';
import { Button, Form, Radio, Space } from 'antd';

import { ReactComponent as QuestionIcon } from '../../assets/icon-question.svg';
import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';

import './style.scss';

const OnboardingStart = ({ url, setAccountInfo }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const personalNumber = Form.useWatch('personalNumber', form);

  useEffect(() => {
    form.setFieldValue('accountType', 'privat');
  }, []);

  const onHandleChange = (event) => {
    console.log('🚀 ~ onHandleChange ~ event:', event.target);
  };
  const handleStartOnbording = () => {
    form.submit();
  };

  const onCreate = (data) => {
    setAccountInfo(data);
    history.push(`${url}/planning`);
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
    <div style={{ padding: '35px 24px', height: '100%' }}>
      <Container widthP={100} height={'100%'} padding='24px 0'>
      <div className='onbording-card-wrapper'>
        <div className='onbording-card card-left'>
        <div className='onbording-step-wrapper'>
          <div className='onbording-card-number'>1</div>
          <h2 className='onbording-card-title'>
            {t('ONBOARDING')}
          </h2>
          <p className='onbording-card-description'>{t('WHAT_TYPE_OF_ACCOUNT_IS')}</p>
        </div>
        </div>
        <div className='onbording-card card-right'>
        <Form
          form={form}
          name="onboarding-form-first"
          layout="vertical"
          onFinish={onCreate}
          className="instrument-form"
          style={{ marginLeft: '109px' }}
        >
          <h2 className='onbording-card-header'>
            {t('WHAT_TYPE_ACCOUNT_OPEN')}
          </h2>
          <p className='onbording-card-subheader'>{t('DO_YOU_WANT_TO_START')}</p>
          <div className='onbording-card-checkbox-wrap'>
          <Form.Item
            name='accountType'
            className="m-top-12 m-bottom-0">
            <Radio.Group onChange={onHandleChange} className='checkbox-block'>
              <Space direction="vertical" align='center'>
                <CustomRadioButtom value='privat' checked>{t('PRIVATE_CUSTOMER')}<QuestionIcon/></CustomRadioButtom>
                <CustomRadioButtom disabled value='corporate'>{t('CORPORATE_CLIENT')}<QuestionIcon/></CustomRadioButtom>
              </Space>
            </Radio.Group>
          </Form.Item>
          </div>
            <Form.Item
              className='custom-required input-personal-number'
              label={t('PERSONAL_NUMBER')}
              name="personalNumber"
            >
              <CustomInputNumber
                className='custom-inputnumber'
                controls={false}
              />
            </Form.Item>
            <Button
              disabled={!personalNumber}
              className="optimise-btn start-onbording-btn"
              onClick={handleStartOnbording}
            >
              {t('SAVE')}
            </Button>
        </Form>
        </div>
      </div>

      </Container>
    </div>
    <MainFooter />
    </div>
  );
};

export default OnboardingStart;

OnboardingStart.propTypes = {
  url: PropTypes.string,
  setAccountInfo: PropTypes.func,
};
