import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';

import './style.scss';

const ModalUnsaveData = ({ open, closeModal, cancelWithoutChange, onSubmit }) => {
  const { t } = useTranslation('');
  return (
    <Modal
      width={650}
      className='modal-content-paper'
      open={open}
      onCancel={closeModal}
      footer={null}
      zIndex={1620}
    >
    <Row justify='center'>
      <Col className='modal-title'>
        {t('UNSAVED_CHANGES')}
      </Col>
    </Row>
    <Row justify='center' className='modal-text-wrapper'>
      <Col>
        <p className='modal-text'>{t('YOU_WANT_WITHOUT_SAVING_ON')}</p>
        <p className='modal-text'>{t('YOU_WANT_WITHOUT_SAVING_TWO')}</p>
      </Col>
    </Row>

      <Row
        className='modal-button-wrapper'
      >
        <Button className='modal-action-btn cancel-btn' onClick={cancelWithoutChange}>
          {t('LEAVE_WITHOUT_SAVING')}
        </Button>
        <Button className='modal-action-btn save-btn' onClick={onSubmit}>
          {t('SAVE_CHANGES')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalUnsaveData;

ModalUnsaveData.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  cancelWithoutChange: PropTypes.func,
  onSubmit: PropTypes.func,
};
