import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';

import { ReactComponent as Recording_01 } from '../../assets/rerording-key-1.svg';
import { ReactComponent as Recording_02 } from '../../assets/rerording-key-2.svg';

const AudioRecordIcon = ({width, height, speed = 0, action}) => {
  console.log('🚀 ~ AudioRecordIcon ~ speed:', speed);
  return (
    <div className='microfone-zone'>
    {action
        ? <Recording_01 width={width} height={height}/>
        : <Recording_02 width={width} height={height}/>
    
    }
      
    </div>
  );
};

export default AudioRecordIcon;

AudioRecordIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    speed: PropTypes.number,
    action: PropTypes.bool,
  };