import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography, Dropdown, Menu, Space, Popconfirm } from 'antd';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu as MyMenu } from '../../../assets';
import { ReactComponent as InfoIcon } from './img/icon-info.svg';
import { ReactComponent as CheckIcon } from './img/icon-check.svg';
import { ReactComponent as PencilIcon } from './img/icon-pencil.svg';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClient,
  getClientData,
  setRiskLevel,
} from '../../../data/store/clients/clientActions';

const { Title } = Typography;

const RiskProfilePartTwo = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const [riskSelected, setRiskSelected] = useState('4');
  const { user } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    dispatch(getClient({ id }));
  }, []);

  useEffect(() => {
    if (user) {
      if (
        !user?.client_data ||
        !Object.keys(user.client_data).includes('riskProfile1')
      ) {
        dispatch(getClientData({ id, name: 'riskProfile1' }));
      }
    }
  }, [user?.client_data]);

  const risk = useMemo(() => {
    const resp = user?.risk_level
      ? user?.risk_level.toString()
      : user?.recomented_risk_level.toString();
    return resp;
  }, [user]);

  useEffect(() => {
    setRiskSelected(risk);
  }, [risk]);

  const handleClick = (key) => {
    setRiskSelected(key);
    dispatch(setRiskLevel({ id, riskSelected: key, history }));
  };
  const lowLevelText = t('LOWLEVELTEXT');
  const hightLevelText = t('HIGHTLEVELTEXT');

  const titleChange = (key) => {
    if (user?.recomented_risk_level < key) return hightLevelText;
    if (user?.recomented_risk_level > key) return lowLevelText;
    if (user?.recomented_risk_level === key) { return 'This is the recommended level.'; }
  };

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Popconfirm
          title={titleChange(1)}
          placement='bottom'
          onConfirm={() => {
            handleClick('1');
          }}
        >
          {t('VERY_LOW_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='2'>
        <Popconfirm
          title={titleChange(2)}
          placement='bottom'
          onConfirm={() => {
            handleClick('2');
          }}
        >
          {t('LOW_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='3'>
        <Popconfirm
          title={titleChange(3)}
          placement='bottom'
          onConfirm={() => {
            handleClick('3');
          }}
        >
          {t('MEDIUM_LOW_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='4'>
        <Popconfirm
          title={titleChange(4)}
          placement='bottom'
          onConfirm={() => {
            handleClick('4');
          }}
        >
          {t('MEDIUM_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='5'>
        <Popconfirm
          title={titleChange(5)}
          placement='bottom'
          onConfirm={() => {
            handleClick('5');
          }}
        >
          {t('MEDIUM_HIGH_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='6'>
        <Popconfirm
          title={titleChange(6)}
          placement='bottom'
          onConfirm={() => {
            handleClick('6');
          }}
        >
          {t('HIGH_RISK')}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key='7'>
        <Popconfirm
          title={titleChange(7)}
          placement='bottom'
          onConfirm={() => {
            handleClick('7');
          }}
        >
          {t('VERY_HIGH_RISK')}
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  const getRisk = (index) => {
    switch (index) {
      case '1':
        return 'VERY_LOW_RISK';
      case '2':
        return 'LOW_RISK';
      case '3':
        return 'MEDIUM_LOW_RISK';
      case '4':
        return 'MEDIUM_RISK';
      case '5':
        return 'MEDIUM_HIGH_RISK';
      case '6':
        return 'HIGH_RISK';
      case '7':
        return 'VERY_HIGH_RISK';

      default:
        break;
    }
  };

  const hendleSubmit = useCallback(() => {
    // dispatch(setRiskLevel({ id, riskSelected, history }));
    history.push(`/dashboard/${id}`);
  }, [history, riskSelected]);
  return (
    <>
      <Title className='onboard-component-title'>{t('RISK_PROFILE')}</Title>
      <Container className='risk-container'>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('RISK')}</h4>
        </header>
        <p className='risk-paragraph'>{t('RISK_PARAGRAF1')}</p>
        <p className='risk-paragraph'>{t('RISK_PARAGRAF2')}</p>
        <div className='risk-table'>
          <div className='risk-table-header'>{t('RISK_CLASS')}</div>
          <div className='risk-table-header risk-table-header-sri'>SRI</div>
          <div className='risk-table-header risk-table-header-center'>
            <div className='risk-table-header-exempel'>
              {t('EXAMPLE_OF_PRODUCT')}
            </div>
          </div>
          <div className='risk-table-header risk-table-header-center'>
            <div className='risk-table-header-annual'>
              {t('VOLATILITY_ANNUAL')}
            </div>
          </div>
          <div className='risk-table-header risk-table-header-center'>
            <div className='risk-table-header-annual'>{t('RETURN_ANNUAL')}</div>
          </div>
          <div className='risk-table-header'>
            {t('RISK_OF_AN_ANNUAL_RETURN_LOWER')}
          </div>

          <div className='risk-table-cell'>{t('VERY_LOW_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-1'>1</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>
              {t('GOVERNMENT_BOND')}
            </div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'< 0,5 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'0,3 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>{'0 %'}</div>

          <div className='risk-table-cell'>{t('LOW_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-2'>2</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>{t('CORPORATE_BOND')}</div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'0,5 – 5 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'2,3 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>{'-2 %'}</div>

          <div className='risk-table-cell'>{t('MEDIUM_LOW_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-3'>3</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>
              {t('CORPORATE_BOND_HIGH_YIELD')}
            </div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'5 – 12 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'3,4 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>{'-7 %'}</div>

          <div className='risk-table-cell'>{t('MEDIUM_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-4'>4</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>
              {t('BROAD_STOCK_PORTFOLIO_INDEX')}
            </div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'12 – 20 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'6,3 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'-14 %'}
          </div>

          <div className='risk-table-cell'>{t('MEDIUM_HIGH_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-5'>5</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>
              {t('STOCK_SMALL_COMPANIES')}
            </div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'20 – 30 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'9,6 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'-22 %'}
          </div>

          <div className='risk-table-cell'>{t('HIGH_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-6'>6</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>
              {t('STRUCTURED_PRODUCT')}
            </div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'30 – 80 %'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>{'21 %'}</div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'-50 %'}
          </div>

          <div className='risk-table-cell'>{t('VERY_HIGH_RISK')}</div>
          <div className='risk-table-cell-sri risk-table-cell-sri-7'>7</div>
          <div className='risk-table-cell risk-table-cell-center'>
            <div className='risk-table-cell-exempel'>{t('WARRANT')}</div>
          </div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'80 % >'}
          </div>
          <div className='risk-table-cell risk-table-cell-center'>{'30 %'}</div>
          <div className='risk-table-cell risk-table-cell-center'>
            {'-72 %'}
          </div>
        </div>
        <div className='risk-info'>
          <a href='./info' target='_blank'>
            <InfoIcon />
            {t('READ_MORE')}
          </a>
        </div>
      </Container>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>
            {t('RISK_CLASSIFICATION_PROPOSAL')}
          </h4>
        </header>
        <div className='risk-paragraph'>{t('RISK_PARAGRAF2')}</div>
        <div className='risk-action'>
          <div className='risk-check-icon'>
            <CheckIcon />
          </div>
          <div className='risk-selected'>{t(getRisk(riskSelected))}</div>
          <div className='risk-check-button'>
            <Dropdown overlay={menu} placement='topLeft'>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <PencilIcon />
                  Ändra riskklass
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <MyMenu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

RiskProfilePartTwo.propTypes = {
  id: PropTypes.string,
};

export default RiskProfilePartTwo;
