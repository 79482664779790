/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Layout, Row } from 'antd';

import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import './style.scss';
import AdminApprovedTable from './AdminApprovedTable';
import MainFooter from '../../components/MainFooter/MainFooter';
import { getAdvisors } from '../../data/store/advisors/advisorAction';
import { getUsers } from '../../data/store/clients/clientActions';
import AdminExecutedTable from './AdminExecutedTable';
import AdminImplementedTable from './AdminImplementedTable';

const { Header } = Layout;

const OrderInstructionsRout = () => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { typeTab } = useParams();

  useEffect(() => {
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
    dispatch(getUsers());
  }, []);

  return (
    <>
      <div className="instrument-wrapper instrument-block-wrap">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'approved' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push('/admin-order-instructions/approved')
                  }
                >
                  {t('APPROVED')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'executing' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push('/admin-order-instructions/executing')
                  }
                >
                  {t('EXECUTING')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'implemented' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push('/admin-order-instructions/implemented')
                  }
                >
                  {t('IMPLEMENTED')}
                </Button>
              </Col>
            </Row>
          </Header>
            {typeTab === 'approved'
              ? <AdminApprovedTable />
              : typeTab === 'executing'
                ? <AdminExecutedTable />
                : typeTab === 'implemented'
                  ? <AdminImplementedTable />
                  : null
            }
        </div>
        <MainFooter />
      </div>
    </>
  );
};
export default OrderInstructionsRout;

OrderInstructionsRout.propTypes = {
};
