export const InstrumentsSW = {
  SORT_BY_NAME: 'Sortera efter namn',
  SORT_BY_CREATION_DATE: 'Sortera efter skapelsedatum',
  DELETE_INSTRUMENT: 'Radera instrument',
  CONNECT_INSTRUMENT: 'Anslut instrument',
  EDIT_INSTRUMENT: 'Redigera instrument',
  SURE_TO_CANCEL: 'Är du säker på att du vill avbryta?',
  SAVE: 'Spara',
  CANCEL: 'Avbryt',
  ERROE_TEXT_MODAL: 'Uppdatera portföljen eller radera begäran:',
  LINK_EDIT_PORTFOLIO: 'Länk till redigera portfölj',
  CONNECT: 'Anslut',
  MAPPING_TO: 'till',
  TICKER: 'Ticker',
};
