/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Layout, Row } from 'antd';

import {
  getAllPortfolio,
  getStepOptimisationPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import Container from '../../components/Container';

import MainFooter from '../../components/MainFooter';

import { FinPlanTable } from './FinPlanTable';

import ModalCreate from './ModalCreate';

import './styles.scss';

const { Header } = Layout;

const FinancialPlanning = ({
  userId,
  portfolioId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const { finPlan } = useSelector((state) => state.clientReducer);
  const portfolioList = useSelector(
    (state) => state.portfolioReducer.portfolioList.list
  );

  useEffect(() => {
    if (portfolioId) dispatch(getStepOptimisationPortfolio({ portfolioId }));
    if (portfolioId) dispatch(getAllPortfolio({ userId }));
  }, [portfolioId]);

  const closeCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('LIFETIMING')}
            </Button>
          </Col>
          {!!finPlan?.length && (
            <Col className="tabs-btn-wrapper" span={6}>
              <Button
                type="link"
                className="optimise-btn"
                onClick={handleOpenCreateModal}
              >
                {t('ADD_FINANCIAL_PLAN')}
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <div className="finplan-summary-container">
        <div>
          <div className="portfolio-title-wrapper">
            <h2 className="finplan-block-title">
              Life <span className="finplan-title-dots"> :</span> Timing
            </h2>
          </div>
          {!finPlan?.length
            ? (
            <>
              <div className="noportfolio-wrapper">
                <Container
                  className="nofinplan-container"
                  widthP={100}
                  padding="31px 40px 34px"
                >
                  <h2 className="finplan-title">{t('NO_FINANCIAL_PLANS')}</h2>
                  <p className="finplan-text">{t('NO_FINANCIAL_PLANS_TEXT')}</p>
                  <Button
                    className="optimise-btn noportfolio-create-btn"
                    onClick={handleOpenCreateModal}
                  >
                    {t('CREATE_FINANCIAL_PLAN')}
                  </Button>
                </Container>
              </div>
            </>
              )
            : (
            <div style={{ padding: '0 24px' }}>
              <Container widthP={100} padding="31px 0px 34px">
                <h2 style={{ paddingLeft: '40px' }} className="finplan-title">{t('FINANCIAL_PLANS')}</h2>
                <FinPlanTable
                  userId={userId}
                  finPlanList={finPlan}
                  portfolioList={portfolioList}
                />
              </Container>
            </div>
              )}
        </div>
        <MainFooter />
      </div>
      <ModalCreate
        clientId={userId}
        t={t}
        openCreateModal={openCreateModal}
        closeCreateModal={closeCreateModal}
      />
    </>
  );
};

export default FinancialPlanning;

FinancialPlanning.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
