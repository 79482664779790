import React from 'react';

import { Typography, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import { Menu } from '../../../assets';
import './style.scss';
import MainButton from '../../../components/MainButton';

const { Title } = Typography;

const ScopeAndInvestable = () => {
  return (
    <Form name='horizontal_login' layout='vertical'>
      <Title style={{ display: 'flex', justifyContent: 'center' }}>
        Ekonomisk situation
      </Title>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>Omfattning och investeringsbart kapital</h4>
        </header>
        <Form.Item label={'Finns det delar som ej ska omfattas av rådgivningen'} style={{ marginTop: 40 }}>
          <TextArea type='text' />
        </Form.Item>
        <div className='ekonomik-subtitle'>
          <span>Kapital som ej ska omfattas av rådgivningen</span>
          <span>17 500 000 kr</span>
        </div>
        <Form.Item>
          <Input type='range' />
        </Form.Item>
        <div className='ekonomik-subtitle'>
          <span>Investeringsbart kapital</span>
          <span>46 500 000 kr</span>
        </div>
        <Form.Item label={'Efter skatt'}>
          <Input type='range' />
        </Form.Item>
      </Container>
      <div className='btn-container'>
        <MainButton style={{ width: 150 }} type='link'>
          Gå tillbaka
        </MainButton>
        <Menu style={{ width: 85 }} />
        <MainButton style={{ width: 150 }} type='submit'>
          Gå vidare
        </MainButton>
      </div>
    </Form>
  );
};

export default ScopeAndInvestable;
