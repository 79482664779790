import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux';

import { Button, Divider, Layout, Menu, Typography } from 'antd';

import LogoType from '../../assets/lt_full_logo.png';

import { ReactComponent as UserIcon } from '../../assets/user-icon.svg';
import { ReactComponent as ClientHome } from '../../assets/client-home-icon.svg';
import { ReactComponent as Collapse } from '../../assets/arrow-updown.svg';
import { ReactComponent as UnCollapse } from '../../assets/arrow-down.svg';
import { ReactComponent as AssetIcon } from '../../assets/asset-icon.svg';
import { ReactComponent as AdmisorsIcon } from '../../assets/advisors-icon.svg';
import { ReactComponent as SupportIcon } from '../../assets/support-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg';
import { ReactComponent as AddClient } from '../../assets/add-icon.svg';
import { ReactComponent as Order } from '../../assets/order-instr-icon.svg';

import './style.scss';
import { logOut } from '../../data/store/user/userActions';
import CustomSearchInput from '../CustomSearchInput/CustomSearchInput';

const { Sider } = Layout;
const { Text } = Typography;

const SideBar = ({
  userId,
  setUserId,
  needSave,
  setSaveAlert,
  setOptAlert,
  optStart,
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation('');
  const containerRef = useRef(null);
  const containerNavRef = useRef(null);
  const { pathname, state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState(null);
  const [hideList, setHideList] = useState(true);
  const [userCollapse, setUserCollapse] = useState(true);
  const [clientMenu, setClientMenu] = useState(null);

  const users = useSelector((state) => state.clientReducer.users.list);
  const isLoadingClient = useSelector((state) => state.clientReducer.isLoading);
  const isLoadingPortfolio = useSelector((state) => state.portfolioReducer.isLoading);
  const { role, profile } = useSelector((state) => state.userReducer.advisor);

  const isLoading = useMemo(() => {
    return isLoadingClient || isLoadingPortfolio;
  }, [isLoadingClient, isLoadingPortfolio]);

  const [filteredUsers, setFilteredUsers] = useState(null);

  useEffect(() => {
    console.log('currentUser', currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (users) setFilteredUsers(users);
  }, [users]);

  const handleFiletUsers = (event) => {
    const { value } = event.target;
    if (users?.length && value?.length > 2) {
      if (isNaN(+value)) {
        const filtered = users?.filter(user => user?.firstName.toLowerCase().includes(value.toLowerCase()) ||
        user?.lastName.toLowerCase().includes(value.toLowerCase()));
        setFilteredUsers(filtered);
      } else if (value?.length >= 3) {
        const filtered = users?.filter(user => user?.portfolio.some(i => i.portfolio_code?.toString()?.includes(value)));
        setFilteredUsers(filtered);
      }
    } else {
      setFilteredUsers(users);
    }
  };

  useEffect(() => {
    if (state?.from) {
      setHideList(false);
    }
  }, [state]);

  useEffect(() => {
    if (userId) {
      setCurrentUser(userId);
      const container = containerRef.current;
      container.scrollTo({ top: 0, behavior: 'instant' });
      setHideList(true);
    } else if (pathname !== '/clients') {
      setCurrentUser(null);
      setHideList(true);
    } else if (!userId) {
      setCurrentUser(null);
      setHideList(true);
    }
  }, [userId]);

  const clientMenuItem = [
    {
      key: 11,
      label: t('OVERVIEW'),
      path: `/clients/${currentUser}/portfolio`,
    },
    {
      key: 12,
      label: t('LIFETIMING'),
      path: `/clients/${currentUser}/lifetiming`,
    },
    {
      key: 13,
      label: t('DOCUMENTS'),
      path: `/clients/${currentUser}/documents`,
    },
    {
      key: 14,
      label: t('SUGGESTED_PORTFOLIOS'),
      path: `/clients/${currentUser}/suggested-portfolios`,
    },
    {
      key: 16,
      label: t('PROFILE'),
      path: `/clients/${currentUser}/inform/profile`,
    },
  ];
  const clientMenuItemAdv = [
    {
      key: 11,
      label: t('OVERVIEW'),
      path: `/clients/${currentUser}/portfolio`,
    },
    {
      key: 12,
      label: t('LIFETIMING'),
      path: `/clients/${currentUser}/lifetiming`,
    },
    {
      key: 13,
      label: t('DOCUMENTS'),
      path: `/clients/${currentUser}/documents`,
    },
    {
      key: 14,
      label: t('SUGGESTED_PORTFOLIOS'),
      path: `/clients/${currentUser}/suggested-portfolios`,
    },
    {
      key: 16,
      label: t('PROFILE'),
      path: `/clients/${currentUser}/inform/profile`,
    },
  ];

  const items = [
    {
      key: '2',
      icon: <AssetIcon />,
      label: t('QUANT'),
      children: [
        // {
        //   key: '21',
        //   label: t('MODEL'),
        //   path: '/quant/model'
        // },
        // {
        //   key: '22',
        //   label: t('PORTFOLIO_CONSTRAINTS'),
        //   path: '/quant/portfolio-constraints'
        // },
        // {
        //   key: '23',
        //   label: t('APPROVED_INSTRUMENTS'),
        //   path: '/quant/approved-instruments'
        // },
        // {
        //   key: '24',
        //   label: t('INVESTOR_BIAS'),
        //   path: '/quant/investor-bias'
        // },
        {
          key: '25',
          label: t('INSTRUMENT_MAPPING'),
          path: '/quant/asset/list'
        },
        {
          key: '26',
          label: t('ASSET_CLASSES'),
          path: '/quant/asset-classes'
        },
      ],
    },
    {
      key: '3',
      icon: <AdmisorsIcon />,
      label: t('ADVISORS_LC'),
      path: '/advisors/advisors-list',
    },
    {
      key: '6',
      icon: <Order />,
      label: t('ORDER_INSTRUCTIONS'),
      path: '/admin-order-instructions/approved',
    },
    {
      key: '4',
      icon: <SupportIcon />,
      label: t('SUPPORT_LC'),
      path: '/support',
    },
    {
      key: '5',
      icon: <SettingsIcon />,
      label: t('SETTINGS'),
      path: '/settings',
    },
  ];
  const itemsAdv = [
    {
      key: '4',
      icon: <SupportIcon />,
      label: t('SUPPORT_LC'),
      path: '/support',
    },
    {
      key: '5',
      icon: <SettingsIcon />,
      label: t('SETTINGS'),
      path: '/settings',
    },
  ];

  const [stateOpenKeys, setStateOpenKeys] = useState();

  useEffect(() => {
    console.log('stateOpenKeys', stateOpenKeys);
  }, [stateOpenKeys]);

  useEffect(() => {
    const suffix = pathname.split('/').at(-1);
    const preffix = pathname.split('/').at(3);
    const befSuffix = pathname.split('/').at(-2);
    if (suffix === 'portfolio') setClientMenu(11);
    if (['portfolio-information', 'holdings', 'fin-planning', 'constraints', 'fixation']
      .includes(suffix)) {
      setClientMenu(11);
      setStateOpenKeys();
    }
    if (suffix === 'lifetiming' || preffix === 'lifetiming') setClientMenu(12);
    if (suffix === 'documents') setClientMenu(13);
    if (suffix === 'suggested-portfolios' || preffix === 'suggested-portfolios') setClientMenu(14);
    if (suffix === 'order-instructions') setClientMenu(15);
    if (['inform'].includes(preffix)) setClientMenu(16);

    if (suffix === 'model') {
      setStateOpenKeys(['2', '21']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'portfolio-constraints') {
      setStateOpenKeys(['2', '22']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'approved-instruments') {
      setStateOpenKeys(['2', '23']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'investor-bias') {
      setStateOpenKeys(['2', '24']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'list' || suffix === 'tbd' || befSuffix === 'edit-instrument') {
      setStateOpenKeys(['2', '25']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'asset-classes') {
      setStateOpenKeys(['2', '26']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'advisors-list' || befSuffix === 'advisors-list') {
      setStateOpenKeys(['3']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (befSuffix === 'admin-order-instructions') {
      setStateOpenKeys(['6']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'support') {
      setStateOpenKeys(['4']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
    if (suffix === 'settings') {
      setStateOpenKeys(['5']);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }
  }, [currentUser, pathname]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys?.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const getClientName = useMemo(() => {
    let user;
    if (users && currentUser) {
      user = users.find((i) => i.ID === +currentUser);
    }
    return user ? `${user?.firstName} ${user?.lastName}` : 'Not found';
  }, [users, currentUser]);

  const getUserName = () => {
    const roleUser = role === 'SUPERADMIN' ? t('ADMIN') : t('ADVISOR');
    const nameUser = profile?.firstName
      ? `${profile?.firstName} ${profile?.lastName}`
      : '';
    return { roleUser, nameUser };
  };

  const scrollToCenter = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const selectedElement = container.querySelector('.selected-user');
      if (selectedElement) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = selectedElement.getBoundingClientRect();
        const scrollTop =
          elementRect.top -
          containerRect.top -
          containerRect.height / 2 +
          elementRect.height / 2;
        container.scrollTo({ top: scrollTop, behavior: 'smooth' });
      }
    }
  }, [hideList]);

  // TODO: unsaved
  const toggleHiddenList = () => {
    if (!needSave && !optStart && !isLoading) {
      if (currentUser) {
        if (!hideList) {
          const container = containerRef.current;
          container.scrollTo({ top: 0, behavior: 'instant' });
        } else {
          setFilteredUsers(users);
          setTimeout(() => {
            scrollToCenter();
          }, 1000);
        }
      }
      setHideList(!hideList);
    } else {
      if (needSave) setSaveAlert({ flag: true, path: '/clients', state: { from: 'unsave' } });
      if (optStart) setOptAlert({ flag: true, path: '/clients', state: { from: 'unsave' } });
    }
  };

  const selectUser = (id) => {
    setStateOpenKeys(undefined);
    setUserId(id);
    const container = containerRef.current;
    container.scrollTo({ top: 0, behavior: 'instant' });
    setHideList(true);
    history.push(`/clients/${id}/portfolio`);
  };

  const selectMenu = ({ key, item }) => {
    console.log('🚀 ~ selectMenu ~ key:', key);
    const { props: { path } } = item;
    if (!needSave && !optStart) {
      if (key <= 11 && key > 20) setStateOpenKeys([key]);
      if (key > 11 && key < 21 && !userId) {
        setHideList(false);
        return;
      }
      setUserId(null);
      setCurrentUser(null);
      setClientMenu(null);
      history.push(path);
    } else {
      if (needSave) setSaveAlert({ flag: true, path });
      if (optStart) setOptAlert({ flag: true, path });
    }
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items);

  const handleClientMenu = (key, path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
      return;
    }
    if (optStart) {
      setOptAlert({ flag: true, path });
      return;
    }
    console.log('🚀 ~ handleClientMenu ~ key:', key);
    setClientMenu(key);
    history.push(path);
  };

  const handleAddUser = () => {
    history.push('/clients/create-account');
  };

  const toggleCollapse = () => {
    setUserCollapse(!userCollapse);
  };
  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <Sider width="258" className="sider-style" theme="light">
      <div className="logo-wrapper">
        <NavLink to={'/'} className="logotype">
          <img src={LogoType} alt="" />
        </NavLink>
      </div>
      <Divider
        style={{ height: '1px', backgroundColor: '#E1E1E9', marginTop: '0' }}
      />
      <div className="client-wrapper">
      <div>
        <div
          className={`client-box-title ${!hideList ? 'uncollapsed' : ''} ${isLoading ? 'desabled' : ''}`}
          onClick={toggleHiddenList}
        >
          <ClientHome />
          <div className="client-box-text-wraper">
            <Text ellipsis={true}>{currentUser ? getClientName : 'Select a client'}</Text>
            {currentUser && <p className="client-box-text">{t('CLIENT')}</p>}
          </div>
          {hideList ? <Collapse /> : <UnCollapse />}
        </div>
        <div className={`client-box ${!hideList ? 'uncollapsed' : ''}`}>
          {!hideList &&
          <div style={{ width: '226px', background: '#FCFCFC', padding: '2px 3px' }}>
            <CustomSearchInput
              width='220px'
              setFocus
              onChange={handleFiletUsers}
              />
          </div>}
          <div
            ref={containerRef}
            className={`client-button-wrapper ${hideList ? 'hidden' : ''}`}
          >
            {filteredUsers &&
              filteredUsers
                .slice()
                .sort((a, b) => textSort(a.firstName, b.firstName))
                .map((item) => {
                  return (
                    <Button
                      key={item.ID}
                      type='text'
                      onClick={() => selectUser(item.ID)}
                      className={`client-list-item ${
                        currentUser === item.ID ? 'selected-user' : ''
                      }`}
                    >
                      <Text ellipsis={true}>
                        {`${item.firstName} ${item.lastName}`}
                      </Text>
                    </Button>
                  );
                })}
          </div>
          {!hideList && (
            <Button
              icon={<AddClient />}
              iconPosition="end"
              onClick={handleAddUser}
              className={'add-client-btn'}
            >
              <Text>{t('ADD_CLIENT')}</Text>
            </Button>
          )}
        </div>
          <div
            ref={containerNavRef}
            className={`client-nav-wrapper ${!hideList ? 'hidden' : ''}`}
          >
        {
          role === 'SUPERADMIN'
            ? currentUser && clientMenuItem.map(i => {
              return (
              <Button
                type='text'
                key={i.key}
                className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                onClick={() => handleClientMenu(i.key, i.path)}
                >
                {i.label}
              </Button>
              );
            })
            : currentUser && clientMenuItemAdv.map(i => {
              return (
              <Button
                type='text'
                key={i.key}
                className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                onClick={() => handleClientMenu(i.key, i.path)}
                >
                {i.label}
              </Button>
              );
            })
        }
        <Menu
          mode="inline"
          selectedKeys={stateOpenKeys}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          style={{
            width: 240,
          }}
          items={role === 'SUPERADMIN' ? items : itemsAdv}
          onClick={selectMenu}
        />
          </div>
      </div>
        <div className={`user-box ${!userCollapse ? 'uncollapsed' : ''}`}>
          <div
            className="user-box-title "
            onClick={toggleCollapse}
          >
            <UserIcon />
            <div className="client-box-text-wraper">
              <Text ellipsis={true}>{getUserName().nameUser}</Text>
              <p className="client-box-text">{getUserName().roleUser}</p>
            </div>
            {userCollapse ? <Collapse /> : <UnCollapse />}
          </div>
          {!userCollapse && (
            <div className="logout-link" onClick={handleLogout}>
              {t('LOG_OUT')}
            </div>
          )}
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;

SideBar.propTypes = {
  userId: PropTypes.number,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  optStart: PropTypes.bool,
};
