export default class SessionService {
  static KEYS = {
    ACCESS_TOKEN: 'acc',
    REFRESH_TOKEN: 'rfr'
  };

  static getItem (key) {
    return sessionStorage.getItem(key);
  }

  static setItem (key, value) {
    return sessionStorage.setItem(key, value);
  }

  static removeItem (key) {
    return sessionStorage.removeItem(key);
  }

  static removeJWTToken () {
    this.removeItem(this.KEYS.ACCESS_TOKEN);
  }

  static getJWTToken () {
    return this.getItem(this.KEYS.ACCESS_TOKEN);
  }

  static setJWTToken (value) {
    this.setItem(this.KEYS.ACCESS_TOKEN, value);
  }
}
