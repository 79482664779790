/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

export const validateCategiry = (onboardingData) => {
  const validateInit = {
    'type-of-account': 'full',
    'client-information': 'empty',
    'purpose-matter': 'empty',
    'financial-situation': 'empty',
    'basic-prerequisites-for-management': 'empty',
    'know-your-client': 'empty',
    'risk-tolerance': 'empty',
    'risk-profile': 'empty',
    'experience-background': 'empty',
  };
  if (onboardingData) {
    if (onboardingData && onboardingData?.result?.client_info) {
      const { result: { client_info } } = onboardingData;
      const array = !client_info
        ? [undefined]
        : Object.values(client_info?.common?.contact_data)
          .concat(Object.values(client_info?.common?.main_info));
      validateInit['client-information'] = array.every(i => !!i)
        ? 'full'
        : array.some(i => !!i)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['client-information'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.invest_target) {
      const { result: { invest_target } } = onboardingData;
      const array = !invest_target
        ? [undefined]
        : [!!invest_target?.additional_questions?.investment_purpose,
            !!invest_target?.additional_questions?.qualitative_goals,
            !!invest_target?.additional_questions?.sustainable_view,
          ];
      validateInit['purpose-matter'] = array.every(i => !!i)
        ? 'full'
        : array.some(i => !!i)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['purpose-matter'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.fin_state) {
      const revenueAndExpenditure = { ...onboardingData?.result?.fin_state?.revenue_and_expenditure };
      delete revenueAndExpenditure.commentary;
      let arrayMandatory = Object.values(revenueAndExpenditure);
      let arrayNonMandatory = [onboardingData?.result?.fin_state?.revenue_and_expenditure?.commentary];

      const financialAssets = { ...onboardingData?.result?.fin_state?.financial_assets };
      delete financialAssets.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(financialAssets));
      arrayNonMandatory = arrayNonMandatory.concat([onboardingData?.result?.fin_state?.financial_assets?.commentary]);

      const fixedAssets = { ...onboardingData?.result?.fin_state?.fixed_assets };
      delete fixedAssets.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(fixedAssets));
      arrayNonMandatory = arrayNonMandatory.concat([onboardingData?.result?.fin_state?.fixed_assets?.commentary]);

      const shoulder = { ...onboardingData?.result?.fin_state?.shoulder };
      delete shoulder.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(shoulder));
      arrayNonMandatory = arrayNonMandatory.concat([onboardingData?.result?.fin_state?.shoulder.commentary]);

      const investableСapital = { ...onboardingData?.result?.fin_state?.investable_capital };
      delete investableСapital.non_covered_elements;
      arrayMandatory = arrayMandatory.concat(Object.values(investableСapital));
      arrayNonMandatory = arrayNonMandatory.concat([onboardingData?.result?.fin_state.investable_capital?.non_covered_elements]);

      validateInit['financial-situation'] = arrayMandatory.every(i => !!i || i === 0 || i === false)
        ? 'full'
        : arrayNonMandatory.some(i => !!i) || arrayMandatory.some(i => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['financial-situation'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.prerequisites) {
      const generalQuestions = { ...onboardingData?.result?.prerequisites?.general_questions };
      delete generalQuestions.future_generation;
      delete generalQuestions.commentary;
      const arrayMandatory = Object.values(generalQuestions);
      const arrayNonMandatory = [
        onboardingData?.result?.prerequisites?.general_questions?.future_generation,
        onboardingData?.result?.prerequisites?.general_questions?.commentary];

      validateInit['basic-prerequisites-for-management'] = arrayMandatory.every(i => !!i || i === 0 || i === false)
        ? 'full'
        : arrayNonMandatory.some(i => !!i) || arrayMandatory.some(i => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['basic-prerequisites-for-management'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.kyc) {
      const citizenshipAndResidence = { ...onboardingData?.result?.kyc?.citizenship_and_residence };
      delete citizenshipAndResidence.citizenship_other;
      let arrayMandatory = Object.values(citizenshipAndResidence);
      const arrayNonMandatory = [
        onboardingData?.result?.kyc.citizenship_and_residence?.citizenship_other,
      ];

      const abroad = { ...onboardingData?.result?.kyc.abroad };
      arrayMandatory = arrayMandatory.concat(Object.values(abroad));

      const savingPurpose = { ...onboardingData?.result?.kyc?.purpose_of_engagement?.savings_purpose };
      arrayMandatory = arrayMandatory.concat(Object.values(savingPurpose));

      if (onboardingData?.result?.kyc.purpose_of_engagement?.describe_other_purpose) {
        const describeOtherPurpose = [onboardingData?.result?.kyc?.purpose_of_engagement?.describe_other_purpose];
        arrayMandatory = arrayMandatory.concat(Object.values(describeOtherPurpose));
      }

      if (onboardingData?.result?.kyc.purpose_of_engagement?.comment_on_proxy) {
        const commentOnProxy = [onboardingData?.result?.kyc?.purpose_of_engagement?.comment_on_proxy];
        arrayMandatory = arrayMandatory.concat(Object.values(commentOnProxy));
      }

      if (onboardingData?.result?.kyc.purpose_of_engagement?.describe_other_behalf) {
        const describeOtherBehalf = [onboardingData?.result?.kyc?.purpose_of_engagement?.describe_other_behalf];
        arrayMandatory = arrayMandatory.concat(Object.values(describeOtherBehalf));
      }

      const behalf = [onboardingData?.result?.kyc?.purpose_of_engagement?.behalf];
      arrayMandatory = arrayMandatory.concat(Object.values(behalf));

      const occupation = { ...onboardingData?.result?.kyc?.occupation };
      arrayMandatory = arrayMandatory.concat(Object.values(occupation));

      const capitalTransfer = { ...onboardingData?.result?.kyc?.capital_transfer };
      delete capitalTransfer.money_origin;
      delete capitalTransfer.bank_capital;
      arrayMandatory = arrayMandatory.concat(Object.values(capitalTransfer));
      const moneyOrigin = { ...onboardingData?.result?.kyc?.capital_transfer?.money_origin };
      arrayMandatory = arrayMandatory.concat(Object.values(moneyOrigin));
      const bankCapital = { ...onboardingData?.result?.kyc?.capital_transfer?.bank_capital };
      arrayMandatory = arrayMandatory.concat(Object.values(bankCapital));

      const depositsWithdrawals = { ...onboardingData?.result?.kyc?.deposits_withdrawals };
      arrayMandatory = arrayMandatory.concat(Object.values(depositsWithdrawals));

      const politicalPosition = { ...onboardingData?.result?.kyc?.political_position };
      arrayMandatory = arrayMandatory.concat(Object.values(politicalPosition));

      validateInit['know-your-client'] = arrayMandatory.every(i => !!i || i === 0 || i === false)
        ? 'full'
        : arrayNonMandatory.some(i => !!i) || arrayMandatory.some(i => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['know-your-client'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.risk_tolerance) {
      const riskTolerance = { ...onboardingData?.result?.risk_tolerance };
      delete riskTolerance.additional_comment;
      const arrayMandatory = Object.values(riskTolerance);
      const arrayNonMandatory = [onboardingData?.result?.risk_tolerance?.additional_comment];

      validateInit['risk-tolerance'] = arrayMandatory.every(i => !!i || i === 0 || i === false)
        ? 'full'
        : arrayNonMandatory.some(i => !!i) || arrayMandatory.some(i => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['risk-tolerance'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.risk_profile) {
      const riskProfile = { ...onboardingData?.result?.risk_profile };

      validateInit['risk-profile'] = riskProfile
        ? 'full'
        : 'empty';
    } else {
      validateInit['risk-profile'] = 'empty';
    }

    if (onboardingData && onboardingData?.result?.experience) {
      const general_info = { ...onboardingData?.result?.experience?.general_info };
      let arrayMandatory = Object.values(general_info);

      const assetType = { ...onboardingData?.result?.experience?.assets_type_knowledge?.asset_type };
      for (const el in assetType) {
        arrayMandatory = arrayMandatory.concat(Object.values(assetType[el]));
      }

      let arrayNonMandatory = [onboardingData?.result?.experience?.assets_type_knowledge?.additional_comment];

      arrayMandatory = arrayMandatory.concat(Object.values([onboardingData?.result?.experience?.portfolio_management?.management_experience]));
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.experience?.portfolio_management?.portfolio_trained,
        onboardingData?.result?.experience?.portfolio_management?.portfolio_understanding,
        onboardingData?.result?.experience?.portfolio_management?.additional_comment,
      ]);

      arrayMandatory = arrayMandatory.concat([onboardingData?.result?.experience?.risk_of_loss?.loss_risk]);

      validateInit['experience-background'] = arrayMandatory.every(i => !!i || i === 0 || i === false)
        ? 'full'
        : arrayNonMandatory.some(i => !!i) || arrayMandatory.some(i => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
    } else {
      validateInit['experience-background'] = 'empty';
    }
  }
  return validateInit;
};
