import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { categoryList } from '../data/itemsCategory';
import { Button, Col, Dropdown, Row } from 'antd';

import { ReactComponent as Tiangel } from '../img/triangel.svg';

const TabsItemsFilter = ({ category, count = 6, categoryFilter, setCategoryFilter, currentFinplan }) => {
  const { t } = useTranslation('');

  const handleMenu = (path) => {
    setCategoryFilter(path);
  };

  const getCount = (name) => {
    if (!currentFinplan) return 0;
    if (name === 'all') return currentFinplan[category]?.length ?? 0;
    const count = currentFinplan[category]?.filter((i) => i.category === name)?.length ?? 0;
    return count;
  };

  let tags = [];
  let more = null;
  if (categoryList[category]?.length > count) {
    tags = categoryList[category]?.slice(0, count);
    more = categoryList[category]?.slice(count);
  } else {
    tags = categoryList[category];
  }

  const items = more.map(i => ({
    key: i.key,
    label: (<a className='portfolio-menu-item tabmore-menu-items'
    onClick={() => handleMenu(i.value)}>
    {t(i.label)} {' '} <span className='finplan-tags-count'>{getCount(i.value)}</span>
  </a>)
  }));

  return (
    <Row justify={'space-between'} className='tabs-filter-row'>
      <Col>
        <Button
        type='text' className={categoryFilter === 'all' ? 'tabs-filter-btn active' : 'tabs-filter-btn' }
        onClick={() => handleMenu('all')}
        >All<span className='finplan-tags-count'>{getCount('all')}</span></Button>
      </Col>
      {
        tags.map(i => {
          return (
            <Col key={i.value}>
              <Button
                className={categoryFilter === i.value ? 'tabs-filter-btn active' : 'tabs-filter-btn' }
                type='text'
                onClick={() => handleMenu(i.value)}
                >
                {t(i.label)}
                <span className='finplan-tags-count'>{getCount(i.value)}</span>
                </Button>
            </Col>
          );
        })
      }
      {
        more &&
        <Dropdown
          overlayClassName={'portfolio-dropdown'}
          placement='bottom'
          menu={{
            items
          }}
        >
        <Button
          type='text'
          icon={<Tiangel />}
          iconPosition='end'
          className='tabmore-menu-items'
        >{t('More')}</Button>
        </Dropdown>
      }
    </Row>
  );
};

export default TabsItemsFilter;

TabsItemsFilter.propTypes = {
  category: PropTypes.string,
  count: PropTypes.number,
  categoryFilter: PropTypes.string,
  setCategoryFilter: PropTypes.func,
  currentFinplan: PropTypes.object,
};
