export const OnboardingQustions = [
  {
    order: 1,
    key: 'type-of-account',
    set: []
  },
  {
    order: 2,
    key: 'client-information',
    set: [
      // {
      //   key: 1,
      //   question: {
      //     en: 'Date of advice',
      //     sw: 'Datum för rådgivning'
      //   },
      //   options: {
      //     en: null,
      //     sw: null,
      //   }
      // },
      // {
      //   key: 2,
      //   question:
      //   {
      //     en: 'The meeting format of the advice',
      //     sw: 'Rådgivningens mötesform'
      //   },
      //   options:
      //   {
      //     en: ['Offline meeting', 'Online meeting', 'Phone meeting'],
      //     sw: ['Offlinemöte', 'Onlinemöte', 'Telefonmöte']
      //   }
      // },
      {
        key: 1,
        questionText: 'FIRST_NAME',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'SURNAME',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'SOCIAL_SECURITY_NUMBER',
        optionsText: null,
      },
      {
        key: 4,
        questionText: 'POSTAL_ADDRESS',
        optionsText: null,
      },
      {
        key: 5,
        questionText: 'ZIP_CODE',
        optionsText: null,
      },
      {
        key: 6,
        questionText: 'CITY',
        optionsText: null,
      },
      {
        key: 7,
        questionText: 'COUNTRY_COMPANY',
        optionsText: null,
      },
      {
        key: 8,
        questionText: 'PHONE',
        optionsText: null,
      },
      {
        key: 9,
        questionText: 'MAILADRESS',
        optionsText: null,
      },
    ]
  },
  {
    order: 3,
    key: 'purpose-matter',
    set: [
      {
        key: 1,
        questionText: 'WHAT_IS_THE_PURPOSE_GOAL',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'HEART_AFFAIRS',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'QUALITATIVE_GOALS',
        optionsText: ['NONE_RETURN_FOCUS', 'BASIC_SUSTAINABILITY', 'SUSTAINABILITY_IN_DEPTH', 'STRONG_IMPACT'],
      },
      {
        key: 4,
        questionText: 'SUSTAINABLE_VIEW',
        optionsText: ['MY_FOCUS_IN_INVESTMENTS', 'I_WANT_INTEGRATE_SUSTAINABILITY', 'SUSTAINABILITY_IS_VERY_IMPORTANT']
      },
      {
        key: 5,
        questionText: 'THERE_OTHER_ASPECTS_WORKING',
        optionsText: null,
      },
    ]
  },
  {
    order: 4,
    key: 'financial-situation',
    set: [
      {
        key: 1,
        questionText: 'INCOME_EMPLOYMENT',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'DO_YOU_HAVE_OTHER_INCOME',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'EXPENDITURE_QUESTION',
        optionsText: null,
      },
      {
        key: 4,
        questionText: 'WHAT_YOUR_SURPLUS',
        optionsText: null,
      },
      {
        key: 5,
        questionText: 'ADDITIONAL_INFORMATION_ABOUT_REVENUE',
        optionsText: null,
      },
      {
        key: 6,
        questionText: 'YOUR_FINANCIAL_INVESTMENTS',
        optionsText: null,
      },
      {
        key: 7,
        questionText: 'CASH_EQUIVALENTS',
        optionsText: null,
      },
      {
        key: 8,
        questionText: 'PENSION_SAVINGS',
        optionsText: null,
      },
      {
        key: 9,
        questionText: 'HAVE_ANY_OTHER_ASSETS',
        optionsText: null,
      },
      {
        key: 10,
        questionText: 'ADDITIONAL_ABOUT_FINANCIAL_ASSETS',
        optionsText: null,
      },
      {
        key: 11,
        questionText: 'ONB_HOUSING',
        optionsText: null,
      },
      {
        key: 12,
        questionText: 'OTHER_FIXED_ASSETS',
        optionsText: null,
      },
      {
        key: 13,
        questionText: 'ADD_ABOUT_FIXED_ASSETS',
        optionsText: null,
      },
      {
        key: 14,
        questionText: 'ONB_HOUSING_2',
        optionsText: null,
      },
      {
        key: 15,
        questionText: 'OTHER_LIABILITIES',
        optionsText: null,
      },
      {
        key: 16,
        questionText: 'ADD_INFORMATION_SHOULDER',
        optionsText: null,
      },
      {
        key: 17,
        questionText: 'NON_COVERED_ELEMENTS',
        optionsText: null,
      },
      {
        key: 18,
        questionText: 'CAPITAL_NOT_COVERED_ADVICE',
        optionsText: null,
      },
      {
        key: 19,
        questionText: 'WHAT_YOUR_INVESTABLE_CAPITAL',
        optionsText: null,
      },
    ]
  },
  {
    order: 5,
    key: 'basic-prerequisites-for-management',
    set: [
      {
        key: 1,
        questionText: 'ABLE_TO_LIQUIDATE_THE_HOLDINGS',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'ANY_THOUGHTS_ON_HOW_FUTURE',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'TIME_HORIZON',
        optionsText: null,
      },
      {
        key: 4,
        questionText: 'ADD_COMMENT_TIME_HORIZON',
        optionsText: null,
      },
      {
        key: 5,
        questionText: 'BUFFER_CAPITAL',
        optionsText: null,
      },
    ]
  },
  {
    order: 6,
    key: 'know-your-client',
    set: [
      {
        key: 1,
        questionText: 'CITIZENSHIP',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'SECOND_CITIZENSHIP',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'TAX_RESIDENCE',
        optionsText: null,
      },
      {
        key: 4,
        questionText: 'ID_DOC_NUMBER',
        optionsText: null,
      },
      {
        key: 5,
        questionText: 'FILL_EXPIRY_DATE_ID_DOCUMENT',
        optionsText: null,
      },
      {
        key: 6,
        questionText: 'USA_RELATIONSHIPS',
        optionsText: null,
      },
      {
        key: 7,
        questionText: 'USA_RELATIONSHIPS_TYPE',
        optionsText: null,
      },
      {
        key: 8,
        questionText: 'FOREIGN_TRANSACTIONS',
        optionsText: null,
      },
      {
        key: 9,
        questionText: 'TRANSACTIONS_AMOUNT',
        optionsText: null,
      },
      {
        key: 10,
        questionText: 'SAVINGS_PURPOSE',
        optionsText: ['SAVINGS_INVESTMENTS', 'FINANCIAL_SECURITY', 'PENSION', 'PRIVATE_CONSUMPTION', 'GIFT_CLOSE_RELATIVE', 'BENEFICIARY', 'OTHER_DESCRIBE'],
        subQuestions: [
          {
            title: 'DESCRIBE_OTHER_PURPOSE_TITLE',
            q: 'DESCRIBE_OTHER_PURPOSE_QUESTION',
            options: null,
          },
        ],
      },
      {
        key: 11,
        questionText: 'BEHALF',
        optionsText: ['ON_HIS_OWN_BEHALF', 'SOMEONE_ELSE_POWER_ATTORNEY', 'OTHER_DESCRIBE'],
        subQuestions: [
          {
            title: 'COMMENT_ON_PROXY_TITLE',
            q: 'COMMENT_ON_PROXY_QUESTION',
            options: null,
          },
          {
            title: 'COMMENT_ON_BEHALF_TITLE',
            q: 'COMMENT_ON_BEHALF_QUESTION',
            options: null,
          },
        ],
      },
      {
        key: 12,
        questionText: 'WHAT_YOUR_MAIN_OCCUPATION',
        optionsText: ['EMPLOYEE', 'SELF_EMPLOYED', 'RETIRED', 'STUDENT', 'OTHER_DESCRIBE'],
        subQuestions: [
          {
            title: 'MAIN_OCCUPATION_TITLE',
            q: 'MAIN_OCCUPATION_QUESTION',
            options: null,
          }
        ],
      },
      {
        key: 13,
        questionText: 'WORK_SECTOR',
        optionsText: null,
      },
      {
        key: 14,
        questionText: 'MONTHLY_INCOME',
        optionsText: ['0 - 20 000 kr', '20 001 - 50 000 kr', '50 001 - 100 000 kr', 'Over 100 000 kr'],
      },
      {
        key: 15,
        questionText: 'MONEY_TRANFER',
        optionsText: null,
      },
      {
        key: 16,
        questionText: 'WHOSE_MONEY',
        optionsText: null,
      },
      {
        key: 17,
        questionText: 'MONEY_ORIGIN',
        optionsText: ['SALARY_PENSION', 'SAVINGS_INVESTMENTS', 'SALE_OF_COMPANY', 'INHERITANCE_GIFT', 'SALE_HOME_PROPERTY', 'OTHER_DESCRIBE'],
        subQuestions: [
          {
            title: 'MONEY_ORIGIN_TITLE',
            q: 'MONEY_ORIGIN_QUESTION',
            options: null,
          }
        ],
      },
      {
        key: 18,
        questionText: 'BANK_CAPITAL',
        optionsText: ['BANK_CAPITAL_OPTION_01', 'BANK_CAPITAL_OPTION_02', 'BANK_CAPITAL_OPTION_03', 'BANK_CAPITAL_OPTION_04', 'BANK_CAPITAL_OPTION_05', 'BANK_CAPITAL_OPTION_06'],
      },
      {
        key: 19,
        questionText: 'TRANSFER_VALUE',
        optionsText: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 SEK', 'TRANSFER_VALUE_OVER'],
      },
      {
        key: 20,
        questionText: 'ESTIMATE',
        optionsText: null,
      },
      {
        key: 21,
        questionText: 'YEARLY_DEPOSIT',
        optionsText: ['TIME_DEPOSIT_OPENING_ACCOUNT', '0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'TRANSFER_VALUE_OVER'],
      },
      {
        key: 22,
        questionText: 'ENTER_ESTIMATE_AVERAGE',
        optionsText: null,
      },
      {
        key: 23,
        questionText: 'DEPOSIT_FREQUENCY',
        optionsText: ['TIMES_0_10', 'TIMES_11_50', 'TIMES_51_100', 'TIMES_MORE_THEN'],
      },
      {
        key: 24,
        questionText: 'WITHDRAWAL_FREQUENCY',
        optionsText: ['TIMES_0_10', 'TIMES_11_50', 'TIMES_51_100', 'TIMES_MORE_THEN'],
      },
      {
        key: 25,
        questionText: 'INDIVIDUAL_TRANSACTIONS',
        optionsText: ['0 - 100 000 kr', '100 001 - 500 000 kr', '500 001 - 1 000 000 kr', '1 000 001 - 5 000 000 kr', 'TRANSFER_VALUE_OVER'],
      },
      {
        key: 26,
        questionText: 'ESTIMATE_TRANSACTIONS',
        optionsText: null,
      },
      {
        key: 27,
        questionText: 'PEP_POSITION',
        optionsText: null,
        subQuestions: [
          {
            title: 'PEP_TYPE_TITLE',
            q: 'PEP_TYPE_QUESTION',
            options: ['PEP_TYPE_OPTION_1', 'PEP_TYPE_OPTION_2', 'PEP_TYPE_OPTION_3', 'PEP_TYPE_OPTION_4',
              'PEP_TYPE_OPTION_5', 'PEP_TYPE_OPTION_6', 'PEP_TYPE_OPTION_7', 'PEP_TYPE_OPTION_8',
              'PEP_TYPE_OPTION_9', 'PEP_TYPE_OPTION_10', 'PEP_TYPE_OPTION_11'
            ]
          },
        ],
      },
      {
        key: 28,
        questionText: 'PEP_RELATIVE',
        optionsText: null,
        subQuestions: [
          {
            title: 'PEP_RELATIVE_TYPE_TITLE',
            q: 'PEP_RELATIVE_TYPE_QUESTION',
            options: ['PEP_TYPE_OPTION_1', 'PEP_TYPE_OPTION_2', 'PEP_TYPE_OPTION_3', 'PEP_TYPE_OPTION_4',
              'PEP_TYPE_OPTION_5', 'PEP_TYPE_OPTION_6', 'PEP_TYPE_OPTION_7', 'PEP_TYPE_OPTION_8',
              'PEP_TYPE_OPTION_9', 'PEP_TYPE_OPTION_10', 'PEP_TYPE_OPTION_11'
            ]
          },],
      },
    ]
  },
  {
    order: 7,
    key: 'risk-tolerance',
    set: [
      {
        key: 1,
        questionText: 'EXPECTED_RETURN',
        optionsText: ['EXPECTED_RETURN_OPTION_1', 'EXPECTED_RETURN_OPTION_2', 'EXPECTED_RETURN_OPTION_3', 'EXPECTED_RETURN_OPTION_4'],
      },
      {
        key: 2,
        questionText: 'RETURN_TARGET',
        optionsText: ['3%', '5%', '7%', '9%', '11%', '15%', '20%'],
      },
      {
        key: 3,
        questionText: 'INVESTMENT_PHILOSOPHY',
        optionsText: ['INVESTMENT_PHILOSOPHY_OPTION_1', 'INVESTMENT_PHILOSOPHY_OPTION_2', 'INVESTMENT_PHILOSOPHY_OPTION_3'],
      },
      {
        key: 4,
        questionText: 'MEDIA_INFLUENCE',
        optionsText: ['MEDIA_INFLUENCE_OPTION_1', 'MEDIA_INFLUENCE_OPTION_2', 'MEDIA_INFLUENCE_OPTION_3', 'MEDIA_INFLUENCE_OPTION_4'],
      },
      {
        key: 5,
        questionText: 'NEGATIVE_NEWS',
        optionsText: ['NEGATIVE_NEWS_OPTION_1', 'NEGATIVE_NEWS_OPTION_2', 'NEGATIVE_NEWS_OPTION_3', 'NEGATIVE_NEWS_OPTION_4'],
      },
      {
        key: 6,
        questionText: 'PORTFOLIO_VALUE_DROP',
        optionsText: ['PORTFOLIO_VALUE_DROP_OPTION_1', 'PORTFOLIO_VALUE_DROP_OPTION_2', 'PORTFOLIO_VALUE_DROP_OPTION_3', 'PORTFOLIO_VALUE_DROP_OPTION_4', 'PORTFOLIO_VALUE_DROP_OPTION_5'],
      },
      {
        key: 7,
        questionText: 'DOUBLE_VALUE_DROP',
        optionsText: ['DOUBLE_VALUE_DROP_OPTION_1', 'DOUBLE_VALUE_DROP_OPTION_2', 'DOUBLE_VALUE_DROP_OPTION_3'],
      },
      {
        key: 8,
        questionText: 'LOW_VALUE_INCREASE',
        optionsText: ['LOW_VALUE_INCREASE_OPTION_1', 'LOW_VALUE_INCREASE_OPTION_2', 'LOW_VALUE_INCREASE_OPTION_3', 'LOW_VALUE_INCREASE_OPTION_4'],
      },
      {
        key: 9,
        questionText: 'PORTFOLIO_EVALUATION',
        optionsText: ['PORTFOLIO_EVALUATION_OPTION_1', 'PORTFOLIO_EVALUATION_OPTION_2', 'PORTFOLIO_EVALUATION_OPTION_3', 'PORTFOLIO_EVALUATION_OPTION_4'],
      },
      {
        key: 10,
        questionText: 'ADDITIONAL_COMMENT',
        optionsText: null,
      },
    ]
  },
  {
    order: 1000,
    key: 'risk-profile',
    set: [
    ]
  },
  {
    order: 8,
    key: 'experience-background',
    set: [
      {
        key: 1,
        questionText: 'FIN_EDUCATION',
        optionsText: null,
      },
      {
        key: 2,
        questionText: 'TRADING_PERIOD',
        optionsText: null,
      },
      {
        key: 3,
        questionText: 'ASSET_TYPE',
        optionsText: null,
      },
      {
        key: 4,
        questionText: 'LISTED_SHARES',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 5,
        questionText: 'UNLISTED_SHARES',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 6,
        questionText: 'UCITS_FUNDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 7,
        questionText: 'SPECIAL_FUNDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 8,
        questionText: 'LISTED_BONDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 9,
        questionText: 'UNLISTED_BONDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 10,
        questionText: 'EQUITY_INDEX_BONDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 11,
        questionText: 'HEDGE_FUNDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 12,
        questionText: 'CERTIFICATE_OF_CREDIT',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 13,
        questionText: 'AUTOCALLS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 14,
        questionText: 'EXCHANGE_TRADED_FUNDS',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 15,
        questionText: 'DERIVATIVES_AND_COMMODITY',
        optionsText: ['DEALS_NUMBER', 'ASSET_EXPERIENCE', 'ASSET_TRAINED'],
      },
      {
        key: 16,
        questionText: 'ASSETS_TYPE_KNOWLEDGE',
        optionsText: null,
      },
      {
        key: 17,
        questionText: 'MANAGEMENT_EXPERIENCE',
        optionsText: ['MANAGEMENT_EXPERIENCE_OPTION_1', 'MANAGEMENT_EXPERIENCE_OPTION_2', 'MANAGEMENT_EXPERIENCE_OPTION_3'],
      },
      {
        key: 18,
        questionText: 'PORTFOLIO_TRAINED',
        optionsText: null,
      },
      {
        key: 19,
        questionText: 'PORTFOLIO_UNDERSTANDING',
        optionsText: null,
      },
      {
        key: 20,
        questionText: 'PORTFOLIO_MANAGEMENT_ADD',
        optionsText: null,
      },
      {
        key: 21,
        questionText: 'LOSS_RISK_QUESTION',
        optionsText: null,
      },
    ]
  },
];
