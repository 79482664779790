export const MyProfileEN = {
  SEND_INVITATION_TO_CLIENT: 'Send invitation to client to access account',
  SENDED_INVITATION: 'Activation email sent. Send again.',
  GO_TO_ONBOARDING: 'Go to Onboarding of Client',
  UPDATE_MESSAGE_SUCCESS: 'Your changes successfuly saved',
  UPDATE_MESSAGE_ERROR: 'Something Wrong here',
  DEACTIVATE_ADVISOR: 'Deactivate advisor',
  ACTIVATE_ADVISOR: 'Activate advisor',
  EDIT_ADVISOR: 'Edit advisor',
  ENABLE_2FA: 'Enable two-factor authentication',
  INSTRUCTION: 'To access the full functionality, you must enter and confirm BankID and/or enable two-factor authentication.',
  DELETE_PNUMBER: 'Delete personal number',
  ALERT_ERROR: 'Error. You have not verified your personal number.',
  ALERT_SUCCESS: 'Success. You have verified your personal number.',
  TITLE_2FA_MODAL: 'Configure Two-Factor Authentication.',
  STEP_ONE_2FA_MODAL: 'Get and install the Authenticator App.',
  STEP_ONE_2FA_MODAL_TEXT: 'Authenticator apps and browser extension like 1Password, Authy, Microsoft Authenticator, Google Authenticator, etc. generate one-time password that are used as a second to verify your identity when prompted during sign-in.',
  STEP_TWO_2FA_MODAL: 'Scan the QR code',
  STEP_TWO_2FA_MODAL_TEXT: 'Use authenticator apps or browser to scan.',
  STEP_THREE_2FA_MODAL: 'Enter Verification Code',
  STEP_THREE_2FA_MODAL_TEXT: 'Once the barcode above is scanned, enter the 6-digit verification code generated by the app.',
  TWO_FACTOR_ACTIVATE_BTN: 'Verify Code and Activate',
  DELETE_CLIENT: 'Delete client',
  CREATED_DATE: 'Created Date',
  ACTION: 'Action',
  INVITATION: 'Invitation',
  ONBOARDING: 'Onboarding',
  UNSAVED_CHANGES: 'Unsaved changes',
  YOU_WANT_WITHOUT_SAVING_ON: 'You are about to leave this page without saving.',
  YOU_WANT_WITHOUT_SAVING_TWO: 'All changes will be lost. Do you want to leave without saving?',
  LEAVE_WITHOUT_SAVING: 'Leave without saving',
  MODAL_DELETE_TITLE: 'Confirm deletion',
  MODAL_DELETE_TEXT: 'Are you sure you want to delete advisor',
  MODAL_DELETE_TEXT_ASSET: 'Are you sure you want to delete asset class',
  MODAL_DELETE_TEXT_CLIENT: 'Are you sure you want to delete client',
  MODAL_DELETE_PORTFOLIO: 'Are you sure you want to delete the portfolio:',
  ENGLISH: 'English',
  SVENSKA: 'Svenska',
  LANGUAGE: 'Language',
  MODAL_DELETE_PLAN: 'Are you sure you want to delete the financial plan:',

};
