/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import ReactQrCode from '@devmehq/react-qr-code';
import { useTranslation } from 'react-i18next';

import './style.scss';
import { Button, Modal, Typography } from 'antd';
import { getTwoFactorSecretCheck } from '../../data/store/user/userActions';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';
const { Title, Text } = Typography;

const ModalTwoFactror = (props) => {
  const { open2FAModal, closeModal } = props;
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { secret } = useSelector((state) => state.userReducer);
  const [code, setCode] = useState('');

  const handleChangeCode = (value) => {
    setCode(value);
  };

  const handleCloseModal = () => {
    setCode('');
    closeModal();
  };

  const handleVerify = () => {
    dispatch(
      getTwoFactorSecretCheck({ code: code?.toString(), handleCloseModal })
    );
  };

  return (
    <Modal
      centered
      open={open2FAModal}
      onCancel={handleCloseModal}
      width={650}
      footer={false}
    >
      <div className="modal-2fa-wrapper">
        <h1 className='modal-twofactor-title'>{t('TITLE_2FA_MODAL')}</h1>
        <ol className='modal-twofactor-list'>
          <li>
            <Title level={5} className="modal-twofactor-header">
              {t('STEP_ONE_2FA_MODAL')}
            </Title>
            <Text className="modal-twofactor-text">
              {t('STEP_ONE_2FA_MODAL_TEXT')}
            </Text>
          </li>
          <li>
            <Title level={5} className="modal-twofactor-header">
              {t('STEP_TWO_2FA_MODAL')}
            </Title>
            <Text className="modal-twofactor-text">
              {t('STEP_TWO_2FA_MODAL_TEXT')}
            </Text>
            <div className="modal-twofactor-wrapper">
              <ReactQrCode value={secret?.otpauth_url} size={200} />
            </div>
          </li>
          <li>
            <Title level={5} className="modal-twofactor-header">
              {t('STEP_THREE_2FA_MODAL')}
            </Title>
            <Text className="modal-twofactor-text">
              {t('STEP_THREE_2FA_MODAL_TEXT')}
            </Text>
          </li>
        </ol>
            <div className="twofactor-button-wrapper">
              <CustomInputNumber
                width={'269px'}
                controls={false}
                stringMode
                maxLength={6}
                className="twofactor-input"
                onChange={(value) => handleChangeCode(value)}
                value={code}
              />
              <Button
                disabled={code?.length !== 6}
                className='twofactor-input-btn'
                onClick={handleVerify}>
                {t('TWO_FACTOR_ACTIVATE_BTN')}
              </Button>
            </div>
      </div>
    </Modal>
  );
};

ModalTwoFactror.propTypes = {
  open2FAModal: PropTypes.bool,
  closeModal: PropTypes.func,
  codeQR: PropTypes.string,
  restartSign: PropTypes.func,
};
export default ModalTwoFactror;
