import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  Select,
  Radio,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Divider
} from 'antd';

import countryEN from '../../constants/countryEN.json';
import PrivateOwnersOwner from './PrivateOwnersOwner';

const CompanyOwner = (props) => {
  const { index, beneficialOwner, fieldsCompany, setFieldsCompany, form, addOwner, deleteOwner, isNotCupTotalPersent } =
    props;
  const { t } = useTranslation('');

  const initOwnersBeneficialOwner = {
    beneficialOwnerID: uuidv4(),
    typeCompanyOwner: null,
    personalNumberOwner: null,
    isMissingPersonalNumber: null,
    dateBirthOwner: null,
    firstNameOwner: null,
    secondNameOwner: null,
    percentageOfVotes: null,
    countryOwner: [],
    countryTaxOwner: null,
    tinOwner: null,
    postalOwner: null,
    zipCodeOwner: null,
    cityOwner: null,
    postCountryOwner: null,
    countryOwningCompany: null,
    taxCountryOwningCompany: null,
    politicallyExposedPosition: null,
    hasNextOwner: null,
  };

  const newData = (arg) => {
    return fieldsCompany.beneficialOwners.map((element, i) =>
      i === index
        ? arg
        : element
    );
  };

  const handleCheckMissing = (event) => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        [event.target.name]: event.target.checked,
        organizationNumberOwner: null,
      })
    });
    form.resetFields();
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        [name]: value,
      })
    });
  };

  const handleNamingInput = (value, name) => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        [name]: value,
      })
    });
  };

  const handleChangeRadio = (event) => {
    const { value, name } = event.target;
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        [name]: value === 'yes',
        ownersBeneficialOwner: value === 'yes' ? [initOwnersBeneficialOwner] : []
      })
    });
  };

  const handleNextOwner = (event) => {
    const { name, value } = event.target;
    if (value === 'yes' && fieldsCompany?.beneficialOwners?.length < 4) addOwner();
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        [name]: value === 'yes',
      })
    });
    if (value === 'no' && fieldsCompany?.beneficialOwners.length !== index + 1) {
      deleteOwner(fieldsCompany?.beneficialOwners[index + 1]?.beneficialOwnerID, index);
    }
  };

  const handleTypeCompanyOwner = (value, name, index) => {
    const newOwnerData = [...beneficialOwner.ownersBeneficialOwner];
    newOwnerData[index] = { ...newOwnerData[index], [name]: value };
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newData({
        ...beneficialOwner,
        ownersBeneficialOwner: [...newOwnerData]
      })
    });
  };

  const addOwnerOwner = () => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: fieldsCompany.beneficialOwners[index].ownersBeneficialOwner.push(initOwnersBeneficialOwner),
    });
  };

  const deleteOwnerOwner = (ownerID, index, parent) => {
    const newOwnerOwnerArray = fieldsCompany.beneficialOwners[parent].ownersBeneficialOwner
      .filter(owner => owner.beneficialOwnerID !== ownerID)
      .map((elem, i) => i === index
        ? { ...elem, hasNextOwner: false }
        : elem
      );
    console.log('🚀 ~ file: CompanyOwner.js:126 ~ deleteOwnerOwner ~ newOwnerOwnerArray', newOwnerOwnerArray);
    const newOwnerArray = fieldsCompany.beneficialOwners
      .map((elem, i) => i === parent
        ? { ...elem, ownersBeneficialOwner: newOwnerOwnerArray }
        : elem
      );

    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newOwnerArray,
    });
  };

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {t(countryEN[data])}
      </Select.Option>
    );
  };

  return (
    <>
      <div className='missing-checkbox-wrapper'>
        <Checkbox
          name='isMissingOrganizationNumber'
          onChange={handleCheckMissing}
          checked={beneficialOwner?.isMissingOrganizationNumber}
        />
        <p className='risk-profile-lable'>{t('MISSING_ORGANIZATION_NUMBER')}</p>
      </div>
      {!beneficialOwner?.isMissingOrganizationNumber && (
        <Form.Item
          label={t('ORGANIZATION_NUMBER_OWNING')}
          name='organizationNumberOwner'
          initialValue={beneficialOwner?.organizationNumberOwner}
          rules={[
            {
              required: true,
              message: 'Enter organization number',
            },
            () => ({
              validator (rule, value) {
                if (!value || value.match(/^(\d{2}[2-9]\d{3}\d{4}$)/)) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('XXXXXX-XXXX');
              },
            }),
          ]}
        >
          <Input
            type='text'
            name='organizationNumberOwner'
            placeholder={`${t('ENTER')} ${t('ORGANIZATION_NUMBER')}`}
            onChange={handleChangeInput}
            value={beneficialOwner?.organizationNumberOwner}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingOrganizationNumber && (
        <Form.Item
          label={t('FOREIGN_ORGANIZATION_NUMBER')}
          name='foreignOrganizationNumberOwner'
          initialValue={beneficialOwner?.foreignOrganizationNumberOwner}
          rules={[
            {
              required: true,
              message: 'Enter foreign organization number',
            },
          ]}
        >
          <Input
            type='text'
            name='foreignOrganizationNumberOwner'
            placeholder={`${t('ENTER')} ${t('FOREIGN_ORGANIZATION_NUMBER')}`}
            onChange={handleChangeInput}
            value={beneficialOwner?.foreignOrganizationNumberOwner}
          />
        </Form.Item>
      )}
      <Form.Item
        label={t('NAME_OWNING_COMPANY')}
        name='nameOwningCompany'
        initialValue={beneficialOwner?.nameOwningCompany}
        rules={[
          {
            required: true,
            message: 'Enter name of the owning company',
          },
        ]}
      >
        <Input
          type='text'
          name='nameOwningCompany'
          placeholder={`${t('ENTER')} ${t('NAME_OWNING_COMPANY')}`}
          onChange={handleChangeInput}
          value={beneficialOwner?.nameOwningCompany}
        />
      </Form.Item>
      {beneficialOwner?.isMissingOrganizationNumber && (
        <>
          <Form.Item
            label={t('ADDRESS_OWNING_COMPANY')}
            name='postalOwner'
            initialValue={beneficialOwner?.postalOwner}
            rules={[
              {
                required: true,
                message: 'Enter address owning company',
              },
            ]}
          >
            <Input
              type='text'
              name='postalOwner'
              placeholder={`${t('ENTER')} ${t('ADDRESS_OWNING_COMPANY')}`}
              onChange={handleChangeInput}
              value={beneficialOwner?.postalOwner}
            />
          </Form.Item>
          <Form.Item
            label={t('ZIPCODE_OWNING_COMPANY')}
            name='zipCodeOwner'
            initialValue={beneficialOwner?.zipCodeOwner}
            rules={[
              {
                required: true,
                message: `${t('ENTER')} ${t('ZIPCODE_OWNING_COMPANY')}`,
              },
              () => ({
                validator (rule, value) {
                  if (
                    !value ||
                    value.match(/^(\d{5}$)/) ||
                    value.match(/^(\d{5}-\d{4}$)/)
                  ) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Enter valid zip code');
                },
              }),
            ]}
          >
            <Input
              placeholder={t(`${t('ENTER')} ${t('ZIPCODE_OWNING_COMPANY')}`)}
              type='text'
              name='zipCodeOwner'
              value={beneficialOwner?.zipCodeOwner}
              onChange={handleChangeInput}
            />
          </Form.Item>
          <Form.Item
            label={t('CITY_OWNING_COMPANY')}
            name='cityOwner'
            initialValue={beneficialOwner?.cityOwner}
            rules={[
              {
                required: true,
                message: `${t('ENTER')} ${t('CITY_OWNING_COMPANY')}`,
              },
            ]}
          >
            <Input
              placeholder={`${t('ENTER')} ${t('CITY_OWNING_COMPANY')}`}
              type='text'
              name='cityOwner'
              value={beneficialOwner?.cityOwner}
              onChange={handleChangeInput}
            />
          </Form.Item>
          <Form.Item
            label={t('COUNTRY_OWNING_COMPANY')}
            name='countryOwningCompany'
            initialValue={beneficialOwner?.countryOwningCompany}
            className='risk-profile-lable'
            rules={[
              {
                required: true,
                message: `${t('ENTER')} ${t('COUNTRY_OWNING_COMPANY')}`,
              },
            ]}
          >
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={beneficialOwner?.countryOwningCompany}
              onChange={(value) =>
                handleNamingInput(value, 'countryOwningCompany')
              }
            >
              <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
              {Object.keys(countryEN).map((item) => {
                return countrysOption(item);
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('TAX_COUNTRY_OWNING_COMPANY')}
            name='taxCountryOwningCompany'
            initialValue={beneficialOwner?.taxCountryOwningCompany}
            className='risk-profile-lable'
            rules={[
              {
                required: true,
                message: `${t('ENTER')} ${t('TAX_COUNTRY_OWNING_COMPANY')}`,
              },
            ]}
          >
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={beneficialOwner?.taxCountryOwningCompany}
              onChange={(value) =>
                handleNamingInput(value, 'taxCountryOwningCompany')
              }
            >
              <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
              {Object.keys(countryEN).map((item) => {
                return countrysOption(item);
              })}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        label={t('PERCENTAGE_OF_VOTES')}
        className='risk-profile-lable'
        name='percentageOfVotes'
        initialValue={beneficialOwner?.percentageOfVotes}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('PERCENTAGE_OF_VOTES')}`,
          },
        ]}
      >
        <InputNumber
          className='percentage-votes'
          min={25}
          max={100}
          step={0.01}
          size='small'
          onChange={(value) =>
            handleNamingInput(value, 'percentageOfVotes')
          }
          value={beneficialOwner?.percentageOfVotes}
        />
      </Form.Item>
      <div className='saving-subtitle customer-subtitle'>
        <span>{t('ANYONE_WHO_CONTROLS_COMPANY')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <Radio.Group
          name='hasControlsMore25Company'
          onChange={(event) => handleChangeRadio(event)}
          value={
            beneficialOwner?.hasControlsMore25Company
              ? 'yes'
              : beneficialOwner?.hasControlsMore25Company === null
                ? ''
                : 'no'
          }
          className='saving-radio customer-radio'
        >
          <Radio value='yes'>{t('YES')}</Radio>
          <Radio value='no'>{t('No')}</Radio>
        </Radio.Group>
      </div>
      {beneficialOwner?.hasControlsMore25Company && (
        <>
        {beneficialOwner?.ownersBeneficialOwner?.map((item, i) => (
          <Fragment key={item.beneficialOwnerID}>
            <p className='risk-profile-lable'>{t('TYPE_OF_OWNER')}</p>
            <Select
              placeholder={t('SELECT_OPTION')}
              className='risk-profile-select'
              type='select'
              value={item?.typeCompanyOwner}
              onChange={(value) =>
                handleTypeCompanyOwner(value, 'typeCompanyOwner', i)
              }
            >
              <Select.Option value='Private individual'>
                {t('INDIVIDUAL')}
              </Select.Option>
              <Select.Option value='Company'>
                {t('COMPANY')}
              </Select.Option>
            </Select>
            {item.typeCompanyOwner === 'Private individual' && (
              <PrivateOwnersOwner
                key={item.beneficialOwnerID}
                index={i}
                parent={index}
                beneficialOwner={item}
                fieldsCompany={fieldsCompany}
                setFieldsCompany={setFieldsCompany}
                addOwner={addOwnerOwner}
                deleteOwner={deleteOwnerOwner}
                form={form}
              />
            )}
            {item.typeCompanyOwner === 'Company' && (
              <div className='saving-subtitle customer-subtitle warning-text'>
                <span>{t('NEED_DOWNLOAD_AND_FILL')}</span>
              </div>
            )}
          </Fragment>
        ))}
        </>
      )}
      <Divider />
      {index < 3 && isNotCupTotalPersent() &&
      <>
      <div className='saving-subtitle customer-subtitle'>
          <span>{t('ANYONE_ELSE_WHO_CONTROLS_MORE')}</span>
        </div>
      <div style={{ display: 'flex' }}>
        <Radio.Group
          name='hasNextOwner'
          onChange={(event) => handleNextOwner(event)}
          value={
            beneficialOwner?.hasNextOwner === null
              ? ''
              : beneficialOwner?.hasNextOwner
                ? 'yes'
                : 'no'
          }
          className='saving-radio customer-radio'
        >
          <Radio value='yes'>{t('YES')}</Radio>
          <Radio value='no'>{t('No')}</Radio>
        </Radio.Group>
      </div>
      </>
      }
    </>
  );
};

CompanyOwner.propTypes = {
  index: PropTypes.number,
  beneficialOwner: PropTypes.object,
  fieldsCompany: PropTypes.object,
  form: PropTypes.object,
  setFieldsCompany: PropTypes.func,
  addOwner: PropTypes.func,
  deleteOwner: PropTypes.func,
  isNotCupTotalPersent: PropTypes.func,
};

export default CompanyOwner;
