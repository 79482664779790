export const colors = {
  'Global Stocks': '#01456e',
  'Swedish Stocks': '#065d8d',
  'Defensive Stocks': '#3d7ea4',
  'Small Cap Stocks': '#6598b6',
  'Momentum Stocks': '#85aec5',
  'Value Stocks': '#9ebed1',
  'Private Equity': '#c5d8e4',
  'Private Equity (debt)': '#dee8ee',

  'Hedge Funds': '#003030',
  'Emerging Markets Bonds': '#006666',
  Highyield: '#009a9a',
  'Highyield (hdg)': '#2f9a9a',
  'Investment Grade': '#669a9a',
  'Investment Grade (hdg)': '#86afaf',
  'Real Estate': '#9cd7d7',
  Forrest: '#bde5e5',

  'Swedish Goverment Bonds': '#656565',
  'American Goverment Bonds': '#737474',
  'Japanese Goverment Bonds': '#959595',
  Gold: '#b9b9b9',
  Cash: '#ededed'
};

export const colorsCategoryOld = {
  Avkastningstillgångar: '#799AA9',
  'Return Assets': '#799AA9',

  'Risk spreaders': '#305572',
  Riskspridare: '#305572',

  'Defensiva Tillgångar': '#718F83',
  'Defensiva tillg': '#718F83',
  'Defensive assets': '#718F83',
};

export const colorsCategory = {
  Avkastningstillgångar: '#4D4D4D',
  'Return Assets': '#4D4D4D',

  'Risk spreaders': '#808080',
  Riskspridare: '#808080',

  'Defensiva Tillgångar': '#B3B3B3',
  'Defensiva tillg': '#B3B3B3',
  'Defensive assets': '#B3B3B3',
  other: '#6367C3',
};

export const colorsCategoryOpt = {
  Avkastningstillgångar: '#EE6002',
  'Return Assets': '#B78160',

  'Risk spreaders': '#917362',
  Riskspridare: '#917362',

  'Defensiva Tillgångar': '#695E57',
  'Defensiva tillg': '#695E57',
  'Defensive assets': '#695E57',
  other: '#B78160',
};

export const colorsBlack = {
  Avkastningstillgångar: '#000000',
  'Return Assets': '#000000',
  'Risk spreaders': '#000000',
  Riskspridare: '#000000',

  'Defensiva Tillgångar': '#000000',
  'Defensiva tillg': '#000000',
  'Defensive assets': '#000000',
  other: '#000000',
};
