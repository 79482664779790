/* eslint-disable camelcase */
import React, { useState, useEffect, Fragment } from 'react';

import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { ReactQrCode } from '@devmehq/react-qr-code';

import { Select, Checkbox, Input, Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { Menu } from '../../assets';
import Container from '../../components/Container';
import { ReactComponent as AFIcon } from './img/icon-af.svg';
import { ReactComponent as ISKIcon } from './img/icon-isk.svg';
import { ReactComponent as KFIcon } from './img/icon-kf.svg';
import { ReactComponent as QuestionIcon } from './img/icon-question.svg';
import { ReactComponent as Telephone } from './img/telephone.svg';
import './style.scss';
import {
  getQRCodeSignBankID
} from '../../data/store/user/userActions';
import axios from 'axios';
import { API_URLS } from '../../constants/api_urls';
import {
  getClient,
  getClientData,
  // getClientFees,
  sendClientApprove,
  setClientFees,
  // setClientScore
} from '../../data/store/clients/clientActions';
import Loading from '../../components/Loading';

const TermsConditionsAgreement = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);
  const { role } = useSelector((state) => state.userReducer.advisor);
  const { codeBankID } = useSelector(state => state.userReducer);

  const [openBankId, setOpenBankId] = useState(false);
  const [codeQR, setCodeQR] = useState('');
  const [alert, setAlert] = useState({ value: '', type: '' });

  useEffect(() => {
    if (id) {
      dispatch(getClient({ id }));
    }
  }, []);

  const initialAccountServie = {
    investmentAdvice: user?.fees?.investment_advice >= 0 ? user?.fees?.investment_advice.toString() : '1.5',
    portfolioManagement: user?.fees?.portfolio_management >= 0 ? user?.fees?.portfolio_management.toString() : '1.5',
    consultation: user?.fees?.consultation >= 0 ? user?.fees?.consultation.toString() : '0',
  };
  const initScoreType = {
    stock_and_fund: {
      must_have: user?.account_setting?.stock_and_fund?.must_have,
      service_type: user?.account_setting?.stock_and_fund?.service_type ?? null,
    },
    investment_savings: {
      must_have: user?.account_setting?.investment_savings?.must_have,
      service_type: user?.account_setting?.investment_savings?.service_type ?? null,
    },
    endowment_insurance: {
      must_have: user?.account_setting?.endowment_insurance?.must_have,
      service_type: user?.account_setting?.endowment_insurance?.service_type ?? null,
    }
  };
  const [accountService, setAccountService] = useState(initialAccountServie);
  const [scoreType, setScoreType] = useState(initScoreType);

  useEffect(() => {
    setAccountService(initialAccountServie);
  }, [user?.fees]);

  useEffect(() => {
    setScoreType(initScoreType);
  }, [user?.account_setting]);

  useEffect(() => {
    if (!user?.client_data || !Object.keys(user.client_data).includes('customerKnowledge')) {
      dispatch(getClientData({ id, name: 'customerKnowledge' }));
    }
  }, [user?.client_data]);

  const handleChangeAccountService = (value, name) => {
    setScoreType({ ...scoreType, [name]: { ...scoreType[name], service_type: value } });
  };
  const handleChangeFeeInput = (event) => {
    const { value, name } = event.target;
    if (!isNaN(+value) || value === '') {
      setAccountService({ ...accountService, [name]: value });
    }
  };

  const modalOpen = () => {
    dispatch(getQRCodeSignBankID(user.type.identification_number));
    setOpenBankId(true);
  };

  const handleSignAgreement = () => {
    if (role !== 'CLIENT') {
      dispatch(sendClientApprove({ id, status: 'CLIENT_STATUS_APPROVED' }));
      dispatch(setClientFees(
        {
          id,
          fees: {
            investment_advice: +accountService.investmentAdvice,
            portfolio_management: +accountService.portfolioManagement,
            consultation: +accountService.consultation,
          },
          history
        }));
    } else {
      modalOpen();
      // dispatch(setClientScore({ id, score: scoreType }));
      // history.push(`/dashboard/${id}`);
    }
  };

  useEffect(() => {
    if (codeBankID?.qrs?.length > 0 && openBankId) { renderCode(); }
  }, [codeBankID, openBankId]);

  const [intervalID, setIntervalID] = useState(null);

  const ModalTitle = () => {
    return (
    <div className='bankId-logo-wrapper'><div className='bankId-logo' /></div>
    );
  };
  const collect = async () => {
    const resp = await axios.get(`${API_URLS.SIGN_COLLECT}?order_ref=${codeBankID.order_ref}`);
    let data;
    resp.status === 200 ? data = resp.data : data = { status: 'error' };
    return data;
  };
  const stopCollect = async () => {
    const resp = await axios.post(`${API_URLS.BANKID_CANCEL_COLLECT}`, { order_ref: codeBankID.order_ref });
    return resp.data;
  };
  const stopInterval = (idInt) => {
    clearInterval(idInt);
    setIntervalID(null);
  };
  const successAlert = (value) => {
    setAlert(value);
    setTimeout(() => {
      setAlert('');
    }, 3500);
  };
  const renderCode = () => {
    const offset = Math.round((Date.now() - codeBankID.start_time / 1000) / 1000);
    let startIndex = offset;
    const idInt = setInterval(code, 1000);
    setIntervalID(idInt);

    async function code () {
      if (startIndex > (codeBankID.qrs?.length - 1) || !openBankId) {
        dispatch({ type: 'CLEAR_QR_BANKID' });
        stopInterval(idInt);
        setIntervalID(null);
        !openBankId
          ? setCodeQR('')
          : setCodeQR('Try again');
        if (openBankId) {
          successAlert(
            { value: `Error. ${`${user.firstName} ${user.lastName}`} don\`t sign agreement.`, type: 'error' }
          );
        }
      } else if (codeBankID?.qrs?.length) {
        const res = await collect();
        if (res?.hint_code === 'userSign') {
          setCodeQR('wait finger');
        } else if (!res?.hint_code && res?.status === 'complete') {
          successAlert(
            { value: `Success. ${res.completion_data.user.name} signed agreement.`, type: 'success' }
          );
          stopInterval(idInt);
          setIntervalID(null);
          setOpenBankId(false);
          setCodeQR('');
          dispatch(getClient({ id }));
        } else if (res?.status === 'forbiden') {
          successAlert(
            { value: `Error. ${res.completion_data.user.name} don\`t sign agreement.`, type: 'error' }
          );
          stopInterval(idInt);
          setIntervalID(null);
          setOpenBankId(false);
          setCodeQR('');
        } else setCodeQR(codeBankID.qrs[startIndex]);
        startIndex += 1;
      }
    }
  };
  const closeBankID = () => {
    setOpenBankId(false);
    setCodeQR('');
    if (codeBankID.order_ref) stopCollect();
    clearInterval(intervalID);
    setIntervalID(null);
    dispatch({ type: 'CLEAR_QR_BANKID' });
  };
  const restartSign = () => {
    if (!intervalID) dispatch(getQRCodeSignBankID(user.type.identification_number));
  };

  if (!user?.ID) {
    return <Loading loading={!user}><div></div></Loading>;
  }
  return (
    <>
    <Modal
        title={<ModalTitle/>}
        centered
        open={openBankId}
        onCancel={closeBankID}
        width={480}
        footer={[]}
      >
        {codeQR && codeQR.includes('bankid.') &&
        <>
          <div className='modal-bankId-header'>{t('MOBILEID_HEADER')}</div>
          <div className='modal-bankId-code-wrapper'><ReactQrCode value={codeQR} size={200}/></div>
          <div className='modal-bankId-link'>
            <a href='#'>{t('USE_BANKID')}</a>
          </div>
        </>}
        {codeQR === 'Try again' &&
        <>
          <div className='modal-bankId-header'>{t('THE_LOGIN_FAILED')}</div>
          <p className='modal-bankId-text'>{t('LOGIN_FAILED_TEXT')}</p>
          <div className='modal-bankId-link'>
            <button onClick={restartSign }>{t('TRY_AGAIN')}</button>
          </div>
        </>}
        {codeQR === 'wait finger' &&
        <>
          <div className='modal-bankId-header'>{t('LOGIN_WITH_BANKID')}</div>
          <div className='modal-bankId-code-wrapper'><Telephone /></div>
          <p className='modal-bankId-text'>{t('TYPE_YOUR_SECURITY')}</p>
        </>}
      </Modal>
      <h1 className='terms-conditions-header'>{t('AGREEMENT_PAGE_HEADER')}</h1>
      <p className='terms-conditions-header-text'>{t('THIS_AGREEMENT')}</p>
      <Container>
        <h3 className='info-header'>
          {user?.type?.type === 'private'
            ? t('SOCIAL_SECURITY_NUMBER')
            : t('ORGANIZATION_NUMBER')}
        </h3>
        <h5 className='info-client'>
          {user?.type?.identification_number || ''}
        </h5>
        <h5 className='info-client'>
          {user?.type?.type === 'private'
            ? t('PRIVATPERSON')
            : t('CORPORATE_CLIENT')}
        </h5>
      </Container>
      <Container>
        <div className='detail-header'>{t('CONTACT_DETAILS')}</div>
        <p className='terms-conditions-title'>{t('NAME')}</p>
        <p className='terms-conditions-field'>{user?.firstName ?? ''}</p>
        <p className='terms-conditions-title'>{t('LAST_NAME')}</p>
        <p className='terms-conditions-field'>{user?.lastName ?? ''}</p>
        <p className='terms-conditions-title'>{t('MAILADRESS')}</p>
        <p className='terms-conditions-field'>{user?.email ?? ''}</p>
        {user?.phones &&
          user?.phones.length &&
          user.phones.map((item) => (
            <Fragment key={item.ID}>
              <p className='terms-conditions-title'>{t('MOBILE_NUMBER')}</p>
              <p className='terms-conditions-field'>{item.phone}</p>
            </Fragment>
          ))}
      </Container>
      {!!user?.address?.length && <Container>
        <div className='detail-header'>{t('ADDRESS')}</div>
        <p className='terms-conditions-title'>{t('POSTAL_ADDRESS')}</p>
        <p className='terms-conditions-field'>
          {user?.address[0]?.postal ?? ''}
        </p>
        <p className='terms-conditions-title'>{t('ZIP_CODE')}</p>
        <p className='terms-conditions-field'>
          {user?.address[0]?.zip_code ?? ''}
        </p>
        <p className='terms-conditions-title'>{t('COUNTRY')}</p>
        {user?.client_data?.customerKnowledge?.data?.country.length &&
        user?.client_data?.customerKnowledge?.data?.country.map((i, index) => {
          return <p key={index} className='terms-conditions-field'>
          {i}
        </p>;
        }
        ) }
        <p className='terms-conditions-title'>{t('CITY')}</p>
        <p className='terms-conditions-field'>{user?.address[0]?.city ?? ''}</p>
      </Container>}
      <p className='terms-conditions-header-text terms-conditions-header-subtext'>
        {t('THE_ABOVE_CONTRACTUAL_PARTIES')}
      </p>
      <ol>
        <li className='terms-conditions-order-list'>{t('ABOUT_CITRONEER')}</li>
        <p className='terms-conditions-paragraph'>
          {t('INFORMATION_ABOUT_CITRONEER')}
        </p>
        <li className='terms-conditions-order-list'>
          {t('OPENING_OF_ACCOUNTS')}
        </li>
        <p className='terms-conditions-paragraph'>
          {t('CUSTOMER_INSTRUCTS_CITRONEER')}
        </p>
        <Container className='terms-conditions-container'>
          <div className='detail-header'>{t('ACCOUNT_SELECTION')}</div>
          <p className='account-text'>
            {t('ACCOUNT_TEXT1')} <br />
            {t('ACCOUNT_TEXT2')}
            <br />
            {t('ACCOUNT_TEXT3')}
            <br />
            {t('ACCOUNT_TEXT4')}
          </p>
          <div className='account-wrapper'>
            <div className='account-checkbox-wrapper'>
              <Checkbox
                checked
                className='experience-background-cell center experience-background-checkbox my-details-checkbox'
              />
              <AFIcon />
              <span className='radio-text'>{t('STOCK_FUND_ACCOUNT')}</span>
              <QuestionIcon />
            </div>
            <div className='account-select-wrapper'>
              <Select
                placeholder={t('CHOOSE_SERVICE')}
                className='experience-background-select account-select'
                name='stock_and_fund'
                type='select'
                value={scoreType.stock_and_fund.service_type}
                onChange={(value) =>
                  handleChangeAccountService(value, 'stock_and_fund')
                }
                disabled={role === 'CLIENT' && user?.status !== 'CLIENT_STATUS_APPROVED' }
              >
                <Select.Option value='Investment advice'>
                  {t('INVESTMENT_ADVICE')}
                </Select.Option>
                <Select.Option value='Portfolio management'>
                  {t('PORTFOLIO_MANAGEMENT')}
                </Select.Option>
                <Select.Option value='Consultation'>
                    {t('CONSULTATION')}
                  </Select.Option>
              </Select>
            </div>
          </div>
          {user?.account_setting?.investment_savings?.must_have &&
          <div className='account-wrapper'>
            <div className='account-checkbox-wrapper'>
              <Checkbox
                checked={!!user?.account_setting?.investment_savings?.must_have}
                className='experience-background-cell center experience-background-checkbox my-details-checkbox'
              />
              <ISKIcon />
              <span className='radio-text'>
                {t('INVESTMENT_SAVINGS_ACCOUNT')}
              </span>
              <QuestionIcon />
            </div>
            <div className='account-select-wrapper'>
              <Select
                placeholder={t('CHOOSE_SERVICE')}
                className='experience-background-select account-select'
                name='investment_savings'
                type='select'
                value={scoreType.investment_savings.service_type}
                onChange={(value) =>
                  handleChangeAccountService(value, 'investment_savings')
                }
              >
                <Select.Option value='Investment advice'>
                  {t('INVESTMENT_ADVICE')}
                </Select.Option>
                <Select.Option value='Portfolio management'>
                  {t('PORTFOLIO_MANAGEMENT')}
                </Select.Option>
                <Select.Option value='Consultation'>
                    {t('CONSULTATION')}
                  </Select.Option>
              </Select>
            </div>
          </div>}
          {user?.type?.type === 'business' && (
            <div className='account-wrapper'>
              <div className='account-checkbox-wrapper'>
                <Checkbox
                  className='experience-background-cell center experience-background-checkbox my-details-checkbox'
                  checked={!!user?.account_setting?.endowment_insurance.must_have}
                />
                <KFIcon />
                <span className='radio-text'>{t('ENDOWMENT_INSURANCE')}</span>
                <QuestionIcon />
              </div>
              <div className='account-select-wrapper'>
                <Select
                  placeholder={t('CHOOSE_SERVICE')}
                  className='experience-background-select'
                  name='endowment_insurance'
                  type='select'
                  value={scoreType.endowment_insurance.service_type}
                  onChange={(value) => handleChangeAccountService(value, 'endowment_insurance')}
                >
                  <Select.Option value='Investment advice'>
                    {t('INVESTMENT_ADVICE')}
                  </Select.Option>
                  <Select.Option value='Portfolio management'>
                    {t('PORTFOLIO_MANAGEMENT')}
                  </Select.Option>
                  <Select.Option value='Consultation'>
                    {t('CONSULTATION')}
                  </Select.Option>
                </Select>
              </div>
            </div>
          )}
        </Container>
        <li className='terms-conditions-order-list'>{t('CHARGES')}</li>
        <Container className='terms-conditions-container'>
          <div className='detail-header'>{t('ASSIGNMENTS_AND_FEES')}</div>
          <p className='account-text terms-conditions-account-text'>
            {t('ASSIGNMENTS_AND_FEES_TEXT')}
          </p>
          <div className='terms-conditions-fee-wrapper'>
            <div className='terms-conditions-fee-column'>
              <p className='terms-conditions-fee-header'>{t('SERVICE')}</p>
              <div className='terms-conditions-fee-row'>
                <span className='terms-conditions-fee-point'>
                  {t('INVESTMENT_ADVICE')}
                </span>
                <QuestionIcon />
              </div>
              <div className='terms-conditions-fee-row'>
                <span className='terms-conditions-fee-point'>
                  {t('PORTFOLIO_MANAGEMENT')}
                </span>
                <QuestionIcon />
              </div>
              <div className='terms-conditions-fee-row'>
                <span className='terms-conditions-fee-point'>
                  {t('CONSULTATION')}
                </span>
                <QuestionIcon />
              </div>
            </div>
            <div className='terms-conditions-fee-column'>
              <p className='terms-conditions-fee-header'>{t('CHARGE')}</p>
              <div className='terms-conditions-fee-row'>
                <Input
                  className='terms-conditions-fee-input terms-conditions-fee-suffix'
                  name='investmentAdvice'
                  value={accountService.investmentAdvice}
                  type='text'
                  suffix={<span className='terms-conditions-fee-suffix'>%</span>}
                  placeholder={t('FEE_IN')}
                  onChange={handleChangeFeeInput}
                  disabled={role !== 'ADVISER'}
                />
              </div>
              <div className='terms-conditions-fee-row'>
                <Input
                  className='terms-conditions-fee-input terms-conditions-fee-suffix'
                  name='portfolioManagement'
                  value={accountService.portfolioManagement}
                  type='text'
                  suffix={<span className='terms-conditions-fee-suffix'>%</span>}
                  placeholder={t('FEE_IN')}
                  onChange={handleChangeFeeInput}
                  disabled={role !== 'ADVISER'}
                />
              </div>
              <div className='terms-conditions-fee-row'>
                <Input
                  className='terms-conditions-fee-input terms-conditions-fee-suffix'
                  name='consultation'
                  value={accountService.consultation}
                  type='text'
                  suffix={<span className='terms-conditions-fee-suffix'>SEK</span>}
                  placeholder={t('FEE_IN')}
                  onChange={handleChangeFeeInput}
                  disabled={role !== 'ADVISER'}
                />
              </div>
            </div>
          </div>
        </Container>
        <li className='terms-conditions-order-list'>{t('TERMS_AND_CONDITIONS')}</li>
        <p className='terms-conditions-paragraph'>
          {t('FOR_THIS_AGREEMENT_P1')}
          <a
          className='terms-conditions-link'
          href='https://www.citroneer.se/allmannavillkor'
          rel="noreferrer"
          target='_blank'
          >www.Citroneer.se/allmannavillkor.</a>
          {t('FOR_THIS_AGREEMENT_P2')}
        </p>
        <li className='terms-conditions-order-list'>{t('CONTRACT_PERIOD_TERMINATION')}</li>
        <p className='terms-conditions-paragraph'>{t('THIS_AGREEMENT_APPLIES')}</p>
        <li className='terms-conditions-order-list'>{t('LIMITATION_OF_LIABILITY')}</li>
        <p className='terms-conditions-paragraph'>{t('LIMITATION_OF_LIABILITY_P1')}</p>
        <p className='terms-conditions-paragraph'>{t('LIMITATION_OF_LIABILITY_P2')}</p>
        <li className='terms-conditions-order-list'>{t('APPLICABLE_LAW_AND_DISPUTE')}</li>
        <p className='terms-conditions-paragraph'>{t('APPLICABLE_LAW_AND_DISPUTE_P1')}</p>
        <li className='terms-conditions-order-list'>{t('CUSTOMER_APPROVAL')}</li>
        <p className='terms-conditions-paragraph'>{t('CUSTOMER_APPROVAL_P1')}
        <a
          className='terms-conditions-link'
          href='https://www.citroneer.se/forkopsinformation'
          rel="noreferrer"
          target='_blank'
          >www.citroneer.se/forkopsinformation.</a>
        </p>
        <p className='terms-conditions-paragraph'>
          {t('CUSTOMER_APPROVAL_P2')}
          <a
            className='terms-conditions-link'
            href='https://www.citroneer.se/allmannavillkor'
            rel="noreferrer"
            target='_blank'
            >www.citroneer.com/allmannavillkor</a>
            {t('CUSTOMER_APPROVAL_P3')}
        </p>
        <li className='terms-conditions-order-list'>{t('COMPLETE_AGREEMENT_SIGNATURES')}</li>
        <p className='terms-conditions-paragraph'>{t('COMPLETE_AGREEMENT_SIGNATURES_P1')}</p>
      </ol>
<div className='terms-conditions-button-wrapper'>
  <button
    className='main-button terms-conditions-button'
    onClick={handleSignAgreement}
    disabled={user?.status !== 'CLIENT_STATUS_APPROVED'}
    >{t('SIGN_AGREEMENT')}
  </button>
</div>
{alert && <Alert message={alert.value} type={alert.type} className='terms-alert'/>}
      <div className='btn-container'>
        <div></div>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TermsConditionsAgreement;
