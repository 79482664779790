import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MainButton = ({ style, type, disabled, onClick, className, children }) => {
  className = disabled ? `main-button disabled ${className ?? ''}` : `main-button ${className ?? ''}`;
  return (
    <button style={style} disabled={disabled} className={className} type={type} onClick={onClick}>
      {children}
    </button>
  );
};

export default MainButton;

MainButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.object
};
