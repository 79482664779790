import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Collapse, Form, Radio, Select, Space } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import { ReactComponent as Icon } from '../../../assets/collaps-icon.svg';
import RecommendedItem from './CategoryTypeOfAdvice/RecommendedItem';
import { getInstrumentsAll } from '../../../data/store/instruments/instrumentsActions';
import { useDispatch } from 'react-redux';
import { decimalSeparator } from '../../../helpers/decimalSeparator';
import { customersDecisionOptions } from '../data/SelectOptions';

const RecommendedLabel = ({ index, label }) => {
  return (
    <div className="types-label-wrapper">
      <div className="types-label-number">{index}</div>
      <p className="types-label-title">{label}</p>
    </div>
  );
};

const CategoryTypeOfAdvice = ({ t, onboardingData, setOnboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [itemsRecommended, setItemsRecommended] = useState({ buying_transactions: [], selling_transactions: [] });

  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
  }, []);

  const knowledgeDiffTypes = [
    {
      key: '1',
      label: <RecommendedLabel index={1} label={t('BUYING_TRANSACTIONS')} />,
      children: <RecommendedItem
        t={t}
        name="buying_transactions"
        itemsRecommended={itemsRecommended}
        setItemsRecommended={setItemsRecommended}
      />,
    },
    {
      key: '2',
      label: <RecommendedLabel index={2} label={t('SELLING_TRANSACTIONS')} />,
      children: <RecommendedItem
        t={t}
        name="selling_transactions"
        itemsRecommended={itemsRecommended}
        setItemsRecommended={setItemsRecommended}
      />,
    },
  ];

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    setOnboardingData({ ...onboardingData, data: { ...onboardingData?.data || {}, ...data } });
  };

  return (
    <div className="card-right-wrap">
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body"
      >
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('TYPE_OF_ADVICE')}
          </h2>
        </div>
        <div className="onbording-check-wrap">
          <Form.Item
            // name={['']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox checked={true} disabled>
              {t('DOCUMENTATION')}
            </CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['type_of_advice', 'type_of_advice', 'account_opening']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('ACCOUNT_OPENING')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['type_of_advice', 'type_of_advice', 'recommendation']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('RECOMMENDATION')}</CustomCheckbox>
          </Form.Item>
        </div>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('ACCOUNT_SELECTION')}
          </h2>
        </div>
        <div className="onbording-check-wrap">
          <Form.Item
            // name={['type_of_advice', 'account_selection', 'account_opening']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox checked={true} disabled>
              {t('STOCK_FUND_ACCOUNT')}
            </CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={[
              'type_of_advice',
              'account_selection',
              'investment_savings_account',
            ]}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('INVESTMENT_SAVINGS_ACCOUNT')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={[
              'type_of_advice',
              'account_selection',
              'deposit_capital_insurance',
            ]}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item"
          >
            <CustomCheckbox>{t('DEPOSIT_CAPITAL_INSURANCE')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={[
              'type_of_advice',
              'account_selection',
              'depot_occupational_pension_insurance',
            ]}
            valuePropName="checked"
            className="filter-check-item"
          >
            <CustomCheckbox>{t('DEPOT_PENSION_INSURANCE')}</CustomCheckbox>
          </Form.Item>
        </div>
        <Form.Item
          name={['type_of_advice', 'account_selection', 'recommendation']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('RECOMMENDATION')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={2000}/>
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('RECOMMENDATION')}
          </h2>
        </div>
        <Form.Item
          label={t('RECOMMENDATION_APPLIES_DEPOSITORY')}
          name={['type_of_advice', 'recommendation', 'recommendation_applies']}
          className="custom-required"
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {[{ value: 0, label: 'Need more information' }]?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          name={[
            'type_of_advice',
            'recommendation',
            'advisers_proposal_change',
          ]}
          className="onboarding-textarea-purpose custom-required"
          label={t('ADVISER_PROPOSAL_CHANGE')}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
        >
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={1000}/>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'recommendation', 'justification']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('JUSTIFICATION_GIVEN_ADVICE')}
        >
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={2000} />
        </Form.Item>
        <Form.Item
          style={{ width: '330px' }}
          label={t('ADVISER_BELIEVE_THAT_CLIENT')}
          name={['type_of_advice', 'recommendation', 'adviser_believe']}
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group onChange={null} className="checkbox-block">
            <Space direction="horizontal" align="center" className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'recommendation', 'adviser_believe_motivate']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('MOTIVATE')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <Form.Item
          style={{ width: '342px' }}
          label={t('ADVISOR_CONSIDER_RECOMMENDATION')}
          name={['type_of_advice', 'recommendation', 'advisor_consider']}
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group onChange={null} className="checkbox-block">
            <Space direction="horizontal" align="center" className="ondoarding-space-wrap">
              <CustomRadioButtom value={false} checked>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'recommendation', 'advisor_consider_motivate']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('MOTIVATE')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <Form.Item
          style={{ width: '342px' }}
          label={t('SUITABILITY_BASED_CUSTOMER')}
          name={['type_of_advice', 'recommendation', 'suitability']}
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group onChange={null} className="checkbox-block">
            <Space direction="vertical" align="start" className="ondoarding-space-wrap">
              <CustomRadioButtom value={'suitability_option_1'}>
                {t('SUITABILITY_OPTION_1')}
              </CustomRadioButtom>
              <CustomRadioButtom value={'suitability_option_2'}>
                {t('SUITABILITY_OPTION_2')}
              </CustomRadioButtom>
              <CustomRadioButtom value={'suitability_option_3'}>
                {t('SUITABILITY_OPTION_3')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'recommendation', 'training_customer']}
          className="onboarding-textarea-purpose"
          label={t('TRAINING_CUSTOMER_DURING_MEETING')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <Form.Item
          style={{ width: '342px' }}
          label={t('WHAT_TYPE_OF_INVESTMENT')}
          name={['type_of_advice', 'recommendation', 'type_of_investment']}
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group onChange={null} className="checkbox-block">
            <Space direction="vertical" align="start" className="ondoarding-space-wrap">
              <CustomRadioButtom value={'type_of_investment_option_1'}>
                {t('TYPE_OF_INVESTMENT_OPTION_1')}
              </CustomRadioButtom>
              <CustomRadioButtom value={'type_of_investment_option_2'}>
                {t('TYPE_OF_INVESTMENT_OPTION_2')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'recommendation', 'total_invested_capital']}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('TOTAL_INVESTED_CAPITAL')}>
          <CustomInputNumber
            min={0}
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('RECOMMENDED_BUYING_AND_SELLING')}
          </h2>
        </div>
        <Collapse
          // accordion
          bordered={false}
          defaultActiveKey={1}
          expandIconPosition={'end'}
          ghost
          expandIcon={<Icon />}
          items={knowledgeDiffTypes}
        />
        <Form.Item
          style={{ marginTop: '12px' }}
          name={['type_of_advice', 'recommended_buying_selling', 'comment']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('COMMENT_RECOMMENDED')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('COSTS_AND_FEES')}
          </h2>
        </div>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'compensation']}
          label={t('COMPENSATION_ADVICE_PORTFOLIO_MANAGEMENT')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'business_volume']}
          label={t('BUSINESS_VOLUME')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'transaction_costs']}
          label={t('TRANSACTION_COSTS')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'fee_per_year']}
          label={t('FEE_PER_YEAR')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'fee_sek_per_year']}
          label={t('FEE_SEK_PER_YEAR')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'fee_per_year_compensation']}
          label={t('FEE_PER_YEAR_COMPENSATION')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'fee_sek_per_year_compensation']}
          label={t('FEE_SEK_PER_YEAR_COMPENSATION')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'total_fee_per_year']}
          label={t('TOTAL_FEE_PER_YEAR')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['type_of_advice', 'costs_and_fees', 'total_fee_sek_per_year']}
          label={t('TOTAL_FEE_SEK_PER_YEAR')}
          required={false}
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          >
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder={t('0,00')}
            width={'360px'} />
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'costs_and_fees', 'costs_associated_advice']}
          className="onboarding-textarea-purpose"
          label={t('COSTS_ASSOCIATED_ADVICE')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('DECISION')}
          </h2>
        </div>
        <Form.Item
          label={t('CUSTOMERS_DECISION')}
          name={['type_of_advice', 'decision', 'customers_decision']}
          className="custom-required"
          required={false}
          rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}>
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}>
            {customersDecisionOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'decision', 'other_decision']}
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}
          label={t('ENTER_OTHER_DECISION')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} max={1000}/>
        </Form.Item>
        <Form.Item
          name={['type_of_advice', 'decision', 'commentary_decision']}
          className="onboarding-textarea-purpose"
          label={t('COMMENTARY_ON_DECISION')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} max={1000} />
        </Form.Item>

        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}>
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryTypeOfAdvice;

CategoryTypeOfAdvice.propTypes = {
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

RecommendedLabel.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
};
