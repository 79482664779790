import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  CLEAR_CONFIRM_STEP,
  RESET_USER_PASSWORD,
  SIGN_UP_USER,
  GET_USER,
  LOG_OUT,
  CONFIRM_AUTH,
  IS_AUTH,
  CHANGE_EMAIL,
  USER_PROFILE_UPDATE,
  GET_ADVISOR_BY_ID,
  UPDATE_ADVISOR_NAME,
  UPDATE_ADVISOR_EMAIL,
  UPDATE_ADVISOR_PHONE,
  UPDATE_ADVISOR_PASSWORD,
  RESEND_EMAIL,
  GET_QR_BANKID,
  CLEAR_QR_BANKID,
  COLLECT_BANKID,
  GET_QR_SIGN_BANKID,
  CONFIRM_EMAIL,
  GET_TWOFACTOR_SECRET,
  GET_TWOFACTOR_CHECK,
  GET_QR_ATTACH_BANKID,
  GET_USER_BY_ID
} from './userActionTypes';

export const login = (loginData) => ({
  type: LOGIN_USER.ACTION,
  payload: loginData
});

export const isAuth = () => ({
  type: IS_AUTH
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD.ACTION,
  payload: email
});

export const confirmEmail = (hash) => ({
  type: CONFIRM_EMAIL.ACTION,
  payload: hash
});

export const clearConfirmStep = () => ({
  type: CLEAR_CONFIRM_STEP
});

export const resetUserPassword = (data) => ({
  type: RESET_USER_PASSWORD.ACTION,
  payload: data
});

export const confirmAuth = (code) => ({
  type: CONFIRM_AUTH.ACTION,
  payload: code
});

export const signUp = (userData) => ({
  type: SIGN_UP_USER,
  payload: userData
});

export const getUser = () => ({
  type: GET_USER.ACTION
});

export const logOut = () => ({
  type: LOG_OUT
});

export const changeEmail = (data) => ({
  type: CHANGE_EMAIL.ACTION,
  payload: data
});

export const resendEmail = (data) => ({
  type: RESEND_EMAIL.ACTION,
  payload: data
});

export const userProfileUpdate = (data) => ({
  type: USER_PROFILE_UPDATE.ACTION,
  payload: data
});

export const getAdvisorById = () => ({ type: GET_ADVISOR_BY_ID.ACTION });

export const updateAdvisorName = (data) => ({
  type: UPDATE_ADVISOR_NAME.ACTION,
  payload: data
});
export const updateAdvisorEmail = (data) => ({
  type: UPDATE_ADVISOR_EMAIL.ACTION,
  payload: data
});
export const updateAdvisorPhone = (data) => ({
  type: UPDATE_ADVISOR_PHONE.ACTION,
  payload: data
});
export const updateAdvisorPassword = (data) => ({
  type: UPDATE_ADVISOR_PASSWORD.ACTION,
  payload: data
});

export const getQRCodeBankID = () => ({
  type: GET_QR_BANKID.ACTION,
});

export const getQRCodeSignBankID = (data) => ({
  type: GET_QR_SIGN_BANKID.ACTION,
  payload: data
});

export const getQRCodeAttachBankID = (data) => ({
  type: GET_QR_ATTACH_BANKID.ACTION,
  payload: data
});

export const clearQRCodeBankID = () => ({
  type: CLEAR_QR_BANKID.ACTION,
});

export const collectBankID = (ref) => ({
  type: COLLECT_BANKID.ACTION,
  payload: ref
});

export const getTwoFactorSecret = () => ({
  type: GET_TWOFACTOR_SECRET.ACTION,
});

export const getTwoFactorSecretCheck = (data) => ({
  type: GET_TWOFACTOR_CHECK.ACTION,
  payload: data
});

export const getUserByID = (id) => ({
  type: GET_USER_BY_ID.ACTION,
  payload: id
});
