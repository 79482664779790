/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Input, Row, Switch as SwitchButton } from 'antd';

import pic from '../../assets/login-pic.png';
import titleLogo from '../../assets/lt_logo.png';
import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';
import { ReactComponent as BankIDLogo } from '../../assets/bankId-logo.svg';
import { ReactComponent as BlackDots } from '../../assets/black-dots.svg';

import { login } from '../../data/store/user/userActions';
import { emailRules, passwordRules } from '../../helpers/validation';

import TwoFactorPage from '../TwoFactorPage/TwoFactorPage';
import BankIDLogin from '../../components/BankIDLogin/BankIDLogin';

import './style.scss';

const LoginPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation('');

  const [remember, setRemember] = useState(true);

  const onChangeRemember = () => {
    setRemember(!remember);
  };
  useEffect(() => {
    console.log('remember', remember);
  }, [remember]);

  const isValid = useMemo(() => {
    return true;
  }, []);

  const handleForgot = () => {
    history.push('/forgot-password');
  };
  const handleSignUp = () => {
    history.push('/sign-up');
  };
  const handleBankIDLogin = () => {
    history.push('/login/bankid');
  };

  const onSubmit = useCallback(
    (fields) => {
      const data = { ...fields };
      dispatch(login({ data, history, remember }));
      form.resetFields();
    },
    [history, remember, dispatch]
  );
  return (
    <div className="login-wrapper">
      <div className="login-picture">
        <img src={pic} alt="login-picture" />
        <BlackDots className='login-black-dots' />
      </div>
      <Switch>
        <Route exact path={`${url}`} >
          <div className="login-form">
            <div className="login-form-title">
              <img src={titleLogo} alt="title logo" />
            </div>
            <Form form={form} name="login" layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label={t('EMAIL')}
                name="email"
                required={false}
                rules={emailRules}>
                <Input
                  className='login-input'
                  placeholder={t('ENTER_EMAIL')}
                  autoComplete="off" />
              </Form.Item>

              <Form.Item
                label={t('PASSWORD')}
                name="password"
                required={false}
              >
                <Input.Password
                  className='login-input'
                  visible="true"
                  placeholder={t('ENTER_PASSWORD')}
                  autoComplete="off"
                />
              </Form.Item>
              <Row justify='space-between'>
                <Col className='login-btn-wrapper'>
                  <SwitchButton
                    defaultChecked
                    onChange={onChangeRemember}
                    checked={remember}
                    id="login-form-remember-switch"
                  />
                  <label htmlFor="login-form-remember-switch">
                    {t('REMEMBER_ME')}
                  </label>
                </Col>
                <Col className='login-btn-wrapper'>
                  <Button
                    type='text'
                    icon={<Arrow/>}
                    iconPosition={'end'}
                    onClick={handleForgot}
                  >
                    {t('FORGOT_YOUR_PASSWORD')}
                  </Button>
                </Col>
              </Row>
              <Row className='login-submit-btn-wrapper'>
                <Button
                  onClick={() => form.submit()}
                  disabled={!isValid}
                  block>
                  {t('LOG_IN')}
                </Button>
              </Row>
              <Divider className='login-divider'/>
              <Row className='login-bankid-btn-wrapper'>
                <Button
                  onClick={handleBankIDLogin}
                  icon={<BankIDLogo />}
                  block>
                  {t('LOGIN_WITH')}
                </Button>
              </Row>
              <Row className='login-bottom-link'>
                <span className='text'>{t('DONT_HAVE_ACCOUNT')}</span>
                <span className='link' onClick={handleSignUp}>{t('SIGN_UP_NOW')}</span>
                <Arrow/>
              </Row>
            </Form>
          </div>
        </Route>
        <Route exact path={`${url}/two-factor-auth`} >
          <TwoFactorPage remember={remember}/>
        </Route>
        <Route exact path={`${url}/bankid`} >
          <BankIDLogin remember={remember}/>
        </Route>
      </Switch>
    </div>
  );
};

export default LoginPage;
