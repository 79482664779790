import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AuthEN } from './localization/authModule/authEN';
import { AuthSW } from './localization/authModule/authSW';
import { PortfolioSW } from './localization/portfolioModule/portfolioSW';
import { PortfolioEN } from './localization/portfolioModule/portfolioEN';
import { OnboardingEN } from './localization/onboardingModule/onboardingEN';
import { OnboardingSW } from './localization/onboardingModule/onboardingSW';
import { MyProfileEN } from './localization/MyProfile/myProfileEN';
import { MyProfileSW } from './localization/MyProfile/myProfileSW';
import { DashboardEN } from './localization/dashboard/dashboardEN';
import { DashboardSW } from './localization/dashboard/dashboardSW';
import { portfolioOptimizationSW } from './localization/portfolioOptimization/portfolioOptimizationSW';
import { portfolioOptimizationEN } from './localization/portfolioOptimization/portfolioOptimizationEN';
import { InstrumentsEN } from './localization/instruments/instrumentsEN';
import { InstrumentsSW } from './localization/instruments/instrumentsSW';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          ...AuthEN,
          ...PortfolioEN,
          ...OnboardingEN,
          ...MyProfileEN,
          ...DashboardEN,
          ...portfolioOptimizationEN,
          ...InstrumentsEN
        },
      },
      sw: {
        translations: {
          ...AuthSW,
          ...PortfolioSW,
          ...OnboardingSW,
          ...MyProfileSW,
          ...DashboardSW,
          ...portfolioOptimizationSW,
          ...InstrumentsSW
        }
      }
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      // escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  });

export default i18n;
