import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useDispatch } from 'react-redux';
import { clearConfirmStep } from '../../../data/store/user/userActions';
import { LOGIN, SIGN_UP } from '../../../constants/routes';
import { NavLink as Nav } from 'react-router-dom';
import { Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Mail } from '../../../assets/mail-pic.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

const ForgotPasswordConfirmation = ({ mail }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  useEffect(() => {
    return () => dispatch(clearConfirmStep());
  });

  return (
    <Row className='sign-up-wrapper reset-pass-input' style={{ width: '360px' }}>
        <p className='forgot-password-title'>
          {t('INSTRUCTIONS_HAS_BEEN_SENT')}
        </p>
        <p className='forgot-password-text'>
          {t('INSTRUCTIONS_HAS_BEEN_SENT_TEXT')}
        </p>
        <p className='forgot-password-mail'>
        {mail}
        </p>
        <div className='forgot-password-mail-pic'>
          <Mail />
        </div>
        <h3 className='forgot-password-nomail'>{t('NO_EMAIL_FROM_US')}</h3>
        <ol className='forgot-password-list'>
          <li>{t('CHECK_YOUR_SPAM')}</li>
          <li>{t('CHECK_YOUR_SPELLING')}</li>
          <li>{t('WAIT_AND_TRY_AGAIN')}</li>
        </ol>
        <Nav to={LOGIN}>
          <p className='reset-pawss-back'>{t('BACK_TO_LOGIN')}
            <Arrow className='terms-link'/>
          </p>
        </Nav>
        <Divider className='divider-password'/>

        <p className='reset-pawss-back reset-pawss-sing-wrapp'>
          {t('DONT_HAVE_AN_ACCOUNT')}
          <a href={SIGN_UP} className='reset-pawss-signup'>{t('SIGN_UP_NOW')}</a>
          <Arrow className='terms-link'/>
        </p>
    </Row>
  );
};

export default ForgotPasswordConfirmation;

ForgotPasswordConfirmation.propTypes = {
  history: PropTypes.object,
  mail: PropTypes.string
};
