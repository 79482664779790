import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Input, Select, Checkbox } from 'antd';
import './style.scss';

const ExpBackTableItem = (props) => {
  const {
    item,
    field,
    handleChangeTableInput,
    handleChangeSelect,
    handleChangeCheckbox,
  } = props;
  const { t } = useTranslation('');
  return (
    <>
      <div className='experience-background-cell'>{t(item.title)}</div>
      <div className='experience-background-cell'>
        <Input
          className='experience-background-input'
          name='numberOfDeals'
          value={field.numberOfDeals}
          type='number'
          max={9999}
          placeholder={t('QUANTITY')}
          onChange={(e) => handleChangeTableInput(e, item.name)}
        />
      </div>
      <div className='experience-background-cell'>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='experience-background-select'
          name='knowledgeAndExperience'
          type='select'
          value={field.knowledgeAndExperience}
          onChange={(value) => handleChangeSelect(value, 'knowledgeAndExperience', item.name)}
        >
          <Select.Option value='none'>{t('NONE')}</Select.Option>
          <Select.Option value='basic'>{t('BASIC')}</Select.Option>
          <Select.Option value='good-knowledge'>
            {t('GOOD_KNOWLEDGE')}
          </Select.Option>
        </Select>
      </div>
      <div className='experience-background-cell center'>
        <Checkbox
          name='hasBeenTrained'
          checked={field.hasBeenTrained}
          onChange={(e) => handleChangeCheckbox(e, item.name)}
          className='experience-background-cell center experience-background-checkbox'
        />
      </div>
      <div className='experience-background-cell pl-62 center'>
        <Checkbox
          name='adviserBelieves'
          checked={field.adviserBelieves}
          onChange={(e) => handleChangeCheckbox(e, item.name)}
          className='experience-background-cell center experience-background-checkbox'
        />
      </div>
    </>
  );
};

ExpBackTableItem.propTypes = {
  item: PropTypes.object,
  field: PropTypes.object,
  handleChangeSelect: PropTypes.func,
  handleChangeTableInput: PropTypes.func,
  handleChangeCheckbox: PropTypes.func,
};

export default ExpBackTableItem;
