import React from 'react';
import './style.scss';

import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import { Header } from 'antd/es/layout/layout';
import MainFooter from '../../components/MainFooter';

import pic from '../../assets/support_pic.png';
import { ReactComponent as Dots } from '../../assets/dots-big-white.svg';

const { Title } = Typography;

const Support = () => {
  const { t } = useTranslation('');

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div>
        <Header className="headerStyle">
          <Row className="overview-tabs-wrapper">
            <Col className="tabs-tab-wrapper" span={18}>
              <Button type="text" className="overview-btn ant-btn-active">
                {t('SUPPORT_LC')}
              </Button>
            </Col>
          </Row>
        </Header>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '24px', padding: '16px 40px 0' }}>
          <Col>
            <h2 className="suport-header-title">{t('NEED_SUPPORT')}?</h2>
          </Col>
        </Row>
        <div style={{ padding: '0 20px' }}>
          <Container widthP={100} mb='24px' padding='0'>
          <div className='support-inner-container'>
            <div className='support-img-wrapper'>
              <img src={pic}/>
              <Dots />
            </div>
            <div className='support-text-wrapper'>
              <Title level={4} className='suport-header'>{t('PLEASE_CONTACT_CITRONEER_ADMIN')}</Title>
              <p className='support-label'>{t('EMAIL').toUpperCase()}</p>
              <a
                href="mailto:support@citroneer.com"
                className='support-text'>
                  support@citroneer.com
                </a>
              <p className='support-label'>{t('PHONE').toUpperCase()}</p>
              <a
                href="tel:+460790746629"
                className='support-text'>+46 72 944 0129</a>
            </div>
          </div>
          </Container>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default Support;
