import React from 'react';
import { useSelector } from 'react-redux';

import SignUpForm from './SignUpForm';
import { CHANGE, PENDING } from '../../constants/authStatus';
import SignUpConfirm from './SignUpConfirm';
import ChangeEmail from './SendEmail/ChangeEmail';

import pic from '../../assets/login-pic.png';
import titleLogo from '../../assets/lt_logo.png';
import { ReactComponent as BlackDots } from '../../assets/black-dots.svg';

const SignUp = () => {
  const status = useSelector(state => state.userReducer.auth.status);
  // const isLoading = useSelector(state => state.userReducer.isLoading);

  const renderComponent = {
    [PENDING]: <SignUpConfirm />,
    [CHANGE]: <ChangeEmail />,
    '': <SignUpForm />
  };

  return (
    <div className="login-wrapper">
      <div className="login-picture">
        <img src={pic} alt="login-picture" />
        <BlackDots className='login-black-dots' />
      </div>
      <div className="login-form">
        <div className="login-form-title">
          <img src={titleLogo} alt="title logo" />
        </div>
        {renderComponent[status]}
      </div>
    </div>
  );
};

export default SignUp;
