import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { deleteSuggestions } from '../../data/store/clients/clientActions';

const ModalDeleteCandidat = ({ userId, modalDeleteCandidat, setModalDeleteCandidat, candidatType, name, text }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const handleCloseDelModal = () => {
    setModalDeleteCandidat(0);
  };

  const handleDelete = () => {
    dispatch(deleteSuggestions({ userId, candidatId: modalDeleteCandidat, candidatType }));
    setModalDeleteCandidat(0);
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalDeleteCandidat}
        onCancel={handleCloseDelModal}
        footer={null}
        destroyOnClose={true}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t(text)}</p>
            <p className="modal-advisor-name">{name}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={handleDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalDeleteCandidat;

ModalDeleteCandidat.propTypes = {
  userId: PropTypes.number,
  name: PropTypes.string,
  text: PropTypes.string,
  candidatType: PropTypes.string,
  modalDeleteCandidat: PropTypes.number,
  setModalDeleteCandidat: PropTypes.func,
};
