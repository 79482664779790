import React, { useCallback } from 'react';
import { NavLink as Nav } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { resendEmail } from '../../../data/store/user/userActions';
import { useTranslation } from 'react-i18next';
import { Divider, Row } from 'antd';

import { ReactComponent as Mail } from '../../../assets/mail-pic.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

import './style.scss';

const SignUpConfirm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const advisor = useSelector((state) => state.userReducer.advisor);
  // const isLoading = useSelector(state => state.userReducer.isLoading);

  const onSend = useCallback(() => {
    dispatch(resendEmail({ userID: advisor.ID }));
  }, [dispatch, advisor]);

  return (
    <Row className="sign-up-confirm-wrapper">
      <div style={{ width: '100%' }}>
        <h2 className="pass-setup-title">{t('CONFIRM_YOUR_EMAIL')}</h2>
      </div>
      <div className="mail-pic-wrapper">
        <Mail />
      </div>
      <div className="mail-text-wrapper">
        <p className="mail-text">{t('CONFIRM_YOUR_EMAIL_TEXT')}</p>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ width: '360px', margin: '0 auto' }}>
          <Divider className='login-divider'/>
        </div>
      </div>
      <div className="confirm-reg-links">
        <Nav to="#" onClick={onSend}>
          <p className="mail-change-link">
            {t('SEND_CONFIRMATION_EMAIL')}
            <Arrow />
          </p>
        </Nav>
      </div>
    </Row>
  );
};

export default SignUpConfirm;
