import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Select,
  Button,
  Radio,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Divider
} from 'antd';

import countryEN from '../../constants/countryEN.json';
import { ReactComponent as IconPlus } from './img/icon-plus.svg';

const PrivateOwnersOwner = (props) => {
  const { index, parent, beneficialOwner, fieldsCompany, setFieldsCompany, addOwner, deleteOwner } =
  props;
  const { t } = useTranslation('');

  const [isAddCountry, setIsAddCountry] = useState(false);

  useEffect(() => {
    if (beneficialOwner?.countryOwner.length > 1) setIsAddCountry(true);
  }, [beneficialOwner]);

  const newParentData = (arg) => {
    const ownersData = newData(arg);
    return fieldsCompany.beneficialOwners.map((element, i) =>
      i === parent
        ? { ...element, ownersBeneficialOwner: ownersData }
        : element
    );
  };

  const newData = (arg) => {
    return fieldsCompany.beneficialOwners[parent].ownersBeneficialOwner.map((element, i) =>
      i === index
        ? arg
        : element
    );
  };
  const handleCheckMissing = (event) => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newParentData({
        ...beneficialOwner,
        [event.target.name]: event.target.checked,
        personalNumberOwner: null,
      })
    });
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newParentData({
        ...beneficialOwner,
        [name]: value,
      })
    });
  };

  const handlePercentageOfVotes = (value, name) => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newParentData({
        ...beneficialOwner,
        [name]: value,
      })
    });
  };

  // addOwner ? -------------------------------------------------------------------------

  const handleNextOwner = (event) => {
    const { name, value } = event.target;
    if (value === 'yes' && fieldsCompany?.beneficialOwners[parent]?.ownersBeneficialOwner?.length < 4) addOwner();
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newParentData({
        ...beneficialOwner,
        [name]: value === 'yes',
      })
    });
    if (value === 'no') {
      deleteOwner(fieldsCompany?.beneficialOwners[parent]?.ownersBeneficialOwner[index + 1]?.beneficialOwnerID, index, parent);
    }
  };

  const handleOwnerCountrySelect = (value, position, type) => {
    if (!type) {
      const newcountry = [...beneficialOwner.countryOwner];
      newcountry[position] = value;
      setFieldsCompany({
        ...fieldsCompany,
        beneficialOwners: newParentData({
          ...beneficialOwner,
          countryOwner: newcountry
        })
      });
    } else if (type) {
      setFieldsCompany({
        ...fieldsCompany,
        beneficialOwners: newParentData({
          ...beneficialOwner,
          [type]: value
        })
      });
    }
  };

  const handleAddCountry = () => {
    setIsAddCountry(true);
  };

  const handleChangeRadio = (event) => {
    const { value, name } = event.target;
    let clearedText;
    if (name === 'hasControlsMore25') clearedText = 'typeControlsMore25';
    let data = {
      ...fieldsCompany,
      beneficialOwners: newParentData({
        ...beneficialOwner,
        [name]: value === 'yes'
      })
    };
    if (clearedText) {
      data = {
        ...data,
        beneficialOwners: newParentData({
          ...beneficialOwner,
          [clearedText]: null
        })
      };
    }
    setFieldsCompany(data);
  };

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {t(countryEN[data])}
      </Select.Option>
    );
  };

  return (
    <>
      <div className='missing-checkbox-wrapper'>
        <Checkbox
          name='isMissingPersonalNumber'
          onChange={handleCheckMissing}
          checked={beneficialOwner?.isMissingPersonalNumber}
        />
        <p className='risk-profile-lable'>{t('MISSING_SWEDISH_NUMBER')}</p>
      </div>
      {!beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('PERSONAL_NUMBER')}
          name={`${beneficialOwner?.beneficialOwnerID}-personalNumberOwner`}
          initialValue={beneficialOwner?.personalNumberOwner}
          rules={[
            {
              required: true,
              message: 'Enter identification number',
            },
            () => ({
              validator (rule, value) {
                if (
                  !value ||
                  value.match(/^(\d{4}((0\d)|(1[012]))(([012]\d)|3[01])\d{4}$)/)
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('YYYYMMDD-XXXX');
              },
            }),
          ]}
        >
          <Input
            type='text'
            name='personalNumberOwner'
            placeholder={`${t('ENTER')} ${t('PRIVAT_NUMBER')}`}
            onChange={handleChangeInput}
            value={beneficialOwner?.personalNumberOwner}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('DATE_OF_BIRTH')}
          name={`${beneficialOwner?.beneficialOwnerID}-dateBirthOwner`}
          initialValue={beneficialOwner?.dateBirthOwner}
          rules={[
            {
              required: true,
              message: 'Enter owner birth date',
            },
            () => ({
              validator (rule, value) {
                if (
                  !value ||
                  value.match(/^(\d{4}((0\d)|(1[012]))(([012]\d)|3[01])$)/)
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('YYYYMMDD');
              },
            }),
          ]}
        >
          <Input
            type='text'
            name='dateBirthOwner'
            placeholder={`${t('ENTER')} ${t('DATE_OF_BIRTH')}`}
            onChange={handleChangeInput}
          />
        </Form.Item>
      )}

      <Form.Item
        label={t('FIRST_NAME')}
        name={`${beneficialOwner?.beneficialOwnerID}-firstNameOwner`}
        initialValue={beneficialOwner?.firstNameOwner}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('FIRST_NAME')}`,
          },
        ]}
      >
        <Input
          placeholder={`${t('ENTER')} ${t('FIRST_NAME')}`}
          type='text'
          name='firstNameOwner'
          onChange={handleChangeInput}
        />
      </Form.Item>
      <Form.Item
        label={t('LAST_NAME')}
        name={`${beneficialOwner?.beneficialOwnerID}-secondNameOwner`}
        initialValue={beneficialOwner?.secondNameOwner}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('LAST_NAME')}`,
          },
        ]}
      >
        <Input
          placeholder={`${t('ENTER')} ${t('LAST_NAME')}`}
          type='text'
          name='secondNameOwner'
          onChange={handleChangeInput}
        />
      </Form.Item>
      <Form.Item
        label={t('PERCENTAGE_OF_VOTES')}
        name={`${beneficialOwner?.beneficialOwnerID}-percentageOfVotes`}
        initialValue={beneficialOwner?.percentageOfVotes}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('PERCENTAGE_OF_VOTES')}`,
          },
        ]}
      >
        <InputNumber
          className='percentage-votes'
          min={25}
          max={100}
          step={0.01}
          size='small'
          onChange={(value) =>
            handlePercentageOfVotes(value, 'percentageOfVotes')
          }
          value={beneficialOwner?.percentageOfVotes}
        />
      </Form.Item>
      <p className='risk-profile-lable'>
        {t('COUNTRY_PERSON_HAVE_CITIZENSHIP')}
      </p>
      <Form.Item
        name={`${beneficialOwner?.beneficialOwnerID}-countryOwner`}
        initialValue={beneficialOwner?.countryOwner[0]}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('COUNTRY_CITIZENSHIP')}`,
          },
        ]}
      >
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          name='countryOwner'
          type='select'
          value={beneficialOwner?.countryOwner[0]}
          onChange={(value) => handleOwnerCountrySelect(value, 0)}
        >
          <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
          {Object.keys(countryEN).map((item) => {
            return countrysOption(item);
          })}
        </Select>
      </Form.Item>

      {isAddCountry && (
        <>
          <p className='risk-profile-lable'></p>
          <Select
            placeholder={t('SELECT_OPTION')}
            className='risk-profile-select'
            type='select'
            value={beneficialOwner?.countryOwner[1]}
            onChange={(value) => handleOwnerCountrySelect(value, 1)}
          >
            <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
            {Object.keys(countryEN).map((item) => {
              return countrysOption(item);
            })}
          </Select>
        </>
      )}

      {!isAddCountry && (
        <div className='goal-addbutton-wrapper'>
          <Button
            className='goal-addbutton'
            onClick={handleAddCountry}
          >
            <IconPlus />
            {t('ADD_ANOTHER_COUNTRY')}
          </Button>
        </div>
      )}

      <p className='risk-profile-lable'>
        {t('WHAT_YOUR_COUNTRY_TAX_RESIDENCE')}
      </p>
      <Form.Item
        name={`${beneficialOwner?.beneficialOwnerID}-countryTaxOwner`}
        initialValue={beneficialOwner?.countryTaxOwner}
        rules={[
          {
            required: true,
            message: `${t('ENTER')} ${t('COUNTRY_TAX')}`,
          },
        ]}
      >
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={beneficialOwner?.countryTaxOwner}
          onChange={(value) =>
            handleOwnerCountrySelect(value, null, 'countryTaxOwner')
          }
        >
          <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
          {Object.keys(countryEN).map((item) => {
            return countrysOption(item);
          })}
        </Select>
      </Form.Item>
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('TIN')}
          name={`${beneficialOwner?.beneficialOwnerID}-tinOwner`}
          initialValue={beneficialOwner?.tinOwner}
          rules={[
            {
              required: true,
              message: `${t('ENTER')} ${t('TIN')}`,
            },
          ]}
        >
          <Input
            placeholder={`${t('ENTER')} ${t('TIN')}`}
            type='text'
            name='tinOwner'
            value={beneficialOwner?.tinOwner}
            onChange={handleChangeInput}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('POSTAL_ADDRESS')}
          name={`${beneficialOwner?.beneficialOwnerID}-postalOwner`}
          initialValue={beneficialOwner?.postalOwner}
          rules={[
            {
              required: true,
              message: t('ENTER_POSTAL_ADDRESS'),
            },
          ]}
        >
          <Input
            placeholder={t('ENTER_POSTAL_ADDRESS')}
            type='text'
            name='postalOwner'
            value={beneficialOwner?.postalOwner}
            onChange={handleChangeInput}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('ZIP_CODE')}
          name={`${beneficialOwner?.beneficialOwnerID}-zipCodeOwner`}
          initialValue={beneficialOwner?.zipCodeOwner}
          rules={[
            {
              required: true,
              message: `${t('ENTER')} ${t('ZIP_CODE')}`,
            },
            () => ({
              validator (rule, value) {
                if (
                  !value ||
                  value.match(/^(\d{5}$)/) ||
                  value.match(/^(\d{5}-\d{4}$)/)
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Enter valid zip code');
              },
            }),
          ]}
        >
          <Input
            placeholder={t(`${t('ENTER')} ${t('ZIP_CODE')}`)}
            type='text'
            name='zipCodeOwner'
            value={beneficialOwner?.zipCodeOwner}
            onChange={handleChangeInput}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('CITY')}
          name={`${beneficialOwner?.beneficialOwnerID}-cityOwner`}
          initialValue={beneficialOwner?.cityOwner}
          rules={[
            {
              required: true,
              message: t('ENTER_CITY'),
            },
          ]}
        >
          <Input
            placeholder={t('ENTER_CITY')}
            type='text'
            name='cityOwner'
            value={beneficialOwner?.cityOwner}
            onChange={handleChangeInput}
          />
        </Form.Item>
      )}
      {beneficialOwner?.isMissingPersonalNumber && (
        <Form.Item
          label={t('COUNTRY_COMPANY')}
          name={`${beneficialOwner?.beneficialOwnerID}-postCountryOwner`}
          initialValue={beneficialOwner?.postCountryOwner}
          className='risk-profile-lable'
          rules={[
            {
              required: true,
              message: `${t('ENTER')} ${t('COUNTRY_COMPANY')}`,
            },
          ]}
        >
          <Select
            placeholder={t('SELECT_OPTION')}
            className='risk-profile-select'
            type='select'
            value={beneficialOwner?.postCountryOwner}
            onChange={(value) =>
              handleOwnerCountrySelect(value, null, 'postCountryOwner')
            }
          >
            <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
            {Object.keys(countryEN).map((item) => {
              return countrysOption(item);
            })}
          </Select>
        </Form.Item>
      )}
      <div className='saving-subtitle customer-subtitle'>
        <span>{t('POLITICALLY_EXPOSED_POSITION_PERSON')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <Radio.Group
          name='politicallyExposedPosition'
          onChange={(event) => handleChangeRadio(event)}
          value={
            beneficialOwner?.politicallyExposedPosition
              ? 'yes'
              : beneficialOwner?.politicallyExposedPosition === null
                ? ''
                : 'no'
          }
          className='saving-radio customer-radio'
        >
          <Radio value='yes'>{t('YES')}</Radio>
          <Radio value='no'>{t('No')}</Radio>
        </Radio.Group>
      </div>
      <Divider />
      {index < 3 &&
      <>
      <div className='saving-subtitle customer-subtitle'>
          <span>{t('ANYONE_ELSE_WHO_CONTROLS')}</span>
        </div>
      <div style={{ display: 'flex' }}>
        <Radio.Group
          name='hasNextOwner'
          onChange={(event) => handleNextOwner(event)}
          value={
            beneficialOwner?.hasNextOwner === null
              ? ''
              : beneficialOwner?.hasNextOwner
                ? 'yes'
                : 'no'
          }
          className='saving-radio customer-radio'
        >
          <Radio value='yes'>{t('YES')}</Radio>
          <Radio value='no'>{t('No')}</Radio>
        </Radio.Group>
      </div>
      </>
      }
    </>
  );
};

PrivateOwnersOwner.propTypes = {
  index: PropTypes.number,
  parent: PropTypes.number,
  beneficialOwner: PropTypes.object,
  fieldsCompany: PropTypes.object,
  form: PropTypes.object,
  setFieldsCompany: PropTypes.func,
  addOwner: PropTypes.func,
  deleteOwner: PropTypes.func,
};

export default PrivateOwnersOwner;
