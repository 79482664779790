import React, { useEffect, useCallback, useState, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Modal, Row, Input, Layout, Col, Button, Table } from 'antd';
import MainButton from '../../components/MainButton';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, downloadReport, getAllReports } from '../../data/store/portfolio/portfolioActions';
import { permissions } from '../../config/permissions';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';

import Container from '../../components/Container';
import dayjs from 'dayjs';

import './style.scss';
import ReportDone from './ReportDone';

const { Header } = Layout;
const { SUPER_ADMIN } = permissions.roles;

const Report = ({ userId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const state = location.state;
  console.log('🚀 ~ Report ~ state:', state);
  const { t } = useTranslation('');
  const fileRef = useRef(null);

  const [reportName, setReportName] = useState('');
  const [modal2Visible, setModal2Visible] = useState(false);

  const [tableColumns, setTableColumns] = useState();

  const user = useSelector(state => state.userReducer.advisor);
  const reports = useSelector(state => state.portfolioReducer.reports.list);
  const { isReportOptPrepare } = useSelector(state => state.portfolioReducer);

  let fetch = false;
  useEffect(() => {
    if (userId && !fetch) {
      fetch = true;
      dispatch(getAllReports(userId));
    }
  }, [userId]);

  const getReportName = useCallback((id) => {
    return reports.find(i => i.ID === id)?.name || '';
  }, [reports]);

  useEffect(() => {
    if (reports?.length) {
      const defaultColumns = [
        {
          title: t('NAME'),
          dataIndex: 'name',
          className: 'holdings-instrument',
          width: '70%',
          sorter: (a, b) => {
            if (a.name < b.name) {
              return 1;
            } else if (a.name > b.name) {
              return -1;
            } else {
              return 0;
            }
          },
        },
        {
          title: t('CREATED_DATE'),
          dataIndex: 'createdAt',
          className: 'holdings-data',
          width: '15%',
          align: 'center',
          defaultSortOrder: 'descend',
          render: (value) => {
            return dayjs(value).format('YYYY-MM-DD');
          },
          sorter: (a, b) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            } else if (a.createdAt > b.createdAt) {
              return -1;
            } else {
              return 0;
            }
          },
        },
        {
          title: t('ACTION'),
          dataIndex: '',
          className: 'holdings-data',
          align: 'center',
          width: '15%',
          render: (_, record) => {
            return (
                <Button
                  className='download-btn'
                  onClick={onDownload(record.ID)}>
                  {t('DOWNLOAD')}
                </Button>
            );
          },
        },

      ];
      setTableColumns(defaultColumns);
    }
  }, [reports]);

  const onCreate = useCallback(() => {
    dispatch(createReport({ clientId: userId, id: '0', name: reportName }));
    setModal2Visible(false);
    setReportName('');
    fileRef.current.value = '';
  }, [reportName]);

  const onChangeName = useCallback((e) => {
    setReportName(e.target.value);
  }, [reportName]);

  const closeTooltip = () => {
    history.replace(location.pathname, {});
  };
  const onDownload = (reportId) => () => {
    console.log('🚀 ~ onDownload ~ reportId:', reportId);
    dispatch(downloadReport(reportId));
    closeTooltip();
  };

  return (
    <div className="client-profile-wrapper">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn ant-btn-active">
              {t('DOCUMENTS')}
            </Button>
          </Col>
          <Col className="tabs-btn-wrapper" span={6}>
            {user.role === SUPER_ADMIN &&
              <Button
                onClick={() => setModal2Visible(true)}
                className="portfolio-details-btn btn-upload"
                icon={<Pluse />}
                iconPosition='end'
              >
              {t('UPLOAD_REPORT')}
            </Button>
            }
          </Col>
        </Row>
      </Header>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('DOCUMENTS')}</h2>
      </div>
      <div style={{ padding: '0 24px' }}>
        <Container widthP={100} mb='24' padding='0'>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}>
            <Col>
              <h2 className="portfolio-header-title">{t('REPORTS')}</h2>
            </Col>
          </Row>
            <Table
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              bordered
              dataSource={reports}
              columns={tableColumns}
            />
        </Container>
      </div>
        <Modal
          title={t('UPLOAD_REPORT')}
          centered
          open={modal2Visible}
          onOk={() => setModal2Visible(false)}
          onCancel={() => setModal2Visible(false)}
          footer={[
            <MainButton key='import' onClick={onCreate}>{t('UPLOAD')}</MainButton>
          ]}
        >
          <Input placeholder={t('ENTER_REPORT_NAME')} onChange={onChangeName} value={reportName} />
          <input ref={fileRef} style={{ marginTop: 20, width: '100%', overflow: 'hidden' }} type="file" id="report" accept=".pdf" name="report"/>
        </Modal>
        {state?.key === 'create' && isReportOptPrepare !== null &&
          <ReportDone
            interval={30}
            isReportOptPrepare={isReportOptPrepare}
            getReportName={getReportName}
            closeTooltip={closeTooltip}
            downloadReport={onDownload}
          />
        }
    </div>
  );
};

export default Report;

Report.propTypes = {
  userId: PropTypes.string,
};
