import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

export class AdvisorService {
  static getAll = async (data) => {
    const { limit, offset } = data;
    return await axios.get(API_URLS.ADVISORS, {
      params: { limit, offset, order: 'DESC' },
    });
  };

  static getAwaitApprove = async (data) => {
    const { limit, offset, status } = data;
    return await axios.get(API_URLS.ADVISORS, {
      params: { limit, offset, status },
    });
  };

  static activate = async (id) => {
    return await axios.put(`${API_URLS.ACTIVATE_ADVISER}/${id}/activate`);
  };

  static deactivate = async (id) => {
    return await axios.put(`${API_URLS.ACTIVATE_ADVISER}/${id}/deactivate`);
  };

  static create = async (data) => {
    return await axios.post(API_URLS.CREATE_ADVISER, data);
  };

  static update = async ({ data, id }) => {
    return await axios.put(`${API_URLS.ADVISOR}/${id}`, data);
  };

  static reinvite = async (id) => {
    return await axios.put(API_URLS.ADVISOR_REINVITE, { userID: id });
  };

  static delete = async (id) => {
    return await axios.delete(`${API_URLS.ADVISOR}/${id}`);
  };
}
