export const behalfOptions = [
  {
    value: 'behalf_option1',
    label: 'ON_HIS_OWN_BEHALF',
  },
  {
    value: 'behalf_option2',
    label: 'SOMEONE_ELSE_POWER_ATTORNEY',
  },
  {
    value: 'behalf_option3',
    label: 'OTHER_DESCRIBE',
  },
];

export const occupationOptions = [
  {
    value: 'main_occupation_option1',
    label: 'EMPLOYEE',
  },
  {
    value: 'main_occupation_option2',
    label: 'SELF_EMPLOYED',
  },
  {
    value: 'main_occupation_option3',
    label: 'RETIRED',
  },
  {
    value: 'main_occupation_option4',
    label: 'STUDENT',
  },
  {
    value: 'main_occupation_option5',
    label: 'OTHER_DESCRIBE',
  },
];

export const monthlyIncomeOptions = [
  {
    value: 'monthly_income_option1',
    label: '0 - 20 000 kr',
  },
  {
    value: 'monthly_income_option2',
    label: '20 001 - 50 000 kr',
  },
  {
    value: 'monthly_income_option3',
    label: '50 001 - 100 000 kr',
  },
  {
    value: 'monthly_income_option4',
    label: 'MONTHLY_INCOME_OVER',
  },
];

export const transferValueOptions = [
  {
    value: 'transfer_value_option1',
    label: '0 - 100 000 kr',
  },
  {
    value: 'transfer_value_option2',
    label: '100 001 - 500 000 kr',
  },
  {
    value: 'transfer_value_option3',
    label: '500 001 - 1 000 000 kr',
  },
  {
    value: 'transfer_value_option4',
    label: '1 000 001 - 5 000 000 kr',
  },
  {
    value: 'transfer_value_option5',
    label: 'TRANSFER_VALUE_OVER',
  },
];

export const yearlyDepositOptions = [
  {
    value: 'yearly_deposit_option1',
    label: 'TIME_DEPOSIT_OPENING_ACCOUNT',
  },
  {
    value: 'yearly_deposit_option2',
    label: '0 - 100 000 kr',
  },
  {
    value: 'yearly_deposit_option3',
    label: '100 001 - 500 000 kr',
  },
  {
    value: 'yearly_deposit_option4',
    label: '500 001 - 1 000 000 kr',
  },
  {
    value: 'yearly_deposit_option5',
    label: '1 000 001 - 5 000 000 kr',
  },
  {
    value: 'yearly_deposit_option6',
    label: 'TRANSFER_VALUE_OVER',
  },
];

export const depositFrequencyOptions = [
  {
    value: 'deposit_frequency_option1',
    label: 'TIMES_0_10',
  },
  {
    value: 'deposit_frequency_option2',
    label: 'TIMES_11_50',
  },
  {
    value: 'deposit_frequency_option3',
    label: 'TIMES_51_100',
  },
  {
    value: 'deposit_frequency_option4',
    label: 'TIMES_MORE_THEN',
  },
];

export const withdrawalFrequencyOptions = [
  {
    value: 'withdrawal_frequency_option1',
    label: 'TIMES_0_10',
  },
  {
    value: 'withdrawal_frequency_option2',
    label: 'TIMES_11_50',
  },
  {
    value: 'withdrawal_frequency_option3',
    label: 'TIMES_51_100',
  },
  {
    value: 'withdrawal_frequency_option4',
    label: 'TIMES_MORE_THEN',
  },
];

export const individualTransactionsOptions = [
  {
    value: 'individual_transactions_option1',
    label: '0 - 100 000 kr',
  },
  {
    value: 'individual_transactions_option2',
    label: '100 001 - 500 000 kr',
  },
  {
    value: 'individual_transactions_option3',
    label: '500 001 - 1 000 000 kr',
  },
  {
    value: 'individual_transactions_option4',
    label: '1 000 001 - 5 000 000 kr',
  },
  {
    value: 'individual_transactions_option5',
    label: 'TRANSFER_VALUE_OVER',
  },
];

export const pepTypeOptions = [
  {
    value: 'pep_type_option1',
    label: 'PEP_TYPE_OPTION_1',
  },
  {
    value: 'pep_type_option2',
    label: 'PEP_TYPE_OPTION_2',
  },
  {
    value: 'pep_type_option3',
    label: 'PEP_TYPE_OPTION_3',
  },
  {
    value: 'pep_type_option4',
    label: 'PEP_TYPE_OPTION_4',
  },
  {
    value: 'pep_type_option5',
    label: 'PEP_TYPE_OPTION_5',
  },
  {
    value: 'pep_type_option6',
    label: 'PEP_TYPE_OPTION_6',
  },
  {
    value: 'pep_type_option7',
    label: 'PEP_TYPE_OPTION_7',
  },
  {
    value: 'pep_type_option8',
    label: 'PEP_TYPE_OPTION_8',
  },
  {
    value: 'pep_type_option9',
    label: 'PEP_TYPE_OPTION_9',
  },
  {
    value: 'pep_type_option10',
    label: 'PEP_TYPE_OPTION_10',
  },
  {
    value: 'pep_type_option11',
    label: 'PEP_TYPE_OPTION_11',
  },
];

export const pepRelativeTypeOptions = [
  {
    value: 'pep_relative_type_option1',
    label: 'PEP_TYPE_OPTION_1',
  },
  {
    value: 'pep_relative_type_option2',
    label: 'PEP_TYPE_OPTION_2',
  },
  {
    value: 'pep_relative_type_option3',
    label: 'PEP_TYPE_OPTION_3',
  },
  {
    value: 'pep_relative_type_option4',
    label: 'PEP_TYPE_OPTION_4',
  },
  {
    value: 'pep_relative_type_option5',
    label: 'PEP_TYPE_OPTION_5',
  },
  {
    value: 'pep_relative_option6',
    label: 'PEP_TYPE_OPTION_6',
  },
  {
    value: 'pep_relative_option7',
    label: 'PEP_TYPE_OPTION_7',
  },
  {
    value: 'pep_relative_option8',
    label: 'PEP_TYPE_OPTION_8',
  },
  {
    value: 'pep_relative_option9',
    label: 'PEP_TYPE_OPTION_9',
  },
  {
    value: 'pep_relative_option10',
    label: 'PEP_TYPE_OPTION_10',
  },
  {
    value: 'pep_relative_option11',
    label: 'PEP_TYPE_OPTION_11',
  },
];

export const expectedReturnOptions = [
  {
    value: 'expected_return_option1',
    label: 'EXPECTED_RETURN_OPTION_1',
  },
  {
    value: 'expected_return_option2',
    label: 'EXPECTED_RETURN_OPTION_2',
  },
  {
    value: 'expected_return_option3',
    label: 'EXPECTED_RETURN_OPTION_3',
  },
  {
    value: 'expected_return_option4',
    label: 'EXPECTED_RETURN_OPTION_4',
  },
];

export const returnTargetOptions = [
  {
    value: '3',
    label: '3%',
  },
  {
    value: '5',
    label: '5%',
  },
  {
    value: '7',
    label: '7%',
  },
  {
    value: '9',
    label: '9%',
  },
  {
    value: '11',
    label: '11%',
  },
  {
    value: '15',
    label: '15%',
  },
  {
    value: '20',
    label: '20%',
  },
];

export const investmentPhilosophyOptions = [
  {
    value: 'investment_philosophy_option1',
    label: 'INVESTMENT_PHILOSOPHY_OPTION_1',
  },
  {
    value: 'investment_philosophy_option2',
    label: 'INVESTMENT_PHILOSOPHY_OPTION_2',
  },
  {
    value: 'investment_philosophy_option3',
    label: 'INVESTMENT_PHILOSOPHY_OPTION_3',
  },
];

export const mediaInfluenceOptions = [
  {
    value: 'media_influence_option1',
    label: 'MEDIA_INFLUENCE_OPTION_1',
  },
  {
    value: 'media_influence_option2',
    label: 'MEDIA_INFLUENCE_OPTION_2',
  },
  {
    value: 'media_influence_option3',
    label: 'MEDIA_INFLUENCE_OPTION_3',
  },
  {
    value: 'media_influence_option4',
    label: 'MEDIA_INFLUENCE_OPTION_4',
  },
];

export const negativeNewsOptions = [
  {
    value: 'negative_news_option1',
    label: 'NEGATIVE_NEWS_OPTION_1',
  },
  {
    value: 'negative_news_option2',
    label: 'NEGATIVE_NEWS_OPTION_2',
  },
  {
    value: 'negative_news_option3',
    label: 'NEGATIVE_NEWS_OPTION_3',
  },
  {
    value: 'negative_news_option4',
    label: 'NEGATIVE_NEWS_OPTION_4',
  },
];

export const portfolioValueDropOptions = [
  {
    value: 'portfolio_value_drop_option1',
    label: 'PORTFOLIO_VALUE_DROP_OPTION_1',
  },
  {
    value: 'portfolio_value_drop_option2',
    label: 'PORTFOLIO_VALUE_DROP_OPTION_2',
  },
  {
    value: 'portfolio_value_drop_option3',
    label: 'PORTFOLIO_VALUE_DROP_OPTION_3',
  },
  {
    value: 'portfolio_value_drop_option4',
    label: 'PORTFOLIO_VALUE_DROP_OPTION_4',
  },
  {
    value: 'portfolio_value_drop_option5',
    label: 'PORTFOLIO_VALUE_DROP_OPTION_5',
  },
];

export const doubleValueDropOptions = [
  {
    value: 'double_value_drop_option1',
    label: 'DOUBLE_VALUE_DROP_OPTION_1',
  },
  {
    value: 'double_value_drop_option2',
    label: 'DOUBLE_VALUE_DROP_OPTION_2',
  },
  {
    value: 'double_value_drop_option3',
    label: 'DOUBLE_VALUE_DROP_OPTION_3',
  },
];

export const lowValueIncreaseOptions = [
  {
    value: 'low_value_increase_option1',
    label: 'LOW_VALUE_INCREASE_OPTION_1',
  },
  {
    value: 'low_value_increase_option2',
    label: 'LOW_VALUE_INCREASE_OPTION_2',
  },
  {
    value: 'low_value_increase_option3',
    label: 'LOW_VALUE_INCREASE_OPTION_3',
  },
  {
    value: 'low_value_increase_option4',
    label: 'LOW_VALUE_INCREASE_OPTION_4',
  },
];

export const portfolioEvaluationOptions = [
  {
    value: 'portfolio_evaluation_option1',
    label: 'PORTFOLIO_EVALUATION_OPTION_1',
  },
  {
    value: 'portfolio_evaluation_option2',
    label: 'PORTFOLIO_EVALUATION_OPTION_2',
  },
  {
    value: 'portfolio_evaluation_option3',
    label: 'PORTFOLIO_EVALUATION_OPTION_3',
  },
  {
    value: 'portfolio_evaluation_option4',
    label: 'PORTFOLIO_EVALUATION_OPTION_4',
  },
];

export const assetExperienceOptions = [
  {
    value: 'asset_experience_option1',
    label: 'ASSET_EXPERIENCE_OPTION_1',
  },
  {
    value: 'asset_experience_option2',
    label: 'ASSET_EXPERIENCE_OPTION_2',
  },
  {
    value: 'asset_experience_option3',
    label: 'ASSET_EXPERIENCE_OPTION_3',
  },
];

export const managementExperienceOptions = [
  {
    value: 'asset_experience_option1',
    label: 'MANAGEMENT_EXPERIENCE_OPTION_1',
  },
  {
    value: 'asset_experience_option2',
    label: 'MANAGEMENT_EXPERIENCE_OPTION_2',
  },
  {
    value: 'asset_experience_option3',
    label: 'MANAGEMENT_EXPERIENCE_OPTION_3',
  },
];

export const customersDecisionOptions = [
  {
    value: 'customers_decision_option1',
    label: 'CUSTOMERS_DECISION_OPTION_1',
  },
  {
    value: 'customers_decision_option2',
    label: 'CUSTOMERS_DECISION_OPTION_2',
  },
  {
    value: 'customers_decision_option3',
    label: 'CUSTOMERS_DECISION_OPTION_3',
  },
  {
    value: 'customers_decision_option4',
    label: 'CUSTOMERS_DECISION_OPTION_4',
  },
  {
    value: 'customers_decision_option5',
    label: 'CUSTOMERS_DECISION_OPTION_5',
  },
];

export const typeOfFeeOptions = [
  {
    value: 'type_of_fee_option1',
    label: 'TYPE_OF_FEE_OPTION_1',
  },
  {
    value: 'type_of_fee_option2',
    label: 'TYPE_OF_FEE_OPTION_2',
  },
  {
    value: 'type_of_fee_option3',
    label: 'TYPE_OF_FEE_OPTION_3',
  },
  {
    value: 'type_of_fee_option4',
    label: 'TYPE_OF_FEE_OPTION_4',
  },
];
