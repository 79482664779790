/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Form,
  Typography,
  Checkbox,
  Select,
  Menu as TagMenu,
  Dropdown,
  Space,
  // Radio
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { useTranslation } from 'react-i18next';

import MainButtonArray from '../../components/MainButtonArray';
import { Menu } from '../../assets';
// import Container from '../../components/Container';
import {
  getClient,
  getClientData,
  setClientData
} from '../../data/store/clients/clientActions';
import { ReactComponent as IconPlus } from './icon-plus.svg';
import './style.scss';
const { Title } = Typography;

const Test = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);
  const [purposeTags, setPurposeTags] = useState([]);
  const PAGE_DATA = 'purposeAndHeart';

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getClient({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('purposeAndHeart')) {
        dispatch(getClientData({ id, name: 'purposeAndHeart' }));
      }
    }
    setPurposeTags(user?.client_data?.purposeAndHeart?.data?.purposeTags ?? []);
  }, [user?.client_data]);

  const initialValues = {
    purposeOfInvestments: user?.client_data?.purposeAndHeart?.data?.purposeOfInvestments,
    clientViewOfInvestments: user?.client_data?.purposeAndHeart?.data?.clientViewOfInvestments,
    anyOtherAspects: user?.client_data?.purposeAndHeart?.data?.anyOtherAspects,
    purposeTags
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const optionsPurpose = [
    {
      label: `${t('PENSION_SAVINGS')}`,
      value: 'pensionSavings',
    },
    {
      label: `${t('INVESTMENT_TOWARDS_TARGET')}`,
      value: 'investmentTowardsTarget',
    },
    {
      label: `${t('INVESTMENT')}`,
      value: 'investment',
    },
    {
      label: `${t('OTHER')}`,
      value: 'other',
    },
  ];
  const handleAddTag = (tagKey) => {
    setPurposeTags([...purposeTags, tagKey]);
  };
  const handleDeleteTag = (tagKey) => {
    setPurposeTags(purposeTags.filter(item => item !== tagKey));
  };
  const tagMenu = (
    <TagMenu>
      <TagMenu.Item
        key='1'
        onClick={() => handleAddTag('SUSTAINABILITY')}
        className='purpose-tags-menu'
        disabled={purposeTags.includes('SUSTAINABILITY')}
      >
        {t('SUSTAINABILITY')}
      </TagMenu.Item>
      <TagMenu.Item
        key='2'
        onClick={() => handleAddTag('LOCAL')}
        className='purpose-tags-menu'
        disabled={purposeTags.includes('LOCAL')}
      >
        {t('LOCAL')}
      </TagMenu.Item>
      <TagMenu.Item
        key='3'
        onClick={() => handleAddTag('NEXT_GENERATION')}
        className='purpose-tags-menu'
        disabled={purposeTags.includes('NEXT_GENERATION')}
      >
        {t('NEXT_GENERATION')}
      </TagMenu.Item>
    </TagMenu>
  );

  const validateTest = (data) => {
    const mandatory = !!data?.purposeOfInvestments?.length && !!data?.clientViewOfInvestments;
    const fullFill = mandatory && !!data?.purposeTags.length && !!data?.anyOtherAspects;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const onSubmit = (fields) => {
    fields.purposeTags = purposeTags;
    const data = {
      type: PAGE_DATA,
      data: fields,
      full_status: validateTest(fields)
    };
    dispatch(setClientData({ id, fields: data }));
    history.push(`/dashboard/${id}`);
  };
  if (!user?.ID) {
    return <></>;
  }
  return (
    <>
      <Title className='onboard-component-title'>{t('PURPOSE_MATTERS')}</Title>
      <Form
        form={form}
        name='horizontal_login'
        layout='vertical'
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <div className='detail-container'>
          <div className='detail-header'>{t('PURPOSE_AND_HEARTA')}</div>
          <p className='purpose-title'>{t('WHAT_IS_THE_PURPOSE')}</p>
          <Form.Item
            className='purpose-checkbox-group'
            name='purposeOfInvestments'
          >
            <Checkbox.Group
              options={optionsPurpose}
              className='purpose-checkbox'
            />
          </Form.Item>
          <p className='purpose-title'>{t('DO_YOU_HAVE_ANY_MATTERS')}</p>
          <Dropdown
            placement='topLeft'
            overlay={tagMenu}
            trigger={['click']}
            className='purpose-tags-addbutton'
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <IconPlus />
                {t('ADD_DESCRIPTIVE_TAGS')}
              </Space>
            </a>
          </Dropdown>
          <div className='purpose-tags-wrapper'>
            {purposeTags.map((item, index) => (
              <p
                className='purpose-tags'
                key={index}
                onClick={() => handleDeleteTag(item)}
              >
                {t(`${item}`)}
              </p>
            ))}
          </div>
          <p className='purpose-title'>{t('WHICH_OF_BELOW_STATEMENTS')}</p>
          <Form.Item name='clientViewOfInvestments'>
            <Select placeholder={t('SELECT_OPTION')} type='select'>
              <Select.Option value='' disabled></Select.Option>
              <Select.Option value='first'>
                {t('MY_FOCUS_IN_INVESTMENTS')}
              </Select.Option>
              <Select.Option value='second'>
                {t('I_WANT_TO_INTEGRATE_SUSTAINABILITY')}
              </Select.Option>
              <Select.Option value='third'>
                {t('SUSTAINABILITY_IS_A_VERY_IMPORTANT')}
              </Select.Option>
            </Select>
          </Form.Item>
          <p className='purpose-title'>{t('ARE_THERE_ANY_OTHER_ASPECTS')}</p>
          <Form.Item name='anyOtherAspects'>
            <TextArea maxLength={200} />
          </Form.Item>
        </div>
        <div className='btn-container'>
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type='link'
            arrayIconStart
            onClick={() => history.goBack()}
          >
            {t('GO_BACK')}
          </MainButtonArray>
          <div className='menu-wrapper'>
            <Menu
              onClick={() => history.replace(`/dashboard/${id}`)}
              style={{ width: 85 }}
            />
            <p className='menu-title'>{t('MY_DETAILS')}</p>
          </div>
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type='submit'
            arrayIconEnd
          >
            {t('MOVE_ON')}
          </MainButtonArray>
        </div>
      </Form>
    </>
  );
};

export default Test;
