import React from 'react';

import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

const CategorySigning = ({ t }) => {
  const [form] = Form.useForm();

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ onCreate ~ data:', data);
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">{t('SIGNING')}</h2>
      </div>
      <Form
        form={form}
        name="onboarding-signing"
        layout="vertical"
        onFinish={onSubmit}
        className="sign-up-form"
      >
        <Form.Item name="signing_one" valuePropName="checked">
          <CustomCheckbox>{t('SIGNING_CHECK_ONE')}</CustomCheckbox>
        </Form.Item>
        <Form.Item name="signing_two" valuePropName="checked">
          <CustomCheckbox>{t('SIGNING_CHECK_TWO')}</CustomCheckbox>
        </Form.Item>
        <Form.Item name="signing_three" valuePropName="checked">
          <CustomCheckbox>{t('SIGNING_CHECK_THREE')}</CustomCheckbox>
        </Form.Item>
        <Form.Item name="signing_four" valuePropName="checked">
          <CustomCheckbox>{t('SIGNING_CHECK_FOUR')}</CustomCheckbox>
        </Form.Item>
        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategorySigning;

CategorySigning.propTypes = {
  t: PropTypes.func,
};
