import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { deleteFinPlan } from '../../data/store/clients/clientActions';
import { useHistory } from 'react-router-dom';

const ModalDeletePlan = ({
  userId, modalDeletePlan,
  setModalDeletePlan, delPlanId, setDelPlanId, redirect
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { finPlan } = useSelector((state) => state.clientReducer);

  const getFinPlanById = () => {
    return finPlan.find(i => i.id === delPlanId)?.name;
  };

  const handleCloseDelModal = () => {
    setDelPlanId(null);
    setModalDeletePlan(false);
  };

  const handleDelete = () => {
    dispatch(deleteFinPlan({ clientId: userId, finplanId: delPlanId }));
    setDelPlanId(null);
    setModalDeletePlan(false);
    redirect && history.replace(`/clients/${userId}/lifetiming`);
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalDeletePlan}
        onCancel={handleCloseDelModal}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_PLAN')}</p>
            <p className="modal-advisor-name">{getFinPlanById()}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={handleDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalDeletePlan;

ModalDeletePlan.propTypes = {
  userId: PropTypes.string,
  redirect: PropTypes.bool,
  modalDeletePlan: PropTypes.bool,
  setModalDeletePlan: PropTypes.func,
  delPlanId: PropTypes.number,
  setDelPlanId: PropTypes.func,
};
