import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useParams,
} from 'react-router-dom';
import { confirmEmail } from '../../data/store/user/userActions';
import { Row } from 'antd';
import GearLoading from '../Loading/GearLoading';
import './style.scss';

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const { hash } = useParams();
  const { t } = useTranslation('');
  const { isLoading, errorMessage } = useSelector(state => state.userReducer);

  useEffect(() => {
    dispatch(confirmEmail(hash));
  }, []);

  return (
    <GearLoading loading={isLoading}>

    <Row className='confirm-row'>
      {
        errorMessage
          ? <p className='confirm-error-message'>{t('CONFIRM_ERROR_MESSAGE')}</p>
          : <p className='confirm-success-message'>{t('CONFIRM_SUCCESS_MESSAGE')}</p>
      }
    </Row>
    </GearLoading>
  );
};

export default ConfirmEmail;
