import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ReactComponent as Forbiden } from '../assets/404-icon.svg';
import './style.scss';

const Forbidden = () => {
  const history = useHistory();
  const { t } = useTranslation('');

  const goHome = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <div className="ant-result ant-result-404 forbiden-wrapper">
      <Forbiden/>
      <h1 className='forbiden-title'>{t('PAGE_NOT_FOUND')}</h1>
      <Button className="modal-action-btn forbiden-btn" onClick={goHome}>{t('BACK_HOME')}</Button>
    </div>
  );
};

export default Forbidden;
