/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { twoDecimalWithSpaces } from '../../../helpers/formatFractional';
import dayjs from 'dayjs';
import _ from 'lodash';

import './style.scss';
// import { useTranslation } from 'react-i18next';

export const Bubble = (props) => {
  // const { t } = useTranslation('');

  const { data } = props;

  const chartData = (item) => {
    return ({
      x: typeof item.start_date === 'string'
        ? new Date(item.start_date).getTime()
        : dayjs(item.start_date).valueOf(),
      y: item?.necessity || item?.probability,
      z: item.amount,
      name: item.name
    });
  };

  const remapMonthlyData = (item) => {
    const diffYear = dayjs(item.end_date).diff(dayjs(item.start_date), 'year') + 1;
    const remapData = [];
    const yearEnd = dayjs(item.start_date).endOf('year');
    for (let i = diffYear; i > 0; i--) {
      const endMonthCount = dayjs(item.end_date).startOf('year').diff(dayjs(item.end_date), 'month');
      if (i === 1) {
        remapData.push({ ...item, start_date: item.end_date, amount: item.amount * (Math.abs(endMonthCount) + 1) });
        break;
      }
      if (i === diffYear) {
        const firstYearMonthCount = yearEnd.diff(dayjs(item.start_date), 'month') + 1;
        remapData.push({
          ...item,
          amount: item.amount * firstYearMonthCount,
          start_date: dayjs(yearEnd).format('YYYY-MM-DD')
        });
      }
      if (i < diffYear && i > 1) {
        remapData.push({
          ...item,
          amount: item.amount * 12,
          start_date: yearEnd.add(i - 1, 'year').format('YYYY-MM-DD')
        });
      }
    }

    const forChart = remapData.map(i => chartData(i));
    return forChart;
  };

  const remapAnnualData = (item) => {
    const start = new Date(item.start_date);
    const end = new Date(item.end_date);
    const diff = dayjs(end).diff(dayjs(start), 'year');
    const remapData = [item];
    if (diff) {
      for (let i = 0; i < diff; i++) {
        remapData.push({ ...item, start_date: dayjs(start).add(i + 1, 'year') });
      }
    }
    const forChart = remapData.map(i => chartData(i));
    return forChart;
  };

  const parsedData = data?.map(item => {
    return (
      item.frequency === 0
        ? (chartData(item))
        : item.frequency === 2
          ? remapAnnualData(item)
          : remapMonthlyData(item)
    );
  });
  const flatData = _.flatMapDeep(parsedData);
  console.log('🚀 ~ Bubble ~ flatData:', flatData);

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'bubble',
      plotBorderWidth: 1,
      zooming: {
        type: 'xy'
      }
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        stickyTracking: false,
      }
    },
    series: [
      {
        name: '',
        data: flatData,
        color: {
          radialGradient: {
            cx: 0.5, cy: 0.5, r: 0.5
          },
          stops: [
            [0, 'rgba(149, 73, 31, 0.25)'],
            [1, 'rgba(251, 123, 52, 0.25)']
          ]
        },
        states: {
          hover: {
            fillColor: {
              radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
              stops: [
                [0, 'rgba(149, 73, 31, 0.35)'],
                [1, 'rgba(251, 123, 52, 0.35)']
              ]
            }
          }
        },
      },
    ],
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      shared: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      useHTML: true,
      formatter: function () {
        return `<h3 class="bubble-toltip-title">${this.point?.name}</h3>` +
            `<p class="bubble-toltip-amount">${twoDecimalWithSpaces(this.point?.z)}kr</p>` +
            `<p class="bubble-toltip-date">${dayjs(this.point?.x).format('YYYY')}</p>`;
      },
      followPointer: false,
      outside: true,
      style: {
        zIndex: 9999
      },
    },
  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

Bubble.propTypes = {
  data: PropTypes.array,
};
