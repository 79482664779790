import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Button, Form, Row, Table } from 'antd';

import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { formatPercentage } from '../../helpers/formatPercentage';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { textSort } from '../../helpers/textSort';

import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';

import EditableCell from './EditableCell';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const ExcludeHoldings = ({ instrumentsForExclude, data, setData, handleDelete }) => {
  const { t } = useTranslation('');

  const [tableColumns, setTableColumns] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const availableInstrument = useMemo(() => {
    const array = instrumentsForExclude.filter(i => data?.length ? data?.every(data => i.ID !== data.ID) : true);

    return array;
  }, [data]);

  const getInstrumentInfo = (id) => {
    const instrument = instrumentsForExclude.find(i => i.ID === id);
    return instrument;
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        className: 'exclude-holdings exclude-name',
        dataIndex: 'ID',
        width: '20%',
        render: (value) => {
          return getInstrumentInfo(value)?.instrumentName;
        },
        sorter: (a, b) => textSort(getInstrumentInfo(a.ID)?.instrumentName, getInstrumentInfo(b.ID)?.instrumentName),
      },
      {
        title: t('ISIN'),
        className: 'exclude-holdings exclude-isin',
        dataIndex: 'ISIN',
        align: 'center',
        width: '11%',
        render: (_, record) => {
          return getInstrumentInfo(record.ID)?.ISIN;
        },
        sorter: (a, b) => textSort(getInstrumentInfo(a.ID)?.ISIN, getInstrumentInfo(b.ID)?.ISIN),
      },
      {
        title: t('NO_SHARES'),
        className: 'exclude-holdings exclude-unitsNumber',
        dataIndex: 'unitsNumber',
        align: 'right',
        width: '12%',
        render: (_, record) => {
          return getInstrumentInfo(record.ID)?.unitsNumber;
        },
        sorter: (a, b) => getInstrumentInfo(a.ID)?.unitsNumber - getInstrumentInfo(b.ID)?.unitsNumber,
      },
      {
        title: t('SHARE_PRICE'),
        className: 'exclude-holdings exclude-price',
        dataIndex: 'latest_value_sek',
        align: 'left',
        width: '11%',
        sorter: (a, b) => getInstrumentInfo(a?.ID)?.latest_value_sek - getInstrumentInfo(b?.ID)?.latest_value_sek,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => {
          return `${twoDecimalWithSpaces(getInstrumentInfo(record?.ID)?.latest_value_sek)} kr`;
        },
      },
      {
        title: t('VALUE'),
        className: 'exclude-holdings exclude-value',
        dataIndex: 'value',
        align: 'left',
        width: '13%',
        render: (_, record) => {
          return `${twoDecimalWithSpaces(getInstrumentInfo(record?.ID)?.value)} kr`;
        },
        sorter: (a, b) => getInstrumentInfo(a?.ID)?.value - getInstrumentInfo(b?.ID)?.value,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FEE'),
        className: 'exclude-holdings exclude-fee holdings-fee',
        dataIndex: 'fee',
        align: 'center',
        width: '9%',
        render: (_, record) => {
          const fee = getInstrumentInfo(record?.ID)?.fee;
          return (
            <p>
              {formatPercentage(fee) ||
                `${'0.00'.replace('.', decimalSeparator())} %`}
            </p>
          );
        },
        sorter: (a, b) => getInstrumentInfo(a?.ID)?.fee - getInstrumentInfo(b?.ID)?.fee,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        className: 'exclude-holdings exclude-assetclass',
        dataIndex: 'assetClass',
        width: '15%',
        sorter: (a, b) => textSort(a.assetClass, b.assetClass),
        render: (_, record) => {
          return (
            <>
              <p className="holdings-assetclass-cell-asset">{getInstrumentInfo(record?.ID)?.financialAsset?.name}</p>
              <p className="holdings-assetclass-cell-category">
                {getInstrumentInfo(record?.ID)?.financialAssetCategory?.name}
              </p>
            </>
          );
        },
      },
      {
        title: t('ACTIONS'),
        className: 'exclude-holdings exclude-operation',
        dataIndex: 'operation',
        align: 'center',
        width: '9%',
        render: (_, record) => (
          <>
            <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDelete(record.key)}
            />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [data, instrumentsForExclude]);

  const handleAdd = () => {
    const newData = {
      key: uuidv4(),
      ID: null,
    };

    data?.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleSave = (row) => {
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
    setEditingKey('');
  };

  const columns = tableColumns?.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        editing: isEditing(record),
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        availableInstrument,
        instrumentsForExclude
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Table
        className='exclude-holdings-table'
        components={components}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
      />
      <Row className='add-btn-wrapper' justify={'end'}>
        <Button
          type="text"
          className="holdings-add-btn"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </>
  );
};

export default ExcludeHoldings;

ExcludeHoldings.propTypes = {
  instrumentsForExclude: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
  handleDelete: PropTypes.func,
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
