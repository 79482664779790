import React, {
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import {
  getClient,
  getClientData,
  setClientData
} from '../../../data/store/clients/clientActions';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';
import './style.scss';
import { questionsOptions } from './selectData';

const { Title } = Typography;

const RiskProfilePartOne = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getClient({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('riskProfile1')) {
        dispatch(getClientData({ id, name: 'riskProfile1' }));
      }
    }
  }, [user?.client_data]);

  const riskSelectOption = (data) => {
    return (
    <Select.Option key={data.id} value={data.value} >{t(data.label)}</Select.Option>
    );
  };

  const initFieldsRisk = {
    riskQuestion_01: user?.client_data?.riskProfile1?.data?.riskQuestion_01 ?? '',
    riskQuestion_02: user?.client_data?.riskProfile1?.data?.riskQuestion_02 ?? '',
    riskQuestion_03: user?.client_data?.riskProfile1?.data?.riskQuestion_03 ?? '',
    riskQuestion_04: user?.client_data?.riskProfile1?.data?.riskQuestion_04 ?? '',
    riskQuestion_05: user?.client_data?.riskProfile1?.data?.riskQuestion_05 ?? '',
    riskQuestion_06: user?.client_data?.riskProfile1?.data?.riskQuestion_06 ?? '',
    riskQuestion_07: user?.client_data?.riskProfile1?.data?.riskQuestion_07 ?? '',
    riskQuestion_08: user?.client_data?.riskProfile1?.data?.riskQuestion_08 ?? '',
    riskQuestion_09: user?.client_data?.riskProfile1?.data?.riskQuestion_09 ?? '',
    comment: user?.client_data?.riskProfile1?.data?.comment ?? '',
  };
  const [fields, setFields] = useState(initFieldsRisk);

  useEffect(() => {
    setFields(initFieldsRisk);
  }, [user?.client_data]);

  const handleChangeSelect = (value, name) => {
    setFields({ ...fields, [name]: value });
  };
  const commentChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const validateFull = useMemo(() => {
    const validate = { ...fields };
    delete validate.comment;
    const resp = Object.values(validate).every(value => !!value);
    return resp;
  }, [fields]);

  const validateRiskOne = () => {
    const validate = { ...fields };
    delete validate.comment;
    const mandatory = Object.values(validate).every(value => !!value);
    const fullFill = mandatory && !!fields.comment;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'riskProfile1',
      data: fields,
      full_status: validateRiskOne()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push('./page-two');
  }, [history, fields]);
  return (
    <>
      <Title className='onboard-component-title'>
        {t('RISK_TOLERANCE')}
      </Title>
      <Container>
        <header className='risk-profile-header'>
          <h4 className='ekonomik-header'>{t('HOW_DO_YOU_VIEW_RISK')}</h4>
        </header>
        <p className='risk-profile-lable'>{t('WHAT_IS_THE_ANNUAL_EXPECTED')}</p>
        <Select
          className='risk-profile-select'
          type='select'
          name='riskQuestion_01'
          value={fields.riskQuestion_01}
          onChange={(value) => handleChangeSelect(value, 'riskQuestion_01')}
          >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_01.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('RETURN_TARGET')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_02'
        value={fields.riskQuestion_02}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_02')}
        >
          <Select.Option value='' disabled>{t('ENTER_THE_RETURN_TARGET')}</Select.Option>
          {questionsOptions.riskQuestion_02.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('WHICH_OF_THE_BELOW_BEST_DESCRIBES')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_03'
        value={fields.riskQuestion_03}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_03')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_03.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('TO_WHICH_EXTENT_DO_FINANCIAL')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_09'
        value={fields.riskQuestion_09}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_09')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_09.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('HOW_IS_YOUR_VIEW_OF_INVESTMENTS')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_04'
        value={fields.riskQuestion_04}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_04')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_04.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('ASSUME_THAT_YOUR_PORTFOLIO_TODAY')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_05'
        value={fields.riskQuestion_05}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_05')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_05.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('HOW_WOULD_YOUR_REACTION_BE_DIFFERENT')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_06'
        value={fields.riskQuestion_06}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_06')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_06.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('WHAT_WOULD_BE_YOUR_REACTION')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_07'
        value={fields.riskQuestion_07}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_07')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_07.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('DO_YOU_COMPARE_YOUR_PORTFOLIO')}</p>
        <Select
        className='risk-profile-select'
        type='select'
        name='riskQuestion_08'
        value={fields.riskQuestion_08}
        onChange={(value) => handleChangeSelect(value, 'riskQuestion_08')}
        >
          <Select.Option value='' disabled>{t('SELECT_OPTION')}</Select.Option>
          {questionsOptions.riskQuestion_08.map(item => {
            return riskSelectOption(item);
          })}
        </Select>
        <p className='risk-profile-lable'>{t('COMMENT_RISK_AND_RETURN')}</p>
        <TextArea
          type='text'
          name='comment'
          value={fields.comment}
          onChange={commentChange}
          maxLength={200}
        />
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
          disabled={!validateFull}
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

RiskProfilePartOne.propTypes = {
  id: PropTypes.string,
};

export default RiskProfilePartOne;
