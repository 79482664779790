import {
  CHANGE_EMAIL,
  CLEAR_CONFIRM_STEP,
  CONFIRM_AUTH,
  FORGOT_PASSWORD,
  GET_ADVISOR_BY_ID,
  GET_USER,
  GO_TO_CHANGE_EMAIL,
  IS_AUTH,
  LOG_OUT,
  LOGIN_USER,
  RESEND_EMAIL,
  RESET_USER_PASSWORD,
  SET_ADVISOR,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_PENDING,
  SIGN_UP_USER_REQUEST,
  SIGN_UP_USER_SUCCESS,
  SIMULATE_CLICK_ON_LINK,
  UPDATE_ADVISOR_EMAIL,
  UPDATE_ADVISOR_NAME,
  UPDATE_ADVISOR_PASSWORD,
  UPDATE_ADVISOR_PHONE,
  USER_PROFILE_UPDATE,
  GET_QR_BANKID,
  CLEAR_QR_BANKID,
  COLLECT_BANKID,
  GET_QR_SIGN_BANKID,
  CONFIRM_EMAIL,
  GET_TWOFACTOR_SECRET,
  GET_TWOFACTOR_CHECK,
  GET_QR_ATTACH_BANKID,
  GET_USER_BY_ID,
  CLEAR_CREATOR_FINPLAN
} from './userActionTypes';
import { CHANGE, CONFIRMATION, PENDING, SUCCESS } from '../../../constants/authStatus';
import { RESET_ERROR, SET_ERROR } from '../helpers/auxiliaryActionTypes';

const initialState = {
  advisor: { role: '', client_id: null },
  codeBankID: {
    order_ref: '',
    qrs: [],
    start_time: null
  },
  secret: '',
  collectBankID: {},
  token: '',
  user: null,
  creatorFinPlan: { firstName: '', lastName: '' },
  isLoading: false,
  isError: false,
  errorMessage: null,
  auth: {
    status: ''
  }
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case LOGIN_USER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        advisor: action.payload
      };

    case LOGIN_USER.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };

    case SIMULATE_CLICK_ON_LINK:
      return {
        ...state,
        forgotPassword: {
          status: CONFIRMATION
        }
      };

    case FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };

    case FORGOT_PASSWORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case CONFIRM_EMAIL.REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null
      };

    case CONFIRM_EMAIL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: null
      };

    case CONFIRM_EMAIL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        errorMessage: 'fail'
      };

    case CLEAR_CONFIRM_STEP:
      return {
        ...state,
        confirmStep: false
      };

    case RESET_USER_PASSWORD.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case RESET_USER_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        forgotPassword: {
          status: ''
        }
      };

    case RESET_USER_PASSWORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SIGN_UP_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SIGN_UP_USER_PENDING:
      return {
        ...state,
        isLoading: false,
        auth: {
          status: PENDING
        }
      };

    case SIGN_UP_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        auth: {
          status: ''
        }
      };

    case SIGN_UP_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_ADVISOR.SUCCESS:
      return {
        ...state,
        advisor: action.payload
      };

    case CONFIRM_AUTH.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case CONFIRM_AUTH.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        auth: {
          status: SUCCESS
        }
      };

    case CONFIRM_AUTH.ERROR:
      return {
        ...state,
        isError: true
      };

    case GET_USER.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_USER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload
      };

    case GET_USER.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case IS_AUTH:
      return {
        ...state,
        auth: {
          status: ''
        }
      };

    case GO_TO_CHANGE_EMAIL:
      return {
        ...state,
        auth: {
          status: CHANGE
        }
      };

    case CHANGE_EMAIL.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case CHANGE_EMAIL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        auth: {
          status: PENDING
        }
      };

    case CHANGE_EMAIL.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };

    case USER_PROFILE_UPDATE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case USER_PROFILE_UPDATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          ...action.payload
        }
      };

    case USER_PROFILE_UPDATE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case LOG_OUT:
      return {
        advisor: {},
        token: '',
        user: null,
        isLoading: false,
        isError: false,
        auth: {
          status: ''
        }
      };

    case GET_ADVISOR_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_ADVISOR_BY_ID.SUCCESS:
      return {
        ...state,
        advisor: action.payload,
        isLoading: false,
        isError: false
      };

    case GET_ADVISOR_BY_ID.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_ADVISOR_EMAIL.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_ADVISOR_EMAIL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        advisor: { ...state.advisor, email: action.payload.email }

      };

    case UPDATE_ADVISOR_EMAIL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_ADVISOR_NAME.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_ADVISOR_NAME.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        advisor: { ...state.advisor, profile: { ...state.advisor.profile, firstName: action.payload.firstName, lastName: action.payload.lastName } }
      };

    case UPDATE_ADVISOR_NAME.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_ADVISOR_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_ADVISOR_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        advisor: { ...state.advisor, profile: { ...state.advisor.profile, phone: action.payload.phone } }
      };

    case UPDATE_ADVISOR_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_ADVISOR_PASSWORD.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_ADVISOR_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };

    case UPDATE_ADVISOR_PASSWORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case RESEND_EMAIL.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case RESEND_EMAIL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };

    case RESEND_EMAIL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_QR_BANKID.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_QR_BANKID.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        codeBankID: action.payload
      };

    case GET_QR_BANKID.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_QR_SIGN_BANKID.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_QR_SIGN_BANKID.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        codeBankID: action.payload
      };

    case GET_QR_SIGN_BANKID.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_QR_ATTACH_BANKID.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_QR_ATTACH_BANKID.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        codeBankID: action.payload
      };

    case GET_QR_ATTACH_BANKID.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case COLLECT_BANKID.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case COLLECT_BANKID.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        collectBankID: action.payload
      };

    case COLLECT_BANKID.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case CLEAR_QR_BANKID.REQUEST:
      return {
        ...state,
        codeBankID: {
          order_ref: '',
          qrs: [],
          start_time: null
        }
      };

    case GET_TWOFACTOR_SECRET.REQUEST:
      return {
        ...state,
      };

    case GET_TWOFACTOR_SECRET.SUCCESS:
      return {
        ...state,
        isError: false,
        secret: action.payload
      };

    case GET_TWOFACTOR_SECRET.ERROR:
      return {
        ...state,
        isError: action.payload
      };

    case GET_TWOFACTOR_CHECK.REQUEST:
      return {
        ...state,
      };

    case GET_TWOFACTOR_CHECK.SUCCESS:
      return {
        ...state,
        isError: false,
      };

    case GET_TWOFACTOR_CHECK.ERROR:
      return {
        ...state,
        isError: action.payload
      };

    case GET_USER_BY_ID.REQUEST:
      return {
        ...state,
      };

    case GET_USER_BY_ID.SUCCESS:
      return {
        ...state,
        creatorFinPlan: action.payload?.profile,
        isError: false,
      };

    case GET_USER_BY_ID.ERROR:
      return {
        ...state,
        isError: action.payload
      };

    case CLEAR_CREATOR_FINPLAN.ACTION:
      return {
        ...state,
        creatorFinPlan: { firstName: '', lastName: '' },
        isError: false,
      };

    case SET_ERROR:
      return {
        ...state,
        isError: action.payload
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false
      };

    default: {
      return state;
    }
  }
};
