import React from 'react';

import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/i18n/i18n';

import store from './data/store/configureStore';

import AppRouting from './pages/Routing/AppRouting';
import '@fontsource/open-sans';
import '@fontsource/roboto';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';

function App () {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AppRouting />
      </Provider>
    </I18nextProvider>
  );
}

export default App;
