/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Slider,
} from 'antd';

import { ReactComponent as Array } from './img/array-icon.svg';
import { ReactComponent as Attached } from './img/attached.svg';
import { ReactComponent as DelImage } from './img/del-cross-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';

import { categoryList } from './data/itemsCategory';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import dayjs from 'dayjs';
import { patchFinPlan } from '../../data/store/clients/clientActions';

const { Header } = Layout;

const CategoryItem = ({ t, name, count, selected }) => {
  return (
    <div className={selected ? 'category-item selected' : 'category-item'}>
      <div className="category-colunm">
        <div className="category-checkbx" />
        <p className="category-name">{t(name)}</p>
      </div>
      <div className="category-colunm">
        {!!count && <div className="category-count">{count}</div>}
        <Array />
      </div>
    </div>
  );
};
const FinancialPlanningItem = ({ userId, finPlan }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const inputmage = useRef(null);

  const { search, pathname } = useLocation();

  const query = useQuery();
  const category = query.get('category');
  const key = query.get('key');
  const { finplanId, mode } = useParams();

  const getFinPlanData = useMemo(() => {
    const plan = finPlan?.find((i) => i.id === +finplanId);
    return plan;
  }, [finplanId, finPlan]);

  const getFinPlanItem = useMemo(() => {
    let planItem = {};
    if (mode === 'edit' && getFinPlanData) {
      planItem = getFinPlanData[category]?.find((i) => i.id === key);
      const editableData = {
        ...planItem,
        start_date: dayjs(planItem?.start_date),
        end_date: dayjs(planItem?.end_date),
      };
      return editableData;
    }
    return planItem;
  }, [getFinPlanData]);

  const [selectedCategory, setSelectedCategory] = useState();
  const [imageUploaded, setImageUploaded] = useState(null);
  const frequency = Form.useWatch('frequency', form);

  useEffect(() => {
    if (key && mode === 'edit' && finplanId) {
      const plan = getFinPlanItem;
      form.setFieldsValue(plan);
      const categoryItem = categoryList[category]?.find(
        (i) => i.value === plan.category
      );
      setSelectedCategory(categoryItem);
    }
  }, [key, mode, finplanId, getFinPlanData]);

  const selectOptions = [
    {
      value: '',
      label: t('CHOOSE_TYPE'),
      disabled: true,
    },
    {
      value: 2,
      label: t('ANNUAL'),
    },
    {
      value: 1,
      label: t('MONTHLY'),
    },
    {
      value: 0,
      label: t('ONE_TIME'),
    },
  ];

  const uploadImage = (event) => {
    const file = event.target.files[0];
    setImageUploaded(file);
  };

  function useQuery () {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (mode === 'new') {
      const init =
        category === 'income'
          ? { value: 'Salary', label: 'SALARY' }
          : category === 'expenses'
            ? { value: 'Housing', label: 'HOUSING' }
            : '';
      setSelectedCategory(init);
    }
  }, [category]);

  const getCount = (category, name) => {
    if (getFinPlanData) {
      const count =
        getFinPlanData[category]?.filter((i) => i.category === name)?.length ??
        0;
      return count;
    }
    return 0;
  };

  const disabledYear = (current) => {
    const startSelected = form.getFieldValue('start_date') || '';
    const start = current < dayjs().startOf('year');
    const end = current <= dayjs(startSelected).startOf('year');
    return { start, end };
  };

  const disabledMonth = (current) => {
    const startSelected = form.getFieldValue('start_date') || '';
    const start = current < dayjs().startOf('month');
    const end = current < dayjs(startSelected).startOf('month');
    return { start, end };
  };

  const handleSelectCategory = (value, label) => {
    mode !== 'edit' && setSelectedCategory({ value, label });
  };

  const handleClearUpload = () => {
    setImageUploaded(null);
    inputmage.current.value = '';
  };

  const handleSaveForm = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const currentPlan = finPlan.find((i) => i.id === +finplanId);
    let currentItems = currentPlan ? currentPlan[category] || [] : [];
    const oldIds = currentItems.map((i) => i.id);
    currentItems = currentItems.filter((i) => i.id !== key);
    const submitData = [
      ...currentItems,
      {
        id: key,
        ...data,
        category: selectedCategory.value,
        start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(data.end_date).format('YYYY-MM-DD'),
      },
    ];
    dispatch(
      patchFinPlan({
        history,
        clientId: userId,
        finplanId,
        body: { [category]: submitData },
        imageUploaded,
        oldIds,
        key,
      })
    );
    form.resetFields();
    setImageUploaded(null);
    if (mode === 'edit') { history.replace(`/clients/${userId}/lifetiming/${finplanId}`); }
  };

  const handleReset = () => {
    form.resetFields();
    setImageUploaded(null);
    history.replace(`/clients/${userId}/lifetiming/${finplanId}`);
  };

  return (
    <>
      <div className="instrument-wrapper">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  disabled={mode === 'edit'}
                  type="text"
                  className={`overview-btn ${
                    category === 'expenses' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() => history.push(`${pathname}?category=expenses`)}
                >
                  {t('EXPENSES')}
                </Button>
                <Button
                  disabled={mode === 'edit'}
                  type="text"
                  className={`overview-btn ${
                    category === 'income' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() => history.push(`${pathname}?category=income`)}
                >
                  {t('INCOME')}
                </Button>
              </Col>
            </Row>
          </Header>
          <div className="top-btn-wrapper">
            <Button
              // disabled={!needSave}
              className="portfolio-details-btn portfolio-export-btn"
              onClick={handleReset}
            >
              {t('CANCEL')}
            </Button>
          </div>
          <div className="finplan-item-wrapper">
            <div style={{ width: '50%' }}>
              <div className="finplan-item-wrap">
                <div className="finplan-category-wrapper">
                  <h2 className="finplan-category-header">
                    {category === 'income' ? t('INCOME') : t('EXPENSES')}
                  </h2>
                  <p className="finplan-category-description">
                    {t('ADD_ITEMS_NECESSARY_CATEGORY')}
                  </p>
                  <div className="finplan-category-list-wrapper">
                    {categoryList[category].map((i) => {
                      return (
                        <div
                          key={i.key}
                          style={{
                            cursor: mode === 'new' ? 'pointer' : 'not-allowed',
                          }}
                          onClick={() => handleSelectCategory(i.value, i.label)}
                        >
                          <CategoryItem
                            name={i.label}
                            count={getCount(category, i.value)}
                            selected={selectedCategory?.value === i.value}
                            t={t}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {selectedCategory && (
              <div className="finplan-category-form-wrapper">
                <h2 className="finplan-category-form-header">
                  {t(selectedCategory?.label)}
                </h2>
                <p className="finplan-category-form-description">
                  {t('WHAT_WANT_ACHIVE')}
                </p>
                <Form
                  form={form}
                  name="finplan-item"
                  layout="vertical"
                  onFinish={onSubmit}
                >
                  <Form.Item
                    label={t('ITEM_NAME')}
                    className="custom-required"
                    placeholder={t('ENTER_ITEM_NAME')}
                    name="name"
                    required={false}
                  >
                    <Input autoComplete="off" className="custom-input" />
                  </Form.Item>
                  {category === 'expenses' && (
                    <div className="image-upload-wrapper">
                      <input
                        ref={inputmage}
                        type="file"
                        accept={'image/*'}
                        className="item-img-upload"
                        onChange={uploadImage}
                      />
                      <Button
                        type="text"
                        className="finplan-item-add-btn"
                        onClick={() => inputmage.current.click()}
                        icon={<Pluse />}
                        iconPosition={'end'}
                      >
                        {t('ADD_ITEM')}
                      </Button>
                      {imageUploaded && (
                        <div className="name-upload-wrapper">
                          <Attached />
                          <span className="name-uploaded">
                            {imageUploaded?.name}
                          </span>
                          <DelImage
                            className="clear-uploaded"
                            onClick={handleClearUpload}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {category === 'expenses' && (
                    <Form.Item
                      label={t('DESCRIPTION')}
                      // className="custom-required"
                      placeholder={t('ENTER_DESCRIPTION')}
                      name="description"
                      required={false}
                    >
                      <Input.TextArea
                        classNames={{ textarea: 'finplan-item-description' }}
                      />
                    </Form.Item>
                  )}
                  {category === 'expenses' && (
                    <Form.Item
                      label={t('AMOUNT')}
                      className="custom-required"
                      name="amount"
                      required={false}
                    >
                      <CustomInputNumber
                        className="custom-inputnumber"
                        min={0}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={t('FREQUENCY')}
                    className="custom-required"
                    name="frequency"
                    required={false}
                  >
                    {/* annual, monthly, one time */}
                    <CustomSelect
                      className="custom-select"
                      placeholder={t('SELECT_OPTION')}
                      type="select"
                    >
                      {selectOptions.map((i) => {
                        return (
                          <Select.Option key={i.value} value={i.value}>
                            {t(i.label)}
                          </Select.Option>
                        );
                      })}
                    </CustomSelect>
                  </Form.Item>
                  <Row style={{ width: '360px' }} justify={'space-between'}>
                    <Col className="">
                      <Form.Item
                        label={t('START')}
                        className="custom-required finplan-datepicker-wrap"
                        name="start_date"
                        rules={[
                          {
                            required: true,
                            message: `${t('ENTER_START')}`,
                          },
                        ]}
                        required={false}
                      >
                        <DatePicker
                          disabled={!frequency && frequency !== 0}
                          picker={frequency === 2 ? 'year' : 'month'}
                          disabledDate={(current) =>
                            frequency === 2
                              ? disabledYear(current).start
                              : disabledMonth(current).start
                          }
                          placeholder={t('ENTER_START')}
                          className="finplan-datepicker"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="">
                      {frequency !== 0 && (
                        <Form.Item
                          label={t('END')}
                          className="custom-required finplan-datepicker-wrap"
                          name="end_date"
                          rules={[
                            {
                              required: true,
                              message: `${t('ENTER_END')}`,
                            },
                          ]}
                          required={false}
                        >
                          <DatePicker
                            disabled={!frequency}
                            picker={frequency === 2 ? 'year' : 'month'}
                            disabledDate={(current) =>
                              frequency === 2
                                ? disabledYear(current).end
                                : disabledMonth(current).end
                            }
                            placeholder={t('ENTER_END')}
                            className="finplan-datepicker"
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  {category === 'income' && (
                    <Form.Item
                      style={{ marginTop: '24px' }}
                      label={t('AMOUNT')}
                      className="custom-required"
                      name="amount"
                      required={false}
                    >
                      <CustomInputNumber
                        className="custom-inputnumber"
                        min={0}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                      />
                    </Form.Item>
                  )}
                  <div className="plan-renge-wrapper">
                    <p className="plan-renge-title plan-renge-necessity">
                      {t(category === 'expenses' ? 'NECESSITY' : 'PROBABILITY')}
                    </p>
                    <div className="range-wrap">
                      <p className="range-min-max fin-plan-range">0</p>
                      <Form.Item
                        className="custom-required plan-item-necessity"
                        name={
                          category === 'expenses' ? 'necessity' : 'probability'
                        }
                        required={false}
                      >
                        <Slider
                          classNames={{
                            rail: 'plan-slider-rail',
                            handle: 'plan-slider-handle',
                            track: 'plan-slider-track',
                          }}
                          min={0}
                          max={100}
                          tooltip={{
                            formatter: (value) => `${value}%`,
                          }}
                        />
                      </Form.Item>
                      <p className="range-min-max fin-plan-range">100</p>
                    </div>
                  </div>
                  {category === 'expenses' && (
                    <>
                      <div className="plan-renge-wrapper">
                        <p className="plan-renge-title plan-renge-necessity">
                          {t('PURPOSE')}
                        </p>
                        <div className="range-wrap">
                          <p className="range-min-max fin-plan-range">0</p>
                          <Form.Item
                            className="custom-required plan-item-necessity"
                            name="purpose"
                            required={false}
                          >
                            <Slider
                              classNames={{
                                rail: 'plan-slider-rail',
                                handle: 'plan-slider-handle',
                                track: 'plan-slider-track',
                              }}
                              min={0}
                              max={100}
                              tooltip={{
                                formatter: (value) => `${value}%`,
                              }}
                            />
                          </Form.Item>
                          <p className="range-min-max fin-plan-range">100</p>
                        </div>
                      </div>
                      <div className="plan-renge-wrapper">
                        <p className="plan-renge-title plan-renge-necessity">
                          {t('HAPPINESS')}
                        </p>
                        <div className="range-wrap">
                          <p className="range-min-max fin-plan-range">0</p>
                          <Form.Item
                            className="custom-required plan-item-necessity"
                            name="happiness"
                            required={false}
                          >
                            <Slider
                              classNames={{
                                rail: 'plan-slider-rail',
                                handle: 'plan-slider-handle',
                                track: 'plan-slider-track',
                              }}
                              min={0}
                              max={100}
                              tooltip={{
                                formatter: (value) => `${value}%`,
                              }}
                            />
                          </Form.Item>
                          <p className="range-min-max fin-plan-range">100</p>
                        </div>
                      </div>
                    </>
                  )}
                  <Button
                    type="text"
                    className="optimise-btn finplan-save"
                    onClick={handleSaveForm}
                  >
                    {t('SAVE')}
                  </Button>
                </Form>
              </div>
            )}
          </div>
          {/* {category === 'income' && <div>Category Income</div>
            }
            {category === 'expenses' && <div>Category Expenses</div>
            } */}
        </div>
      </div>
    </>
  );
};

export default FinancialPlanningItem;

FinancialPlanningItem.propTypes = {
  userId: PropTypes.string,
  finPlan: PropTypes.array,
};

CategoryItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  count: PropTypes.number,
  selected: PropTypes.bool,
};
