/* eslint-disable react/prop-types */
import React from 'react';

const Timer = ({ seconds }) => {
  return (
    <div
      style={{
        color: '#ffffff',
        fontSize: 14,
      }}
      data-testid={'timer'}
    >
      {seconds !== undefined
        ? `${Math.floor(seconds / 60)
            .toString()
            .padStart(2, '0')}:${String(Math.round(seconds % 60)).padStart(
            2,
            '0'
          )}`
        : '--:--'}
    </div>
  );
};

export default Timer;
