import React, { useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Select, Radio, Checkbox, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { v4 as uuidv4 } from 'uuid';

import Container from '../../components/Container';
import countryEN from '../../constants/countryEN.json';
import PrivateOwner from './PrivateOwner';
import CompanyOwner from './CompanyOwner';
import PersonsTogetherStandsControl from './PersonsTogetherStandsControl';

const CompanyKnowledge = (props) => {
  const { user, fieldsCompany, setFieldsCompany, hendleSubmit } = props;
  const { t } = useTranslation('');
  const [form] = Form.useForm();

  const initBeneficialOwner = {
    typeCompanyOwner: null,
    beneficialOwnerID: uuidv4(),
    personalNumberOwner: null,
    organizationNumberOwner: null,
    foreignOrganizationNumberOwner: null,
    isMissingPersonalNumber: null,
    isMissingOrganizationNumber: null,
    dateBirthOwner: null,
    firstNameOwner: null,
    secondNameOwner: null,
    nameOwningCompany: null,
    percentageOfVotes: null,
    countryOwner: [],
    countryTaxOwner: null,
    tinOwner: null,
    postalOwner: null,
    zipCodeOwner: null,
    cityOwner: null,
    postCountryOwner: null,
    countryOwningCompany: null,
    taxCountryOwningCompany: null,
    politicallyExposedPosition: null,
    hasControlsMore25Company: null,
    typeControlsMore25Company: null,
    ownersBeneficialOwner: [],
    hasNextOwner: null,
  };
  const initPersonsTogetherStandsControl = {
    personaID: null,
    roleInCompany: null,
    personalNumber: null,
    isMissingPersonalNumber: null,
    dateBirth: null,
    firstName: null,
    secondName: null,
    country: [],
    countryTax: null,
    tin: null,
    postal: null,
    zipCode: null,
    city: null,
    postCountry: null,
    politicallyExposedPosition: null,
  };

  const initFieldsCompany = {
    companyFrom:
      user?.client_data?.customerKnowledge?.data?.companyFrom ?? null,
    industry: user?.client_data?.customerKnowledge?.data?.industry ?? null,
    companyCountryTax:
      user?.client_data?.customerKnowledge?.data?.companyCountryTax ?? null,
    isFinancial:
      user?.client_data?.customerKnowledge?.data?.isFinancial ?? null,
    isActive: user?.client_data?.customerKnowledge?.data?.isActive ?? null,
    employees: user?.client_data?.customerKnowledge?.data?.employees ?? null,
    turnover: user?.client_data?.customerKnowledge?.data?.turnover ?? null,
    whereCompanyMoney:
      user?.client_data?.customerKnowledge?.data?.whereCompanyMoney ?? null,
    whereCompanyMoneyOther:
      user?.client_data?.customerKnowledge?.data?.whereCompanyMoneyOther ??
      null,
    hasAuditor: user?.client_data?.customerKnowledge?.data?.hasAuditor ?? null,
    hasControlsMore25:
      user?.client_data?.customerKnowledge?.data?.hasControlsMore25 ?? null,
    hasCoOwners:
      user?.client_data?.customerKnowledge?.data?.hasCoOwners ?? null,
    coOwnersCount:
      user?.client_data?.customerKnowledge?.data?.coOwnersCount ?? null,
    twoOrMoreRelatedParties:
      user?.client_data?.customerKnowledge?.data?.twoOrMoreRelatedParties ?? null,
    peopleControlVotes:
      user?.client_data?.customerKnowledge?.data?.peopleControlVotes ?? null,
    beneficialOwners: user?.client_data?.customerKnowledge?.data
      ?.beneficialOwners ?? [{}],
    personsTogetherStandsControl: user?.client_data?.customerKnowledge?.data
      ?.personsTogetherStandsControl ?? [],
  };

  useEffect(() => {
    setFieldsCompany(initFieldsCompany);
  }, [user?.client_data]);

  const handleChangeSelect = (value, name, type) => {
    form.resetFields();
    if (type === 'company') {
      setFieldsCompany({ ...fieldsCompany, [name]: value });
    }
  };

  const handleSetPersone = (value, name) => {
    const personsTogetherStandsControl = [];
    if (value !== 'More than 4') {
      for (let i = 0; i < +value; i++) {
        const data = { ...initPersonsTogetherStandsControl, personaID: uuidv4() };
        personsTogetherStandsControl.push(data);
      }
    }
    setFieldsCompany({ ...fieldsCompany, [name]: value, personsTogetherStandsControl });
  };

  const handleChangeRadio = (event, type) => {
    const { value, name } = event.target;
    let clearedText;
    if (type === 'company') {
      if (name === 'hasControlsMore25') clearedText = 'typeControlsMore25';
      let newData = { ...fieldsCompany, [name]: value === 'yes' };
      if (clearedText) newData = { ...newData, [clearedText]: null };
      setFieldsCompany(newData);
    }
  };

  const handleHasOwner = (event) => {
    const { value, name } = event.target;
    if (name === 'hasControlsMore25') {
      if (value === 'yes') {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
          beneficialOwners: [initBeneficialOwner],
        });
      } else {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
          beneficialOwners: [{}],
        });
      }
    }
    if (name === 'hasCoOwners') {
      if (value === 'yes') {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
        });
      } else {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
          coOwnersCount: null
        });
      }
    }
    if (name === 'twoOrMoreRelatedParties') {
      if (value === 'yes') {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
        });
      } else {
        setFieldsCompany({
          ...fieldsCompany,
          [name]: value === 'yes',
          peopleControlVotes: null,
          personsTogetherStandsControl: [],
        });
      }
    }
  };

  const handleTypeCompanyOwner = (value, name, index) => {
    form.resetFields();
    const newData = [...fieldsCompany.beneficialOwners];
    newData[index] = { ...newData[index], [name]: value };
    setFieldsCompany({ ...fieldsCompany, beneficialOwners: newData });
  };

  const addOwner = () => {
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: fieldsCompany.beneficialOwners.push(initBeneficialOwner),
    });
  };

  const deleteOwner = (ownerID, index) => {
    const newOwnerArray = fieldsCompany.beneficialOwners
      .filter(owner => owner.beneficialOwnerID !== ownerID)
      .map((elem, i) => i === index
        ? { ...elem, hasNextOwner: !((fieldsCompany.beneficialOwners.length - index) === 1) }
        : elem
      );
    setFieldsCompany({
      ...fieldsCompany,
      beneficialOwners: newOwnerArray,
    });
  };

  const isNotCupTotalPersent = () => {
    const total = fieldsCompany.beneficialOwners.reduce((previousValue, currentValue) => previousValue + currentValue.percentageOfVotes, 0);
    return total <= 75;
  };

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {t(countryEN[data])}
      </Select.Option>
    );
  };

  const whereCompanyMoneyOptions = [
    {
      label: `${t('PROFIT_FROM_BUSINESS')}`,
      value: 'Profit from business',
    },
    {
      label: `${t('COMPANY_SALES')}`,
      value: 'Company sales',
    },
    {
      label: `${t('SHARE_CAPITAL')}`,
      value: 'Share capital',
    },
    {
      label: `${t('INVESTMENTS')}`,
      value: 'Investments',
    },
    {
      label: `${t('MEMBERSHIP_FEES')}`,
      value: 'Membership fees',
    },
    {
      label: `${t('DONATIONS')}`,
      value: 'Donations',
    },
    {
      label: `${t('ALLOWANCE_2')}`,
      value: 'Allowance',
    },
    {
      label: `${t('OTHER_DESCRIBE')}`,
      value: 'Other (describe)',
    },
  ];
  const onChangeChekboxGroup = (value) => {
    if (!value.includes('Other (describe)')) {
      setFieldsCompany({
        ...fieldsCompany,
        whereCompanyMoneyOther: null,
      });
    }
    setFieldsCompany({
      ...fieldsCompany,
      whereCompanyMoney: value,
    });
  };
  const onChangeChekboxOther = (event) => {
    const { name, value } = event.target;
    setFieldsCompany({
      ...fieldsCompany,
      [name]: value,
    });
  };

  return (
    <>
      <Container>
        <header className='legal-header'>
          <h4 className='legal-header'>{t('ABOUT_THE_COMPANY')}</h4>
        </header>
        <p className='risk-profile-lable'>{t('WHAT_COMPANY_FORM')}</p>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={fieldsCompany?.companyFrom}
          onChange={(value) =>
            handleChangeSelect(value, 'companyFrom', 'company')
          }
        >
          <Select.Option value='Limited company/Corporation'>
            {t('LIMITED_COMPANY')}
          </Select.Option>
          <Select.Option value='Limited partnership'>
            {t('LIMITED_PARTNERSHIP')}
          </Select.Option>
          <Select.Option value='Sole proprietorship'>
            {t('SOLE_PROPRIETORSHIP')}
          </Select.Option>
          <Select.Option value='Public limited company in Sweden'>
            {t('PUBLIC_LIMITED_COMPANY')}
          </Select.Option>
          <Select.Option value='State/Region/Municipality'>
            {t('STATE_REGION')}
          </Select.Option>
          <Select.Option value='Economic association'>
            {t('ECONOMIC_ASSOCIATION')}
          </Select.Option>
          <Select.Option value='Non-profit association'>
            {t('NON_PROFIT_ASSOCIATION')}
          </Select.Option>
          <Select.Option value='Foundation/Trust'>
            {t('FOUNDATION_TRUST')}
          </Select.Option>
          <Select.Option value='Religious community'>
            {t('RELIGIOUS_COMMUNITY')}
          </Select.Option>
        </Select>
        <p className='risk-profile-lable'>
          {t('WHAT_INDUSTRY_DOES_THE_COMPANY')}
        </p>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={fieldsCompany?.industry}
          onChange={(value) => handleChangeSelect(value, 'industry', 'company')}
        >
          <Select.Option value='Construction/Infrastructure'>
            {t('CONSTRUCTION_INFRASTRUCTURE')}
          </Select.Option>
          <Select.Option value='Data/Technology/IT'>
            {t('DATA_TECHNOLOGY_IT')}
          </Select.Option>
          <Select.Option value='Operation/Maintenance'>
            {t('OPERATION_MAINTENANCE')}
          </Select.Option>
          <Select.Option value='Economics/Finance'>
            {t('ECONOMICS_FINANCE')}
          </Select.Option>
          <Select.Option value='Real estate'>{t('REAL_ESTATE')}</Select.Option>
          <Select.Option value='R&D/Science'>{t('RD_SCIENCE')}</Select.Option>
          <Select.Option value='Sales/Business development'>
            {t('SALES_BUSINESS_DEVELOPMENT')}
          </Select.Option>
          <Select.Option value='Hotel/Restaurant/Tourism'>
            {t('HOTEL_RESTAURANT_TOURISM')}
          </Select.Option>
          <Select.Option value='HR/Rekrytering'>
            {t('HR_REKRYTERING')}
          </Select.Option>
          <Select.Option value='Import/Export/Trade'>
            {t('IMPORT_EXPORT_TRADE')}
          </Select.Option>
          <Select.Option value='Law'>{t('LAW')}</Select.Option>
          <Select.Option value='Customer support/service'>
            {t('CUSTOMER_SUPPORT_SERVICE')}
          </Select.Option>
          <Select.Option value='Agriculture/Forestry'>
            {t('AGRICULTURE_FORESTRY')}
          </Select.Option>
          <Select.Option value='Management'>{t('MANAGEMENT')}</Select.Option>
          <Select.Option value='Logistics/Transportation'>
            {t('LOGISTICS_TRANSPORTATION')}
          </Select.Option>
          <Select.Option value='Marketing/Advertising'>
            {t('MARKETING_ADVERTISING')}
          </Select.Option>
          <Select.Option value='Healtcare'>{t('HEALTCARE')}</Select.Option>
          <Select.Option value='Writer/Publishing'>
            {t('WRITER_PUBLISHING')}
          </Select.Option>
          <Select.Option value='Security/Rescue service'>
            {t('SECURITY_RESCUE_SERVICE')}
          </Select.Option>
          <Select.Option value='Theology/Psychology/Philosophy'>
            {t('THEOLOGY_PSYCHOLOGY_PHILOSOPHY')}
          </Select.Option>
          <Select.Option value='Manufacturing/Production'>
            {t('MANUFACTURING_PRODUCTION')}
          </Select.Option>
          <Select.Option value='Education'>{t('EDUCATION')}</Select.Option>
        </Select>
        <p className='risk-profile-lable'>{t('COUNTRY_COMPANY_TAX')}</p>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={fieldsCompany?.companyCountryTax}
          onChange={(value) =>
            handleChangeSelect(value, 'companyCountryTax', 'company')
          }
        >
          <Select.Option value='Sweden'>{t('Sweden')}</Select.Option>
          {Object.keys(countryEN).map((item) => {
            return countrysOption(item);
          })}
        </Select>
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('COMPANY_FINANCIAL_INSTITUTION')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='isFinancial'
            onChange={(event) => handleChangeRadio(event, 'company')}
            value={
              fieldsCompany?.isFinancial === null
                ? ''
                : fieldsCompany?.isFinancial
                  ? 'yes'
                  : 'no'
            }
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('IS_COMPANY_ACTIVE_PASSIVE')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='isActive'
            onChange={(event) => handleChangeRadio(event, 'company')}
            value={
              fieldsCompany?.isActive === null
                ? ''
                : fieldsCompany?.isActive
                  ? 'yes'
                  : 'no'
            }
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('ACTIVE')}</Radio>
            <Radio value='no'>{t('PASSIVE')}</Radio>
          </Radio.Group>
        </div>
        <p className='risk-profile-lable'>{t('HOW_MANY_EMPLOYEES')}</p>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={fieldsCompany?.employees}
          onChange={(value) =>
            handleChangeSelect(value, 'employees', 'company')
          }
        >
          <Select.Option value='0'>0</Select.Option>
          <Select.Option value='1-9'>1-9</Select.Option>
          <Select.Option value='10-50'>10-50</Select.Option>
          <Select.Option value='51-99'>51-99</Select.Option>
          <Select.Option value='over 100'>{t('OVER_100')}</Select.Option>
        </Select>
        <p className='risk-profile-lable'>{t('TURNOVER_IN_THE_COMPANY')}</p>
        <Select
          placeholder={t('SELECT_OPTION')}
          className='risk-profile-select'
          type='select'
          value={fieldsCompany?.turnover}
          onChange={(value) => handleChangeSelect(value, 'turnover', 'company')}
        >
          <Select.Option value='Newly started company'>
            {t('NEWLY_STARTED_COMPANY')}
          </Select.Option>
          <Select.Option value='Less than 3 million SEK'>
            {t('LESS_THAN_3_MILLION')}
          </Select.Option>
          <Select.Option value='3-40 million SEK'>
            {t('MILLION_3_40')}
          </Select.Option>
          <Select.Option value='Over 40 million SEK'>
            {t('OVER_40_MILLION')}
          </Select.Option>
        </Select>
        <p className='risk-profile-lable'>
          {t('WHERE_DOES_THE_COMPANYS_MONEY')}
        </p>
        {fieldsCompany?.whereCompanyMoney && <Checkbox.Group
          defaultValue={fieldsCompany.whereCompanyMoney}
          onChange={onChangeChekboxGroup}
          options={whereCompanyMoneyOptions}
          className='purpose-checkbox'
        />}
        {fieldsCompany?.whereCompanyMoney?.includes('Other (describe)') && (
          <>
            <p className='risk-profile-lable'>{t('OTHER_DESCRIBE')}</p>
            <TextArea
              maxLength={200}
              className='saving-input'
              name='whereCompanyMoneyOther'
              value={fieldsCompany?.whereCompanyMoneyOther}
              placeholder={t('OTHER_DESCRIBE')}
              onChange={onChangeChekboxOther}
            />
          </>
        )}
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('COMPANY_HAVE_AUDITOR')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='hasAuditor'
            onChange={(event) => handleChangeRadio(event, 'company')}
            value={
              fieldsCompany?.hasAuditor === null
                ? ''
                : fieldsCompany?.hasAuditor
                  ? 'yes'
                  : 'no'
            }
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>
      </Container>
      <Container>
        <header className='legal-header'>
          <h4 className='legal-header'>{t('OWNERSHIP_STRUCTURE')}</h4>
        </header>
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('ANYONE_WHO_CONTROLS_MORE')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='hasControlsMore25'
            onChange={(event) => handleHasOwner(event)}
            value={
              fieldsCompany?.hasControlsMore25 === null
                ? ''
                : fieldsCompany?.hasControlsMore25
                  ? 'yes'
                  : 'no'
            }
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>

        {fieldsCompany?.hasControlsMore25 && (
          <>
            <Form
              id='owner'
              form={form}
              onFinish={hendleSubmit}
              name='owner'
              layout='vertical'
              style={{ marginTop: '16px' }}
            >
              {fieldsCompany?.beneficialOwners?.map((item, index) => (
                <Fragment key={item.beneficialOwnerID}>
                  <p className='risk-profile-lable'>{t('TYPE_OF_OWNER')}</p>
                  <Select
                    placeholder={t('SELECT_OPTION')}
                    className='risk-profile-select'
                    type='select'
                    value={item?.typeCompanyOwner}
                    onChange={(value) =>
                      handleTypeCompanyOwner(value, 'typeCompanyOwner', index)
                    }
                  >
                    <Select.Option value='Private individual'>
                      {t('INDIVIDUAL')}
                    </Select.Option>
                    <Select.Option value='Company'>
                      {t('COMPANY')}
                    </Select.Option>
                  </Select>
                  {item.typeCompanyOwner === 'Private individual' && (
                    <PrivateOwner
                      key={item.beneficialOwnerID}
                      index={index}
                      beneficialOwner={item}
                      fieldsCompany={fieldsCompany}
                      setFieldsCompany={setFieldsCompany}
                      addOwner={addOwner}
                      deleteOwner={deleteOwner}
                      form={form}
                      isNotCupTotalPersent={isNotCupTotalPersent}
                    />
                  )}
                  {item.typeCompanyOwner === 'Company' && (
                    <CompanyOwner
                      key={item.beneficialOwnerID}
                      index={index}
                      beneficialOwner={item}
                      fieldsCompany={fieldsCompany}
                      setFieldsCompany={setFieldsCompany}
                      addOwner={addOwner}
                      deleteOwner={deleteOwner}
                      form={form}
                      isNotCupTotalPersent={isNotCupTotalPersent}
                    />
                  )}
                </Fragment>
              ))}
            </Form>
          </>
        )}
        {fieldsCompany?.hasControlsMore25 &&
          <>
            <div className='saving-subtitle customer-subtitle'>
              <span>{t('CO_OWNERS_WHO_ARE_RELATED')}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Radio.Group
                name='hasCoOwners'
                onChange={(event) => handleHasOwner(event)}
                value={
                  fieldsCompany?.hasCoOwners === null
                    ? ''
                    : fieldsCompany?.hasCoOwners
                      ? 'yes'
                      : 'no'
                }
                className='saving-radio customer-radio'
              >
                <Radio value='yes'>{t('YES')}</Radio>
                <Radio value='no'>{t('No')}</Radio>
              </Radio.Group>
            </div>
            {fieldsCompany?.hasCoOwners &&
              <>
                <p className='risk-profile-lable'>{t('HOW_MANY_CO_OWNERS_OWNERS')}</p>
                <Select
                  placeholder={t('SELECT_OPTION')}
                  className='risk-profile-select'
                  type='select'
                  value={fieldsCompany?.coOwnersCount}
                  onChange={(value) =>
                    handleChangeSelect(value, 'coOwnersCount', 'company')
                  }
                >
                  <Select.Option value='2'>2</Select.Option>
                  <Select.Option value='3'>3</Select.Option>
                  <Select.Option value='4'>4</Select.Option>
                  <Select.Option value='More than 4'>{t('MORE_THAN_4')}</Select.Option>
                </Select>
              </>
            }
                {fieldsCompany?.coOwnersCount === 'More than 4' && (
                <div className='saving-subtitle customer-subtitle warning-text'>
                  <span>{t('NEED_DOWNLOAD_AND_FILL')}</span>
                </div>
                )}
          {isNotCupTotalPersent() &&
            <>
              <div className='saving-subtitle customer-subtitle'>
                <span>{t('ARE_THERE_TWO_OR_MORE_PARTIES')}</span>
              </div>
              <div style={{ display: 'flex' }}>
                <Radio.Group
                  name='twoOrMoreRelatedParties'
                  onChange={(event) => handleHasOwner(event)}
                  value={
                    fieldsCompany?.twoOrMoreRelatedParties === null
                      ? ''
                      : fieldsCompany?.twoOrMoreRelatedParties
                        ? 'yes'
                        : 'no'
                  }
                  className='saving-radio customer-radio'
                >
                  <Radio value='yes'>{t('YES')}</Radio>
                  <Radio value='no'>{t('No')}</Radio>
                </Radio.Group>
              </div>
            </>
          }
          {fieldsCompany?.twoOrMoreRelatedParties &&
            <>
              <p className='risk-profile-lable'>{t('HOW_MANY_PEOPLE_CONTROL_VOTES')}</p>
              <Select
                placeholder={t('SELECT_OPTION')}
                className='risk-profile-select'
                type='select'
                value={fieldsCompany?.peopleControlVotes}
                onChange={(value) =>
                  handleSetPersone(value, 'peopleControlVotes')
                }
              >
                <Select.Option value='2'>2</Select.Option>
                <Select.Option value='3'>3</Select.Option>
                <Select.Option value='4'>4</Select.Option>
                <Select.Option value='More than 4'>{t('MORE_THAN_4')}</Select.Option>
              </Select>
              {
                !!fieldsCompany?.peopleControlVotes && fieldsCompany?.peopleControlVotes !== 'More than 4' && (
                  fieldsCompany?.personsTogetherStandsControl?.map((item, index) => (
                  <PersonsTogetherStandsControl
                      key={item.personaID}
                      index={index}
                      persona={item}
                      fieldsCompany={fieldsCompany}
                      setFieldsCompany={setFieldsCompany}
                      form={form}
                    />
                  )))
              }
              {fieldsCompany?.peopleControlVotes === 'More than 4' &&
                <div className='saving-subtitle customer-subtitle warning-text'>
                  <span>{t('NEED_DOWNLOAD_AND_FILL')}</span>
                </div>
              }
            </>

            }
          </>
        }
      </Container>
    </>
  );
};

CompanyKnowledge.propTypes = {
  user: PropTypes.object,
  fieldsCompany: PropTypes.object,
  setFieldsCompany: PropTypes.func,
  hendleSubmit: PropTypes.func,
};

export default CompanyKnowledge;
