/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowUp } from '../../assets/arrow-lefup-green.svg';

import { ReactComponent as PortfolioUnselected } from '../../assets/portfolio-unselected.svg';
import { ReactComponent as PortfolioSelected } from '../../assets/portfolio-selected.svg';
import { fractionalWithSpaces } from '../../helpers/formatFractional';
import { useTranslation } from 'react-i18next';

const PortfolioListItem = ({ item, selectedRowKeys, setSelectedRowKeys, deprecated, is_locked }) => {
  const { t } = useTranslation('');
  const { name, total_value, portfolio_code } = item;

  const isSelecte = useMemo(() => {
    return selectedRowKeys?.includes(item?.ID);
  }, [selectedRowKeys, item]);

  const setItemSelected = () => {
    if (selectedRowKeys?.includes(item?.ID)) {
      setSelectedRowKeys(selectedRowKeys.filter(i => i !== item?.ID));
    } else {
      setSelectedRowKeys([...selectedRowKeys, item?.ID]);
    }
  };

  return (
      <div
        onClick={setItemSelected}
        className={`portfolio-card ${!isSelecte ? 'unselected' : ''}`}>
        <p className='portfolio-card-icons'>
          {isSelecte ? <PortfolioSelected /> : <PortfolioUnselected/>}
        </p>
        <p className='portfolio-card-name'>{name}</p>
        <p className='portfolio-card-code'>
          {portfolio_code}
          {deprecated
            ? <span className='portfolio-card-deprecated'>{t('IN_EXECUTION')}</span>
            : is_locked
              ? <span className='portfolio-card-deprecated'>{t('LOCKED')}</span>
              : null}
        </p>
        <p className='portfolio-card-value'>{`${fractionalWithSpaces(total_value)} SEK`}</p>
        <p className='portfolio-card-string'>
          <ArrowUp />
          <span className='portfolio-card-string-dig'>+5.50%</span>
          <span className='portfolio-card-string-text'> than last month</span>
        </p>
      </div>
  );
};

export default PortfolioListItem;

PortfolioListItem.propTypes = {
  item: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  deprecated: PropTypes.bool,
  is_locked: PropTypes.bool,
};
