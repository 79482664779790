export const InstrumentsEN = {
  SORT_BY_NAME: 'Sort by name',
  SORT_BY_CREATION_DATE: 'Sort by creation date',
  DELETE_INSTRUMENT: 'Delete instrument',
  CONNECT_INSTRUMENT: 'Connect instrument',
  EDIT_INSTRUMENT: 'Edit instrument',
  SURE_TO_CANCEL: 'Are you sure you want to cancel?',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  ERROE_TEXT_MODAL: 'Update the portfolio or delete the request:',
  LINK_EDIT_PORTFOLIO: 'Link to edit portfolio',
  CONNECT: 'Connect',
  MAPPING_TO: 'to',
  TICKER: 'Ticker',
  DIVERSIFICATION: 'Diversification',
};
