/*eslint-disable*/
import { useEffect } from 'react';
import { notification } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {RESET_ERROR, RESET_NOTIFICATION} from '../../data/store/helpers/auxiliaryActionTypes';

const SuccessNotification = () => {
  const dispatch = useDispatch();
  const notificationMessage = useSelector(state => state.portfolioReducer.notification);

  const openNotification = (placement, type, message, ...rest) => {
    notification[type]({
      message: message,
      description: rest.map(message => message),
      placement
    });
  };

  useEffect(() => {
    if(notificationMessage?.success) {
      openNotification('bottomRight', 'success', 'Success', notificationMessage.success);
      setTimeout(() => dispatch({ type: RESET_NOTIFICATION }), 1000);
    }
    if(notificationMessage?.info){
      openNotification('bottomRight', 'info', 'Info', notificationMessage.info);
      setTimeout(() => dispatch({ type: RESET_NOTIFICATION }), 1000);
    }
  }, [notificationMessage?.success, notificationMessage?.info]);

  return null;
};

export default SuccessNotification;
