import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, DatePicker, Divider } from 'antd';
import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';
import { ReactComponent as Copy } from '../../assets/copy-icon.svg';
import { ReactComponent as Hour } from '../../assets/hour-icon.svg';
import './style.scss';
import { rescheduleOnboarding } from '../../data/store/clients/clientActions';
import ModalReschedule from './ModalReschedule/ModalReschedule';

const OnboardingPlanningComplete = ({ userId, url }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { onbording } = useSelector((state) => state.clientReducer);
  const [date, setDate] = useState(null);
  const [modalRescheduleVisible, setModalRescheduleVisible] = useState(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  const meetingStart = onbording?.start_date;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = dayjs(meetingStart).tz(userTimezone);

  const formattedDate =
    startDate.format('dddd MMM D, YYYY [⋅] h:mm A') +
    ' – ' +
    startDate.add(1, 'hour').format('h:mm A') +
    ` (${startDate.format('z')} - ${userTimezone})`;

  const [copySuccess, setCopySuccess] = useState(false);

  const guest = useMemo(() => {
    const guest = onbording?.members?.split(' ');
    return guest;
  }, [onbording]);

  const handleStartScript = () => {
    history.push(`${url}/script`);
  };
  const handleStartRecord = () => {
    console.log('Record started');
    history.push(`${url}/script?category=recorder`);
    
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(onbording?.meeting_link)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch((err) => {
        console.log('🚀 ~ handleCopy ~ err:', err);
        setCopySuccess(false);
      });
  };

  const handleReschedule = (value) => {
    if(onbording?.type === 'offline') {
      console.log('Selected Time: ', dayjs(value).format());
      dispatch(rescheduleOnboarding({ clientId: userId, id: onbording.id, start_date: dayjs(value).format() }));
      setDate(null);
    } else {
      setModalRescheduleVisible(value);
    }
  };

  const handleModalRescheduleClose = () => {
    setDate(null);
    setModalRescheduleVisible(null);
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div style={{ padding: '35px 24px', height: '100%' }}>
        <Container widthP={100} height={'100%'} padding="24px 0">
          <div className="onbording-card-wrapper">
            <div className="onbording-card card-left">
              <div className="onbording-step-wrapper">
                <div className="onbording-card-number">3</div>
                <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
                <p className="onbording-card-description">
                  {t('PLEASE_USE_SCRIPT')}
                </p>
              </div>
            </div>
            <div className="onbording-card card-right">
              {onbording?.type === 'online'
                ? (
                <div className="card-right-wrap">
                  <div style={{ width: '327px', margin: '0 auto' }}>
                    <h2 className="onbording-card-header complet-step">
                      {t('YOUR_MEETING_DETAILS')}
                    </h2>
                    <p className="onbording-card-subtitle">
                      {t('MEETING_LINK')}
                    </p>
                    <p className="onbording-card-meeting-link">
                      <a
                        href={onbording?.meeting_link}
                        target="_blank"
                        className=""
                        rel="noreferrer"
                      >
                        {onbording?.meeting_link}
                      </a>
                      {!copySuccess
                        ? (
                        <Copy onClick={handleCopy} />
                          )
                        : (
                        <span className="onbording-card-copied">
                          {t('COPIED')}
                        </span>
                          )}
                    </p>
                    <p className="onbording-card-subtitle">{t('WHEN')}</p>
                    <p className="onbording-card-meeting-link">
                      {formattedDate}
                    </p>
                    <p className="onbording-card-subtitle">{t('GUESTS')}</p>
                    {guest?.map((guest, index) => {
                      return (
                        <p key={index} className="onbording-card-meeting-link">
                          {guest}
                        </p>
                      );
                    })}
                    <DatePicker
                      name="newMeetingDate"
                      className="date-picker-btn"
                      showTime={{ format: 'HH:mm', secondStep: 60 }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder={t('RESCHEDULE')}
                      suffixIcon={<Picker />}
                      onChange={(value) => handleReschedule(value)}
                      value={date}
                    />
                  </div>
                  <Button
                    className="optimise-btn start-script-btn"
                    onClick={handleStartScript}
                  >
                    {t('SCRIPT')}
                  </Button>
                </div>
                  )
                : onbording?.type === 'offline' || onbording?.type === ''
                  ? (
                    <div className="card-right-wrap scheduled-meeting">
                      <div style={{ width: '100%', margin: '0 auto' }}>
                        <h2 className="onbording-card-header complet-step">
                          {t('YOUR_SCHEDULED_MEETING')}
                        </h2>
                        <p className="onbording-card-subtitle">
                          {t('MEETING_DETAILS')}:
                        </p>
                        <p className="onbording-card-subtitle offline-meeting"><Picker />{dayjs(onbording?.start_date ?? '').format('dddd MMM D, YYYY')}</p>
                        <p className="onbording-card-subtitle offline-meeting hour"><Hour />{dayjs(onbording?.start_date ?? '').format('HH:mm')}</p>
                        <Button
                          className="optimise-btn start-script-btn record-start-btn"
                          onClick={handleStartRecord}>
                          {t('START_RECORDING')}
                        </Button>
                        <Divider className="custom-divider">{t('OR')}</Divider>
                        <div className='reschedule-wrapper'>
                          <DatePicker
                            name="newMeetingDate"
                            className="date-picker-btn"
                            showTime={{ format: 'HH:mm', secondStep: 60 }}
                            format="YYYY-MM-DD HH:mm"
                            placeholder={t('RESCHEDULE')}
                            suffixIcon={<Picker />}
                            onChange={(value) => handleReschedule(value)}
                            value={date}
                          />
                        </div>
                      </div>
                    </div>
                    )
                  : null}
            </div>
          </div>
        </Container>
      </div>
      <ModalReschedule
        clientId={userId}
        modalRescheduleVisible={modalRescheduleVisible}
        handleModalRescheduleClose={handleModalRescheduleClose}
        oldTime={onbording?.start_date}              
        id={onbording?.id}              
      />
      <MainFooter />
    </div>
  );
};

export default OnboardingPlanningComplete;

OnboardingPlanningComplete.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
};
