export const AuthEN = {
  LOG_IN: 'Login',
  WARNING_MESSAGE: 'Never give out your login details and never log in at the request of someone else',
  WELCOME_TO_QUANT: 'Welcome to Quant',
  DO_NOT_HAV_AN_ACCOUNT: ' Do not have an account?',
  REGISTER_NOW: ' Register now!',
  SUBMIT: 'Submit',
  NEWSROOM: 'Newsroom',
  ABOUT_US: 'About us',
  OUR_SERVICES: 'Our Services',
  CAREER: 'Career',
  WHAT_IS_QUANT: 'What is Quant?',
  CONTACT: 'Contact',
  LEGAL_INFORMATION: 'Legal information',
  PRIVACY_POLICY: 'Privacy Policy',
  BECOME_A_PART: 'Become a part of our advisory corps',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  PHONE: 'Phone',
  CONFIRM_PASSWORD: 'Confirm password',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password',
  SET_USER_PASSWORD: 'Set user\'s password',
  TWO_FACTOR: 'Verify you 2FA',
  EMAIL: 'Email',
  ENTER_EMAIL: 'Enter your email',
  PASSWORD: 'Password',
  ENTER_PASSWORD: 'Enter your password',
  SHOW_PASSWORD: 'Show password',
  TERM_AND_CONDITIONS: 'Terms and conditions agreement',
  USER_TERM_AND_CONDITIONS: 'Use terms and conditions agreement',
  AGREEMENT_TEXT: 'I have read, understood and agree to the above terms and conditions',
  REGISTER: 'Register',
  ROLE: 'Role*',
  ADVISOR: 'Advisor',
  ADVISORS: 'ADVISORS',
  ADMIN: 'Admin',
  CLIENT: 'Client',
  CONFIRMATION_EMAIL_TEXT: 'You have received a confirmation email to the email address you provided, please confirm your email to proceed',
  CHANGE_EMAIL: 'Change email address',
  SEND_CONFIRMATION_EMAIL: 'Send confirmation email again',
  SIMULATION_CONFIRM_REGISTRATION: 'Simulation confirm registration',
  REGISTRATION: 'Registration',
  SEND_EMAIL: 'Send email',
  BACK_TO_LOGIN: 'Back to the login',
  Are_YOU_A_NEW_CUSTOMER: 'Are you a new customer',
  SENT_INSTRUCTION_MESSAGE: 'We have sent instructions for resetting your password',
  NO_EMAIL_FROM_US: 'No email from us',
  CHECK_YOUR_SPAM: 'Check your spam folder',
  CHECK_YOUR_SPELLING: 'Check your email spelling',
  WAIT_AND_TRY_AGAIN: 'Wait 15 minutes and try again',
  SIMULATE_SEND_MESSAGE: 'Simulate send message',
  YOU_NEW_PASSWORD: 'Your new password',
  CHANGE_PASSWORD: 'Change password',
  SET_UP_PASSWORD: 'Set up password',
  YOU_ARE_DONE: 'You are done',
  THANKS_FOR_APPLYING: 'Thanks for applying for membership. As soon as your application has been reviewed and approved you will receive an email confirming your access status and grant or deny you access as a Client/Advisor',
  GO_TO_HOME_PAGE: 'Go to home page',
  CLIENTS: 'CLIENTS',
  SUPPORT: 'SUPPORT',
  SEARCH: 'SEARCH',
  SEARCH_LC: 'Search',
  LOG_OUT: 'Logout',
  SELECT_PERSON: 'Select a person',
  CREATE_NEW_CLIENT: 'Create new client',
  CHOSE_ACCOUNT_TEXT: 'Choose whether to create an alias account or a complete account',
  ALIAS_ACCOUNT: 'Alias account',
  COMPLETE_ACCOUNT: 'Complete account',
  MOVE_ON: 'Move on',
  CREATE_CLIENT_ACCOUNT: 'Create New Client',
  NAME: 'Name',
  NEED_SUPPORT: 'Need support',
  PLEASE_CONTACT_CITRONEER_ADMIN: 'Please contact Citroneer Admin',
  MY_PROFILE: 'My profile',
  PROFILE: 'Profile',
  SAVE: 'Save',
  INSTRUMENTS: 'INSTRUMENTS',
  MOBILE: 'Mobile',
  MY_CLIENTS: 'Clients',
  AWAITING_APPROVAL: 'Awaiting approval',
  USER_NOT_ACTIVE: 'User not active',
  CONFIRM_ERROR_MESSAGE: 'Sorry, you were unable to verify your email.',
  CONFIRM_SUCCESS_MESSAGE: 'Your email has been successfully verified!',
  VERIFY: 'Verify',
  VERIFY_YOUR_TWO_FACTOR: 'Verify your two-factor authentication (2FA) settings',
  AUTHENTICATION_CODE: 'Authentication code',
  CODE_FOOTER: 'Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code.',
  BACK_TO_CITRONEERS: 'Back to Citroneer Home page',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  LOGIN_WITH: 'Login with BankID',
  DONT_HAVE_ACCOUNT: 'Dont have an account?',
  SIGN_UP_NOW: 'Sign up now',
  ASSET_UNIVERSE: 'Asset Universe',
  ADVISORS_LC: 'Advisors',
  SUPPORT_LC: 'Support',
  NOT_ACTIVE: 'Not active',
  NOT_ACTIVATED_YET: 'Not activated yet',
  DEACTIVATE: 'Deactivate',
  DONT_HAVE_AN_ACCOUNT: 'Dont have an account?',
  INSTRUCTIONS_HAS_BEEN_SENT: 'Instructions has been sent',
  INSTRUCTIONS_HAS_BEEN_SENT_TEXT: 'We have sent instructions for resetting your password to:',
  CONFIRM_YOUR_EMAIL: 'Confirm your email',
  CONFIRM_YOUR_EMAIL_TEXT: 'You have received a confirmation email to the email address you provided, please confirm your email to proceed',
  CONFIRM_BANKID: 'Confirm BankID',
  PAGE_NOT_FOUND: 'Page not found',
  BACK_HOME: 'Back Home',
};
