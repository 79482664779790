/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';
import { implementePortfolio } from '../../data/store/clients/clientActions';

const ModalImplemente = ({
  openModalImplemente,
  closeModalImplemente,
  executed,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.clientReducer);

  const getCandidate = () => {
    const candidat = executed?.find((i) => i.id === openModalImplemente);
    return candidat;
  };

  const handleImplemente = () => {
    const data = {
      callBack: closeModalImplemente,
      candidatId: openModalImplemente,
      portfolioId: getCandidate()?.portfolio_id
    };
    dispatch(implementePortfolio(data));
  };

  return (
    <Modal
      width={650}
      className="modal-translation"
      open={openModalImplemente}
      onCancel={closeModalImplemente}
      destroyOnClose={true}
      zIndex={1600}
      footer={null}
    >
      <Row justify="center">
        <Col className="modal-title">{t('CONFIRM_IMPLEMENTE')}</Col>
      </Row>
      <Row justify="center" className="modal-text-wrapper">
        <Col>
          <p className="modal-advisor-text">{t('WANT_ORDER_IMPLEMENTE')}</p>
          <p className="modal-advisor-name">{getCandidate()?.name}</p>
        </Col>
      </Row>
      <Row className="modal-advisor-btn-wrapper">
        <Button
          disabled={isLoading}
          className="modal-action-btn cancel-btn"
          onClick={closeModalImplemente}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={isLoading}
          className="modal-action-btn save-btn"
          onClick={handleImplemente}
        >
          {t('IMPLEMENTE')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalImplemente;

ModalImplemente.propTypes = {
  executed: PropTypes.array,
  openModalImplemente: PropTypes.number,
  closeModalImplemente: PropTypes.func,
};
